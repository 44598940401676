function PulseSPSlipMode()
{
    BaseSPSlipMode.call(this);

    this.Deposit = 0;
    this.ToWins = [];
    this.StakeUpdatedByUser = [];
    this.ActivationRank = 10;

    this.Order = 10000;
    this.ID = "pulse";
    this.PurchaseTypeID = SPPurchaseType.PulseBet;
    this.eventsManager = new EventsManager();
    this.eventsManager.addEventListener("onFreePulseBetSelect", this, this.setSelectedFreeBets.bind(this), true);
    this.eventID = -1;
    this.selectedFreeBets = [];
}

$.extend(PulseSPSlipMode.prototype, BaseSPSlipMode.prototype,
    {
        activate: function ()
        {
            this.Active = true;
			BetSlip.OnPurchaseUpdated["PulseSPSlipMode"] = this.onPurchaseUpdated.bind(this);
        },

        serialize: function ()
        {
            return { ID: this.ID };
        },

        deserialize: function (data)
        {

        },

        selectionUpdated: function (item, initcall)
        {

        },

        selectionRemoved: function (item, initcall)
        {

        },

        canPlaceBet: function ()
        {
            if (this.Deposit > 0) return true;
            return false;
        },

        clear: function ()
        {

        },

        dispatchError: function (errorMessage)
        {
            this.eventsManager.dispatchEvent("onPulseBetPlacementResult", { status: CONSTANTS.BetPlacementStatus.Error, eventId: this.eventID, errorMessage: errorMessage }, true);
        },

        setDeposit: function (viewKey, deposit)
        {
            if (typeof deposit === "string")
            {
                deposit = BetSlip.SanitizeNumberString(deposit);
            }
            if (isNaN(deposit))
            {
                deposit = 0;
            }

            this.Deposits[viewKey] = deposit;
        },

        sendonPulseBetWaitingResponse: function ()
        {
            this.eventsManager.dispatchEvent("onPulseBetWaitingResponse", -1, true);
        },

        sendonBonusMoneyBetting: function ()
        {
            this.eventsManager.dispatchEvent("onBonusMoneyBetting", "", true);
        },

        getNumberOfBets: function ()
        {
            return 1;
        },

        getTotalGain: function ()
        {
            BetSlip.Selections[BetSlip.Selections.length - 1] && BetSlip.Selections[BetSlip.Selections.length - 1].getGain(this.Deposit);
        },
        placeBets: function (eventTypeId)
        {
			if (BaseSPSlipMode.prototype.isGeolocationInProgress.call(this))
			{
				return;
			}

            if (!this.Active) return;

            var fd = this.getTotalDeposit();
            var totals = BetSlip.getFooterTotals();

            this.clearErrors();

            var lines = [];
            var deposits = [];
            var multiLineData = [];
            var freeBets = [];
            var totalDeposit = 0;

            // the total deposit, without any free bets
            var totalRealMoneyDeposit = 0;
            var hasFreeBet = false;

            for (var key in BetSlip.Selections)
            {
                var item = BetSlip.Selections[key];

                if (item.EventTypeID === eventTypeId)
                {
                    this.eventID = item.EventID;

                    if (this.selectedFreeBets && this.selectedFreeBets[eventTypeId])
                    {
                        var freeBet = UserInfo.current.FreeBets[this.selectedFreeBets[eventTypeId]];
                        item.FreeBet = freeBet;
                        freeBets[item.ViewKey] = item.FreeBet;
                        hasFreeBet = true;
                    }

                    lines.push(item);
                }

                if (!item.Valid && item.EventTypeID === eventTypeId)
                {
                    this.dispatchError($dict.bs("GeneralError"));
                    this.clearSelections();
                    this.eventID = -1;
                    break;
                }
            }

            if (UserInfo.TaxProvider.isUserUnderTax() && BetSlip.validatePurchaseWithCountryTaxes(totals.Taxes, this.getTotalDeposit()))
            {
                this.dispatchError($dict.bs("CountryTaxInvalidOdds"));
                this.clearSelections();
                this.eventID = -1;
                return;
            }

            if (fd <= 0)
            {
                this.dispatchError($dict.bs("InvalidBet"));
                this.eventID = -1;
                return;
            }

            if (this.Deposit)
            {
                totalDeposit += this.Deposit;

                var minBet = item.minBet;
                var regulationMinBet = this.getRegulationMinBet();
                minBet = Boolean(regulationMinBet) && (regulationMinBet > minBet) ? regulationMinBet : minBet;

                if (!hasFreeBet)
                {
                    totalRealMoneyDeposit += this.Deposit;
                }

                deposits.push(this.Deposit);
            }

            if (totalDeposit <= 0)
            {
                //totalDeposit can be 0 because of regulations min bet so we don't need to continue with placement
                this.setBetInProcess(false, true);
                return;
            }

            if (this.getRegulationMinPurchaseStake() > totalDeposit)
            {
                this.dispatchError($dict.bs("UnderMinTotalCost"));
                this.clearSelections();
                this.sendonPulseBetWaitingResponse();
                this.eventID = -1;
                return;
            }

            if (this.isOverMaxWinRegulation(this.getTotalGain(), totalDeposit))
            {
                this.dispatchError($dict.bs("RegulationsMaxWin").format("{0}{1}".format(BetSlip.UserCurrencyCode, MoneyFormat.format(this.getRegulationMaximumPurchaseWin()))));
                this.sendonPulseBetWaitingResponse();
                this.clearSelections();
                this.eventID = -1;
                return;
            }


            if (!BetSlip.SkipClientUserBalanceCheckForSingles && totalRealMoneyDeposit > UserInfo.current.getBalance())
            {
                if (typeof (QuickDepositBlock) !== "undefined")
                {
                    QuickDepositBlock.showDepositPopupIfNeed();
                }
                this.dispatchError($dict.bs("NotEnoughFunds"));
                this.clearSelections();
                this.sendonPulseBetWaitingResponse();
                this.eventID = -1;
                return;
            }

            var ref = this;

            BettingPageMethods.placeSinglePurchase(this.formatPurchaseRequest(lines, null, null, null, null, null, null, multiLineData, freeBets, null, null, this.Deposit),
                function (res)
                {
                    var purchases = [eval(res)];
                    ref.placePurchaseCallBack(purchases);
                    ref.clearSelections();
                },
                function (res)
                {
                    ref.placePurchaseCallBackError(res);
                },
                this
            );
        },

        clearSelections: function ()
        {
            for (var key in BetSlip.Selections)
            {
                if (BetSlip.Selections[key] && BetSlip.Selections[key].EventID == this.eventID)
                {
                    delete BetSlip.Selections[key];
                }
            } 

            BetSlip.saveState();
        },

        placePurchaseCallBackError: function (response)
        {
            this.dispatchError($dict.bs("GeneralError"));
            this.clearSelections();
            this.eventID = -1;
            this.eventsManager.dispatchEvent("onPulseBetWaitingResponse", this.eventID, true);
        },

        getPulseSelection: function (result)
        {
            var selections = [];
            for (var i = 0; i < 3; i += 1)
            {
                var selection = result.Selections[i];
                if (selection)
                {
                    selections[selection.ViewKey] = BetSlip.Selections[selection.ViewKey];
                }
            }
            return selections;
        },

        shouldShowBonusBettingMessage: function (purchase)
        {
            return BetSlip.ShowBonusBettingMessage && purchase.BonusAmountStake * 1 > 0;
        },

        parsePlaceBets: function (result, keepPreviousPurchase)
        {
            if (result == -1) // User is not logged in
            {
                BetSlip.Selections = [];
                return;
            }
            var shouldShowBonusBettingMessage = this.shouldShowBonusBettingMessage(result);

            switch (result.Status)
            {
                case 1:
                    {
                        var currentPurchase = Array.first(result);
                        var currentSelection = result.Selections[3];
                        var endTime = currentSelection.QAParameter1 + currentSelection.QAParameter2;

                        var placementResult = {
                            status: CONSTANTS.BetPlacementStatus.Success,
                            eventId: this.eventID,
                            ticketId: currentPurchase.PurchaseID || result.PurchaseID,
                            timeInterval: this.getTimeInterval(currentSelection.QAParameter1, endTime)
                        };
                        var infoMessage = shouldShowBonusBettingMessage ? $dict.bs("BonusBettingReceiptMessage") : "";
                        this.eventID = -1;
                        this.eventsManager.dispatchEvent("onPulseBetWaitingResponse", placementResult.eventId, true);
                        this.eventsManager.dispatchEvent("onBonusMoneyBetting", infoMessage, true);
                        this.eventsManager.dispatchEvent("onPulseBetPlacementResult", placementResult, true);
                        this.eventsManager.dispatchEvent("onPlacedPulseBetTimeReload", null, true);
                        SPPurchaseBuilder.createPulseSlipPurchase(result, this.getPulseSelection(result), keepPreviousPurchase, false);
                        this.removeSelectedFreeBets(currentPurchase);
                        UserInfo.updateBalance();                      
                        break;
                    }
                case 3:   //Waiting
                    {
                        var eventId = this.eventID;
                        var infoMessage = shouldShowBonusBettingMessage ? $dict.bs("BonusBettingMessage") : "";
                        this.eventID = -1;

                        for (var k in result.Bets)
                        {
                            BetSlip.CurrentMode.SaveReservedFreeBetsInStorage(result.Bets[k].FreeBetID);
                        }

                        this.eventsManager.dispatchEvent("onPulseBetWaitingResponse", eventId, true);
                        this.eventsManager.dispatchEvent("onBonusMoneyBetting", infoMessage, true);
                        this.eventsManager.dispatchEvent("onPlacedPulseBetTimeReload", null, true);
                        SPPurchaseBuilder.createPulseSlipPurchase(result, this.getPulseSelection(result), keepPreviousPurchase, false);
                        UserInfo.updateBalance();
                        break;

                    }
                case 2: //Rejected
                case -10: // generic error
                    {
                        this.dispatchError(this.translateBetSlipError(
                            {
                                Status: result.ErrorCode,
                                ErrorText: result.Message,
                                Regulations: result.Regulations,
                                userCurrency: result.userCurrency
                            }));

                        this.clearSelections();
                        this.eventID = -1;

                        this.sendonPulseBetWaitingResponse();

                        break;
                    }
                case -1: //Selections Error
                    {
                        var errorsHelper = new BetSlipErrorMessagesHelper();
                        for (var sIdx in result.Selections)
                        {
                            var line = result.Selections[sIdx];
                            var selection = Array.find(BetSlip.Selections, function (item) { return item.ViewKey == line.ViewKey; });
                            if (!selection) continue;

                            selection.processBetUpdate(line);

                            (selection.UpdatedOdds || line.Status === -1) && (line.ErrorText = $dict.bs("OddsChanged"));

                            if (line.Status == -2)
                            {
                                this.dispatchError($dict.bs("LineClosed"));
                                break;
                            }
                            else
                            {
                                this.dispatchError(this.translateBetSlipError(line, selection, false));
                                break;
                            }
                        }

                        this.clearSelections();
                        var eventId = this.eventID;
                        this.eventID = -1;

                        this.eventsManager.dispatchEvent("onPulseBetWaitingResponse", eventId, true);
                        break;
                    }
                case -2: //Bets Error
                    {
                        var isErrorShown = false;
                        var errorMessage;
                        for (var bIdx in result.Bets)
                        {
                            var bet = result.Bets[bIdx];
                            for (var i = 0; i < 3; i += 1)
                            {
                                var line = result.Selections[i];
                                if (!line) continue;

                                var selection = Array.find(BetSlip.Selections, function (item) { return item.ViewKey == line.ViewKey; });
                                if (!selection) continue;

                                selection.processBetUpdate(line);

                                selection.UpdatedOdds && (errorMessage = $dict.bs("OddsChanged"));
                                selection.UpdatedPoints && (errorMessage = $dict.bs("PointsChanged"));
                                selection.UpdatedOdds && selection.UpdatedPoints && (errorMessage = $dict.bs("AllChanged"));

                                if (errorMessage)
                                {
                                    this.dispatchError(errorMessage);
                                    isErrorShown = true;
                                    break;
                                }
                                else if (line.Status == -2)
                                {
                                    this.dispatchError($dict.bs("LineClosed"));
                                    isErrorShown = true;
                                    break;
                                }
                                else
                                {
                                    this.dispatchError(this.translateBetSlipError(bet, selection, false));
                                    isErrorShown = true;
                                    break;
                                }
                            }
                        }

                        this.clearSelections();
                        var eventId = this.eventID;
                        !isErrorShown && this.dispatchError(this.translateBetSlipError(bet, line, false));
                        this.eventID = -1;
                        this.eventsManager.dispatchEvent("onPulseBetWaitingResponse", eventId, true);

                        break;
                    }
                case -3: //Free bet error
                    {
                        for (var idx in result.FreeBets)
                        {
                            var fBet = result.FreeBets[idx];
                            var viewKey = fBet.MappingKey;
                            var selection = BetSlip.Selections[viewKey];
                            if (!selection) continue;

                            this.dispatchError(this.translateBetSlipError(fBet, selection, false)); 
                            selection.FreeBet = null;
                        }

                        this.clearSelections();
                        var eventId = this.eventID;
                        this.eventID = -1;
                        this.eventsManager.dispatchEvent("onPulseBetWaitingResponse", eventId, true);

                        break;
                    }
                case -4: //Betting limit error
                    {
                        this.dispatchError($string('General').BettingLimitHit.replace('$VALUE$', UserInfo.current.toUserFormatedCurrencyString(result.RemainingBettingLimit)));
                        BetSlip.sendBetLimitsErrorToJSApi(result);

                        this.clearSelections();
                        var eventId = this.eventID;
                        this.eventID = -1;
                        this.eventsManager.dispatchEvent("onPulseBetWaitingResponse", eventId, true);

                        break;
                    }
                case -18:
                    {
                        this.clearSelections();
                        var eventId = this.eventID;
                        this.eventID = -1;
                        this.eventsManager.dispatchEvent("onPulseBetWaitingResponse", eventId, true);
                        var errorText = this.getVerificationErrorMessage(result.ErrorCode);
                        this.dispatchError(errorText);

                        break;
                    }
            }
        },

        selectionAdded: function (item, initcall)
        {

        },

        canActivate: function ()
        {
            return true;
        },

        IsMaxBetButtonEnabled: function ()
        {
            return false;
        },

        getTotalDeposit: function ()
        {
            return this.Deposit;
        },

        clearErrors: function ()
        {

        },
        /////////////////////////////////// Template Functions

        updateView: function ()
        {
            return "";
        },

        // Template logic splitter
        buildOptions: function (selections)
        {
            return "";
        },

        // Adapter for Array.push template implementation
        buildSelectionInnerView: function (__html, selection, selectionUpdated)
        {
            return "";
        },

        buildHeader: function ()
        {
            return "";
        },

        selectionRemoved: function ()
        { },

        convertSecondsToMinutes: function (timeInSeconds)
        {
            var minutes = Math.floor(timeInSeconds / 60);
            var seconds = timeInSeconds % 60;
            var minutesString = minutes < 10 ? "0" + minutes : minutes;
            var secondsString = seconds < 10 ? "0" + seconds : seconds;

            return "{0}:{1}".format(minutesString, secondsString);
        },

        getTimeInterval: function (startTime, endTime)
        {
            return "({0}-{1})".format(this.convertSecondsToMinutes(startTime), this.convertSecondsToMinutes(endTime));
        },

        onPurchaseUpdated: function (sender, purchases)
        {
            var pKey, sKey;
            for (pKey in purchases)
            {
                var currentPurchase = purchases[pKey];

                if (currentPurchase.PurchaseTypeID == SPPurchaseType.PulseBet)
                {
                    var time = 0;
                    var startTime = 0;
                    for (sKey in currentPurchase.Selections)
                    {
                        if (time)
                        {
                            time += currentPurchase.Selections[sKey].QAParameter2 + 1;
                        }
                        else
                        {
                            time = currentPurchase.Selections[sKey].QAParameter1 + currentPurchase.Selections[sKey].QAParameter2;
                            startTime = currentPurchase.Selections[sKey].QAParameter1;

                        }
                    }

                    if (SPPurchaseStatus.IsWaiting(currentPurchase.Status))
                    {
                        return;
                    }
                    var status;
                    if (currentPurchase.Status == SPPurchaseStatus.Accepted)
                    {
                        status = CONSTANTS.BetPlacementStatus.Success;
                        this.removeSelectedFreeBets(currentPurchase);
                    }
                    else
                    {
                        status = CONSTANTS.BetPlacementStatus.Error;
                        var errorMessage = $dict.bs("YourBetWasRejected");
                    }

                    var placementResult = {
                        status: status,
                        eventId: currentPurchase.Selections[sKey].EventID,
                        ticketId: currentPurchase.PurchaseID,
                        timeInterval: this.getTimeInterval(startTime, time),
                        errorMessage: errorMessage
                    };

                    this.eventsManager.dispatchEvent("onPulseBetPlacementResult", placementResult, true);
                    this.eventsManager.dispatchEvent("onPlacedPulseBetTimeReload", null, true);
                }
            }
        },

        removeSelectedFreeBets: function (currentPurchase)
        {
            if (currentPurchase.Bet &&
                currentPurchase.Bet.Selection &&
                currentPurchase.Bet.Selection.EventTypeID &&
                this.selectedFreeBets[currentPurchase.Bet.Selection.EventTypeID])
            {
                delete this.selectedFreeBets[currentPurchase.Bet.Selection.EventTypeID];               
                this.eventsManager.dispatchEvent("onSelectFreeBetFromPulse", null, true);
            }
        },

        getAvailableFreeBets: function (pulseBetEvent, isAllowedDuplicates)
        {
            var freeBets = [];
            var balance = 0;

            if (UserInfo.current && UserInfo.current.isBalancesInitiated())
            {
                freeBets = UserInfo.current.FreeBets;
                balance = UserInfo.current.getBalance();
            }
            else
            {
                freeBets = UserInfo.getFreeBetsFromStorage();
            }

            var availableFreeBets = [];
            for (var i in freeBets)
            {
                var freeBet = freeBets[i];

                // Reseved free bets are set when the bet is in waiting status                
                if (this.hasAvailableResevedFreeBets(freeBet, BetSlip.Modes["pulse"])) continue;

                //Avoid duplicating free bets in selectedFreeBets for Pulse
                if (!isAllowedDuplicates)
                {
                    if (this.getCurrentlySelectedFreeBet(freeBet.BonusID, pulseBetEvent) || this.getSelectionsFreeBet(freeBet.BonusID, pulseBetEvent))
                        continue;
                }

                if (!BetSlip.SkipClientUserBalanceCheckForSingles && freeBet.IsRiskFreeBet && balance < freeBet.Amount)
                {
                    continue;
                }

                if (freeBet.IsAllowedForLine(pulseBetEvent, pulseBetEvent.BetType))
                {
                    freeBet.Title = Bets.formatCurrencyStake(freeBet.Amount) + " " + freeBet.Name;
                    availableFreeBets.push(freeBet);
                }
            }
            
            return availableFreeBets;
        },

        removeAllSelectedFreePulseBets: function ()
        {
            this.selectedFreeBets = [];
        },

        getCurrentlySelectedFreeBet: function (bonusID, selection)
        {
            for (var key in this.selectedFreeBets)
            {
                if (this.selectedFreeBets.hasOwnProperty(key) && key != selection.EventTypeID && this.selectedFreeBets[key] == bonusID)
                    return this.selectedFreeBets[key];
            }            
        },

        getSelectionsFreeBet: function (bonusID, pulseBetEvent)
        {
            return Array.find(BetSlip.Selections, function (sel) { return (sel.EventTypeID !== pulseBetEvent.EventTypeID && sel.FreeBet && sel.FreeBet.BonusID == bonusID); });
        },

        setSelectedFreeBets: function (data)
        {            
            if (data.marketTypeId && data.freeBetId)
            {
                this.selectedFreeBets[data.marketTypeId] = data.freeBetId;
            }
            else
            {
                delete this.selectedFreeBets[data.marketTypeId];
            }
            
            this.eventsManager.dispatchEvent("onSelectFreeBetFromPulse", undefined, true);
        },

        getSelectedFreeBets: function ()
        {
            return this.selectedFreeBets;
        }
    });

window.PulseSPSlipMode = PulseSPSlipMode;
