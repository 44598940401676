﻿var TSPCombinatorPurchase = (function (base) {
    "use strict";
    var api = $.extend({}, base); // use 'api' only to define extension properties and always 'this' inside functions

    api.getLinesDescription = function() {
        return Purchases.getCombinatorLinesDescription(this);
    };

    return api;
}(TSPComboPurchase));

window.TSPCombinatorPurchase = TSPCombinatorPurchase;