﻿var MultiLineSubType = {
    Straight: 1,
    Reverse: 2,  //Forecast only
    Combination: 3
};

function MultiLineItem(masterEventID, eventID, lineID, Odds, groups, positions, EventDate, maxBet, isTriCast, eventTypeId, positionsCount, isVirtual)
{
    BaseSlipSelection.call(this, masterEventID, eventID, lineID, eventTypeId);

    this.Selections = groups;
    this.Positions = positions;

    this.InitializeOdds(Odds);
    this.InCombo = false;
    this.IsVirtual = isVirtual;
    this.BetType = (isTriCast ? BetTypes.TriCast : BetTypes.ForeCast);
    this.BetSubTypeID = MultiLineSubType.Straight;
    if (typeof positionsCount != 'undefined') {
        if (positionsCount == 2)
            this.BetType = BetTypes.ForeCast;
        else if (positionsCount == 3)
            this.BetType = BetTypes.TriCast;
        else if (positionsCount == 4) {
            this.BetType = BetTypes.FirstFour;
        }
    }

    var reverse = Array.find(positions, function (el) { return el.Position == 0 });
    if (reverse != null)
    {
        if (this.BetType == 11 && Array.getLength(this.Selections) == 2)
        {   //Reverse (forecast only)
            this.BetSubTypeID = MultiLineSubType.Reverse;
        }
        else
        {   //Combination
            this.BetSubTypeID = MultiLineSubType.Combination;
        }
    }

    this.EachWayIncluded = false;
    this.IsEachWayEnabled = false;
    this.IsEachWaySelection = false;

    this.ReleatedToID = -1;

    this.PlaceTermsID = -1;
    this.OddsTermsID = -1;
    this.EachWayTermsID = -1;

    this.EWOdds = -1;
    this.EWDeposit = -1;
    this.EWGain = -1;
    this.TeamID = -1;

    this.QAParameter1 = 0;
    this.QAParameter2 = 0;
    this.Initialized = false;
    this.EventDate = new Date(EventDate);
    this.maxBet = maxBet;
    this.minBet = 0;
    this.IsMultiLine = true;
    this.PurchasedSelections = [];
}

$.extend(MultiLineItem.prototype, BaseSlipSelection.prototype,
{
    getUpdateRequest: function ()
    {
        return [
            !!this.IsMultiLine,
            this.ViewKey,
            this.EventID,
            this.BetType,
            this.getCastItemLinePositionMapping()
        ];
    },

    getQASlipSelection: function(selections)
    {
        var line = Array.find(this.Selections, function (line) { return true; });

        if (!selections) 
        {
            selections = this.getBetSlipSelections();
        }

        var qaSlipSelection = Array.find(selections, function (s) { return s.ViewKey === line; });

        return qaSlipSelection;
    },

    getQASlipSelectionNameToPosition: function (idx, dict, selections)
    {
        var ref = this;
        if (!selections) 
        {
            selections = this.getBetSlipSelections();
        }

        var findSelection = function (s)
            {
                return s.ViewKey == ref.Selections[idx];
            }

        var qaSlipSelection = Array.find(selections, findSelection);
            
        if (!qaSlipSelection)
        {
            qaSlipSelection = Array.find(this.PurchasedSelections, findSelection);
        }

        if (!qaSlipSelection)
        {
            return null;
        }

        var position = this.getSelectionPositionName(qaSlipSelection, dict);

        return {
            QASlipSelection: qaSlipSelection,
            Position: position
        };
    },

    getSelectionPositionName: function (qaSlipSelection, dict)
    {
        var result = Array.find(this.Positions, function (el) { return el.ViewKey === qaSlipSelection.ViewKey });

        var position = "";
        if (result != null)
        {
            if (result.Position > 0)
            {
                if (result.Position === 1)
                {
                    position = dict("FirstPlace");
                } else if (result.Position === 2)
                {
                    position = dict("SecondPlace");
                } else if (result.Position === 3)
                {
                    position = dict("ThirdPlace");
                }
                else if (result.Position === 4) {
                    position = dict("FourthPlace");
            }
        }
        }

        return position;
    },

    getBetName: function(dict)
    {
        var betName = '';

        if (this.BetType === BetTypes.ForeCast)
        {
            switch (this.BetSubTypeID)
            {
                case MultiLineSubType.Straight:
                    betName = dict("StraightForecast");
                    break;
                case MultiLineSubType.Combination:
                    betName = dict("CombinationForecast");
                    break;
                case MultiLineSubType.Reverse:
                    betName = dict("ReverseForecast");
                    break;
            }
        }
        else if (this.BetType === BetTypes.TriCast)
        {
            switch (this.BetSubTypeID)
            {
                case MultiLineSubType.Straight:
                    betName = dict("StraightTricast");
                    break;
                case MultiLineSubType.Combination:
                    betName = dict("CombinationTricast");
                    break;
            }
        }
        else if (this.BetType === 21) {
            betName = dict("FirstFour");
        }
        return betName;
    },

    getPostRequest: function (deposit)
    {
        var res = [
            this.ViewKey,     //0
            this.EventID,     //1
            this.LineID,      //2
            this.Odds,        //3
            deposit,           //4
            //5 - actual adds, the odds that we are actually showing to the client
            (currentOddStyle == OddStyle.FRACTIONAL ? FractionalOddsConverter.encodeFraction(this.getFormatedOdds()) : this.getFormatedOdds() * 1)
                + ":" + this.getActualOddsStyleID(),
            this.EachWayIncluded,              //6
            this.IsEachWayEnabled,             //7
            this.PlaceTermsID,                 //8
            this.OddsTermsID,                  //9
            this.EachWayTermsID,               //10
            this.EWOdds,                       //11
            this.EWDeposit,                    //12
            this.EWGain                        //13
        ];

        return res;
    },

    saveBetSlipSelections: function()
    {
        this.ClonedBetSlipSelections = $.extend({}, BetSlip.Selections);
    },

    getBetSlipSelections: function()
    {
        return this.ClonedBetSlipSelections || BetSlip.Selections;
    },

    getRealOdd: function ()
    {
        for (var idx in this.Selections)
        {
            var sel = this.getBetSlipSelections()[this.Selections[idx]];
            if (!sel || sel.isSPSelection() || sel.BetType == BetTypes.ForeCast || sel.BetType == BetTypes.TriCast) return 0;
            var dd = Math.floor(((sel.Odds > 0 ? (sel.Odds / 100 + 1) : (1 - 100 / sel.Odds)) * 1000).toPrecision(12)) / 1000 * sel.ComboRate;
            if (dd < 1.001) dd = 1.001;
            return dd;
        }
    },

    getRealOddEW: function ()
    {
        if (this.isSPSelection()) return 0;
        var ewOdds = this.getEWOdds();
        var dd = Math.floor(((ewOdds > 0 ? (ewOdds / 100 + 1) : (1 - 100 / ewOdds)) * 1000).toPrecision(12)) / 1000 * this.ComboRate;
        if (dd < 1.001) dd = 1.001;
        return dd;
    },

    // Odds are usually retrieved using the global currentOddsStyle variable. If an explicit odds-style is specified
    // or the current mode of the betslip is specifying an alternative odds-style, use them instead.
    getFormatedOdds: function (oddsStyle)
    {
        if (this.Odds == 0) return 0;

        var alternativeOddsStyle = oddsStyle || BetSlip.getAlternativeOddsStyle();
        return Odds.convertFormat(this.Odds, this.EventTypeID, this.LineTypeID, undefined, alternativeOddsStyle, this.BetType, this.BranchID, this.FractionalOdds);
    },

    // Returns the odd style that the selection is actually seen in by the user. This may not be the current odd style
    // as there are requirements for example when the user is in an Asian odd style that they should see the odds on
    // the combo tab in decimal. Also is a requirement that when in Asian odd style the user sees some events on the
    // single tab in Decimal rather than in the current Asian odd style (for instance Money line on Soccer events).
    // See Odds.convertFormat(), Odds.convert() and Odds.showDecimalInsteadOfAsian() in Odds.js
    getActualOddsStyleID: function ()
    {
        var alternativeOddsStyle = BetSlip.getAlternativeOddsStyle();
        var oddsStyleID = Odds.showDecimalInsteadOfAsian(this.BetType, this.EventTypeID, this.BetTypeID, this.BranchID) ? OddStyle.EUROPEAN : currentOddStyle;
        oddsStyleID = alternativeOddsStyle ? alternativeOddsStyle : oddsStyleID;
        return oddsStyleID;
    },

    isSplit: function ()
    {
        return false;
    },

    processBetUpdate: function (updates, firstcall)
    {
        if (updates == -1)
        {
            this.Valid = false;
            this.Final = true;
            this.LastUpdate = new Date();
            this.ErrorText = this.Error = $dict.bs("LineClosed");
            if (this.Live) this.LineClosed = true;
            this.Initialized = true;
            this.Updated = false;

            return;
        }

        var hasUpdatedSelection = this.Selections.any(function (index) { return BetSlip.Selections[index] && BetSlip.Selections[index].Updated; });
        if (!firstcall && !hasUpdatedSelection) {
            this.Updated = false;
            return;
        }

        if (!this.Initialized)
        {
            this.BranchID = updates.BranchID;
        }

        this.Odds = updates.Odds;

        this.Valid = true;
        this.Final = false;
        this.LastUpdate = new Date();
        this.Initialized = true;
        this.Updated = true;
        this.minBet = this.calculateMinbet();
    },

    calculateMinbet: function()
    {
        var minBet=0;

        for (var i = 0; i < this.Selections.length; i++)
        {
            var selectionFromMultiLineItem = BetSlip.Selections[this.Selections[i]];

            if (selectionFromMultiLineItem && selectionFromMultiLineItem.Initialized) minBet = selectionFromMultiLineItem.minBet > minBet ? selectionFromMultiLineItem.minBet : minBet;
        }

        return minBet;
    },

    clearUpdatedFlags: function ()
    {
        this.Updated = false;
        this.UpdatedOdds = false;
    },

    serialize: function ()
    {
        return $.extend({}, BaseSlipSelection.prototype.serialize.call(this),
            {
                BetType: this.BetType,
                Live: false,
                Odds: this.Odds,
                LineTypeName: this.LineTypeName,
                BetTypeID: this.BetTypeID,
                LineTypeID: this.LineTypeID,
                EventName: this.EventName,
                LineName: this.LineName,
                MappedLineTypeID: this.MappedLineTypeID,
                SplitTypeID: this.SplitType,
                EachWayIncluded: this.EachWayIncluded,
                IsEachWayEnabled: this.IsEachWayEnabled,
                PlaceTermsID: this.PlaceTermsID,
                OddsTermsID: this.OddsTermsID,
                EachWayTermsID: this.EachWayTermsID,
                EWOdds: this.EWOdds,
                EWDeposit: this.EWDeposit,
                EWGain: this.EWGain,
                IsEachWaySelection: this.IsEachWaySelection,
                TeamID: this.TeamID,
                FractOddsDividend: this.FractOddsDividend,
                FractOddsDivisor: this.FractOddsDivisor,
                FractionalOdds: this.FractionalOdds,
                Selections: this.Selections,
                Positions: this.Positions,
                EventDate: this.EventDate,
                maxBet: this.maxBet,
                minBet: this.minBet,
                PurchasedSelections: this.PurchasedSelections
            });
    },

    getMaxBet: function ()
    {
        var maxBet = this.maxBet;
        for (var idx in this.Selections)
        {
            var qaSel = this.getBetSlipSelections()[this.Selections[idx]];
            if (!qaSel) continue;

            if (qaSel.MaxBetForCustomer)
            {
                maxBet = qaSel.MaxBetForCustomer;
                break;
            }
        }
        return maxBet;
    },

    getNumberOfBets: function ()
    {
        if (this.BetSubTypeID == MultiLineSubType.Straight)
            return 1;

        //Reverse/combination - get permutations
        var nbrLines = Array.getLength(this.Selections);
        var betSize = (this.BetType == BetTypes.FirstFour ? 4 : (this.BetType == BetTypes.ForeCast ? 2 : 3));
        var nbrBets = 1;

        for (var i = 0; i < betSize; i++)
        {
            nbrBets *= (nbrLines - i);
        }

        if (this.IsVirtual)
        {
            nbrBets /= this.BetType == BetTypes.ForeCast ? 2 : 6;
        }

        return nbrBets;
    },

    getDeposit: function ()
    {
        var deposit = BetSlip.CurrentMode.Deposits[this.ViewKey];
        if (!deposit) return 0;

        return deposit * this.getNumberOfBets();
    },

    getFormatedOddsEW: function (oddsStyle)
    {
        var alternativeOddsStyle = oddsStyle || BetSlip.getAlternativeOddsStyle();
        return Odds.convertFormat(this.EWOdds, this.EventTypeID, this.BetTypeID, undefined, alternativeOddsStyle, this.BetType, this.BranchID, this.EachWayFractionalOdds);
    },

    getGainEW: function ()
    {
        if (this.isSPSelection()) return 0;

        var fodd = this.EWOdds > 0 ? (this.EWOdds / 100 + 1) : (1 - 100 / this.EWOdds);
        return fodd * this.EWDeposit;
    },

    recalcEWOdds: function (backSafe)
    {
        if (!this.IsEachWayEnabled)
            return;

        if (this.isSPSelection())
        {   //Selection is SP - no odds
            this.EWOdds = 0;
        }
        else if (this.hasEWOddsFromServer())
        {   //Have EW odds from server - use them
            this.EWOdds = this.EachWayAmericanOdds;
        }
        else
        {   //No EW odds from server - calculate them
            var fodd = this.Odds > 0 ? (this.Odds / 100 + 1) : (1 - 100 / this.Odds);
            var cPortionOdds = (fodd - 1) / this.OddsTermsID + 1;
            this.EWOdds = Odds.decimalToAmerican(cPortionOdds);
        }

        if (backSafe)
        {
            var currentOdds = this.getFormatedOddsEW();
            this.EWOdds = -1;
            return currentOdds;
        }
    },

    recalcEWGain: function (deposit)
    {
        if (!this.IsEachWayEnabled)
            return;

        this.recalcEWOdds();
        this.EWDeposit = deposit;
        if (this.isSPSelection())
        {
            this.EWGain = 0;
        }
        else
        {
            this.EWGain = deposit * Odds.convertFromAmericanToDecimal(this.EWOdds);
        }
    },

    hasEWOddsFromServer: function ()
    {
        return (typeof this.EachWayAmericanOdds != "undefined" && this.EachWayAmericanOdds != 0);
    },

    getUniPostRequest: function (deposit)
    {
        return [
            this.ViewKey,             // 0 SlotID
            this.EventID,            // 1 EventID
            this.EventTypeID,        // 2 EventTypeID
            this.LineID,             // 3 LineID
            this.LineTypeID,        // 4 LineTypeID // FIX YOUR BULLSHITS
            this.BetType,            // 5 BetTypeID
            this.LineGroupID,        // 6 LineGroupID
            this.BetSide,            // 7 BetSide
            this.Points,             // 8 Points
            this.Odds,               // 9 Odds
            deposit,                 // 10 deposit
            this.Score1,             // 11 Score1
            this.Score2,             // 12 Score2
            this.IsBuyPoints,        // 13 IsBuyPoints
            this.BuyPointsPoints,    // 14 BuyPointsPoints
            this.BuyPointsOdds,      // 15 BuyPointsOdds
            this.EachWayIncluded,    // 16 IsEWIncluded
            this.IsEachWayEnabled,   // 17 IsEachWayEnabled
            this.PlaceTermsID,       // 18 PlaceTermsID
            this.OddsTermsID,        // 19 OddsTermsID
            this.EachWayTermsID,     // 20 EachWayTermsID
            this.EWOdds,             // 21 EWOdds
            this.EWDeposit,          // 22 EWDeposit
            this.EWGain,             // 23 EWGain
            (currentOddStyle == OddStyle.FRACTIONAL ? FractionalOddsConverter.encodeFraction(this.getFormatedOdds()) : this.getFormatedOdds() * 1)
                + ":" + this.getActualOddsStyleID(), //24 client odds
            this.Authorization,                  // 25 Authorization
            this.getCastItemSelectionsData(),    // 26 Selections
            this.getCastItemPositionData(),      // 27 Positions
            this.CastSelection,                  // 28 is in cast ?,
            this.BetSubTypeID
        ];
    },

    getCastItemSelectionsData: function ()
    {
        var result = ""
        for (var i in this.Selections)
        {
            result += this.Selections[i] + ";";
        }
        return result;
    },

    getCastItemPositionData: function ()
    {
        var result = ""
        for (var i in this.Positions)
        {
            result += this.Positions[i].ViewKey + ":" + this.Positions[i].Position + ";";
        }
        return result;
    },

    getCastItemLinePositionMapping: function ()
    {
        var result = [];
        var selections = this.getBetSlipSelections();
        for (var i in this.Positions)
        {
            var viewKey = this.Positions[i].ViewKey;
            var bSelection = Array.find(selections, function (s) { return s.ViewKey == viewKey });
            
            if (!bSelection)
            {
                continue;
            }

            result.push(bSelection.LineID + ":" + this.Positions[i].Position);
        }
        return result.join(";");
    },

    getEventTime: function ()
    {   
        var eventTime = "";
        if (this.EventDate)
        {
            var useUKTime = function () {
                var selections = this.Selections;
                return selections &&
                    selections.length > 0 &&
                    selections.all(function (item) { return BetSlip.Selections[item].BranchID === 61 });
            }
            var date = this.EventDate;
            date = (UseAustralianHorseRacingView && !useUKTime()) ? Data.getLocalTime(date) : Data.getUkTime(date);
            eventTime = date.toStringEx("HH:mm");
        }
        return eventTime;
    },

    formatPurchaseRequest: function (stake)
    {
        var request = new Object();
        request.EventID = this.EventID;
        request.BetTypeID = this.BetType;
        request.BetSubTypeID = this.BetSubTypeID;
        request.Positions = this.Positions;
        request.Odds = this.Odds;
        request.ViewKey = this.ViewKey;
        request.Stake = stake;
        
        return request;
    },

}, TMultiLineItem);


function addMultiLineOdd(masterEventID, eventID, lineID, Odds, variants, eventTypeId, isVirtual)
{
    if (Array.getLength(variants) > 0)
    {
        //check if the item exist in bet slip.

        for (var i in variants.Groups)
        {
            var pos = [];
            for (var m in variants.Groups[i])
            {
                pos.push(Array.find(variants.GroupPositions, function (el) { return el.ViewKey == variants.Groups[i][m] }));
            }
            var idx = BetSlip.add(new MultiLineItem(masterEventID, eventID, BetSlip.getViewKey(), Odds, variants.Groups[i], pos, variants.EventDate, variants.MaxBet, variants.IsTriCast, eventTypeId, variants.PositionsCount, isVirtual));
        }
    }
}

window.MultiLineSubType = MultiLineSubType;
window.MultiLineItem = MultiLineItem;
window.addMultiLineOdd = addMultiLineOdd;