﻿var TSPSingleBet = (function ()
{
    "use strict";
    var api = {}; // use 'api' only to define extension properties and always 'this' inside functions

    api.getBetTitle = function ()
    {
        return Purchases.getSPSingleBetTitle(this);
    };

    // Required by TPurchases.js
    api.getDeposit = function () {
        return Purchases.getSingleBetDeposit(this);
    };

    // Required by TPurchases.js
    api.isDepositUpdated = function () {
        return this.DepositUpdated;
    };

    // Required by TPurchases.js
    api.getReturn = function () {
        return Purchases.getSingleBetReturn(this);
    };

    // Required by TPurchases.js
    api.getStatusClass = function () {
        return Purchases.getBetStatusClass(this);
    };

    return api;
}());

window.TSPSingleBet = TSPSingleBet;