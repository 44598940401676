function NextMinutes(eventTypeName)
{
    this.EventTypeName = eventTypeName;
}

$.extend(NextMinutes.prototype,
{
    isYesLine: function (line)
    {
        return line.QATypeParameter1 == 1714;
    },

    buildEventNameForBet: function (line, eventTypeName)
    {
        return line + " " + eventTypeName;
    },

    buildPickLineName: function (qaBet)
    {
        return qaBet.LineName;
    }
});

window.NextMinutes = NextMinutes;