﻿var TSPSystemPurchase = (function (base)
{
    "use strict";
    var api = $.extend({}, base); // use 'api' only to define extension properties and always 'this' inside functions

    // Add overrides

    // Required by TPurchases.js
    api.updateContext = function (context)
    {
        return Purchases.updateComboContext(this, context);
    };

    // Required by Data/UniSlip/*Purchase.js functions
    api.getStatusClass = function ()
    {
        return "";
    };

    // Required by TPurchases.js
    api.getLinesDescription = function ()
    {
        return Purchases.getSystemLinesDescription(this);
    };

    api.getBets = function ()
    {
        return this.getSortedBets();
    };

    return api;
}(TSystemPurchase));

window.TSPSystemPurchase = TSPSystemPurchase;