﻿//WebSite JS file

/* exported FastMarket */
/* globals ArrayOfFunc */
function FastMarket(splitTypeCode)
{
    "use strict";

    var initArray = this.parseCode(splitTypeCode);
    this.StartAfter = parseInt(initArray[1]);
    this.IntervalInMinutes = parseInt(initArray[2] + initArray[3]);
    this.ViewBuilder = this.createViewBuilder(splitTypeCode);
}

FastMarket.prototype.createViewBuilder = function (splitTypeCode) {
    var arr = splitTypeCode.toString();

    if (arr[0] == 1) {
        return new NextMinutes();
    }
    else {
        return new FirstInNextMinutes();
    }
};

FastMarket.prototype.parseCode = function (codeToParse)
{
    "use strict";

    return codeToParse.toString();
};

FastMarket.prototype.formatMinutes = function (minutes)
{
    "use strict";

    if (minutes < 10)
    {
        minutes = "0" + minutes;
    }

    return minutes;
};

FastMarket.prototype.calculateFastMarketInterval = function (lines)
{
    "use strict";

    var values = Array.getValues(lines);

    /* jshint newcap: false */
    var arrayOfParams = ArrayOfFunc(values, function (o)
    {
        return o.QATypeParameter2;
    });
    /* jshint newcap: true */

    var maxParam = Math.max.apply(null, arrayOfParams);
    if (!maxParam)
    {
        return "";
    }

    return this.getFastMarketInterval(maxParam);
};

FastMarket.prototype.getFastMarketInterval = function (fromTime)
{
    "use strict";

    if (fromTime == -1)
    {
        fromTime = 0;
    }

    var startInterval = this.formatMinutes(fromTime + this.StartAfter);
    var endIterval = this.formatMinutes(fromTime + this.StartAfter + this.IntervalInMinutes - 1);

    var res = [];
    res.push(startInterval);
    res.push(endIterval);

    return res;
};

FastMarket.prototype.getPickLine = function (qaLine) {
    var intervalPoints = this.getFastMarketInterval(qaLine.QAParameter2);
    var lineName = this.ViewBuilder.buildPickLineName(qaLine);
    var isGameIntervalUpdated = false;

    if (BetSlip && BetSlip.CurrentMode && typeof BetSlip.CurrentMode.isSelectionPropUpdated == "function" && qaLine) {
        isGameIntervalUpdated = BetSlip.CurrentMode.isSelectionPropUpdated(qaLine.ViewKey, BetSlipSelectionPropCasesWithBlinking.GameInterval);
    }
    else {
        isGameIntervalUpdated = qaLine && qaLine.UpdatedGameInterval;
    }

    return lineName + this.getInterval(intervalPoints[0], intervalPoints[1], isGameIntervalUpdated);
};

FastMarket.prototype.getInterval = function (start, end, isForSplitEventTitle)
{
    return isForSplitEventTitle ? " {0}:00 - {1}:59".format(start, end) : " ({0}:00-{1}:59)".format(start, end);
};

window.FastMarket = FastMarket;
