﻿function SPBaseBet()
{
    this.ViewKey = BetSlip.getViewKey();
    this.Rate = 0;
    this.Deposit = 0;
    this.Odds = 0;
    this.Error = false;
    this.Countable = false;
    this.BetsCount = 0;

    this.Status = 0;
    //this.ErrorText = "";
    //this.LastUpdate = null;
    this.Final = false;
    this.LastUpdate = new Date();
}

$.extend(SPBaseBet.prototype,
{
    serialize: function ()
    {
    },

    restore: function (purchase, data)
    {
    },

    getVisualClass: function ()
    {
        switch (this.Status)
        {
            case SPPurchaseStatus.Accepted:
                return "success";
            case SPPurchaseStatus.Declined:
                return "error";
            case SPPurchaseStatus.Waiting:
            case SPPurchaseStatus.NewOffer:
            case SPPurchaseStatus.ClientAccepted:
            case SPPurchaseStatus.NotAuthorized:
            case SPPurchaseStatus.RegulatorTimeOut:
                return "waiting";
        }
    },
    getBetSelections: function()
    {
        var sel = [];
        var ref = this;
      	if(this.Selections) return this.Selections;
        for (var i = 0; i < this.Mappings.length; i++) {
            sel.push(ref.Purchase.Selections.find(function(s){ return s.ViewKey == ref.Mappings[i].ViewKey}));
        }
        return sel;
    },
    getSelectionsGroups: function (selections)
    {
        var selectionGroups = [];
        for (var i in selections)
        {
            var selection = selections[i];
            var groupKey = selection.getComboGroupKey();
            if (typeof selectionGroups[groupKey] == 'undefined') {
                selectionGroups[groupKey] = [];
            }
            selectionGroups[groupKey].push(selection);
        }

        return selectionGroups;
    },
    getTotalGainForEachComboIndividually: function () {
        var gain = 0;
        var selections = this.getBetSelections();
        var selectionsGroups = this.getSelectionsGroups(selections);
        var complexSets = calcDifferentStraightCombinations(selectionsGroups, this.IsEWBet);
        var isComplexSet = function (set) { return set.length > 1 };
        var roundingMode = typeof BetSlip != "undefined" && BetSlip.GainRoundingMode || 0;
        var simpleSets = complexSets.any(isComplexSet) ? sbCombinationCalculations.prepareSets([], complexSets) : [complexSets];
        if(complexSets.any(isComplexSet))
        {
            for (var i = 0; i < simpleSets.length; i++) {
                for (var j = 0; j < simpleSets[i].length; j++) {
                    simpleSets[i][j] = [simpleSets[i][j]];
                }
            }
        }
        var deposit = this.Deposit;
        var sumFunc = function (odd) { return GainUtil.round(sbMathOperations.Multiplication(roundDecimalOdds(odd), deposit, 6), roundingMode, 6) };
        if(this.BetType == BetTypes.VirtualCombo || this.BetType == BetTypes.Combo)  
        {
            for (var i = 0; i < simpleSets.length; i++) {
                var set = simpleSets[i];
                var setGain = sbCombinationCalculations.processSet(this.VariationType,
                    set, 
                    sumFunc);
                gain = sbMathOperations.Аccumulation(gain, setGain, 6); 
            }
        }
        else
        {
            var grpCount = Array.getLength(selectionsGroups);
            var comboSize = Math.min(grpCount, this.VariationType);
            for (var i = 0; i < simpleSets.length; i++) {
                var set = simpleSets[i];
                if (this.VariationType < grpCount) {
                    gain = sbMathOperations.Аccumulation(gain,
                        sbCombinationCalculations.processSet(this.VariationType, set, sumFunc),
                        6);
                }
                else {
                    for (var j = 2; j <= comboSize; j++) {
                        gain = sbMathOperations.Аccumulation(gain,
                            sbCombinationCalculations.processSet(j, set, sumFunc),
                            6);
                    }
                }
            }
            if (this.VariationType > grpCount)
            {
                var singleTotalReturn = this.getSingleTotalReturn(selections, this.Deposit);
                gain = sbMathOperations.Аccumulation(gain, this.IsEWBet ?
                     singleTotalReturn. eachway :singleTotalReturn.allSingle,6);
            }
        }
        return gain;
    },

    getSingleTotalReturn: function(selections, stake) {
        allSingleGain = 0,
            allSingleGainEW = 0,
            roundingMode = typeof BetSlip != "undefined" && BetSlip.GainRoundingMode || 0,
            keys = Object.keys(selections);

        for (i = 0; i < keys.length; i++) {
            var value = sbMathOperations.Multiplication(selections[keys[i]].getRealOdd(BetSlip.BaseOddsRoundingMode), stake, 6);
                allSingleGain = sbMathOperations.Аccumulation(allSingleGain,
                    GainUtil.round(value, roundingMode, 6), 6);

            if (selections[keys[i]].getRealOddEW) {
                allSingleGainEW = sbMathOperations.Аccumulation(allSingleGainEW,
                     GainUtil.round(selections[keys[i]].getRealOddEW(BetSlip.BaseOddsRoundingMode) * stake, roundingMode, 6), 6);
            } else {
                allSingleGainEW = sbMathOperations.Аccumulation(allSingleGainEW, value, 6);
            }
        }

        return { eachway: allSingleGainEW, allSingle: allSingleGain }
    },

    getTotalGain: function ()
    {
        // If we are taxed with Polish tax on stake ( Turnover tax )
        var deposit = this.Deposit;
        deposit = UserInfo.TaxProvider.applyTaxToDeposit(deposit);
        var gain;
        if ((this.BetType == BetTypes.VirtualSystem || this.BetType == BetTypes.System
            || this.BetType == BetTypes.VirtualCombo ||
            this.BetType == BetTypes.Combo) && (typeof BetSlip != "undefined" && BetSlip.CalcEachCombinationIndividualy)) {
        gain = this.getTotalGainForEachComboIndividually();       
        }
        else
        {
           gain = this.Rate * deposit;
        }
        if (this.FreeBetStake && gain > 0 && this.FreeBet && !this.FreeBet.IsRiskFreeBet)
            gain -= this.FreeBetStake;

        var roundingMode = typeof BetSlip != "undefined" && BetSlip.GainRoundingMode || 0;

        return GainUtil.round(gain, roundingMode, 6);
    },

    getTotalDeposit: function ()
    {
        return this.Deposit * this.BetsCount;
    },

    setRejected: function ()
    {
        if ((new Date()).getTime() - this.LastUpdate.getTime() < 30000)         /// Bet info can be missed in server responce
            return;                                                                 /// becouse it was not yet loaded to cache, we

        /// takinkg 10 sec timeout to be sure that bet
        /// is in the cache
        if ((this.Status != SPPurchaseStatus.Waiting) && (this.Status != SPPurchaseStatus.Declined)) return;
        /// setting bet status to declined

        this.ErrorText = this.Error = this.StatusText = $dict.bs("BetNotAccepted");
        this.LastUpdate = new Date();

        this.Purchase.updateStatus(SPPurchaseStatus.Declined);
    },

    getWaitingBetRequest: function ()
    {
        if (SPPurchaseStatus.IsWaiting(this.Status))
        {
            return {
                RowID: this.WaitingBetID, //RowID is actually the new WaitingBetID GUID
                WainingBetID: 0,
                WaitingBetType: this.BetType,
                State: this.Status,
                Odds: this.Odds,
                Deposit: this.Deposit
            };
        }
        else
        {
            return false;
        }
    },

    getTotalGainEW: function ()
    {
        return 0;
    },

    updateStatus: function (waitingBetID, eventTypeID)
    {
        this.updateStatusText();
        this.WaitingBetID = this.RowID = !this.Final ? waitingBetID : null;
    },

    updateStatusText: function ()
    {
        var isLive = this.IsSingleBet && this.IsLive && this.Selection.EventTypeID == 39;

        if (!this.Final)
        {
            if (this.Status == SPPurchaseStatus.Waiting && this.Deposit > this.MaxBet && !isLive)
                this.StatusText = $dict.bs("DepositOverMaxBet");
            else if (this.Status == SPPurchaseStatus.Waiting)
                this.StatusText = $dict.bs("Waiting");
            else
            {
                this.StatusText = $dict.bs("NewOffer");
                var pointsChanged = (this.IsSingleBet && this.Selection.UpdatedPoints) || (this.UpdatedPoints);

                if (this.DepositUpdated && this.OddsUpdated && pointsChanged)
                {
                    this.StatusText = $dict.bs("AllChanged");
                }
                else if (this.DepositUpdated && this.OddsUpdated)
                {
                    this.StatusText = $dict.bs("OddsDepositChanged");
                }
                else if (this.DepositUpdated && pointsChanged)
                {
                    this.StatusText = $dict.bs("DepositPointsChanged");
                }
                else if (this.OddsUpdated && pointsChanged)
                {
                    this.StatusText = $dict.bs("OddsPointsChanged");
                }
                else if (this.OddsUpdated)
                {
                    this.StatusText = $dict.bs("OddsChanged");
                }
                else if (pointsChanged)
                {
                    this.StatusText = $dict.bs("PointsChanged");
                }
                else if (this.DepositUpdated)
                {
                    this.StatusText = $dict.bs("DepositChanged");
                }
            }
        }
        else
        {
            if (this.Status == SPPurchaseStatus.Accepted)
            {
                this.StatusText = $dict.bs("BetAccepted") + this.BetID;
            }
            else
            {
                this.StatusText = $dict.bs("BetNotAccepted");
            }
        }
    },

    updateFromServer: function (data, status)
    {   //For new offers
        this.DepositUpdated = (this.Deposit != data.Stake);
        this.OddsUpdated = (this.Odds != data.Odds);
        this.Deposit = data.Stake;
        this.Odds = data.Odds;
        this.Rate = Odds.convertFromAmerican(this.Odds, OddStyle.EUROPEAN);
        this.Gain = data.Gain;
        this.LastUpdate = new Date();
        if (this.Selection)
        {
            this.Selection.FractOddsDividend = data.Dividend;
            this.Selection.FractOddsDivisor = data.Divisor;

            if (this.Selection.FractOddsDividend && this.Selection.FractOddsDivisor)
            {
                this.Selection.FractionalOdds = this.Selection.FractOddsDividend + '/' + this.Selection.FractOddsDivisor;
            }
        }
        if (status)
        {
            this.Status = status;
            this.updateStatusText();
        }

        if (this.Selection)
        {
            var currentDeposit = UserInfo.TaxProvider.applyTaxToDeposit(this.Deposit);
            this.Selection.recalcEWGain(currentDeposit);
        }
    }
});

//*************************************************************************************************************************

function SPSingleBet(bet, selection, status, waitingBetID)
{
    SPBaseBet.call(this);

    this.BetType = 1;
    this.BuyPoints = false;

    this.Selection = selection;
    this.Odds = this.Selection.Odds;
    this.BetsCount = 1;
    this.Deposit = bet.Stake;
    this.FreeBetStake = bet.FreeBetStake;
    this.Status = status;
    this.BetID = bet.BetID;
    this.MaxBet = bet.MaxBet;
    this.IsLive = bet.IsLive;
    this.Final = ((status == SPPurchaseStatus.Accepted) || (status == SPPurchaseStatus.Declined));
    this.IsEWBet = false;
    this.RowID = null;
    this.IsSingleBet = true;
    this.FreeBet = selection.FreeBet;
    this.LoyaltyPointsPromotions = bet.LoyaltyPointsPromotions;
    this.EWLoyaltyPointsPromotions = bet.EWLoyaltyPointsPromotions;
	this.Gain = bet.Gain;
	
    this.updateStatus(waitingBetID, this.Selection.EventTypeID);
}

$.extend(SPSingleBet.prototype, SPBaseBet.prototype,
{
    serialize: function ()
    {
        return {
            BetType: this.BetType,
            SelectionViewKey: this.Selection.ViewKey,
            Deposit: this.Deposit,
            Status: this.Status,
            BetID: this.BetID,
            WaitingBetID: this.WaitingBetID,
            ViewKey: this.ViewKey
        }
    },

    restore: function (purchase, data)
    {
    },
    getToWin: function ()
    {
        return Math.round(((this.Gain - this.Deposit) * 100).toFixed(4)) / 100;
	},

    getTotalGain: function ()
    {
        if (this.Selection.isSPSelection() || BetTypes.IsForeCast(this.Selection.BetType) || BetTypes.IsTriCast(this.Selection.BetType))
        {
            return 0;
        }

        var roundingMode = typeof BetSlip != "undefined" && BetSlip.GainRoundingMode || 0;
        var depositWithAppliedTaxes = UserInfo.TaxProvider.applyTaxToDeposit(this.Deposit);
        var gainWithAppliedTaxes = this.Selection.getGain(depositWithAppliedTaxes);
      
        if (this.FreeBetStake && this.FreeBet && !this.FreeBet.IsRiskFreeBet) {
            return GainUtil.round(gainWithAppliedTaxes - this.FreeBetStake, roundingMode);
        }
        
        if (this.Status === SPPurchaseStatus.Accepted || this.Status === SPPurchaseStatus.Waiting)
        {   
            return GainUtil.round(gainWithAppliedTaxes, roundingMode);
        }

        this.Gain = this.Selection.isToWin() ? Math.round(((depositWithAppliedTaxes + this.getToWin()) * 100).toFixed(4)) / 100
            : GainUtil.round(this.Selection.getGain(depositWithAppliedTaxes), roundingMode);
    
        return GainUtil.round(this.Gain, roundingMode);
    },

    getToWin: function ()
    {
        if (typeof (this.ToWin) == "undefined")
            this.ToWin = this.Selection.getToWinFromDeposit(this.Deposit);
        return this.ToWin;
    },

    getTotalGainEW: function ()
    {
        if (this.Selection.isSPSelection())
        {
            return 0;
        }
        var roundingMode = typeof BetSlip != "undefined" && BetSlip.GainRoundingMode || 0;

        return GainUtil.round(this.Selection.getGainEW(), roundingMode);
    },

    getWaitingBetRequest: function ()
    {
        if (SPPurchaseStatus.IsWaiting(this.Status))
        {
            var res = {
                RowID: this.WaitingBetID, //RowID is actually the new WaitingBetID GUID
                WainingBetID: 0,
                WaitingBetType: this.BetType,
                State: this.Status,
                Odds: this.Selection.Odds,
                Deposit: this.Deposit
            };

            if (typeof this.Selection.Points != "undefined")
                res.Points = this.Selection.Points;

            return res;
        }
        else
        {
            return false;
        }
    }

}, TSPSingleBet);

//*************************************************************************************************************************
function SPMultiLineBet(bet, selections, multiLineItem, status, waitingBetID)
{
    SPBaseBet.call(this);
    this.BetType = bet.BetTypeID;
    this.BuyPoints = false;
    this.IsTeaser = false;
    this.Selection = multiLineItem;
    this.ChildSelections = selections;
    this.BetID = bet.BetID;

    this.BetsCount = bet.NumberOfBets;
    this.Deposit = bet.Stake;
    this.FreeBetStake = bet.FreeBetStake;
    this.Status = status;
    this.MaxBet = bet.MaxBet;
    this.Final = ((status == SPPurchaseStatus.Accepted) || (status == SPPurchaseStatus.Declined));
    this.Odds = bet.Odds;
    this.Rate = Odds.convertFromAmerican(this.Odds, OddStyle.EUROPEAN);
    this.RowID = null;
    this.IsEWBet = false;
    this.FreeBet = multiLineItem.FreeBet;

    this.updateStatus(waitingBetID);
}

$.extend(SPMultiLineBet.prototype, SPBaseBet.prototype,
{
    serialize: function ()
    {
        return {
            BetType: this.BetType,
            BetSize: this.BetSize,
            Odds: this.Odds,
            Rate: this.Rate,
            BetsCount: this.BetsCount,
            Deposit: this.Deposit,
            Status: this.Status,
            BetID: this.BetID,
            WaitingBetID: this.WaitingBetID,
            Selection: this.Selection,
            ChildSelections: this.ChildSelections,
            ViewKey: this.ViewKey
        };
    },

    restore: function (purchase, data)
    {
    },

}, TSPSingleBet);

$.extend(SPComboBet.prototype, SPBaseBet.prototype,
{
    serialize: function ()
    {
        return {
            BetType: this.BetType,
            BetSize: this.BetSize,
            Odds: this.Odds,
            Rate: this.Rate,
            BetsCount: this.BetsCount,
            Deposit: this.Deposit,
            Status: this.Status,
            BetID: this.BetID,
            WaitingBetID: this.WaitingBetID,
            ViewKey: this.ViewKey
        };
    },

    restore: function (purchase, data)
    {
    },

    getComboMaxBet: function ()
    {
        var maxBetCombo;

        for (var key in this.Selections)
        {
            var line = this.Selections[key];

            if (!maxBetCombo)
            {
                maxBetCombo = line.MaxBetCombo;
            }
            else if (line.MaxBetCombo < maxBetCombo && line.MaxBetCombo > 0)
            {
                maxBetCombo = line.MaxBetCombo;
            }
        }

        if (maxBetCombo > 0 && BetSlip.StakeRounding == BetSlip.StakeRoundingMethods.ZeroDecimalPoints)
        {
            maxBetCombo = Math.floor(maxBetCombo);
        }

        return maxBetCombo;
    },

    getStakeBreakdown: function (currencyFormat)
    {
        var betsText = this.BetsCount > 1 ? $dict.ob("ComboNamesBets") : $dict.ob("ComboNameBet");
        var stakePerBet = this.Deposit;
        var currencyCode = this.Purchase.getCurrencyCode();
        currencyFormat = currencyFormat || stakeBreakdownCurrencyFormat;
        return ("{0} {1} " + $string("General").x + " ").format(this.NumberOfBets, betsText) + currencyFormat.format(MoneyFormat.format(stakePerBet), currencyCode);
    }

}, TSPComboBet);

//*************************************************************************************************************************
function SPTeaserBet(bet, selections, status, waitingBetID)
{
    SPBaseBet.call(this);
    this.BetType = 4;
    this.IsTeaser = true;

    this.Selections = selections;
    this.Deposit = bet.Stake;
    this.FreeBetStake = bet.FreeBetStake;
    this.Status = status;
    this.BetID = bet.BetID;
    this.Final = ((status == SPPurchaseStatus.Accepted) || (status == SPPurchaseStatus.Declined));
    this.MaxBet = bet.MaxBet;
    this.IsLive = bet.IsLive;
    this.IsEWBet = false;
    this.RowID = null;
    this.Odds = bet.Odds;
    this.Rate = Odds.convertFromAmericanToDecimal(this.Odds, true);
    this.TeaserTypeID = bet.TeaserTypeID;
    this.BetName = bet.BetName;

    this.updateStatus(waitingBetID);
}

$.extend(SPTeaserBet.prototype, SPBaseBet.prototype,
{
    serialize: function ()
    {
        var vkArr = [];
        for (var i in this.Selections)
        {
            vkArr.push(this.Selections[i].ViewKey);
        }
        return {
            BetType: this.BetType,
            SelectionViewKeys: vkArr,
            Odds: this.Odds,
            Rate: this.Rate,
            TeaserTypeID: this.TeaserTypeID,
            Deposit: this.Deposit,
            Status: this.Status,
            BetID: this.BetID,
            WaitingBetID: this.WaitingBetID,
            ViewKey: this.ViewKey
        };
    },

    restore: function (purchase, data)
    {
    },

    getGain: function ()
    {
        var gain = this.Rate * this.Deposit;

        if (this.FreeBetStake && this.FreeBet && !this.FreeBet.IsRiskFreeBet)
            gain -= this.FreeBetStake;

        return gain;
    },


}, TTeaserBet);

//*************************************************************************************************************************
function SPSystemBet(bet, selections, status, waitingBetID)
{
    SPBaseBet.call(this);
    this.BetType = 13;
    this.IsTeaser = false;

    this.BetSize = bet.ComboSize;
    this.BetsCount = bet.NumberOfBets;
    this.BetName = bet.BetName;
    this.Selections = selections;
    this.Deposit = bet.Stake;
    this.Status = status;
    this.BetID = bet.BetID;
    this.Final = ((status == SPPurchaseStatus.Accepted) || (status == SPPurchaseStatus.Declined));
    this.IsEWBet = bet.IsEachWay;
    this.MaxBet = bet.MaxBet;
    this.IsLive = bet.IsLive;
    this.VariationType = bet.ComboSize;
    this.Odds = bet.Odds;
    this.Rate = bet.ClientOdds ? bet.ClientOdds : Odds.convertFromAmerican(this.Odds, OddStyle.EUROPEAN);
    this.Gain = bet.Gain;
    this.RowID = null;
    this.LoyaltyPointsPromotions = bet.LoyaltyPointsPromotions;

    this.updateStatus(waitingBetID);
}

$.extend(SPSystemBet.prototype, SPBaseBet.prototype,
{
    serialize: function ()
    {
        var vkArr = [];
        for (var i in this.Selections)
        {
            vkArr.push(this.Selections[i].ViewKey);
        }
        return {
            BetType: this.BetType,
            BetSize: this.BetSize,
            SelectionViewKeys: vkArr,
            Odds: this.Odds,
            Rate: this.Rate,
            Deposit: this.Deposit,
            Status: this.Status,
            BetID: this.BetID,
            WaitingBetID: this.WaitingBetID,
            ViewKey: this.ViewKey
        };
    },

    restore: function (purchase, data)
    {
    },

    getComboMaxBet: function ()
    {
        var maxBetCombo;

        for (var key in this.Selections)
        {
            var line = this.Selections[key];

            if (!maxBetCombo)
            {
                maxBetCombo = line.MaxBetCombo;
            }
            else if (line.MaxBetCombo < maxBetCombo && line.MaxBetCombo > 0)
            {
                maxBetCombo = line.MaxBetCombo;
            }
        }

        if (maxBetCombo > 0 && BetSlip.StakeRounding == BetSlip.StakeRoundingMethods.ZeroDecimalPoints)
        {
            maxBetCombo = Math.floor(maxBetCombo);
        }

        return maxBetCombo;
    },

}, TSystemBet);

/**** serizlization *****/

$.extend(SPPurchase.prototype,
{
    serialize: function ()
    {
        var sls = [];

        for (var i in this.Selections)
            sls.push(this.Selections[i].serialize());

        //Currently we don't need to serialize bet data - that comes from server
        //var bts = [];

        //for (var i in this.Bets)
        //    bts.push(this.Bets[i].serialize());

        return {
            Type: "SPPurchase",
            Selections: sls,
            //Bets: bts,
            ViewKey: this.ViewKey,
            PurchaseStatus: this.PurchaseStatus,
            PurchaseTypeID: this.PurchaseTypeID,
            AltOddStyle: this.alternativeOddsStyle,
            PurchaseID: this.PurchaseID,
            WaitingBetID: this.WaitingBetID,
            Final: this.Final,
            SystemKey: this.SystemKey
        };
    },

    getComboVariants: function ()
    {
        // populate selection groups
        var selectionGroups = [];
        for (var j in this.Selections)
        {
            var selection = this.Selections[j];
            var groupKey = selection.getComboGroupKey();
            if (typeof selectionGroups[groupKey] == 'undefined')
            {
                selectionGroups[groupKey] = [];
            }
            selectionGroups[groupKey].push(selection);
        }
        var countArray = selectionGroups.select(function (grp) { return Array.getLength(grp); });
        var variants = BetMath.sumOfProducts(countArray);

        return variants;
    },

    deserialize: function (data)
    {   //Currently not in use, bets are reconstructed based on data from server. Possible to phase out the need for web storage and serialize/deserialize
        //if (!data || data.Type != "SPPurchase") return;

        //this.ViewKey = data.ViewKey;
        //this.PurchaseStatus = data.PurchaseStatus;
        //this.PurchaseTypeID = data.PurchaseTypeID;
        //this.Final = data.Final;
        //this.SystemKey = data.SystemKey;
        //this.PurchaseID = data.PurchaseID;
        //this.WaitingBetID = data.WaitingBetID;

        //for (var i in data.Selections)
        //{
        //    var sData = data.Selections[i];
        //    var sel = BaseSlipSelection.deserializeSelection(sData);
        //    sel.ViewKey = sData.ViewKey;
        //    //Use this to copy all properties from session storage for the purchase templates
        //    sel.processBetUpdate(sData);
        //    this.Selections.push(sel);
        //}

        //for (var i in data.Bets)
        //{
        //    var betData = data.Bets[i];

        //    switch (betData.BetType)
        //    {
        //        case 1:
        //            //Single bets
        //            var selection = this.Selections.find(function (s) { return s.ViewKey == betData.SelectionViewKey; });
        //            if (selection)
        //            {
        //                var bet = new SPSingleBet(selection, betData.Deposit, betData.Status, betData.BetID, betData.WaitingBetID);
        //                bet.ViewKey = betData.ViewKey;
        //                this.Bets.push(bet);
        //            }
        //            break;
        //        case 2:
        //            //Combo bets
        //            var bet = new SPComboBet(betData.BetSize, betData.Odds, betData.Rate, betData.BetsCount, betData.Deposit, betData.Status, betData.BetID, betData.WaitingBetID);
        //            bet.ViewKey = betData.ViewKey;
        //            this.Bets.push(bet);
        //            break;
        //        case 3:
        //            //Teaser bets
        //            var selections = [];
        //            for (var idx in betData.SelectionViewKeys)
        //            {
        //                var teaserSel = this.Selections.find(function (s) { return s.ViewKey == betData.SelectionViewKeys[idx]; });
        //                if (teaserSel) selections.push(teaserSel);
        //            }

        //            if (Array.getLength(selections) > 0)
        //            {
        //                var bet = new SPTeaserBet(selections, betData.Odds, betData.Rate, betData.TeaserTypeID, betData.Deposit, betData.Status, betData.BetID, betData.WaitingBetID);
        //                bet.ViewKey = betData.ViewKey;
        //                this.Bets.push(bet);
        //            }
        //            break;
        //        case 13:
        //            //System bets
        //            var selections = [];
        //            for (var idx in betData.SelectionViewKeys)
        //            {
        //                var teaserSel = this.Selections.find(function (s) { return s.ViewKey == betData.SelectionViewKeys[idx]; });
        //                if (teaserSel) selections.push(teaserSel);
        //            }

        //            if (Array.getLength(selections) > 0)
        //            {
        //                var bet = new SPSystemBet(betData.BetType, betData.selections, betData.Odds, betData.Rate, betData.Deposit, betData.Status, betData.BetID, betData.WaitingBetID);
        //                bet.ViewKey = betData.ViewKey;
        //                this.Bets.push(bet);
        //            }
        //            break;
        //    }
        //}
    },

    restore: function (data)
    {
        return this.deserialize(data);
    },

    getSelectionGroups: function (selections)
    {
        if (!selections)
            selections = this.Selections;
        // populate selection groups
        var selectionGroups = [];
        for (var i in selections)
        {
            var selection = selections[i];
            var groupKey = selection.getComboGroupKey();
            if (typeof selectionGroups[groupKey] == 'undefined')
            {
                selectionGroups[groupKey] = [];
            }
            selectionGroups[groupKey].push(selection);
        }

        return selectionGroups;
    },

    getSelectionsToBuild: function ()
    {
        var selectionsToBuild = [];
        var containEW = this.Bets.any(function (b) { return b.IsEWBet; });
        for (var i in this.Selections)
        {
            var selection = this.Selections[i];
            if (selection.BetType == 7 && selection.IsEachWaySelection) continue;
            if (containEW && selection.BetType == 7) selection.EachWayIncluded = true;
            if (IsUKBetSlip && !selection.IsInMultiples) continue;
            if (!selection.IsExtended) selectionsToBuild.push(selection);
        }
        return selectionsToBuild;
    },

    // Decimal style odds must be displayed when the current odds-style is Malaysian odds-style or Indo
    getAlternativeOddsStyle: function ()
    {
        if (this.PurchaseTypeID == SPPurchaseType.Combo || this.PurchaseTypeID == SPPurchaseType.StraightCombo || this.PurchaseTypeID == SPPurchaseType.System)
        {
            if (currentOddStyle == OddStyle.MALAY || currentOddStyle == OddStyle.INDO || 
                (currentOddStyle == OddStyle.HONGKONG && !UseHKOddsStyleForAllMarkets))
                return OddStyle.EUROPEAN;
        }
    },
});

/***** GUI *********************/

$.extend(SPPurchase.prototype,
{
    getGuiElement: function ()
    {
        return document.getElementById("purchase_" + this.ViewKey);
    },

    isEmpty: function ()
    {
        return Array.isEmpty(this.Bets);
    },

    expand: function ()
    {
        this.Expanded = true;
        var elem = this.getGuiElement();

        if (elem)
        {
            elem.classList.add("expanded");
        }
    },

    getExpanded: function ()
    {
        return this.Expanded || this.PurchaseStatus == SPPurchaseStatus.NewOffer;
    },

    getStatusText: function ()
    {
        switch (this.PurchaseStatus)
        {
            case SPPurchaseStatus.Accepted:
                return $dict.bs("BetAccepted") + this.PurchaseID;
            case SPPurchaseStatus.Declined:
                return $dict.bs("BetsNotAccepted");
            case SPPurchaseStatus.RegulatorTimeOut:
                return $dict.bs("RegulatorTimedOut");
            case SPPurchaseStatus.NewOffer:

                var depositUpdated = false;
                var oddsUpdated = false;
                var pointsUpdated = false;

                for (var bIdx in this.Bets)
                {
                    var bet = this.Bets[bIdx];
                    if (bet.DepositUpdated)
                    {
                        depositUpdated = true;
                    }

                    if (bet.OddsUpdated)
                    {
                        oddsUpdated = true;
                    }

                    if (bet.Selection)
                    {
                        if (bet.Selection.UpdatedPoints)
                        {
                            pointsUpdated = true;
                        }
                    }
                    else
                    {
                        var betSelections = bet.Purchase.getMappedSelections(bet.Mappings);

                        if (betSelections && betSelections.any(function (sel) { return sel.UpdatedPoints; }))
                        {
                            pointsUpdated = true;
                        }
                    }
                }

                if (oddsUpdated && depositUpdated && pointsUpdated)
                {
                    return $dict.bs("AllChanged");
                }
                else if (oddsUpdated && depositUpdated)
                {
                    return $dict.bs("OddsDepositChanged");
                }
                else if (oddsUpdated && pointsUpdated)
                {
                    return $dict.bs("OddsPointsChanged");
                }
                else if (oddsUpdated)
                {
                    return $dict.bs("OddsChanged");
                }
                else if (depositUpdated)
                {
                    return $dict.bs("DepositChanged");
                }
                else if (pointsUpdated)
                {
                    return $dict.bs("PointsChanged");
                }
            default:
                if (this.Bets.any(function (element) { return element.Status == SPPurchaseStatus.Waiting && element.Deposit > element.MaxBet && !element.IsLive; }))
                {
                    return $dict.bs("DepositOverMaxBet");
                }

                return $dict.bs("Waiting");
        }

        return false;
    },

    getExternalIdText: function (separator)
    {
        if (typeof (BetSlipRegulations) === "undefined" || !BetSlipRegulations.IsItalian)
        {
            return "";
        }

        switch (this.PurchaseStatus)
        {
            case SPPurchaseStatus.Accepted:
                return separator + $dict.bs("BetAcceptedByRegulator") + this.ExternalTicketID + "\n" + $dict.bs("ExternalTimestamp") + this.ExternalTimestamp;
            default:
                return "";
                break;
        }
    },

    getTotalDeposit: function ()
    {
        var res = 0;
        for (var i in this.Bets)
        {
            var bet = this.Bets[i];
            res += bet.getTotalDeposit();
        }
        return res;
    },

    getTotalGain: function ()
    {
        var res = 0;
        for (var i in this.Bets)
        {
            var bet = this.Bets[i];
            res += bet.getTotalGain();
        }
        return res;
    },

    canRemove: function ()
    {
        return this.Final;
    },

    canAutoRemove: function ()
    {
        return this.canRemove() && ((new Date()).getTime() - this.LastUpdateTime > 15000);
    },

    remove: function ()
    {
        var element = this.getGuiElement();
        if (element)
        {
            element.parentNode.removeChild(element);
        }
    },

    restoreSelections: function ()
    {
    },

    updateView: function ()
    {
        var __html = [];
        var element = this.getGuiElement();
        if (element)
        {
            this.buildInnerView(__html);
            element.innerHTML = __html.join("");
            element.className = this.getStatusClass();
        }
    },

    collapse: function ()
    {
        if (this.PurchaseStatus == SPPurchaseStatus.NewOffer) return;

        this.Expanded = false;
        var element = this.getGuiElement();
        if (element)
        {
            element.classList.remove("expanded");
        }
    },

    getSelections: function ()
    {
        var res = [];
        for (var i in this.Selections)
        {
            //res.push(this.Selections[i]);
            var selection = this.Selections[i];
            res[selection.ViewKey] = selection;
        }

        return res;
    },

    getAllSelections: function ()
    {
        var res = [];
        for (var i in this.Bets)
        {
            var betSelections = this.Bets[i].Selections;

            for (var key in betSelections)
            {
                var selection = betSelections[key];

                if (Array.indexOf(Array.getKeys(res), selection.ViewKey) == -1)
                {
                    res[selection.ViewKey] = selection;
                }
            }
        }

        return res;
    },

    getSortedBets: function ()
    {
        var res = Array.getValues(this.Bets);
        res.sort(
            chainSort(

                function (b1, b2)
                {
                    if (b1.VariationType > b2.VariationType) return 1;
                    if (b1.VariationType < b2.VariationType) return -1;
                    return 0;
                },

                function (b1, b2)
                {
                    if (b1.IsEWBet && b2.IsEWBet) return 0;
                    return b1.IsEWBet ? 1 : -1;
                }
            )
        );

        return res;
    },

    // Returns the summary of a combination of bets - Doubles, Trebles, 4folds, etc. If the number of bets for
    // this variation type (ex. 4 out of 4, i.e. full combo), then minOdds will equal maxOdds and minWin will equal maxWin
    calculateVariationSummary: function (bet)
    {
        function VariationSummary()
        {
            this.variationType = 0;
            this.minOdds = 0;
            this.maxOdds = 0;
            this.numberOfBets = 1;
            this.minWin = 0;
            this.maxWin = 0;
            this.deposit = 0; // stake per bet
            this.isVariationFullCombo = true;
            this.totalDeposit = 0; // total stake for all bets from this variation type
            this.DepositUpdated = false;
            this.OddsUpdated = false;
            this.isEW = false;
        }

        var varSummary = new VariationSummary();
        var variants = bet.Purchase.getComboVariants();
        var roundingMode = typeof BetSlip != "undefined" && BetSlip.GainRoundingMode || 0;
        varSummary.variationType = bet.VariationType;
        varSummary.numberOfBets = bet.BetsCount;
        varSummary.isVariationFullCombo = (varSummary.numberOfBets == 1);
        varSummary.deposit = bet.Deposit;
        varSummary.totalDeposit = varSummary.numberOfBets * varSummary.deposit;
        varSummary.FreeBetStake = bet.FreeBetStake;
        varSummary.FreeBet = bet.FreeBet;
        varSummary.isEW = bet.IsEWBet;
        varSummary.BetName = bet.BetName;


        if (varSummary.isVariationFullCombo)
        {
            varSummary.minOdds = varSummary.maxOdds = Odds.convertFromAmericanToDecimal(bet.Odds);   // american

            if (ComboBonusProvider.RecalculatedComboBonuses && ComboBonusProvider.RecalculatedComboBonuses[bet.VariationType] && !bet.FreeBetID && !bet.IsEWBet && variants[bet.VariationType] == bet.BetsCount)
            {
                varSummary.minWin = varSummary.maxWin = GainUtil.round(ComboBonusProvider.RecalculatedComboBonuses[bet.VariationType].ComboBonusOdds * UserInfo.TaxProvider.applyTaxToDeposit(varSummary.deposit), GainRoundModes.FLOOR);
            }
            else
            {
                varSummary.minWin = varSummary.maxWin = bet.getTotalGain();
            }
        }
        else
        {
            varSummary.minOdds = this.getMinOdds(bet);
            varSummary.maxOdds = this.getMaxOdds(bet);

            varSummary.minWin = varSummary.deposit * varSummary.minOdds;

            // Calculate max winnings as if all the bets for this variation type are won - use the sum up their odds
            if (ComboBonusProvider.RecalculatedComboBonuses && ComboBonusProvider.RecalculatedComboBonuses[bet.VariationType] && !bet.FreeBetID && !bet.IsEWBet && variants[bet.VariationType] == bet.BetsCount)
            {
                varSummary.maxWin = ComboBonusProvider.RecalculatedComboBonuses[bet.VariationType].ComboBonusOdds * UserInfo.TaxProvider.applyTaxToDeposit(varSummary.deposit);
            }
            else
            {
                varSummary.maxWin = bet.getTotalGain();
            }
        }

        varSummary.DepositUpdated = bet.DepositUpdated;
        varSummary.OddsUpdated = bet.OddsUpdated;
        varSummary.minWin = GainUtil.round(varSummary.minWin, roundingMode);
        varSummary.maxWin = GainUtil.round(varSummary.maxWin, roundingMode);
        varSummary.LoyaltyPointsPromotions = bet.LoyaltyPointsPromotions;

        return varSummary;
    },

    getMinOdds: function (bet)
    {
        var minOdds = 0;
        if (!bet)
        {
            return minOdds;
        }

        var variationType = bet.VariationType;
        var minOddsSelections = [];

        // get selection groups sorted by their odds ascending
        var selectionGroups = this.getSortedSelectionGroups(true, bet.Selections);
        if (Array.getLength(selectionGroups) == 0)
        {
            return minOdds;
        }

        for (var i in selectionGroups)
        {
            var selections = selectionGroups[i];
            if (Array.getLength(selections) > 0)
            {
                // add the selection with min odds from each selection group
                minOddsSelections.push(selections[0]);
            }
        }
        if (Array.getLength(minOddsSelections) == 0)
        {
            return minOdds;
        }

        // sort minOddsSelections ascending
        minOddsSelections.sort(chainSort(function (e1, e2)
        {
            var odds1 = bet.IsEWBet && e1.IsEachWayEnabled ? e1.EWOdds : e1.Odds;
            var odds2 = bet.IsEWBet && e2.IsEachWayEnabled ? e2.EWOdds : e2.Odds;
            if (odds1 > odds2) return 1;
            if (odds1 < odds2) return -1;
            return 0;
        }));

        if (bet instanceof SPComboBet)
        {
            if (variationType <= Array.getLength(minOddsSelections))
            {
                minOdds = this.calculateOddsByVariationType(variationType, minOddsSelections, bet.IsEWBet);
            }
        }
        else if (bet instanceof SPSystemBet)
        {
            var systemKey = IsUKBetSlip ? parseInt(variationType) : parseInt(this.SystemKey);
            if (systemKey == Array.getLength(bet.Selections))
            {
                // when there are no single bets included, the minimum odds should be formed from the lowest double odds
                minOdds = this.calculateOddsByVariationType(2, minOddsSelections, bet.IsEWBet);
            }
            else if (systemKey > Array.getLength(bet.Selections))
            {
                // when there are single bets included, the minimum odds should be the lowest single odd
                minOdds = this.calculateOddsByVariationType(1, minOddsSelections, bet.IsEWBet);
            }
            else if (variationType <= Array.getLength(minOddsSelections))
            {
                minOdds = this.calculateOddsByVariationType(variationType, minOddsSelections, bet.IsEWBet);
            }
        }

        minOdds = Math.floor((minOdds * 100).toFixed(2)) / 100;
        //Store as decimal odds, since converting to US and back creates rounding problems
        //return Odds.convertFromDecimal(minOdds, OddStyle.AMERICAN);
        return minOdds;
    },

    getMaxOdds: function (bet)
    {
        var maxOdds = 0;
        if (!bet)
        {
            return maxOdds;
        }

        var variationType = bet.VariationType;
        var maxOddsSelections = [];

        // get selection groups sorted by their odds descending
        var selectionGroups = this.getSortedSelectionGroups(false, bet.Selections);
        if (Array.getLength(selectionGroups) == 0)
        {
            return maxOdds;
        }

        for (var i in selectionGroups)
        {
            var selections = selectionGroups[i];
            if (Array.getLength(selections) > 0)
            {
                // add the selection with max odds from each selection group
                maxOddsSelections.push(selections[0]);
            }
        }
        if (Array.getLength(maxOddsSelections) == 0)
        {
            return maxOdds;
        }

        // sort maxOddsSelections descending
        maxOddsSelections.sort(chainSort(function (e1, e2)
        {
            var odds1 = bet.IsEWBet && e1.IsEachWayEnabled ? e1.EWOdds : e1.Odds;
            var odds2 = bet.IsEWBet && e2.IsEachWayEnabled ? e2.EWOdds : e2.Odds;

            if (odds1 > odds2) return -1;
            if (odds1 < odds2) return 1;
            return 0;
        }));

        if (variationType <= Array.getLength(maxOddsSelections))
        {
            maxOdds = this.calculateOddsByVariationType(variationType, maxOddsSelections, bet.IsEWBet);
        }

        maxOdds = Math.floor((maxOdds * 100).toFixed(2)) / 100;
        //Store as decimal odds, since converting to US and back creates rounding problems
        //return Odds.convertFromDecimal(maxOdds, OddStyle.AMERICAN);
        return maxOdds;
    },

    getMappedSelections: function (mappings)
    {
        var selections = [];
        for (var idx in mappings)
        {
            var mapObj = mappings[idx];

            var mappedSelection = Array.find(this.Selections, function (sel) { return sel.ViewKey == mapObj.ViewKey; });
            if (mappedSelection) selections.push(mappedSelection);
        }
        return selections;
    },
    // Calculates the information displayed on the final system purchase: total stake, min/max odds, min/max winnings.
    // Note that if the system purchase contains single bets the min odd is the minimal odd of all the selections and
    // respectively the minimal winning is the winning of that single bet.
    calculateSystemSummary: function (bet)
    {
        if (!bet)
            return null;

        var sysSummary = new SystemSummary();
        sysSummary.numberOfBets = bet.BetsCount;
        sysSummary.deposit = bet.Deposit;
        sysSummary.totalDeposit = bet.Deposit * bet.BetsCount;
        sysSummary.BetName = bet.BetName;

        var lineCount = Array.getLength(bet.Selections);

        var minSingleOdd = Number.MAX_VALUE;
        var minSingleWin = 0;
        var systemMinOdds = 0, systemMaxOdds = 0;
        var maxOdds = Odds.convertFromAmericanToDecimal(bet.Odds);
        var variants = bet.Purchase.getComboVariants();

        if (this.SystemKey * 1 > lineCount)
        {
            sysSummary.hasSingleBets = true;
            for (var sIdx in bet.Selections)
            {
                var selection = bet.Selections[sIdx];
                if (selection.Odds < minSingleOdd)
                {
                    minSingleOdd = selection.Odds;
                    minSingleWin = selection.getGain(sysSummary.deposit);
                }
            }
            minSingleOdd = Odds.convertFromAmericanToDecimal(minSingleOdd);
        }

        if (this.SystemKey * 1 < lineCount)
        {
            maxOdds = this.getMaxOdds(bet);
        }

        sysSummary.minOdds = sysSummary.hasSingleBets ? minSingleOdd : this.getMinOdds(bet);
        sysSummary.minWin = sysSummary.hasSingleBets ? minSingleWin : (sysSummary.minOdds * sysSummary.deposit);

        if (ComboBonusProvider.RecalculatedComboBonuses && ComboBonusProvider.RecalculatedComboBonuses[bet.VariationType] && !bet.FreeBetID && !bet.IsEWBet && variants[bet.VariationType] == bet.BetsCount)
        {
            sysSummary.maxWin = bet.getTotalGain() + (ComboBonusProvider.RecalculatedComboBonuses[bet.VariationType].AdditionalOdds * UserInfo.TaxProvider.applyTaxToDeposit(sysSummary.deposit));
        }
        else
        {
            sysSummary.maxWin = bet.getTotalGain();
        }

        sysSummary.maxOdds = maxOdds;
        var roundingMode = typeof BetSlip != "undefined" && BetSlip.GainRoundingMode || 0;
        sysSummary.maxWin = GainUtil.round(sysSummary.maxWin, roundingMode);
        sysSummary.minWin = GainUtil.round(sysSummary.minWin, roundingMode);

        return sysSummary;
    },

    calculateCombinedSystemSummaryCommon: function (bets, calcEW, containSP)
    {
        var totalMaxOdds = 0;
        var combinedSummary = new SystemSummary()

        for (var bIdx in bets)
        {
            var bet = bets[bIdx];
            var sysSummary = undefined;

            if (!calcEW && !bet.IsEWBet)
            {
                sysSummary = this.calculateSystemSummary(bet);
            }

            if (calcEW && bet.IsEWBet)
            {
                sysSummary = this.calculateSystemSummary(bet);
            }

            if (sysSummary != undefined)
            {
                combinedSummary.numberOfBets += sysSummary.numberOfBets;
                combinedSummary.deposit = sysSummary.deposit;
                combinedSummary.totalDeposit += sysSummary.totalDeposit;

                if ((sysSummary.minOdds < combinedSummary.minOdds || combinedSummary.minOdds == 0) && !containSP)
                {
                    combinedSummary.minOdds = sysSummary.minOdds;
                }

                if ((sysSummary.maxOdds > combinedSummary.maxOdds) && !containSP)
                {
                    combinedSummary.maxOdds = sysSummary.maxOdds;
                }

                combinedSummary.maxWin += sysSummary.maxWin;

                if ((sysSummary.minWin < combinedSummary.minWin || combinedSummary.minWin == 0) && !containSP)
                {
                    combinedSummary.minWin = sysSummary.minWin;
                }

                if (bet.IsEWBet)
                {
                    combinedSummary.EWLoyaltyPointsPromotions = bet.LoyaltyPointsPromotions;
                }
                else
                {
                    combinedSummary.LoyaltyPointsPromotions = bet.LoyaltyPointsPromotions;
                }
            }
        }

        combinedSummary.isEW = calcEW;

        combinedSummary.DepositUpdated = bets.any(function (b) { return b.DepositUpdated; });
        combinedSummary.OddsUpdated = bets.any(function (b) { return bet.OddsUpdated; });

        return combinedSummary;
    },

    calculateCombinedSystemSummary: function (bets, containSP)
    {
        return this.calculateCombinedSystemSummaryCommon(bets, false, containSP);
    },

    calculateCombinedSystemSummaryEW: function (bets, containSP)
    {   //Only the E/W bets
        return this.calculateCombinedSystemSummaryCommon(bets, true, containSP);
    },

    // returns an associative array with selections sorted by their Odds ascending
    // or descending and grouped by selection combo group key
    getSortedSelectionGroups: function (asc, selections)
    {
        if (!selections)
        {
            selections = this.Selections;
        }
        // populate selection groups
        var selectionGroups = [];
        for (var i in selections)
        {
            var selection = selections[i];

            if (IsUKBetSlip && !selection.IsInMultiples)
            {
                continue;
            }

            var groupKey = selection.getComboGroupKey();
            if (typeof selectionGroups[groupKey] == 'undefined')
            {
                selectionGroups[groupKey] = [];
            }
            selectionGroups[groupKey].push(selection);
        }

        // sort selection groups
        for (var i in selectionGroups)
        {
            var selectionGroup = selectionGroups[i];
            selectionGroup.sort(chainSort(function (e1, e2)
            {
                if (e1.Odds > e2.Odds) return asc ? 1 : -1;
                if (e1.Odds < e2.Odds) return asc ? -1 : 1;
                return 0;
            }));
        }
        return selectionGroups;
    },

    // calculates the odds from the provided oddsSelectionArr for the provided variationType
    calculateOddsByVariationType: function (variationType, oddsSelectionArr, isEW) {
        if (variationType > Array.getLength(oddsSelectionArr)) {
            return 0;
        }

        var odds = 1;
        for (var i = 0; i < variationType; i++) {
            var sel = oddsSelectionArr[i];
            var odd = BetMath.roundDecimalOdds(isEW && sel.IsEachWayEnabled ? sel.getRealOddEW() : sel.getRealOdd(), BetSlip.BaseOddsRoundingMode, !BetSlip.ForceTwoDigitsForOddsRounding);
            odds = sbMathOperations.Multiplication(odds, odd, 6);
        }
        return BetMath.roundDecimalOdds(odds, BetSlip.ComboOddsRoundingMode, !BetSlip.ForceTwoDigitsForOddsRounding);
    },

    getCurrencyCode: function ()
    {
        return BetSlip.getCurrencyCode();
    },

    formatMessageForZeroOdds: function ()
    {
        var eventTypeID = this.Bet.Selection.EventTypeID;
        switch (eventTypeID)
        {
            case 784:
                return $dict.betgui("BestToteOrSP");
            case 785:
                return $dict.betgui("BestTote");
            case 786:
                return $dict.betgui("MidTote");
            default:
                return $dict.betgui("SP");
        }
    },
});

$.extend(SPPurchase.prototype,    
    TSPComboPurchase,
    TSPSystemPurchase,
    TSPUKPurchase,
    TSPPurchase);

/***** status update calls ******/

$.extend(SPPurchase.prototype,
{
    updateFromServer: function (datahash, asynccall)
    {
        for (var rowid in datahash)
        {
            if (rowid === this.WaitingBetID)
            {
                this.LastUpdateTime = (new Date()).getTime();
                var data = datahash[rowid];

                for (var sIdx in data.Selections)
                {
                    var selUpdatedData = data.Selections[sIdx];
                    var selection = Array.find(this.Selections, function (sel) { return sel.ViewKey == selUpdatedData.ViewKey; });
                    if (selection)
                    {
                        selection.UpdatedOdds = (selection.Odds != selUpdatedData.Odds);
                        selection.Odds = selUpdatedData.Odds;
                        selection.UpdatedPoints = (selection.Points != selUpdatedData.Points);
                        
                        //while bet is placing Points for ML are set to null instead of 0 
                        //(0 points are set when the selection is added to BetSlip because ML doesn't have Points)
                        //which breaks retain selection for YourBet
                        selection.Points = selUpdatedData.Points == null ? selection.Points : selUpdatedData.Points;
                        if (selection instanceof QALiveSlipSelection)
                        {
                            selection.processHighlightUpdates(selUpdatedData, false);
                        }
                    }
                }

                for (var bIdx in data.Bets)
                {
                    var betData = data.Bets[bIdx];
                    var bet = Array.find(this.Bets, function (b) { return b.BetID == betData.BetID; });
                    if (bet)
                    {
                        bet.updateFromServer(betData, data.BetState);
                        if (typeof ConfigurationLoyaltyProgram !== "undefined" && ConfigurationLoyaltyProgram.isInitilized)
                        {
                            bet.LoyaltyPointsPromotions = BetSlipUtil.getBetLoyaltyPoints(data, bet);
                        }
                    }
                    else if (typeof ConfigurationLoyaltyProgram !== "undefined" && ConfigurationLoyaltyProgram.isInitilized)
                    {
                        betData.LoyaltyPointsPromotions = BetSlipUtil.getBetLoyaltyPoints(data, betData);
                        BetSlipUtil.setEWLoyaltyPoints(this.Bets, betData);
                    }
                }

                this.PurchaseID = data.BetID;
                this.ExternalTicketID = data.ExternalTicketID;

                if (data.BetState == SPPurchaseStatus.Declined)
                {//remove reserved freebets if freebets
                    for (var key in this.Bets)
                    {
                        if (this.Bets[key] instanceof SPSingleBet)
                        {
                            if (this.Bets[key].Selection.FreeBet)
                            {
                                BetSlip.CurrentMode.removeReservedFreeBets(this.Bets[key].Selection.FreeBet.BonusID);
                            }
                        }
                        else if (this.Bets[key] instanceof SPComboBet)
                        {
                            if (this.Bets[key].FreeBetID)
                            {
                                BetSlip.CurrentMode.removeReservedFreeBets(this.Bets[key].FreeBetID);
                            }
                        }

                    }

                }
                this.updateStatus(data.BetState);

                if (typeof (data.Printout) !== "undefined")
                {
                    this.Printout = data.Printout;
                }

                break;
            }
        }
    },

    restore: function (data)
    {

    },

    getAllWaitingBets: function ()
    {
        var arr = [];

        for (var i in this.Bets)
        {
            var wb = this.Bets[i].getWaitingBetRequest();
            if (wb) arr.push(wb);
        }

        return arr;
    },

    updateStatus: function (newStatus)
    {
        this.Status = this.PurchaseStatus = newStatus;
        this.Final = ((this.PurchaseStatus == SPPurchaseStatus.Accepted) || (this.PurchaseStatus == SPPurchaseStatus.Declined));
        var shouldSkip = false;
        for (var i in this.Bets)
        {
            var bet = this.Bets[i];
            bet.Status = this.PurchaseStatus;
            bet.Final = this.Final;
            if (bet.BetType == BetTypes.PulseBet || bet.BetType == BetTypes.YourBet)
            {
                shouldSkip = true;
            }
        }

        for (var i in this.Selections)
        {
            var sel = this.Selections[i];
            sel.Final = this.Final;
        }
        if (!shouldSkip)
        {
            this.updateView();
        }
    },

    //*****************************************
    //New Offer methods
    findBet: function (rowID)
    {   //Necessary for backwards compatibility
        return this.WaitingBetID == rowID ? this : null;
    },

    setWaiting: function ()
    {
        this.updateStatus(SPPurchaseStatus.Waiting);
    },

    setRejected: function ()
    {
        if (this.Status != SPPurchaseStatus.Waiting && this.Status != SPPurchaseStatus.Declined) return;

        this.updateStatus(SPPurchaseStatus.Declined);
    },

    setDeclined: function ()
    {   //Remove purchase from slip - like in OBS
        BetSlip.removePurchase(this.ViewKey);
    },

    getBetsArray: function ()
    {
        return this.Bets;
    },

    getBankersCount: function (selectionsGroups)
    {
        var bankerCount = 0;
        for (var groupKey = 0 in selectionsGroups)
        {
            if (selectionsGroups.hasOwnProperty(groupKey))
            {
                for (var sIdx in selectionsGroups[groupKey])
                {
                    if (selectionsGroups[groupKey].hasOwnProperty(sIdx) &&
                        selectionsGroups[groupKey][sIdx].IsBanker)
                    {
                        bankerCount++;
                        break;
                    }
                }
            }
        }
        return bankerCount;
    }
});

function SystemSummary()
{
    this.numberOfBets = 0;
    this.deposit = 0;      // stake per bet
    this.totalDeposit = 0; // total stake for all bets in the system purchase
    this.hasSingleBets = false;
    this.minOdds = 0;      // if the system hassingles then this is the minimal american odd of all selections
    this.maxOdds = 0;
    this.minWin = 0;
    this.maxWin = 0;
    this.isEW = false;
    this.DepositUpdated = false;
    this.OddsUpdated = false;
}

function SPBaseCashOutBet(bet, status, waitingBetID)
{
    SPBaseBet.call(this);

    this.Status = status;
    this.BetID = bet.BetID;
    this.Final = ((status == SPPurchaseStatus.Accepted) || (status == SPPurchaseStatus.Declined));
    this.RowID = null;

    this.updateStatus(waitingBetID);
}

$.extend(SPBaseCashOutBet.prototype, SPBaseBet.prototype,
{
    updateStatus: function (waitingBetID)
    {
        if (!this.Final)
        {
            this.WaitingBetID = waitingBetID;
            this.RowID = waitingBetID;
        }
        else
        {
            this.WaitingBetID = null;
            this.RowID = null;
        }
    },

    updateFromServer: function (data)
    {
        this.CashOutAmountUpdated = this.CashOutAmount != data.Amount;
        this.CashOutAmount = data.Amount;
    }
});

function SPSingleCashOutBet(bet, selection, status, waitingBetID)
{
    SPBaseCashOutBet.call(this, bet, status, waitingBetID);

    this.BetType = 1;

    this.Selection = selection;
    this.BetsCount = 1;
}

$.extend(SPSingleCashOutBet.prototype, SPBaseCashOutBet.prototype, {});

function SPComboCashOutBet(bet, status, waitingBetID)
{
    SPBaseCashOutBet.call(this, bet, status, waitingBetID);

    this.BetType = 2;
    this.IsTeaser = false;
    this.BetSize = bet.ComboSize;
    this.BetsCount = bet.NumberOfBets;
    this.BetName = bet.BetName;
    this.IsEWBet = bet.IsEachWay;
    this.VariationType = bet.ComboSize;
}

$.extend(SPComboCashOutBet.prototype, SPBaseCashOutBet.prototype, {});

function SPCashOutPurchase(selections, bet, status, viewKey, purchaseTypeID, purchaseID, waitingBetID, cashOutAmount, isPartial)
{
    SPPurchase.call(this, selections, [bet], status, viewKey, purchaseTypeID, null, null, purchaseID, waitingBetID);

    this.IsCashOutRequest = true;
    this.IsPartialCashOutRequest = isPartial;
    this.Bet.CashOutAmount = cashOutAmount;

    this.onStatusChanged = {};
}

$.extend(SPCashOutPurchase.prototype, SPPurchase.prototype,
{
    updateFromServer: function (datahash, asynccall)
    {
        for (var rowid in datahash)
        {
            if (rowid === this.WaitingBetID)
            {
                this.LastUpdateTime = (new Date()).getTime();

                var bdata = null;
                var data = datahash[rowid];

                for (var bIdx in data.Bets)
                {
                    if (data.Bets[bIdx].BetID == this.Bet.BetID)
                    {
                        bdata = data.Bets[bIdx]
                    }
                }

                if (bdata)
                {
                    this.Bet.updateFromServer(bdata);
                }

                this.updateStatus(data.BetState);

                executeEvents(this.onStatusChanged, this, { state: data.BetState, betdata: bdata });

                break;
            }
        }
    },

    getStatusText: function ()
    {
        switch (this.PurchaseStatus)
        {
            case SPPurchaseStatus.Accepted:
                return $dict.bs("CashOutBetAccepted") + this.Bet.BetID;
            case SPPurchaseStatus.Declined:
                return $dict.bs("CashOutBetNotAccepted");
            case SPPurchaseStatus.NewOffer:
                return $dict.bs("CashOutNewOffer");
            case SPPurchaseStatus.RegulatorTimeOut:
                return $dict.bs("RegulatorTimedOut");
            default:
                return $dict.bs("Waiting");
        }

        return false;
    }

}, TSPCashOutPurchase);

window.SPBaseBet = SPBaseBet;
window.SPSingleBet = SPSingleBet;
window.SPMultiLineBet = SPMultiLineBet;
window.SPTeaserBet = SPTeaserBet;
window.SPSystemBet = SPSystemBet;
window.SystemSummary = SystemSummary;
window.SPSingleCashOutBet = SPSingleCashOutBet;
window.SPComboCashOutBet = SPComboCashOutBet;
window.SPCashOutPurchase = SPCashOutPurchase;

jsRequire("/JSComponents/Data/UniSlip/SPPurchase.ext.js");
