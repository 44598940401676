var SplitType8 = (function (base) {
    "use strict";
    var api = $.extend({}, base);

    api.getLineEventTypeName = function (selection) {        
        return selection.EventTypeName;
    };

    return api;
})(BaseSplitType);

window.SplitType8 = SplitType8;