﻿var ComboBonusPresenter = (function ()
{
    "use strict";

    var IsAdditionalMessageShown = false; 
    function getMessageType(bonusType)
    {
        var comboBonus = ComboBonusProvider.ComboBonuses[bonusType];
        var MessageEnum = ComboBonusProvider.BonusMessages;

        if (comboBonus)
        {
            if (comboBonus.MessageType === MessageEnum.AllLinesGetBonus)
            {
                return $dict.bs("AllLinesGetBonus").format(comboBonus.Percent);
            }
            else if (comboBonus.MessageType === MessageEnum.NotAllLineGetBonus)
            {
                return $dict.bs("NotAllLineGetBonus").format(comboBonus.Percent);
            }
            else if (comboBonus.MessageType === MessageEnum.AllLinesGetBonusBetween)
            {
                return $dict.bs("AllLinesGetBonusBetween").format(comboBonus.MinPercent, comboBonus.MaxPercent);
            }
            else if (comboBonus.MessageType === MessageEnum.NotLinesGetBonusBetween)
            {
                return $dict.bs("NotLinesGetBonusBetween").format(comboBonus.MinPercent, comboBonus.MaxPercent);
            }
        }     
    }

    function getMessageTypeOpenBets(msgType, percent, minPercent, maxPercent)
    {
        if (msgType === 1)
        {
            return $dict.ob("AllLinesGetBonus").format(percent);
        }
        else if (msgType === 2)
        {
            return $dict.ob("NotAllLineGetBonus").format(percent);
        }
        else if (msgType === 3)
        {
            return $dict.ob("AllLinesGetBonusBetween").format(minPercent, maxPercent);
        }
        else if (msgType === 4)
        {
            return $dict.ob("NotLinesGetBonusBetween").format(minPercent, maxPercent);
        }
    }

    function getAdditionalMessage()
    {
        return $dict.bs("AdditionalBonusMessage");
    }

    function getAdditionalMessageInfoLink()
    {
        return $dict.bs("AdditionalBonusMessageInfoLink");
    }

    function getAdditionalStaticMessage()
    {
        return $dict.bs("AdditionalBonusMessageStatic");
    }

    var winningBonusPopup = 
    {
        initialized: false,
        popup: null,
        title: null,
        content: null,
        setTitle: function(html)
        {
            if (!this.title) { this.init(); }
            this.title.innerHTML = html || "";
        },
        setContent: function(html)
        {
            if (!this.content) { this.init(); }
            this.content.innerHTML = html || "";
        },
        init: function()
        {
            window.popups = window.popups || {};
            popups.winningBonusPopup = popups.winningBonusPopup || new Popup("winning_bonus_popup");

            this.popup = document.getElementById("winning_bonus_popup");
            this.title = this.popup.getElementsByClassName("popupWindowHead")[0].children[0];
            this.content = this.popup.getElementsByClassName("popupMessage")[0].children[0];

            var popup = this.popup;
            document.body.appendChild(popup);
            this.initialized = true;
        },
        open: function()
        {
            if (!this.initialized) { this.init(); }
            popups.winningBonusPopup.show();
        }
    };

    var openComboBonusMessage =
    {
        betSlip: function(comboBonus)
        {
            var msg = $dict.bs("ComboBonusMessage") || $dict.ob("ComboBonusMessage");
            winningBonusPopup.setTitle(msg);
            winningBonusPopup.setContent(getMessageType(comboBonus));
            winningBonusPopup.open();
        },

        betHistoryDetails: function(msgType, percent, minPercent, maxPercent)
        {
            var msg = $dict.ob("ComboBonusMessage") || $dict.bs("ComboBonusMessage");
            winningBonusPopup.setTitle(msg);
            winningBonusPopup.setContent(getMessageTypeOpenBets(msgType, percent, minPercent, maxPercent));
            winningBonusPopup.open();
        },

        additionalMessageInPopup: function()
        {
            var msg = $dict.bs("ComboBonusAdditionalMessage") || $dict.ob("ComboBonusAdditionalMessage");
            var winningBonusPopupContent = $dict.bs("AdditionalBonusMessage") + $dict.bs("AdditionalBonusMessageInfoLink");
            winningBonusPopup.setTitle(msg);
            
            winningBonusPopup.setContent(winningBonusPopupContent);
            winningBonusPopup.open();
        },

        additionalMessage: function ()
        {
            var additionalMessage = document.getElementById("combo-bonus-additional-msg-container");
            var infoMessage = document.getElementById("combo-bonus-info-msg");

            if (additionalMessage && infoMessage)
            {                
                additionalMessage.classList[ComboBonusPresenter.IsAdditionalMessageShown ? "add" : "remove"]("isHidden");
                infoMessage.classList[ComboBonusPresenter.IsAdditionalMessageShown ? "remove" : "add"]("isHidden");
                ComboBonusPresenter.IsAdditionalMessageShown = !ComboBonusPresenter.IsAdditionalMessageShown;
            }
        }
    };

    function getExtraWinningFromComboBonus(bet)
    {
        var extraWinnings = bet.ComboPromotion.AdditionalOdds * bet.Deposit;
        return (extraWinnings.toFixed(2)) * 1;
    }

    return {
        openComboBonusMessage: openComboBonusMessage,
        getAdditionalStaticMessage: getAdditionalStaticMessage,
        getAdditionalMessageInfoLink: getAdditionalMessageInfoLink,
        getAdditionalMessage: getAdditionalMessage,
        getMessageTypeOpenBets: getMessageTypeOpenBets,
        getMessageType: getMessageType,
        getExtraWinningFromComboBonus: getExtraWinningFromComboBonus,
        winningBonusPopup: winningBonusPopup,
        IsAdditionalMessageShown: IsAdditionalMessageShown
    };
}());

window.ComboBonusPresenter = ComboBonusPresenter;