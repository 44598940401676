function BaseSPSlipMode()
{
    this.Errors = [];
    this.Active = false;
    this.CurrentError = false;
    this.IsBetInProcess = false;
    this.IsBetPlacedWithBonus = false;
    this.SelectionDrawType = "defaultLine";
    this.IsUKBetSlip = 0;
    this.IsSelectionRemoveButton = true;
    this.MinSelections = 1;

    this.UnsufficientFundsErrorId = -3;

    this.Order = 0;
    this.ID = "base";
    this.TabClass = "base";
    this.Name = "";
    //this.PurchaseTypeID = 0;

    this.EWVariants = [];
    this.SelectedEWVariants = [];
    this.EWSelections = [];

    // Hashsets containing update dates of selections so that blinking is not removed because of too many push updates
    this.SelectionsWithUpdatedOdd = [];
    this.SelectionsWithUpdatedScore = [];
    this.SelectionsWithUpdatedPoints = [];
    this.SelectionsWithUpdatedMatchSet = [];
    this.SelectionsWithUpdatedSetGame = [];
    this.SelectionsWithUpdatedGamePoint = [];
    this.SelectionsWithUpdatedGameInterval = [];

    //For UK Slip
    this.SelectedSystemEWVariants = [];

    this.FreeBet = null;
    this.initBetNames();

    this.HasPreviewMode = false;

    this.BankersCount = 0;
	this.acceptOddsChangesMode = CONSTANTS.AcceptOddsChangesModes.Disabled;
	
	//Geolocation
	this.isRunningGeoCheck = false;
}

$.extend(BaseSPSlipMode.prototype,
{
    init: function ()
    {

    },

    activate: function ()
    {
		this.Active = true;
    },

    deactivate: function ()
    {
		this.Active = false;
    },

    calcGain: function (odds, deposit)
    {
        if (!odds || !deposit) return 0;

        if (BetSlip.UseItalianGainRounding)
        {
            return odds * deposit;
        }
        else
        {
            var roundingMode = typeof BetSlip != "undefined" && BetSlip.GainRoundingMode || 0;
            var vodd = this.floorDecimal(odds);
            return GainUtil.round((vodd * deposit).toFixed(12), roundingMode);
        }
    },

    floorDecimal: function (num)
    {   //JS uses floating point numbers so precision is an issue when using Math.floor
        //Used for odds/gain calculations in the betslip
        return Odds._formatCurrentOdds(num);
    },

    SaveReservedFreeBetsInStorage: function (FreeBetID)
    {
        var array = [];
        if (!WebStorage.isSupported())
        {
            return;
        }

        var reservedFreeBets = this.getReservedFreeBets();
        if (reservedFreeBets)
        {
            array = reservedFreeBets.ReservedFreeBets;
            if (array)
            {
                array.push(FreeBetID);
            }
            else
            {
                array = [];
                array[0] = FreeBetID;
            }
        }
        else
        {
            array.push(FreeBetID);
        }

        StorageUtils.saveToStorage("ReservedFreeBets", JSON.stringify({ ReservedFreeBets: array }), sessionStorage);
    },

    getReservedFreeBets: function ()
    {

        if (!WebStorage.isSupported())
        {
            return [];
        }

        var sdata = StorageUtils.getFromStorage("ReservedFreeBets");

        var data = JSON.parse(sdata);
        if (!data)
        {
            return [];
        }

        return data;
    },

    initBetNames: function ()
    {

    },

    getBetNames: function ()
    {
        return [];
    },

    deselectStakeDropdown: function ()
    {
        if (!BetSlip.isDefaultStakeAndStakeValuesAvailable()) return;
        var dropDown = document.getElementById("stakedropdown");
        if (dropDown && dropDown.selectedIndex != -1)
        {
            dropDown.options[dropDown.selectedIndex].selected = false;
            dropDown.value = 0;
            dropDown.selectedIndex = -1;
        }
    },

    removeReservedFreeBets: function (FreeBetID)
    {
        var reservedFreeBets = this.getReservedFreeBets();
        for (var i = 0; i < Array.getLength(reservedFreeBets.ReservedFreeBets) ; i++)
        {
            if (reservedFreeBets.ReservedFreeBets[i] == FreeBetID)
            {
                reservedFreeBets.ReservedFreeBets.splice(i, 1);
                StorageUtils.saveToStorage("ReservedFreeBets", JSON.stringify({ ReservedFreeBets: reservedFreeBets.ReservedFreeBets }), sessionStorage);
            }
        }
    },

    updateView: function ()
    {
        if (!this.Active) return;

        var __html = [], containerElement = document.getElementById("bet-slip-container");
        if (containerElement)
        {
            this.buildInnerView(__html);
            containerElement.innerHTML = __html.join("");
        }

        this.deselectStakeDropdown();
        BetSlip.updateScrollbar();
    },

    updateSelection: function (selection) {
        if (!this.Active) return;

        if (selection.IsMultiLine) {
            this.updateMultipleSelection(selection);
            return;
        }

        var element = document.getElementById("sce_" + selection.ViewKey);
        if (element) {
            var __html = this.buildSelectionInnerView(selection, true);
            element.innerHTML = __html;
        }

        BetSlip.updateScrollbar();
    },

    updateMultipleSelection: function (selection) {
        if (!this.Active) return;
        // cache properties of current active element (ID, value, position)
        var activeElementID = (document.activeElement && document.activeElement.tagName && document.activeElement.tagName.toLowerCase() === 'input') ? document.activeElement.id : "";
        var activeElementValue = (document.activeElement && document.activeElement.tagName && document.activeElement.tagName.toLowerCase() === 'input') ? document.activeElement.value : "";
        var shouldFocusElement = document.activeElement && typeof (document.activeElement.getBoundingClientRect) == 'function' ? document.activeElement.getBoundingClientRect() : null;

        var element = document.getElementById("sce_" + selection.ViewKey);
        if (element) {
            element.innerHTML = this.buildSelectionInnerView(selection, true);
            // used cached properties to set focus back on input stake box
            this.setFocusOnStakeBox(activeElementID, activeElementValue, shouldFocusElement);

            if (activeElementID !== '') {
                var inputObject = null;
                inputObject = document.getElementById(activeElementID);
                if (!inputObject)
                    return;

                // for IExplorer - set selectionStart and selectionEnd to a (hardcoded) number bigger than input.value.length to move cursor to the end
                if (inputObject.type != "number") {
                    inputObject.selectionStart = 1000;
                    inputObject.selectionEnd = 1000;
                }
            }
        }
        BetSlip.updateScrollbar();
    },

    recalculateSelectionsStakeWin: function (selection)
    {

    },

    buildSummary: function ()
    {
    },

    // Sets (or re-sets) the betslip in status "Bet in process"
    setBetInProcess: function (isBetInProcess, updateView)
    {
        var placeBetElement = document.getElementById("PlaceBetButton"),
            placeElement = document.getElementById("place"),
            reqAmtMsg = document.getElementById("reqAmtMsg");
        this.IsBetInProcess = isBetInProcess;
        BetSlip.isBetInProcess = isBetInProcess;

        if (updateView)
        {
            this.updateView();
        }

        // Disable/enable "Place bets" button and show/hide request amount message
        if (placeBetElement) placeBetElement.disabled = this.IsBetInProcess;
        if (placeElement) placeElement.classList[this.IsBetInProcess ? "add" : "remove"]("disabledBtn");
        if (reqAmtMsg) reqAmtMsg.classList[this.IsBetInProcess ? "add" : "remove"]("isHidden");

    },
    
    setBetPlacedWithBonus: function (isBetPlacedWithBonus, updateView)
    {
        this.IsBetPlacedWithBonus = isBetPlacedWithBonus;

        if (updateView)
        {
            this.updateView();
        }
    },

    setError: function (errorMessage, updateView)
    {
        this.CurrentError = errorMessage;
        if (updateView) this.updateView();
    },

    setPurchaseError: function (error)
    {
        if (!error) return;

        var errorText =
        this.translateBetSlipError(
        {
            Status: error.ErrorCode,
            ErrorText: error.Message,
            Regulations: error.Regulations,
            userCurrency: error.userCurrency
        });

        this.setError(errorText);
    },

    GetEachWayData: function ()
    {
        return null;
    },

    GetSystemUKEachWayData: function ()
    {
        return null;
    },

    isComboSystemOrTeaserBet: function ()
    {
        return false;
    },

    getOddStyleID: function (prefferedOddstyle, line)
    {
        if (this.isComboSystemOrTeaserBet() || Odds.showDecimalInsteadOfAsian(line.BetType, line.EventTypeID, line.BetTypeID, line.BranchID, true))
        {
            if (currentOddStyle == OddStyle.AMERICAN || currentOddStyle == OddStyle.EUROPEAN || currentOddStyle == OddStyle.FRACTIONAL ||
                (currentOddStyle == OddStyle.HONGKONG && UseHKOddsStyleForAllMarkets))
            {
                return currentOddStyle;
            }
            else
            {
                return OddStyle.EUROPEAN;
            }
        }
        return prefferedOddstyle != undefined ? prefferedOddstyle : this.OddStyleID;
    },

    getSystemBetNames: function ()
    {
        return null;
    },

    getComboFreeBets: function ()
    {
        return null;
    },

    getTeaserFreeBets: function ()
    {
        return null;
    },

    getRiskFreeBetDeposit: function ()
    {
        return 0;
    },

    formatPurchaseRequest: function (linesArray, singleDeposits, comboDeposits, teaserDeposits, teaserTypeID, systemDeposit, systemKey, multiLineData, freeBets, comboBonuses, combinatorDeposits, pulseDeposits)
    {
        var eachWayData = this.GetEachWayData();
        var eachWaySystemUKData = this.GetSystemUKEachWayData();
        var purchTypeID = this.PurchaseTypeID;
        var isPurchaseFromActionBetting = false;
        ComboBonusProvider.clearRecalculatedComboBonuses();

        for (var key in linesArray)
        {
            var line = linesArray[key];
            if (line.IsBuyPoint && !this.TeaserTypeID)
            {
                line.Odds = line.BuyPointsOdds;
                line.Points = line.BuyPointsPoints;
            }

            var oddStyleID = this.getOddStyleID(currentOddStyle, line);
            var clientOdds = line.getFormatedOdds(oddStyleID);
            line.ClientOdds = oddStyleID == OddStyle.FRACTIONAL ? clientOdds : clientOdds.toStrippedNumberString();

            if (oddStyleID == OddStyle.FRACTIONAL && !Lines.isTypeSP(line.LineTypeID))
            {
                var fractOdds = line.ClientOdds.split('/');
                line.ClientDividend = fractOdds[0] * 1;
                line.ClientDivisor = fractOdds[1] * 1;
            }

            var pointsToReduce;

            if (this.TeaserTypeID)
            {
                var teaserInfo = TeaserSPTypes.getTeaserInfo(this.TeaserTypeID);
                if (teaserInfo)
                {
                    pointsToReduce = teaserInfo.PointsToReduce;
                }
            }

            line.YourBet = line.getYourBetFormattedForPurchase(pointsToReduce);

            if (line.IsEachWayEnabled)
            {
                line.EachWayString = Bets.getEachWayString(line.PlaceTermsID, line.OddsTermsID, false);
            }

            line.EnableAsianStyleMinMax = !!BetSlip.EnableAsianStyleMinMax;
            isPurchaseFromActionBetting = isPurchaseFromActionBetting || line.IsAddedFromActionBetting;
        }

        var betNames = this.getBetNames();
        var systemBetNames = this.getSystemBetNames();
        var comboFreeBets = this.getComboFreeBets();
        var teaserFreeBet = this.getTeaserFreeBets();
        var comboBonus = comboBonuses ? comboBonuses : {};
        var customerTimeZone = new Date().toString().match(CONSTANTS.TimeZoneOffsetRegex)[1];

        var purchase =
        {
            selections: linesArray,
            deposits: {
                single: singleDeposits,
                combo: comboDeposits,
                teaser: teaserDeposits,
                system: systemDeposit,
                combinator: combinatorDeposits,
                pulse: pulseDeposits
            },
            oddStyleIDFromClient: oddStyleID,
            eachWayData: eachWayData,
            teaserType: teaserTypeID,
            systemKey: systemKey,
            multiLineData: multiLineData,
            PurchaseTypeID: purchTypeID,
            FreeBets: freeBets,
            BetNames: betNames,
            SystemBetNames: systemBetNames,
            ComboFreeBets: comboFreeBets,
            TeaserFreeBet: teaserFreeBet,
            eachWaySystemUKData: eachWaySystemUKData,
            ComboBonuses: comboBonus,
            acceptOddsChangesMode: this.acceptOddsChangesMode,
            IsActionBet: isPurchaseFromActionBetting,
            CustomerTimeZone: customerTimeZone
        };

        return purchase;
    },

    formatMultiPurchaseRequest: function (linesArray, singleDeposits, multiLineData, freeBets)
    {
        var purchases = [];
        for (var key in linesArray)
        {
            var line = [linesArray[key]];
            var deposit = [singleDeposits[key]];
            if (deposit == 0) continue;
            var fBet = linesArray[key].FreeBet;
            var fBetArr = [];
            if (fBet && freeBets)
            {
                var index = freeBets.indexOf(fBet);
                if (index != -1)
                {
                    fBetArr[index] = fBet;
                }
            }
            var singlePurchaseRequest = this.formatPurchaseRequest(line, deposit, null, null, null, null, null, null, fBetArr);


            purchases.push(singlePurchaseRequest);
        }

        if (multiLineData)
        {
            for (var idx in multiLineData)
            {
                var multiLineItem = multiLineData[idx];
                var lines = [];
                for (var pIdx in multiLineItem.Positions)
                {
                    var posData = multiLineItem.Positions[pIdx];
                    var line = linesArray.firstOrDefault(function (sel) { return sel.ViewKey == posData.ViewKey });
                    if (line) lines.push(line);
                }
                var multiLinePurchaseRequest = this.formatPurchaseRequest(lines, 0, null, null, null, null, null, [multiLineItem], freeBets);
                purchases.push(multiLinePurchaseRequest);
            }
        }

        return purchases;
    },

    formatUKMultiPurchaseRequest: function (lines, singleDeposits, comboDeposits, teaserDeposit, teaserTypeID, systemDeposits, systemKeys, multiLineData, freeBets, comboBonuses)
    {
        var purchasesInformation = [];

        var hasSingleDeposits = singleDeposits.any(function (d) { return d > 0; }) || multiLineData.any(function (item) { return item.Stake > 0; });
        var multiplesLines = Array.findAllValues(lines, function (line) { return line && line.IsInMultiples; });

        if (BetSlip.PlaceSingleBetsWithMultiplePurchases && hasSingleDeposits)
        {
            purchasesInformation = purchasesInformation.concat(this.formatMultiPurchaseRequest(lines, singleDeposits, multiLineData, freeBets));
        }
        else if (hasSingleDeposits)
        {
            purchasesInformation.push(this.formatPurchaseRequest(lines, singleDeposits, null, null, null, null, null, multiLineData, freeBets))
        }

        if (BetSlip.PlaceComboBetsWithMultiplePurchases)
        {
            for (var key in comboDeposits)
            {
                var current = [];
                if (comboDeposits[key])
                {
                    current[key] = comboDeposits[key];
                    purchasesInformation.push(this.formatPurchaseRequest(multiplesLines, null, current, null, null, null, null, null, freeBets, comboBonuses));
                }
            }

            for (var key in systemDeposits)
            {
                var current = {};
                if (systemDeposits[key])
                {
                    current[key] = systemDeposits[key];
                    purchasesInformation.push(this.formatPurchaseRequest(multiplesLines, null, null, null, null, current, systemKeys, null, null, comboBonuses));
                }
            }
        }
        else if ((comboDeposits !== null && comboDeposits.length > 0) || systemDeposits !== null && !BetSlipUtil.isEmptyDepositObject(systemDeposits))
        {
            purchasesInformation.push(this.formatPurchaseRequest(multiplesLines, null, comboDeposits, null, null, systemDeposits, systemKeys, null, freeBets, comboBonuses))
        }


        if (this.isTeaserOn && teaserDeposit > 0)
        {
            purchasesInformation.push(this.formatPurchaseRequest(lines, null, null, teaserDeposit, teaserTypeID, null, null, null, freeBets, comboBonuses));
        }

        return purchasesInformation;
    },

    translateBetSlipError: function (postResult, selection, isCombined)
    {
        if (!postResult)
        {
            return $dict.bs("ErrorGeneral");
        }
        var errorText = $dict.bs(postResult.ErrorText);
        var errorsHelper = new BetSlipErrorMessagesHelper();
        // Patch for some server messages that are not translated on the server. So error codes in GetErrorDescription()
        // in DLOddsManager.cs are hardcoded here. This way we translate them with massages from the CMS Dictionary
        switch (postResult.Status)
        {
            case -1:
                errorText = (postResult.ErrorText == "Not enough funds" ? $dict.bs("NotEnoughFunds") : postResult.ErrorText);
                break;
            case -2:
                errorText = (isCombined ? $dict.bs("LinesWereClosed") : $dict.bs("LineClosed"));
                break;
            case -3:
                errorText = $dict.bs("NotEnoughFunds");
                break;
            case -10:
                errorText = $dict.bs("ErrorGeneral");
                break;
            case -11:
                errorText = $dict.bs("TimeoutError");
                break;
            case -12:
                errorText = $dict.bs("RejectedByRegulator");
                break;
            case -20:
                errorText = $dict.bs("ErrorWait");
                break;
            case -34:
                errorText = $dict.bs("RestrictedCountry");
                break;
            case -50:
                errorText = $dict.bs("AlreadyCashedOutBet");
                break;
            case -101:
                errorText = $dict.bs("LeagueIsNotClientSide");
                break;
            case -102:
                errorText = $dict.bs("LineIsNotEnagled");
                break;
            case -103:
                errorText = $dict.bs("EventIsNotClientSide");
                break;
            case -190:
                errorText = $dict.bs("ErrorCustomerSuspended");
                break;
            case -260:
                errorText = $dict.bs("ExpiredSelectionMinutes");
                break;
            case -501:
                errorText = $dict.bs("MinimalBet").concat(" " + MoneyFormat.format(postResult.minBet));
                break;
            case -533:
                errorText = $dict.bs("MinimalBetOverFree").concat(" " + MoneyFormat.format(postResult.minBet));
                break;
            case -502:
                if (typeof UKSPSlipMode != "undefined" && BetSlip.CurrentMode instanceof UKSPSlipMode)
                {
                    errorText = errorsHelper.getUKMaxStakeForItemErrorMsg(postResult, selection)
                }
                else
                {
                    errorText = errorsHelper.getMaxStakeForItemErrorMsg(postResult, selection);
                }
                break;
            case -503:
                errorText = $dict.bs("ErrorLineDanger");
                break;
            case -504:
                errorText = $dict.bs("ScoreChanged");
                break;
            case -566:
                errorText = $dict.bs("CustomerLiveBetNotAllowed");
                break;
            case -620:
                errorText = $dict.bs("ErrorWaitLive");
                break;
            case -900:
                errorText = $dict.bs("AlreadySettledSelections");
                break;
            case -1000:
                errorText = $dict.bs("ErrorMaxBetOver");
                break;
            case -1001:
                errorText = $dict.bs("ErrorMatchMaxBetOver").format(MoneyFormat.format(postResult.matchMaxBet));
                break;
            case -1002:
                errorText = $dict.bs("ErrorMinBetOver");
                break;
            case -1003:
                errorText = $dict.bs("ErrorMaxExposure") + " " + BetSlip.formatMoneyWithRounding(postResult.maxExposure) + " " + postResult.userCurrency;
                break;
            case -1004:
                errorText = $dict.bs("ErrorMaxExposurePerEvent");
                break;
            case -1006:
                errorText = $dict.bs("MaxWinForPurchase").format(MoneyFormat.format(postResult.maxWin), postResult.userCurrency);
                break;
            case -1007:
                errorText = $dict.bs("MaxRepeatedBetsReached");
                break;
            case -1010:
                errorText = $dict.bs("SingleMaxReturnOver").format("{0}{1}".format(postResult.userCurrency, BetSlip.formatMoneyWithRounding(postResult.Regulations.SingleMaxReturn)));
                break;
            case -1011:
                errorText = $dict.bs("ComboMaxReturnOver").format("{0}{1}".format(postResult.userCurrency, BetSlip.formatMoneyWithRounding(postResult.Regulations.ComboMaxReturn)));
                break;
            case -1012:
                errorText = $dict.bs("SystemMaxReturnOver").format("{0}{1}".format(postResult.userCurrency, BetSlip.formatMoneyWithRounding(postResult.Regulations.SystemMaxReturn)));
                break;
            case -1020:
                errorText = $dict.bs("MinimumPurchaseStake").format("{0}{1}".format(postResult.userCurrency, BetSlip.formatMoneyWithRounding(postResult.Regulations.MinimumPurchaseStake)));
                break;
            case -1021:
                errorText = $dict.bs("RegulationsMaxWin").format("{0}{1}".format(postResult.userCurrency, BetSlip.formatMoneyWithRounding(postResult.Regulations.MaximumPurchaseWin)));
                break;
            case -1101:
                errorText = $dict.bs("SingleStakeIncrement").format("{0}{1}".format(postResult.userCurrency, BetSlip.formatMoneyWithRounding(postResult.Regulations.SingleStakeIncrement)));
                break;
            case -1102:
                errorText = $dict.bs("SystemStakeIncrement").format("{0}{1}".format(postResult.userCurrency, BetSlip.formatMoneyWithRounding(postResult.Regulations.SystemStakeIncrement)));
                break;
            case -1103:
                errorText = $dict.bs("SelectionsInSystem");
                break;
            case -1104:
                errorText = $dict.bs("SelectionsInSystem");
                break;
            case -1105:
                errorText = $dict.bs("PlaceAtLeastOnePermutation");
                break;
            case -1106:
                errorText = $dict.bs("NotApplicableForStraightCombo");
                break;
            case -1107:
                errorText = errorsHelper.getRegulationMinBetStakeErrorMsg(postResult);
                break;
            case -1108:
                errorText = $dict.bs("UnderMinTotalCost");
                break;
            case -1110:
                errorText = $dict.bs("ForbiddenTeamIDsGeneral");
                break;
            case -1120:
                errorText = $dict.bs("MBNError");
                break;
            case -1130:
                errorText = $dict.bs("ForbiddenTeamIDsCyprus");
                break;
            case -1140:
                errorText = $dict.bs("ForbiddenLeagueIDs");
                break;
            case -2001:
                errorText = $dict.bs("ErrorOverMaxPermutations");
                break;
            case -3001:
                errorText = $dict.bs("ErrorFreeBetNotFound");
                break;
            case -3002:
                errorText = $dict.bs("ErrorFreeBetExpired");
                break;
            case -3003:
                errorText = $dict.bs("ErrorFreeBetNotAllowed");
                break;
            case -3101:
                errorText = $dict.bs("CashOutNotAllowedForBet");
                break;
            case -3104:
                errorText = $dict.bs("CashOutAmountDifference");
                break;
            case -5002:
                errorText = $dict.bs("CustomerNotFound");
                break;
            case -5006:
                errorText = $dict.bs("RestrictedCustomer");
                break;
            case -5007:
                errorText = $dict.bs("PhoneNotVerified");
                break;
            case -5023:
                errorText = $dict.bs("NoExistingSession");
                break;
            case -5025:
                errorText = $dict.bs("SeamlessError160");
                break;
            case -5026:
                errorText = $dict.bs("SeamlessError170");
                break;
            case -5027:
                errorText = $dict.bs("SeamlessError180");
                break;
            case -5028:
                errorText = $dict.bs("SeamlessError190");
                break;
            case -5029:
                errorText = $dict.bs("SeamlessError200");
                break;
            case -5030:
                errorText = $dict.bs("SeamlessError210");
                break;
            case -5031:
                errorText = $dict.bs("SeamlessError220");
                break;
            case -5032:
                errorText = $dict.bs("SeamlessError230");
                break;
            case -5033:
                errorText = $dict.bs("SeamlessError240");
                break;
            case -6000:
                errorText = $dict.bs("PlaceNewPurchaseSelectionsError");
                break;
            case -6001:
                errorText = $dict.bs("YourBetOddsDifferentFromPlaced");
                break;
            case -6004:
                errorText = $dict.bs("DepositLimitNotSet");
                break;
            case -8001:
                const minOdds = BetSlip.MinBetOddsThreshold ? Odds.format(BetSlip.MinBetOddsThreshold) : '';
                errorText = $dict.bs("BetOddsTooLow").concat(" {0}".format(minOdds));
                break;
            case -8002:
                errorText = $dict.bs("OddsTooHigh");
                break;
            case -11001:
                errorText = $dict.bs("CreateReserveDBError");
                break;
            case -11005:
                errorText = $dict.bs("CountryTaxInvalidOdds");
        }

        return errorText;
    },

    getAvailableFreeBets: function ()
    {

    },

    getFreeBetDeposit: function ()
    {
        return 0;
    },

    formatSelectionOddsForSlip: function (selection, oddStyle, hideSeparator)
    {
        if (!selection) return "";

        if (selection instanceof MultiLineItem && selection.Odds == 0)
        {
            return "";
        }

        var formattedOdds = selection.getFormatedOdds(oddStyle);
        if (selection.isSPSelection() || (selection.Odds == 0 && selection.isToteEvent()))
        {
            formattedOdds = selection.getOddsAbbreviation();
        }

        if (hideSeparator) return formattedOdds;

        return formattedOdds;
    },

    getSelections: function ()
    {
        return [];
    },

    getSplitCount: function ()
    {
        return 0;
    },

    getTotalDeposit: function () { return 0 },

    getTotalGain: function () { return 0 },

    getTotalGainWithBonuses: function () { return this.getTotalGain() },

    getTotalPayOut: function ()
    {
        var totalDeposit = UserInfo.TaxProvider.applyTaxToDeposit(this.getTotalDeposit());
        var totalGain = this.getTotalGain();
        // win taxes are not applying to stake part.
        var selectionsCount = BetSlip.getSelectionsCount();
        return this.applyTaxToWin(totalGain - totalDeposit, selectionsCount) + totalDeposit;
    },

    getTotalPayOutWithBonuses: function ()
    {
        var totalDeposit = UserInfo.TaxProvider.applyTaxToDeposit(this.getTotalDeposit());
        var totalGainWithBonuses = this.getTotalGainWithBonuses();
        // win taxes are not applying to stake part.
        var selectionsCount = BetSlip.getSelectionsCount();
        return this.applyTaxToWin(totalGainWithBonuses - totalDeposit, selectionsCount) + totalDeposit;
    },

    getComboMinBet: function (selections, dontUseSplit)
    {
        selections = selections || this.getSelections();

        var mx = constMinBet;

        for (var key in selections)
        {
            if (selections[key].minBet > mx) mx = selections[key].minBet;
        }

        mx = Bets.roundMin(mx);

        var regulationMinBet = this.getRegulationMinBet();
        mx = Boolean(regulationMinBet) && (regulationMinBet > mx) ? regulationMinBet : mx;

        return mx;
    },

    getRegulationMinPurchaseStake: function ()
    {
        if (typeof (BetSlipRegulations) === "undefined" || !BetSlipRegulations.isActive) return 0;

        if (BetSlipRegulations.hasOwnProperty('MinimumPurchaseStake'))
        {
            return BetSlipRegulations.MinimumPurchaseStake;
        }

        return 0;
    },

    getRegulationMaximumPurchaseWin: function ()
    {
        if (typeof (BetSlipRegulations) === "undefined" || !BetSlipRegulations.isActive) return 0;

        if (BetSlipRegulations.hasOwnProperty('MaximumPurchaseWin'))
        {
            return BetSlipRegulations.MaximumPurchaseWin;
        }

        return 0;
    },

    getRegulationMinBet: function ()
    {
        if (typeof (BetSlipRegulations) === "undefined" || !BetSlipRegulations.isActive) return 0;

        if (BetSlipRegulations.hasOwnProperty('MinimumBetStake'))
        {
            return BetSlipRegulations.MinimumBetStake;
        }

        return 0;
    },

    getRegulationSingleMinBetStake: function ()
    {
        if (typeof (BetSlipRegulations) === "undefined" || !BetSlipRegulations.isActive) return 0;

        if (BetSlipRegulations.hasOwnProperty('MinimumSingleBetStake'))
        {
            return BetSlipRegulations.MinimumSingleBetStake;
        }

        return 0;
    },

    getRegulationComboMinBetStake: function ()
    {
        if (typeof (BetSlipRegulations) === "undefined" || !BetSlipRegulations.isActive) return 0;

        if (BetSlipRegulations.hasOwnProperty('MinimumComboBetStake'))
        {
            return BetSlipRegulations.MinimumComboBetStake;
        }

        return 0;
    },

    getRegulationSystemMinBetStake: function ()
    {
        if (typeof (BetSlipRegulations) === "undefined" || !BetSlipRegulations.isActive) return 0;

        if (BetSlipRegulations.hasOwnProperty('MinimumSystemBetStake'))
        {
            return BetSlipRegulations.MinimumSystemBetStake;
        }

        return 0;
    },

    getRegulationCombinatorMinBetStake: function ()
    {
        if (typeof (BetSlipRegulations) === "undefined" || !BetSlipRegulations.isActive) return 0;

        if (BetSlipRegulations.hasOwnProperty('MinimumCombinatorBetStake'))
        {
            return BetSlipRegulations.MinimumCombinatorBetStake;
        }

        return 0;
    },

    isOverMaxWinRegulation: function(gain, deposit)
    {
        if (typeof (BetSlipRegulations) === "undefined") return false;

        var winnings = Math.floor(((gain - deposit) * 100).toFixed(4)) / 100;

        var maximumPurchaseWin = this.getRegulationMaximumPurchaseWin();
        if (maximumPurchaseWin === 0)
        {
            return false;
        }

        var result = winnings > maximumPurchaseWin;
        return result;
    },

    // Is the given selection showing a "To Win" box instead of a "Stake" box
    isToWin: function (selection)
    {
        var isCurrentOddAsian = (currentOddStyle == OddStyle.MALAY || currentOddStyle == OddStyle.INDO || (IsNegativeAmericanLikeAsian && currentOddStyle == OddStyle.AMERICAN)),
            currentClientOdd = Odds.convert(selection.Odds, selection.EventTypeID, selection.BetTypeID, undefined, undefined, selection.BetType, selection.BranchID) < 0;

        return BetSlip.isAsianMode && isCurrentOddAsian && currentClientOdd;
    },

    setFocusOnStakeBox: function (elementID, elementValue, shouldFocusElement)
    {
        var element = document.getElementById(elementID);

        if (typeof element != 'undefined' && element != null && shouldFocusElement && (shouldFocusElement.top >= 0 && shouldFocusElement.top <= document.documentElement.clientHeight))
        {
            element.focus();
            element.value = elementValue;
        }
    },

    CheckForSPIncluded: function ()
    {
        return false;
    },

    CheckForNoOddsSelectionIncluded: function ()
    {
        return false;
    },

    calculateLoyaltyPoints: function ()
    {
        return {};
    },

    checkForErrors: function (viewKey)
    {
        this.Errors[viewKey] = false;

        for (var idx in this.Errors)
        {
            if (this.Errors[idx]) return false;
        }

        return true;
    },

    getArrayMinNonZeroValue: function (array)
    {
        var filteredArray = array.filter(function (val) { return val > 0; });
        if (filteredArray.length > 0)
        {
            return Math.min.apply(null, filteredArray);
        }

        return 0;
    },

    isSelectionPropUpdated: function (viewKey, prop)
    {
        var isPropInfoUpdated = false;

        switch (prop)
        {
            case BetSlipSelectionPropCasesWithBlinking.Odds:
                var currentTime = new Date();
                isPropInfoUpdated = currentTime - this.SelectionsWithUpdatedOdd[viewKey] < CartUpdateTimeout;
                break;
            case BetSlipSelectionPropCasesWithBlinking.Score:
                var currentTime = new Date();
                isPropInfoUpdated = currentTime - this.SelectionsWithUpdatedScore[viewKey] < CartUpdateTimeout;
                break;
            case BetSlipSelectionPropCasesWithBlinking.Points:
                var currentTime = new Date();
                isPropInfoUpdated = currentTime - this.SelectionsWithUpdatedPoints[viewKey] < CartUpdateTimeout;
                break;
            case BetSlipSelectionPropCasesWithBlinking.MatchSet:
                var currentTime = new Date();
                isPropInfoUpdated = currentTime - this.SelectionsWithUpdatedMatchSet[viewKey] < CartUpdateTimeout;
                break;
            case BetSlipSelectionPropCasesWithBlinking.SetGame:
                var currentTime = new Date();
                isPropInfoUpdated = currentTime - this.SelectionsWithUpdatedSetGame[viewKey] < CartUpdateTimeout;
                break;
            case BetSlipSelectionPropCasesWithBlinking.GamePoint:
                var currentTime = new Date();
                isPropInfoUpdated = currentTime - this.SelectionsWithUpdatedGamePoint[viewKey] < CartUpdateTimeout;
                break;
            case BetSlipSelectionPropCasesWithBlinking.GameInterval:
                var currentTime = new Date();
                isPropInfoUpdated = currentTime - this.SelectionsWithUpdatedGameInterval[viewKey] < CartUpdateTimeout;
                break;
        }
        return isPropInfoUpdated;
    },

    setLastSelectionUpdateProps: function (selection)
    {
        if (selection.UpdatedOdds)
        {
            this.SelectionsWithUpdatedOdd[selection.ViewKey] = new Date();
        }

        if (selection.UpdatedScore)
        {
            this.SelectionsWithUpdatedScore[selection.ViewKey] = new Date();
        }

        if (selection.UpdatedPoints)
        {
            this.SelectionsWithUpdatedPoints[selection.ViewKey] = new Date();
        }

        if (selection.UpdatedMatchSet)
        {
            this.SelectionsWithUpdatedMatchSet[selection.ViewKey] = new Date();
        }

        if (selection.UpdatedSetGame)
        {
            this.SelectionsWithUpdatedSetGame[selection.ViewKey] = new Date();
        }

        if (selection.UpdatedGamePoint)
        {
            this.SelectionsWithUpdatedGamePoint[selection.ViewKey] = new Date();
        }

        if (selection.UpdatedGameInterval)
        {
            this.SelectionsWithUpdatedGameInterval[selection.ViewKey] = new Date();
        }
    },

    setElementsBlinking: function (viewKey)
    {
        var oddsElement = document.getElementById("cOddsToWhow_" + viewKey),
            scoreElement = document.getElementById("cScore_" + viewKey),
            pointsElement = document.getElementById("cPoints_" + viewKey),
            matchSetElement = document.getElementById("cMatchSet" + viewKey),
            setGameElement = document.getElementById("cSetGame" + viewKey),
            gamePointElement = document.getElementById("cGamePoint" + viewKey),
            gameIntervalElement = document.getElementById("cGameInterval" + viewKey);

        if (oddsElement) oddsElement.classList[this.isSelectionPropUpdated(viewKey, BetSlipSelectionPropCasesWithBlinking.Odds) ? "add" : "remove"]("updated");
        if (scoreElement) scoreElement.classList[this.isSelectionPropUpdated(viewKey, BetSlipSelectionPropCasesWithBlinking.Score) ? "add" : "remove"]("updated");
        if (pointsElement) pointsElement.classList[this.isSelectionPropUpdated(viewKey, BetSlipSelectionPropCasesWithBlinking.Points) ? "add" : "remove"]("updated");

        //tennis fast markets
        if (matchSetElement) matchSetElement.classList[this.isSelectionPropUpdated(viewKey, BetSlipSelectionPropCasesWithBlinking.MatchSet) ? "add" : "remove"]("updated");
        if (setGameElement) setGameElement.classList[this.isSelectionPropUpdated(viewKey, BetSlipSelectionPropCasesWithBlinking.SetGame) ? "add" : "remove"]("updated");
        if (gamePointElement) gamePointElement.classList[this.isSelectionPropUpdated(viewKey, BetSlipSelectionPropCasesWithBlinking.GamePoint) ? "add" : "remove"]("updated");

        //soccer fast markets
        if (gameIntervalElement) gameIntervalElement.classList[this.isSelectionPropUpdated(viewKey, BetSlipSelectionPropCasesWithBlinking.GameInterval) ? "add" : "remove"]("updated");
    },

    applyStakeIncrement: function (deposit, increment)
    {
        if (increment == 0)
        {
            return deposit;
        }

        deposit = deposit - (deposit % increment);
        return deposit;
    },

    applyTaxToWin: function (win)
    {
        return win;
    },

    getGainPerBetType: function ()
    {
        return 0;
    },

    shouldDelayAcceptedPurchases: function (purchases)
    {
        return BetSlip.ShowBonusBettingMessage && purchases.any(function (p) { return p.BonusAmountStake * 1 > 0 && p.Status === SPPurchaseStatus.Accepted; });
    },
    
    filterPurchases: function (purchases, checkAccepted)
    {
        return purchases.filter(function (p)
        {
            return checkAccepted ? p.Status === SPPurchaseStatus.Accepted : p.Status !== SPPurchaseStatus.Accepted;
        });
    },

	onGeolocationResponse: function(message)
	{
		this.isRunningGeoCheck = false;
		this.setBetInProcess(false, true);
		sbMsgBus.unsubscribe(sbMsgBus.sbMsgBusPredefinedTopics.GeoTopics.SB_GeoPlaceBetTopic, this.onGeolocationResponse);
		message.canPlaceOrCashoutBet && this.placeBets();
	},

	isGeolocationInProgress: function ()
	{
		if (sbMsgBus && typeof GeolocationFacade !== "undefined" && GeolocationFacade.shouldUserGeolocateOnPlaceBet())
		{
			this.isRunningGeoCheck = true;

			var placeBetMsg = {};

			placeBetMsg[sbMsgBus.sbMsgBusPredefinedTopics.GeneralTopics.SB_PlaceBetTopic] = {
				senderId: sbMsgBus.sbMsgBusPredefinedTopics.GeoMessageSenderId.Sport
			};
			
			this.onGeolocationResponse = this.onGeolocationResponse.bind(this);
			sbMsgBus.subscribe(sbMsgBus.sbMsgBusPredefinedTopics.GeoTopics.SB_GeoPlaceBetTopic, this.onGeolocationResponse, false);
			sbMsgBus.post(placeBetMsg);
			this.setBetInProcess(true, true);
			return true;
		}
		return false;
	},

    placePurchaseCallBack: function (purchases, comboSelections)
    {
        if (this.shouldDelayAcceptedPurchases(purchases))
        {
            var acceptedPurchases = this.filterPurchases(purchases, true),
                notAcceptedPurchases = this.filterPurchases(purchases, false);

            if (acceptedPurchases && acceptedPurchases.length)
            {
                this.setBetPlacedWithBonus(true, true);
                var ref = this;
                setTimeout(function ()
                {
                    ref.setBetPlacedWithBonus(false);
                    ref.processPurchases(acceptedPurchases, comboSelections);
                }, BonusBettingMessageTimeout);
            }

            if (notAcceptedPurchases && notAcceptedPurchases.length)
            {
                this.processPurchases(notAcceptedPurchases, comboSelections);
            }
        }
        else
        {
            this.processPurchases(purchases, comboSelections);
        }
    },

    processPurchases: function (purchases, comboSelections)
    {
        if (this.shouldDelayAcceptedPurchases(purchases))
        {
            var acceptedPurchases = this.filterPurchases(purchases, true),
                notAcceptedPurchases = this.filterPurchases(purchases, false);

            if (acceptedPurchases && acceptedPurchases.length)
            {
                this.setBetPlacedWithBonus(true, true);
                var ref = this;
                setTimeout(function ()
                {
                    ref.setBetPlacedWithBonus(false);
                    ref.processPurchases(acceptedPurchases, comboSelections);
                }, BonusBettingMessageTimeout);
            }

            if (notAcceptedPurchases && notAcceptedPurchases.length)
            {
                this.processPurchases(notAcceptedPurchases, comboSelections);
            }
        }
        else
        {
            this.processPurchases(purchases, comboSelections);
        }
    },

    processPurchases: function (purchases, comboSelections)
    {
        BetSlip.sendToJSApi();

        if (purchases == -1) purchases = []; // If user is not logged-in
        var keepSelections = false;
        for (var i in purchases)
        {
            var purchase = purchases[i];
            if (purchase == -1)
                continue;

            this.parsePlaceBets(purchase, keepSelections, comboSelections);
            if (!keepSelections)
            {
                keepSelections = true;
            }
        }

        this.setBetInProcess(false);
        this.updateView();

        for (var i in purchases)
        {
            var purchase = purchases[i];
            if (purchase == -1)
                continue;

            //Check if insufficient funds and quick deposit popup are using
            if (purchase.ErrorCode == this.UnsufficientFundsErrorId && typeof (QuickDepositBlock) != "undefined")
            {
                QuickDepositBlock.showDepositPopupIfNeed();
                break;
            }
        }

        //if (this.ID == "single")
        //{
        //    BetSlip.updateBSFooter();
        //}

        BetSlip.afterBetSuccessfullyPlaces(purchases);
    },

    placePurchaseCallBackError: function (result, ref)
    {
        var customErrorMessage = null;
        try
        {
            var customError = eval(result);
            
            if(customError != null && customError.code)
                customErrorMessage = $dict.bs(customError.code);
        }
        catch (ex)
        {
            console.warn(ex);
            customErrorMessage = null;
        }
        finally {}
        ref.setError(customErrorMessage || $dict.bs("CommunicationError"));
        ref.setBetInProcess(false);
        ref.updateView();
        BetSlip.updateBSFooter();
    },


    setCursorAtPosition: function (element, position)
    {
        if (element.setSelectionRange)
        {
            element.setSelectionRange(position, position);
        }
    },

    getElementCursorPosition: function (element) {
        if (element.type && /email|text|search|url|telephone/.test(element.type)) {
            return element.selectionStart;
        }
    },
    
    removeOverlayFromLiveSelection: function (selection)
    {
        if (selection.Live && selection.Valid && !selection.Danger && !selection.LineClosed)
        {
            // Remove "Suspended", "Line Closed" message if the selection's "Danger", "LineClosed" has been lifted
            var overlayElement = document.querySelector("#sce_" + selection.ViewKey + " div.overlay");
            if (overlayElement)
            {
                overlayElement.parentElement.removeChild(overlayElement);
            }
        }
    },

    shouldShowMaxbetButtonForMultiSelections: function (hasLiveSelections, selections)
    {
        var currentSelections = selections ? selections : this.getSelectionsInCombo();

        //Check if combo is Mixed (live + pre-match) and show button only if both are on, otherwise remain old logic
        var selectionsInCombo = currentSelections.filter(function (sel) { return sel != undefined });

        if (this.isMixedCombo(selectionsInCombo)) {
            return BetSlip.ShowMaxBetButtonLive && BetSlip.ShowMaxBetButtonPrematch && (UserInfo.current || document.body.classList.contains("loggedin"));
        }
        else {
            var enabledForLiveSelections = typeof hasLiveSelections != "undefined" ? BetSlip.ShowMaxBetButtonLive && hasLiveSelections : BetSlip.ShowMaxBetButtonLive;
            var enabledForPrematchSelections = typeof hasLiveSelections != "undefined" ? BetSlip.ShowMaxBetButtonPrematch && !hasLiveSelections : BetSlip.ShowMaxBetButtonPrematch;

            return (enabledForLiveSelections || enabledForPrematchSelections) && (UserInfo.current || document.body.classList.contains("loggedin"));
        }
    },

    isMixedCombo: function (selections)
    {
        if (!selections[0])
        {
            return false;
        }

        var firstSelConstructorName = selections[0].constructor.name;

        for (var i = 1; i < selections.length; i++) {
            if (firstSelConstructorName !== selections[i].constructor.name) {
                return true;
            }
        }

        return false;
    },

    getBankersCount: function (selectionsGroups)
    {
        var bankerCount = 0;
        for (var groupKey = 0 in selectionsGroups)
        {
            if (selectionsGroups.hasOwnProperty(groupKey))
            {
                for (var sIdx in selectionsGroups[groupKey])
                {
                    if (selectionsGroups[groupKey].hasOwnProperty(sIdx) && selectionsGroups[groupKey][sIdx].IsBanker)
                    {
                        bankerCount++;
                        break;
                    }
                }
            }
        }
        return bankerCount;
    },

    resetAllBankers: function (selections, groups)
    {
        var i;

        for (i in selections) {
            if (selections.hasOwnProperty(i)) {
                selections[i].IsBanker = false;
            }
        }

        for (i in groups) {
            if (groups.hasOwnProperty(i)) {
                groups[i].IsBanker = false;
            }
        }

        this.BankersCount = 0;
    },

    hasAvailableResevedFreeBets: function (freeBet, mode)
    {
        var currentMode = mode || BetSlip.CurrentMode;
        // Reserved free bets are ids which are stored when selections are in waiting status
        // and has choosen free bet 
        var reservedFreeBets = currentMode.getReservedFreeBets();

        return Array.find(reservedFreeBets.ReservedFreeBets,
               function (e) { return e == freeBet.BonusID; }); 
    },

    getVerificationErrorMessage: function(errorCode) {
        var errorKey = errorCode === -193 || errorCode === -192 ? 'ExternallyNotVerified' : 'BetslipVerificationMessage';
        return $dict.bs(errorKey);
    }

});

window.BaseSPSlipMode = BaseSPSlipMode;

includeExtension("/JSComponents/Data/UniSlip/Modes/BaseSPSlipMode.ext.js");