﻿var BetSlipLogin = (function ()
{
    'use strict';
    var api = {};
    api.init = function (loginPanelCode)
    {
        this.loginPanelCode = loginPanelCode;
    };

    api.show = function ()
    {
        if (this.loginPanelCode)
        {
            Application.navigateTo(this.loginPanelCode, false, [
                { customTitle: 'placebets-header' }
            ]);
        }
        else
        {
            var loginButton = document.getElementById('loginButton');
            loginButton && loginButton.click();
        }
    };

    api.hide = function () {};

    return api;
}());

window.BetSlipLogin = BetSlipLogin;