﻿function SPBaseMyBetsCashoutBetDrawer()
{
    'use strict';

    this.buildBet = function (__html, bet)
    {
        bet.Purchase.buildBet(__html, bet);
    };

    this.buildBetError = function (__html, bet)
    {
        bet.Purchase.buildBetError(__html, bet);
    };

    this.showHideErrorMessage = function (bet, hasError)
    {
        SPBaseMyBetsCashoutBetDrawer.prototype.showHideErrorMessage(bet, hasError, this.buildBetError);
    };
  
    this.redrawBet = function (bet) {
        SPBaseMyBetsCashoutBetDrawer.prototype.redrawBet(bet, this.buildBet);
    };
}

$.extend(SPBaseMyBetsCashoutBetDrawer.prototype, BaseCashoutPurchaseBetDrawer);

function SPMyBetsCashoutBetDrawer()
{
    'use strict';

    this.getAllHosts = function ()
    {
        // override because we dont need empty host
        return this.__hosts;
    };

    this.redrawCashOutNewOffer = function (bet, __html, host)
    {
        var purchase, length = BetSlip.Purchases.length;

        for (var i = 0; i < length; i += 1)
        {
            purchase = BetSlip.Purchases[i];
            if (purchase && purchase.Bet.BetID === bet.BetID)
            {
                return this.buildCashOutSection(bet, __html, 'newOffer', purchase, host);
            }
        }

        console.log('Error in finding the bet\'s waiting Id! ');
    };

    this.updateView = function (bet, __html, host, processEmptyValues)
    {
        if (__html.length === 0 && !processEmptyValues)
        {
            return;
        }
        var updateElement = document.getElementById(host.BlockUniqueID + 'cashout_section_' + bet.UIElementId);
        updateElement.innerHTML = (Array.isArray(__html) ? __html.join('') : __html);
    };

    /* jshint maxcomplexity: 7 */
    this.buildCashOutBetView = function (__html, bet, cashOutStatus, host)
    {
        switch (cashOutStatus)
        {
            case SPPurchaseStatus.Accepted:
                {
                    __html = this.buildCashOutSection(bet, __html, 'accepted', null, host);
                    break;
                }
            case SPPurchaseStatus.Declined:
                {
                    //as a Temporary solution, need to force rendering "Cashout Declined" for case when Cashout Is Disabled
                    __html = this.buildCashOutSection(bet, __html, 'declined', null, host, true);
                    bet.CashOutStatus = null; //reset cashout status to normal when setTimeout executes
                    bet.LastPartialCashoutAmount = 0;
                    setTimeout(this.redrawBet.bind(this, bet), 10000);
                    break;
                }
            case SPPurchaseStatus.Waiting:
            case SPPurchaseStatus.ClientAccepted:
                {
                    //create waiting button
                    __html = this.buildCashOutSection(bet, __html, 'waiting', null, host);
                    break;
                }
            case SPPurchaseStatus.NewOffer:
                {
                    __html = this.redrawCashOutNewOffer(bet, __html, host);
                    break;
                }
            default:
                __html = this.buildCashOutSection(bet, __html, 'normal', null, host);
        }

        return __html;
    };

    this.redrawBet = function (bet)
    {
        var status = bet.CashOutStatus;
        SPMyBetsCashoutBetDrawer.prototype.foreachUIElement(bet.UIElementId, function (betEl, host)
        {
            var __html = this.buildCashOutBetView([], bet, status, host);
            //as a Temporary solution, need to force rendering "Empty Cashout Box" when Cashout Is Disabled
            this.updateView(bet, __html, host, true);

        }.bind(this));

        return true;
    };

    this.removeBet = function (bet)
    {
        if (!bet)
        {
            return false;
        }

        this.foreachBetUIElement(bet, function (betEl)
        {
            var wholeEl = betEl.parentNode.parentNode.parentNode;
            wholeEl.parentNode.removeChild(wholeEl);
        });

        return true;
    },

        this.buildCashOutDanger = function (bet, __html, host)
        {
            var isResponsiveHost = !!host.CurrentHTMLTemplate;
            isResponsiveHost ? __html = host.buildCashOutDanger(bet) : bet.buildCashOutDanger(__html, host);
            return __html;
        };

    this.buildCashOutSection = function (bet, __html, cashoutStatus, purchase, host, ignoreIsCashoutAllowed)
    {
        var isResponsiveHost = !!host.CurrentHTMLTemplate;
        isResponsiveHost ? __html = host.buildCashOutSection(bet, cashoutStatus, purchase) : bet.buildCashOutSection(__html, cashoutStatus, purchase, host, ignoreIsCashoutAllowed);
        return __html;
    };

    this.showHideDangerNotification = function (bet, hasDanger)
    {
        var ref = this;
        if (hasDanger)
        {
            SPMyBetsCashoutBetDrawer.prototype.foreachUIElement(bet.UIElementId, function (betEl, host)
            {
                var dangerEl = document.getElementById(host.BlockUniqueID + bet.HasCashOutDangerUIElementId);
                if (!dangerEl)
                {
                    var __html = ref.buildCashOutDanger(bet, [], host);
                    betEl.parentNode.parentNode.parentNode.insertAdjacentHTML('beforeend', Array.isArray(__html) ? __html.join('') : __html);
                }
            });
        }
        else
        {
            SPMyBetsCashoutBetDrawer.prototype.foreachUIElement(bet.HasCashOutDangerUIElementId, function (dangerEl)
            {
                dangerEl.parentNode.removeChild(dangerEl);
            });
        }
    };

    this.updateCashOutTaxInfo = function (bet)
    {
        SPMyBetsCashoutBetDrawer.prototype.foreachUIElement(bet.CashOutTaxInfoUIElementId, function (cashOutTaxInfoElement, host)
        {
            if (host.CurrentHTMLTemplate.buildCashOutTaxInfo)
            {
                if (UserInfo.TaxProvider.shouldHasCashoutTaxMessege(bet))
                {
                    cashOutTaxInfoElement.innerHTML = host.CurrentHTMLTemplate.buildCashOutTaxInfo(host, bet);
                    cashOutTaxInfoElement.classList.add('myBetsLine__cashoutTaxMessage');
                }
                else
                {
                    cashOutTaxInfoElement.innerHTML = '';
                    cashOutTaxInfoElement.classList.remove('myBetsLine__cashoutTaxMessage');
                }
            }
        });
    };

    this.hideCashOutButtons = function (uiElements)
    {
        SPMyBetsCashoutBetDrawer.prototype.hideCashOutButtons(uiElements);
        uiElements.betUIElementId && uiElements.betUIElementId.classList.remove('hasSliderBtn');
    };

    this.showCashoutButtons = function (uiElements)
    {
        SPMyBetsCashoutBetDrawer.prototype.showCashoutButtons(uiElements);
        uiElements.betUIElementId && uiElements.betUIElementId.classList.add("hasSliderBtn");
    };
}

SPMyBetsCashoutBetDrawer.prototype = Object.create(SPBaseMyBetsCashoutBetDrawer.prototype);
SPMyBetsCashoutBetDrawer.prototype.constructor = SPMyBetsCashoutBetDrawer;

window.SPMyBetsCashoutBetDrawer = SPMyBetsCashoutBetDrawer;