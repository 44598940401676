function EditOpenBetSPSlipMode()
{
    ComboSPSlipMode.call(this);

    this.Order = 6;
    this.ID = "editopenbet";
    this.Name = $dict.bs("ComboBetTab");
    this.TabClass = "betting_slip_nbs editopenbet";
    var totalDepositAreaElement = document.getElementById("totalDepositArea");
    this.TotalDepositAreaContainer = totalDepositAreaElement ? totalDepositAreaElement.firstElementChild : null;
    this.BetsTabsContainer = document.getElementById("idBetsTabs");
    this.SelectionDrawType = "editModeLine";
    this.IsSelectionRemoveButton = false;
    this.BetID = null;
    this.PurchaseID = null;
    this.CashOutUpdateIntervalID = null;
}

$.extend(EditOpenBetSPSlipMode.prototype, ComboSPSlipMode.prototype,
{
    activate: function ()
    {
        this.Active = true;
        this.HasBeenActivated = true;
        this.TotalDepositAreaContainer ? this.TotalDepositAreaContainer.innerText = $dict.bs("EditModeStake") : "";
        this.BetsTabsContainer ? this.BetsTabsContainer.classList.add("isHidden") : "";

        var spMyBetsCache = SPMyBetsCache.getInstance();
        spMyBetsCache.addCashoutUpdatesSubsriber("EditOpenBetSPSlipMode");
		spMyBetsCache.addPurchaseUpdateCashOutData("EditOpenBetSPSlipMode", this.processCashOutDataUpdate.bind(this));
    },

    processCashOutDataUpdate: function (sender, data)
    {
        if (data.PurchaseID !== this.PurchaseID)
        {
            return;
        }

        var ref = this;
        var bets = data.Bets;
        var bet = bets ? bets.firstOrDefault(function (bet) { return bet.BetID == ref.BetID }) : null;

        if (bet && bet.IsAllowed && !bet.IsDanger)
        {
            this.updateStake(bet.Amount)
        }
    },

    deactivate: function ()
    {
        this.Active = false;
        this.TotalDepositAreaContainer ? this.TotalDepositAreaContainer.innerText = $dict.bs("TotalDeposit") : "";
        this.BetsTabsContainer ? this.BetsTabsContainer.classList.remove("isHidden") : "";

        var spMyBetsCache = SPMyBetsCache.getInstance();
        spMyBetsCache.removeCashoutUpdatesSubsriber("EditOpenBetSPSlipMode");
        spMyBetsCache.removePurchaseUpdateCashOutData("EditOpenBetSPSlipMode");
    },

    canActivate: function ()
    {
        return 1;
    },

    updateStake: function (amount)
    {
        this.VariationsDeposits = [];
        var straightComboKey = this.Variants.length - 1;
        if (this.VariationsDeposits[straightComboKey] && this.VariationsDeposits[straightComboKey] === amount)
        {
            return false;
        }

        this.setDeposit(straightComboKey, amount);
        return true;
    },

    serialize: function ()
    {
        var serializedObject = ComboSPSlipMode.prototype.serialize.call(this);
        serializedObject["BetID"] = this.BetID;
        serializedObject["PurchaseID"] = this.PurchaseID;
        return serializedObject;
    },

    deserialize: function (data)
    {
        this.BetID = data.BetID;
        this.PurchaseID = data.PurchaseID;
        ComboSPSlipMode.prototype.deserialize.call(this, data);
    },

    createEditMyBetRequest: function (request)
    {
        var editMyBetRequest = {
            Purchase: request,
            ExistingPurchaseId: this.PurchaseID,
            ExistingBetId: this.BetID,
            Stake: this.VariationsDeposits[this.Variants.length - 1]
        }

        return editMyBetRequest;
    },

    placeBets: function (startIndex)
    {

		if (BaseSPSlipMode.prototype.isGeolocationInProgress.call(this))
		{
			return;
		}

        if (!startIndex)
        {
            startIndex = this.getStartIndex();
        }

        if (this.IsBetInProcess) return;

        this.setBetInProcess(true, true);

        if (!this.check())
        {
            this.setBetInProcess(false, true);
            return;
        }

        var totals = BetSlip.getFooterTotals();
        if (UserInfo.TaxProvider.isUserUnderTax() && BetSlip.validatePurchaseWithCountryTaxes(totals.Taxes, this.getTotalDeposit()))
        {
            this.setBetInProcess(false, true);
            this.setError($dict.bs("CountryTaxInvalidOdds"));
            return;
        }

        if (this.SetFromComboPromotion)
        {
            Data.sendToGoogleAnalytics("Combo of The Day", "Bet Slip", this.Deposit);
            this.SetFromComboPromotion = 0;
        }

        var lines = [];
        var comboBonuses = {};

        var isLiveCombo = false;

        var selectionsInCombo = this.getSelectionsInCombo();
        var oddStyleIDFromClient = BetSlip.getAlternativeOddsStyle() || currentOddStyle;

        var ewIncluded = this.CheckForEachWayIncluded();

        if (ComboBonusProvider.ComboBonuses != null)
        {
            comboBonuses = ComboBonusProvider.ComboBonuses;
        }

        for (var key in selectionsInCombo)
        {
            if (!selectionsInCombo[key].Valid || selectionsInCombo[key].Danger)
            {
                this.setError($dict.bs("ComboEventDanger"));
                this.setBetInProcess(false, true);
                return;
            }

            if (selectionsInCombo[key].Live)
            {
                isLiveCombo = true;
            }

            lines.push(selectionsInCombo[key]);
        }

        var deps = Array.findAll(this.VariationsDeposits, function (v) { return (v && v != 0); });
        var deposits = [];
        for (var i = startIndex; i < deps.length; i++)
            deposits[i] = deps[i] ? deps[i] : null;

        var freeBets = this.getComboFreeBets();

        var ref = this;

        BetSlip.disablePlaceBetButton();

        var purchaseRequest = this.formatPurchaseRequest(lines, null, deposits, null, null, null, null, null, freeBets, comboBonuses)
        var editMybetsRequest = this.createEditMyBetRequest(purchaseRequest);

        BettingPageMethods.EditMyBet(editMybetsRequest,
                 function (result)
                 {
                     var purchase = [eval(result)];
                     ref.placePurchaseCallBack(purchase);
                     purchase[0] &&
                     (purchase[0].Status == SPPurchaseStatus.Accepted || purchase[0].Status == SPPurchaseStatus.Waiting) ?
                     BetSlip.exitFromEditMode() : "";
                 },
                 this.placePurchaseCallBackError,
                 this
             );

        // Suppress an unexpected JS error on updateView() so that it cannot stop the "bet in process" state
        try { this.updateView(); } catch (e) { if (console && console.log) console.log(e); }
    },

}, TEditOpenBetSPSlipMode);

window.EditOpenBetSPSlipMode = EditOpenBetSPSlipMode;
