function FirstInNextMinutes(eventTypeName)
{
    NextMinutes.call(this, eventTypeName);
}

$.extend(FirstInNextMinutes.prototype, NextMinutes.prototype,
{
    buildEventNameForBet: function (lineTypeName, eventTypeName)
    {
        return eventTypeName;
    },

    buildPickLineName: function (qaBet)
    {
        return qaBet.LineTypeName || qaBet.LineName;
    }
});

window.FirstInNextMinutes = FirstInNextMinutes;