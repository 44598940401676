window.addEventListener('load', function () {
    function iterateChosenBetHTML(el) {
        var selectedElement = el.getAttribute('id');
        if (selectedElement == 'loginButton' || selectedElement == 'registerButton') {
            return;
        }

        var oddSelectClass = '';
        for (var i = 0; i < el.classList.length; i += 1) {
            if (el.classList.item(i).startsWith(getUniqueOddsClassPrefix())) {
                oddSelectClass = el.classList.item(i);
                break;
            }
        }

        if (!el.classList.contains('chosenBet')) {
            var maxRegularLinesCountsError = $dict.bs("MaxRegularLinesCounts").format(BetSlip.maxSelectionsNumber);
            if (Array.getLength(BetSlip.Selections) < BetSlip.maxSelectionsNumber && BetSlip.CurrentMode.CurrentError !== maxRegularLinesCountsError) {
                addRemoveChosenBetClassByDataParams(oddSelectClass, el, true);
            }
            else {
                alert(maxRegularLinesCountsError);

            }
        }
        else {
            addRemoveChosenBetClassByDataParams(oddSelectClass, el, false);
        }

    };

    function betButtonCallback(e) {
        if (e.target.classList.contains('bet_btn') || e.target.classList.contains('bet-button') || e.target.classList.contains('rj-ev-list__bet-btn')) {
            iterateChosenBetHTML(e.target);
        }
    }
    
    function addRemoveChosenBetClassByDataParams(selector, element, add) {
        if (BetSlip.IsInEditMode) {
            return;
        }
    
        if (add) {
            BetSlipAnimatedOddsAdd(element);
        }
        else {
            element.classList.remove('chosenBet');
        }
    
        if (!selector) {
            return;
        }
    
        var elements = document.getElementsByClassName(selector);
        if (elements) {
            for (var i = 0; i < elements.length; i += 1) {
                add ? elements[i].classList.add('chosenBet') : elements[i].classList.remove('chosenBet');
            }
        }
    }    

    document.body.addEventListener('click', betButtonCallback);
});
