﻿var Purchases = (function (uniSlip)
{
    "use strict";

    var toCultureString = function (n)
    {
        return Number.prototype.hasOwnProperty("toCultureString") ? n.toCultureString() : n;
    };

    var api = {};

    api.getBetStatusClass = function (bet)
    {
        switch (bet.Status)
        {
            case 1: { return "positive"; }
            case 2: { return "negative"; }
        }

        return "";
    };

    api.getStatusClass = function (purchase)
    {
        switch (purchase.PurchaseStatus)
        {
            case SPPurchaseStatus.Accepted: { return "positive"; }
            case SPPurchaseStatus.RegulatorTimeOut:
            case SPPurchaseStatus.Declined: { return "negative"; }
            case SPPurchaseStatus.Waiting:
            case SPPurchaseStatus.NewOffer:
            case SPPurchaseStatus.ClientAccepted:
            case SPPurchaseStatus.NotAuthorized: { return "emphasis"; }
        }

        return "";
    };

    api.getSingleBetDeposit = function (bet)
    {
        var result = bet.Deposit;
        result = toCultureString(Math.round((result * 100).toFixed(4)) / 100);
        result = Bets.formatCurrencyStake(result, uniSlip.UserCurrencyCode);

        if (bet.Selection.isToWin())
        {
            result += bet.Selection.isToWin() ? " / " + Bets.formatCurrencyStake(bet.getToWin(), uniSlip.UserCurrencyCode) : "";
        }

        return result;
    };

    api.getComboBetDeposit = function (bet, context)
    {
        var varSummary = context.VariationSummary;
        var translatedBet = varSummary.numberOfBets == 1 ? $dict.bs("ComboNameBet") : $dict.bs("ComboNamesBets");
        var depositSummary = toCultureString(varSummary.totalDeposit / varSummary.numberOfBets);
        return varSummary.numberOfBets + " " + translatedBet + " " + $string("General").x + " " + Bets.formatCurrencyStake(depositSummary, uniSlip.UserCurrencyCode);
    };

    api.getSystemBetDeposit = function (bet, context)
    {
        var varSummary = context.VariationSummary;
        var stringToFormat = "{0} {1} " + $string("General").x + " {2}";
        var depositSummary = toCultureString(varSummary.totalDeposit / varSummary.numberOfBets);

        return stringToFormat.format(varSummary.numberOfBets, $dict.bs("ComboNamesBets"), Bets.formatCurrencyStake(depositSummary, uniSlip.UserCurrencyCode));
    };

    api.getTeaserBetDeposit = function (bet)
    {
        var betsCount = bet.Purchase.Bets.length,
            betsCountName = $dict.bs(betsCount === 1 ? "ComboNameBet" : "ComboNamesBets"),
            depositSummary = toCultureString(bet.Deposit / betsCount);

        return "{0} {1} {2} {3}".format(betsCount, betsCountName, $string("General").x, Bets.formatCurrencyStake(depositSummary, uniSlip.UserCurrencyCode));
    };

    api.getBetTitle = function (bet)
    {
        if (!Modes.isNBS())
        {
            return bet.BetID ? "#" + bet.BetID : bet.StatusText;
        }

        if (bet.StatusText === $dict.bs("DepositOverMaxBet") || bet.StatusText === $dict.bs("BetNotAccepted"))
        {
            return bet.StatusText;
        }

        return "";
    };

    api.getSPSingleBetTitle = function (bet)
    {
        return api.getBetTitle(bet);
    };

    api.getComboBetOdds = function (bet, context)
    {
        /*globals currentOddStyle */
        var varSummary = context.VariationSummary,
            oddStyle = uniSlip.getAlternativeOddsStyle("combo") || currentOddStyle;

        if (bet.IsEWBet)
        {
            oddStyle = context.Purchase.alternativeOddsStyle;
        }

        if (varSummary.isVariationFullCombo)
        {
            return Odds.format(Odds.convertFromDecimal(varSummary.maxOdds, oddStyle), oddStyle);
        }

        return Odds.format(Odds.convertFromDecimal(varSummary.minOdds, oddStyle), oddStyle) + " / " +
            Odds.format(Odds.convertFromDecimal(varSummary.maxOdds, oddStyle), oddStyle);
    };

    api.getSystemBetOdds = function (bet, context)
    {
        /*globals currentOddStyle */
        var varSummary = context.VariationSummary,
            oddStyle = context.Purchase.getAlternativeOddsStyle() ? context.Purchase.getAlternativeOddsStyle() : currentOddStyle;

        if (bet.IsEWBet)
        {
            oddStyle = context.Purchase.alternativeOddsStyle;
        }

        return Odds.format(Odds.convertFromDecimal(varSummary.minOdds, oddStyle), oddStyle) + " / " +
            Odds.format(Odds.convertFromDecimal(varSummary.maxOdds, oddStyle), oddStyle);
    };

    api.getTeaserBetOdds = function (bet)
    {
        /*globals currentOddStyle */
        var alternativeOddsStyle = bet.Purchase.getAlternativeOddsStyle(),
            oddsStyle = alternativeOddsStyle ? alternativeOddsStyle : currentOddStyle;

        return Odds.format(Odds.convertFromAmerican(bet.Odds, oddsStyle), oddsStyle);
    };

    /* jshint maxcomplexity: 7 */
    api.getSingleBetReturn = function (bet)
    {
        var totalGain;
        if (IsUKBetSlip) {
            totalGain = bet.Selection.EachWaySingleIncluded ?
            bet.getTotalGainEW() :
            bet.getTotalGain();
        }
        else
        {
            totalGain = bet.Selection.EachWayIncluded ?
            bet.getTotalGainEW() :
            bet.getTotalGain();
        }
        if (bet.Selection.isSPSelection() || totalGain * 1 === 0 || bet.Selection.isTotesSelection())
        {
            return $dict.bs("NotAvailable");
        }

        return Bets.formatCurrencyStake(totalGain, uniSlip.UserCurrencyCode);
    };

    /* jshint maxcomplexity: 7 */
    api.getComboBetReturn = function (bet, context)
    {
        if (context.HasSP)
        {
            return $dict.bs("NotAvailable");
        }

        var varSummary = context.VariationSummary;

        if (varSummary.isVariationFullCombo)
        {
            return Bets.formatCurrencyStake(varSummary.maxWin, uniSlip.UserCurrencyCode);
        }

        return Bets.formatCurrencyStake(varSummary.minWin, uniSlip.UserCurrencyCode) + " / " +
            Bets.formatCurrencyStake(varSummary.maxWin, uniSlip.UserCurrencyCode);
    };

    api.getSystemBetReturn = function (bet, context)
    {
        if (context.HasSP)
        {
            return $dict.bs("NotAvailable");
        }

        var varSummary = context.VariationSummary;

        return Bets.formatCurrencyStake(varSummary.minWin, uniSlip.UserCurrencyCode) + " / " +
            Bets.formatCurrencyStake(varSummary.maxWin, uniSlip.UserCurrencyCode);
    };

    api.getTeaserBetReturn = function (bet)
    {
        return Bets.formatCurrencyStake(bet.getTotalGain(), uniSlip.UserCurrencyCode);
    };

    api.getComboBetDescription = function (bet, context)
    {
        var varSummary = context.VariationSummary,
            variationTypeName = bet.BetType === BetTypes.Combinator ? bet.BetName : $dict.bs("ComboNames_" + bet.VariationType),
            betsCount = varSummary.numberOfBets,
            betsString = $dict.bs(varSummary.numberOfBets === 1 ? "ComboNameBet" : "ComboNamesBets"),
            ewString = bet.IsEWBet ? " - " + $string("General").EachWayTag : "";

        return variationTypeName + " - " + betsCount + " " + betsString + ewString;
    };

    api.getSystemBetDescription = function (bet, context) {
        var varSummary = context.VariationSummary,
            selectionGroups = context.Purchase.getSelectionGroups(),
            ll = Array.getLength(selectionGroups),
            betsCount = varSummary.numberOfBets,
            betsString = $dict.bs("ComboNamesBets"),
            ewString = bet.IsEWBet ? " - " + $string("General").EachWayTag : "",
            bankersCount = context.Purchase.getBankersCount(selectionGroups),
            variationTypeName;

        if (bankersCount > 0)
        {
            variationTypeName = ("{0} {1}".format($dict.bs("SystemName"), bet.BetName));
        }
        else
        {
            variationTypeName = bet.VariationType < ll ? ("{0} {1}/{2}".format($dict.bs("SystemName"), bet.VariationType, ll)) : $dict.bs("SystemNames_{0}_{1}".format(ll, bet.VariationType));
        }

        if (IsUKBetSlip && bet.BetName)
        {
            variationTypeName = bet.BetName;
        }

        return variationTypeName + " - " + betsCount + " " + betsString + ewString;
    };

    api.getTeaserBetDescription = function (bet)
    {
        var betsCount = bet.Purchase.Bets.length,
            betsCountName = $dict.bs(betsCount === 1 ? "ComboNameBet" : "ComboNamesBets");

        return "{0} - {1} {2}".format(bet.BetName, betsCount, betsCountName);
    };

    api.getVariationSummary = function (bet, context)
    {
        var variations;

        if (!bet.IsEWBet && context.Variations[bet.VariationType])
        {
            variations = context.Variations[bet.VariationType];
            delete context.Variations[bet.VariationType];
        }
        else if (bet.IsEWBet && context.EWVariations[bet.VariationType])
        {
            variations = context.EWVariations[bet.VariationType];
            delete context.EWVariations[bet.VariationType];
        }

        return variations ? context.Purchase.calculateVariationSummary(bet.VariationType, variations) : null;
    };

    api.getSystemVariationSummary = function (bet, context)
    {
        var bets = [];

        bets.push(bet);

        var containEW = bets.any(function (b) { return b.IsEWBet; });

        if (containEW)
        {
            return context.Purchase.calculateCombinedSystemSummaryEW(bets, context.HasSP);
        }
        else
        {
            return context.Purchase.calculateCombinedSystemSummary(bets, context.HasSP);
        }
    };

    api.skipBet = function (bet, context)
    {
        context.VariationSummary = bet.getVariationSummary(context);

        return !context.VariationSummary;
    };

    api.getSingleBets = function (purchase)
    {
        var keys = Object.keys(purchase.Bets),
            bet, result = [], i, len;

        for (i = 0, len = keys.length; i < len; i += 1)
        {
            bet = purchase.Bets[keys[i]];

            if (bet.Selection.EachWayIncluded)
            {
                // Clone each-way selection as a separate bet
                var realPart = cloneSPSingleBet(bet, bet.Selection);
                realPart.Selection.EachWayIncluded = false;
                var eachWayPart = cloneSPSingleBet(bet, bet.Selection);
                eachWayPart.FreeBetStake = 0;

                result.push(realPart, eachWayPart);
            }
            else
            {
                result.push(bet);
            }
        }

        return result;
    };

    var cloneSPSingleBet = function (bet, selection)
    {
        selection = $.extend(Object.create(QASlipSelection.prototype), selection);

        /*globals SPSingleBet */
        return new SPSingleBet($.extend({ Stake: bet.Deposit }, bet), selection, bet.Status, bet.WaitingBetID);
    };

    api.updateComboContext = function (purchase, context)
    {
        updateComboState(purchase);

        var selections = purchase.getSelections(), i;

        for (i in selections)
        {
            if (selections.hasOwnProperty(i))
            {
                updateComboSelectionContext(purchase, selections[i]);
            }
        }

        context.HasSP = purchase.HasSP;
        context.Purchase = purchase;

        if (!Modes.isNBS())
        {
            context.Variations = purchase.Variations.slice();
            context.EWVariations = purchase.EWVariations.slice();
        }

        return context;
    };

    var updateComboState = function (purchase)
    {
        purchase.HasSP = false;
        purchase.HasEW = purchase.HasEW || hasComboEachway(purchase);

        if (!Modes.isNBS())
        {
            purchase.Variations = purchase.Variations || purchase.getVariations();
            purchase.EWVariations = purchase.EWVariations || purchase.getEWVariations();
        }
    };

    var hasComboEachway = function (purchase)
    {
        for (var i in purchase.Bets)
        {
            if (purchase.Bets.hasOwnProperty(i) && purchase.Bets[i].IsEWBet)
            {
                return true;
            }
        }

        return false;
    };

    var updateComboSelectionContext = function (purchase, selection)
    {
        if (selection.isSPSelection())
        {
            purchase.HasSP = true;
        }

        if (purchase.HasEW && selection.BetType == 7 && !selection.IsEachWaySelection)
        {
            selection.EachWayIncluded = true;
        }
    };

    api.getSingleLinesDescription = function (purchase)
    {
        var keys = Object.keys(purchase.Bets),
            count = keys.length, i, len, bet,
            totalDeposit = toCultureString(purchase.getTotalDeposit());

        for (i = 0, len = count; i < len; i += 1)
        {
            bet = purchase.Bets[keys[i]];
            if (!bet.Selection.EachWayIncluded) { continue; }

            count += 1;
        }

        /*globals $string */
        return count + " " + $string("General").Lines + " - " +
            Bets.formatCurrencyStake(totalDeposit, uniSlip.UserCurrencyCode);
    };

    api.getComboLinesDescription = function (purchase)
    {
        var bet = Array.first(purchase.Bets),
            linesCount = Object.keys(purchase.getSelections()).length,
            totalDeposit = toCultureString(purchase.getTotalDeposit()),
            variationTypeName = purchase.ContainsPermutations ? "" :
                ($dict.bs("ComboNames_" + bet.VariationType) + " - ");

        return linesCount + " " + $string("General").Lines + " - " + variationTypeName +
            Bets.formatCurrencyStake(totalDeposit, uniSlip.UserCurrencyCode);
    };

    api.getCombinatorLinesDescription = function (purchase) {
        var linesCount = Object.keys(purchase.getSelections()).length,
            totalDeposit = toCultureString(purchase.getTotalDeposit());

        return linesCount + " " + $string("General").Lines + " - " +
            Bets.formatCurrencyStake(totalDeposit, uniSlip.UserCurrencyCode);
    };

    api.getSystemLinesDescription = function (purchase)
    {
        var comboGroups = purchase.getSelectionGroups(),
            cg = Array.getLength(comboGroups),
            systemKey = purchase.SystemKey * 1,
            linesCount = Object.keys(purchase.getSelections()).length,
            bankersCount = purchase.getBankersCount(comboGroups),
            totalDeposit = toCultureString(purchase.getTotalDeposit()),
            variationTypeName;

        if (bankersCount > 0)
        {
            variationTypeName = ("{0} {1}{2}/{3}".format($dict.bs("SystemName"), bankersCount + "B+", systemKey, cg - bankersCount));
        }
        else
        {
            variationTypeName = systemKey < cg ?
           ("{0} {1}/{2}".format($dict.bs("SystemName"), systemKey, cg)) :
           $dict.bs("{0}_{1}_{2}".format("SystemNames", cg, systemKey));
        }

        return linesCount + " " + $string("General").Lines + " - " + variationTypeName + " - " +
            Bets.formatCurrencyStake(totalDeposit, uniSlip.UserCurrencyCode);
    };

    api.getUKLinesDescription = function (purchase)
    {
        var selections = purchase.getSelections(),
            linesCount = Object.keys(selections).length,
            totalDeposit = toCultureString(purchase.getTotalDeposit());

        if (selections.any(function (sel) { return sel.IsMultiLine; }))
        {
            var castSelections = Array.findAll(selections, function (sel) { return sel.CastSelection && !sel.VisibleCastSelection; });
            linesCount = linesCount - Array.getLength(castSelections);
        }

        return linesCount + " " + $string("General").Lines + " - " +
            Bets.formatCurrencyStake(totalDeposit, uniSlip.UserCurrencyCode);
    };

    api.getTeaserLinesDescription = function (purchase)
    {
        var totalDeposit = toCultureString(purchase.Bet.Deposit);

        return "{0} - {1}".format(purchase.Bet.BetName, Bets.formatCurrencyStake(totalDeposit, uniSlip.UserCurrencyCode));
    };

    api.getStatus = function(purchaseStatus)
    {
        switch (purchaseStatus)
        {
            case 1:
                return 'accepted';
                break;
            case 2:
                return 'rejected';
                break;
            default:
                return 'waiting';
        }
    };

    api.isWaitingStatus = function (purchaseStatus)
    {
        return api.getStatus(purchaseStatus) == 'waiting';
    }

    api.isAcceptedStatus = function (purchaseStatus)
    {
        return api.getStatus(purchaseStatus) === 'accepted';
    };
    
    api.isNewOfferStatus = function (bet)
    {
        return bet.Status === SPPurchaseStatus.NewOffer;
    };

    api.isSingleBet = function (purchase, bet, context)
    {
        return (context.isSingle && !IsUKBetSlip && purchase.PurchaseTypeID === SPPurchaseType.Single) ||
               (IsUKBetSlip && (bet instanceof SPMultiLineBet || bet instanceof SPSingleBet));
    };

    api.hasComboBonus = function (bet)
    {
        var isCombinationBet = (bet instanceof SPComboBet || bet instanceof SPSystemBet);
        var variants = isCombinationBet ? bet.Purchase.getComboVariants() : [];
        return isCombinationBet && variants[bet.VariationType] == bet.BetsCount;
    };

    return api;
}(BetSlip));

window.Purchases = Purchases;