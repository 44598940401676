function ComboNoCombinationSPSlipMode()
{
    ComboSPSlipMode.call(this);  
    
    this.ID = "comboNoCombination";
    this.Name = $dict.bs("ComboBetNoCombinationsTab");
    this.PurchaseTypeID = SPPurchaseType.StraightCombo;
}

$.extend(ComboNoCombinationSPSlipMode.prototype, BaseSPSlipMode.prototype, ComboSPSlipMode.prototype,
{
    CheckForEachWayIncluded: function (key)
    {
        if (typeof (BetSlipRegulations) !== "undefined" && BetSlipRegulations.hasOwnProperty('HideEachWay') && BetSlipRegulations.HideEachWay == true)
        {
            return false;
        }

        if (key)
        {
            return !!this.SelectedEWVariants[key];
        }

        for (var idx in this.SelectedEWVariants)
        {
            if (this.SelectedEWVariants[idx] == true)
                return true;
        }
        return false;
    },

    CheckForEachWayEnabled: function (key)
    {
        if (typeof (BetSlipRegulations) !== "undefined" && BetSlipRegulations.hasOwnProperty('HideEachWay') && BetSlipRegulations.HideEachWay == true)
        {
            return false;
        }

        if (key)
            return (this.EWVariants[key] > 0);

        for (var idx in this.EWVariants)
        {
            if (idx < 2) continue;
            if (this.EWVariants[idx] > 0)
                return true;
        }
        return false;
    },

    getCurrentDeposit: function (deposit)
    {       
        return UserInfo.TaxProvider.applyTaxToDeposit(deposit);
    }
});

window.ComboNoCombinationSPSlipMode = ComboNoCombinationSPSlipMode;