﻿var BookABet = (function bookABet()
{

    var _bookABetService = null;
    var _storageUtilities = null;
    var _lastCode = null;
    var _lastQrCode = null;
    var _lastBookABetString = null;
    var _bookABetContainer = null;

    function getBookBetString()
    {
        return _storageUtilities.getFromStorage("unislip");
    }

    function bookBetSuccessCallback(rawResponse)
    {
        var response = eval(rawResponse);
        _bookABetContainer().classList.add("code-visible");
        if (response.status)
        {
            var code = response.code;
            setBookABetCode(code);
            _lastCode = code;
            _lastQrCode = response.qrCode;

            if (_lastQrCode && typeof _lastQrCode === 'string') {
                var qrInd = _lastQrCode.indexOf('<svg');
                qrInd != -1 ? _lastQrCode = _lastQrCode.slice(0, 5) + "viewBox = '0 0 29 29' " + _lastQrCode.slice(5) : _lastQrCode;
            }

            BetSlip.updateView();
        }
        else
        {
            _bookABetContainer().classList.add("error-visible");
            setBookABetMessage($dict.bs("BookABetError"));
        }
    }

    function setBookABetCode(code)
    {
        var codeWrapper = document.querySelector(".book-a-bet-container .code-container");
        codeWrapper.classList.remove("error");
        var messageElement = document.querySelector(".book-a-bet-message");
        messageElement.innerText = $dict.bs("YourBetslipCodeIs");
        var codeElement = document.querySelector(".book-a-bet-code");
        codeElement.innerText = code;
    }

    function setBookABetMessage(text)
    {
        var codeWrapper = document.querySelector(".book-a-bet-container .code-container");
        codeWrapper.classList.add("error");
        var messageElement = document.querySelector(".book-a-bet-message");
        messageElement.innerText = text;
        var codeElement = document.querySelector(".book-a-bet-code");
        codeElement.innerText = "";
    }

    function getIsBookABetVisible()
    {
        return BetSlip.getSelectionsCount() > 0 && !BetSlip.Selections.any(function (s) { return s.Live; });
    }

    function onBetSlipStateSaved()
    {
        var bookABetContainer = _bookABetContainer();
        if (!bookABetContainer)
        {
            return;
        }
        var containerClassList = bookABetContainer.classList;
        if (getIsBookABetVisible())
        {
            containerClassList.remove("hidden");
        }
        else
        {
            containerClassList.add("hidden");
        }
        if (_lastBookABetString !== getBookBetString())
        {
            containerClassList.remove("code-visible");
            containerClassList.remove("error-visible");
            _lastCode = null;
            _lastQrCode = null;
        }
    }

    return {

        init: function (storageUtilities, bookABetService)
        {
            _storageUtilities = storageUtilities;
            _bookABetService = bookABetService;
            BetSlip.OnBetSlipStateSaved.BookABet = onBetSlipStateSaved;
            _bookABetContainer = document.querySelector.bind(document, ".book-a-bet-container");
        },

        bookBet: function ()
        {
            _lastBookABetString = getBookBetString();
            _bookABetService.BookBet(_lastBookABetString, BetSlip.config.EnableBookABetQrCode, bookBetSuccessCallback);
        },

        getLastCode: function ()
        {
            return _lastCode;
        },

        getLastQrCode: function ()
        {
            return _lastQrCode;
        },

        getIsBookABetVisible: getIsBookABetVisible,

        changeOnlineModeToRetail: function (betslipState)
        {
            // key - online mode, value - retail mode
            var retailModes = {};
            retailModes["comboNoCombinationSingle"] = "combo";
            var retailMode = retailModes[betslipState.CurrentMode];
            if (retailMode)
            {
                betslipState.CurrentMode = retailMode;
            }
        },

        fillBetSlip: function (betslipState)
        {
            BetSlip.clear();
            this.changeOnlineModeToRetail(betslipState);
            BetSlip.setState(betslipState);
            BetSlip.selectSlipMode(betslipState.CurrentMode);
            BetSlip.CurrentMode.activate();
            BetSlip.updateView();
        }
    };
})();

window.BookABet = BookABet;