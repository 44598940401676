//WebSite JS file
function TennisFastMarket(splitTypeCode) {
    'use strict';

    this.UseGamePoints = Math.floor(splitTypeCode % 1000 / 100) > 0;
    this.TeamSplit = Math.floor(splitTypeCode % 10000 / 1000) > 0;
}

TennisFastMarket.prototype.ComposeName = function (encScore, eventTypeName, selection) {
    'use strict';

    var scoreInfo = new TennisFastMarket.ScoreInfo(encScore);
    var viewKey = selection && selection.ViewKey;

    var formattedEventTypeName = (function (fastMarket)
    {
        /* jshint maxcomplexity:8 */
        return eventTypeName.replace(/([^-]+)(\{[sgp]\})/gi, function (match, name, tag)
        {
            switch (tag.toLowerCase())
            {
                case '{s}':
                    var isMatchSetUpdated = false;

                    if (BetSlip && BetSlip.CurrentMode && typeof BetSlip.CurrentMode.isSelectionPropUpdated == 'function' && typeof viewKey != 'undefined')
                    {
                        isMatchSetUpdated = BetSlip.CurrentMode.isSelectionPropUpdated(viewKey, BetSlipSelectionPropCasesWithBlinking.MatchSet);
                    }
                    else
                    {
                        isMatchSetUpdated = selection && selection.UpdatedMatchSet;
                    }

                    return fastMarket.getScoreHtml(name + ' ' + scoreInfo.MatchSet, 'cMatchSet', viewKey, isMatchSetUpdated);
                case '{g}':
                    var isSetGameUpdated = false;

                    if (BetSlip && BetSlip.CurrentMode && typeof BetSlip.CurrentMode.isSelectionPropUpdated == 'function' && typeof viewKey != 'undefined')
                    {
                        isSetGameUpdated = BetSlip.CurrentMode.isSelectionPropUpdated(viewKey, BetSlipSelectionPropCasesWithBlinking.SetGame);
                    }
                    else
                    {
                        isSetGameUpdated = selection && selection.UpdatedSetGame;
                    }

                    return fastMarket.getScoreHtml(name + ' ' + scoreInfo.SetGame, 'cSetGame', viewKey, isSetGameUpdated);
                case '{p}':

                    var isGamePointUpdated = false;

                    if (BetSlip && BetSlip.CurrentMode && typeof BetSlip.CurrentMode.isSelectionPropUpdated == 'function' && typeof viewKey != 'undefined')
                    {
                        isGamePointUpdated = BetSlip.CurrentMode.isSelectionPropUpdated(viewKey, BetSlipSelectionPropCasesWithBlinking.GamePoint);
                    }
                    else
                    {
                        isGamePointUpdated = selection && selection.UpdatedGamePoint;
                    }

                    if (fastMarket.UseGamePoints)
                    {
                        return fastMarket.getScoreHtml(name + ' ' + scoreInfo.GamePoint, 'cGamePoint', viewKey, isGamePointUpdated);
                    }
            }
            return match;
        });
        /* jshint maxcomplexity:5 */
    })(this);

    return formattedEventTypeName;
};

/* Tennis Market Score Info */
TennisFastMarket.ScoreInfo = function(value)
{
    'use strict';

    // decompose number
    this.MatchSet = Math.floor(value / 10000);
    value = value % 10000;
    this.SetGame = Math.floor(value / 100);
    this.GamePoint = value % 100;
};

TennisFastMarket.ScoreInfo.prototype.ToString = function(usePoint)
{
    'use strict';

    function formatNumber(val)
    {
        if (val == 1)
        {
            return val + 'st';
        } else if (val == 2)
        {
            return val + 'nd';
        } else if (val == 3)
        {
            return val + 'rd';
        } else
        {
            return val + 'th';
        }
    }

    var result = formatNumber(this.MatchSet) + ' Set - ' + formatNumber(this.SetGame) + ' Game';
    if (usePoint)
    {
        result = result + ' - ' + formatNumber(this.GamePoint) + ' Point';
    }
    return result;
};

window.TennisFastMarket = TennisFastMarket;