﻿var QuickStake = (function (base, template) {
    var instance = Object.create(base || {});
    $.extend(instance, template);

    instance.getQuickStakeSeries = function(){
        return window.quickStakeSeries || [];
    };

    instance.quickStakeButtonClickHandler = function (event) {
        if (!event.target.classList.contains(instance.buttonClassName)) {
            return;
        }

        var clickedButton = event.target;
        var clickedButtonValue = parseInt(clickedButton.getAttribute("value"));
        var comboNumber = clickedButton.getAttribute("data-combonumber");

        var stakeBox = document.getElementById("stakebox_" + comboNumber);
        if (stakeBox) {
            var stakeBoxValue = StakeInputParser.parse(stakeBox.value);
            stakeBox.value = instance._calculateStake(stakeBoxValue, clickedButtonValue);
            UniSlipBlock.setComboStake(comboNumber, stakeBox);
        }

        var placeBetButton = document.getElementById("totalDeposit");
        var placeBetButtonValue = parseFloat(placeBetButton.innerHTML);
        placeBetButton.innerHTML = instance._calculateStake(placeBetButtonValue, clickedButtonValue);
    };

    return instance;
})(BaseQuickStake, TQuickStake);

window.QuickStake = QuickStake;