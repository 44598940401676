var BaseQuickStake = (function () {
    "use strict";

    var instance = {};

    instance.buildView = function (__html, comboNumber) {
        instance.buildView.call(this, __html, comboNumber, this.getQuickStakeSeries());
    };

    instance.isAvailable = function () {
        return !!this.getQuickStakeSeries().length;
    };

    instance.bindButtonsClickFunctionality = function () {
        var quickStakeButtons = document.getElementById(this.buttonsContainerId);

        if (!quickStakeButtons) {
            return;
        }

        quickStakeButtons.addEventListener("click", this.quickStakeButtonClickHandler);
    };

    instance.quickStakeButtonClickHandler = function () {
        throw "not implemented";
    };

    instance.getQuickStakeSeries = function () {
        throw "not implemented";
    };

    instance._calculateStake = function (currentValue, newValue) {
        return isNaN(currentValue) ? newValue : newValue + currentValue;
    };

    return instance;
})();

window.BaseQuickStake = BaseQuickStake;