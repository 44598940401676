﻿function QASlipSelection(masterEventID, eventID, lineID, Odds, eventTypeId, leagueId, lineTypeID)
{
    BaseSlipSelection.call(this, masterEventID, eventID, lineID, eventTypeId, false, leagueId, lineTypeID);

    this.InitializeOdds(Odds);
    this.InCombo = false;

    this.BetType = 7;

    this.EachWayIncluded = false;
    this.IsEachWayEnabled = false;
    this.IsEachWaySelection = false;

    //Needed for UK Slip so that we don't include e/w in single bets from combos or systems
    this.EachWaySingleIncluded = false;
    this.EachWayMultiplesIncluded = false;

    this.ReleatedToID = -1;

    this.PlaceTermsID = -1;
    this.OddsTermsID = -1;
    this.EachWayTermsID = -1;

    this.EWOdds = -1;
    this.EWDeposit = -1;
    this.EWGain = -1;
    this.TeamID = -1;

    this.QAParameter1 = 0;
    this.QAParameter2 = 0;

    this.CastSelection = false;
    this.Position = 0;
    this.VisibleCastSelection = false;
    this.Swapped = false;
    this.LeagueID = leagueId;
}

$.extend(QASlipSelection.prototype, BaseSlipSelection.prototype,
{
    getComboGroupKey: function ()
    {
        var keyBase = "{0}_{1}_{2}";
        var grpID = this.EventGroupID();
        var evtID = this.MasterEventID || Math.max(this.FeedEventID, 0) || this.EventID;
        var seKey = this.getSameEventComboKey();

        return keyBase.format(grpID, evtID, seKey);
    },

    getUpdateRequest: function ()
    {
        return [
            !!this.IsMultiLine,
            this.ViewKey,
            this.EventID,
            this.LineID,
            this.Odds
        ];
    },

    getPickLine: function (showNameExtension, pointsToReduce)
    {
        var helper = SelectionHelper.create(this);
        return helper.getPickLine(showNameExtension, pointsToReduce);
    },

    getPostRequest: function (deposit)
    {
        var res = [
            this.ViewKey,     //0
            this.EventID,     //1
            this.LineID,      //2
            this.Odds,        //3
            deposit,          //4
            //5 - actual adds, the odds that we are actually showing to the client
            (currentOddStyle == OddStyle.FRACTIONAL ? FractionalOddsConverter.encodeFraction(this.getFormatedOdds()) : this.getFormatedOdds() * 1)
                + ":" + this.getActualOddsStyleID(),
            this.EachWayIncluded,              //6
            this.IsEachWayEnabled,             //7
            this.PlaceTermsID,                 //8
            this.OddsTermsID,                  //9
            this.EachWayTermsID,               //10
            this.EWOdds,                       //11
            this.EWDeposit,                    //12
            this.EWGain,                       //13
             0,                                //14 - authorization
            (this.QAParameter2 == -1 ? 0 : this.QAParameter2)  // 15 - qaParameter2 from client side
        ];

        return res;
    },

    getRealOdd: function (roundingMode)
    {
        if (this.isSPSelection()) return 0;
        var dd = Math.floor(((this.Odds > 0 ? (this.Odds / 100 + 1) : (1 - 100 / this.Odds)) * 1000).toPrecision(12)) / 1000 * this.ComboRate;
        if (dd < 1.001) dd = 1.001;
        
        if (roundingMode)
        {
            dd = BetMath.roundDecimalOdds(dd, roundingMode, !BetSlip.ForceTwoDigitsForOddsRounding);
        }

        return dd;
    },
    getRealOddEW: function (roundingMode)
    {
        if (this.isSPSelection()) return 0;
        var dd = Math.floor(((this.EWOdds > 0 ? (this.EWOdds / 100 + 1) : (1 - 100 / this.EWOdds)) * 1000).toPrecision(12)) / 1000 * this.ComboRate;
        if (dd < 1.001) dd = 1.001;

        if (roundingMode)
        {
            dd = BetMath.roundDecimalOdds(dd, roundingMode, !BetSlip.ForceTwoDigitsForOddsRounding);
        }

        return dd;
    },

    // Odds are usually retrieved using the global currentOddsStyle variable. If an explicit odds-style is specified
    // or the current mode of the betslip is specifying an alternative odds-style, use them instead.
    getFormatedOdds: function (oddsStyle)
    {
        var alternativeOddsStyle = oddsStyle || BetSlip.getAlternativeOddsStyle();
        var formattedOdds = Odds.convertFormat(this.Odds, this.EventTypeID, this.BetTypeID, undefined, alternativeOddsStyle, this.BetType, this.BranchID, this.FractionalOdds);

        if (BetSlip.BaseOddsRoundingMode && !isNaN(formattedOdds * 1))
        {
            formattedOdds = Odds.format(BetMath.roundDecimalOdds(formattedOdds * 1, BetSlip.BaseOddsRoundingMode, !BetSlip.ForceTwoDigitsForOddsRounding)) + "";
        }

        return formattedOdds;
    },

    // Returns the odd style that the selection is actually seen in by the user. This may not be the current odd style
    // as there are requirements for example when the user is in an Asian odd style that they should see the odds on
    // the combo tab in decimal. Also is a requirement that when in Asian odd style the user sees some events on the
    // single tab in Decimal rather than in the current Asian odd style (for instance Money line on Soccer events).
    // See Odds.convertFormat(), Odds.convert() and Odds.showDecimalInsteadOfAsian() in Odds.js
    getActualOddsStyleID: function ()
    {
        var alternativeOddsStyle = BetSlip.getAlternativeOddsStyle();
        var oddsStyleID = Odds.showDecimalInsteadOfAsian(this.BetType, this.EventTypeID, this.BetTypeID, this.BranchID) ? OddStyle.EUROPEAN : currentOddStyle;
        oddsStyleID = alternativeOddsStyle ? alternativeOddsStyle : oddsStyleID;
        return oddsStyleID;
    },

    isSplit: function ()
    {
        return false;
    },

    processBetUpdate: function (updates)
    {
        if (this.IsEachWaySelection) return;
        var firstcall = !this.Initialized;

        BaseSlipSelection.prototype.processBetUpdate.call(this, updates);
        if (firstcall)
        {
        	this.EventDate = updates.EventDate;
        	this.LineTypeName = this.EventTypeID == 341 ? updates.LineTypeID == 19 || updates.LineTypeID == 18 ? $string("General").Antepost + " " + $string("BettingHistory").Win : $string("BettingHistory").Win
														: updates.LineTypeName; // RaceCard 
            this.BetTypeID = updates.LineTypeID; // BetTypeID is actually LineTypeID
            this.LineTypeID = updates.LineTypeID;
            this.UseEventName = updates.UseEventName;
            this.EventName = this.getEventName(updates.EventName);
            this.LineName = updates.LineName;
            this.AsianLineName = updates.AsianLineName || this.LineName;
            this.MappedLineTypeID = updates.MappedLineTypeID;
            this.Swapped = updates.Swapped || false;
            //***********Separating dependency between client and event types***********
            if (specialsID[this.EventTypeID])
                this.SplitType = specialsID[this.EventTypeID];
            else
                this.SplitType = updates.SplitTypeID;
            //***********END***********
        }

        if (!this.Valid || updates.length == 0)
            return;
        this.SISEventID = updates.SISEventID;
        this.GBSEventID = updates.GBSEventID;
        this.FeedEventID = updates.FeedEventID;
        this.FeedProviderID = updates.FeedProviderID
        this.RaceCardEventID = updates.RaceCardEventID 

        if (this.FeedProviderID != RacingFeedProviders.GBS)
        {
            this.AlternateLineChanged = false;

            if (this.AlternateLineID != updates.AlternateLineID || this.AlternateLineOdds != updates.AlternateLineOdds)
            {
                this.AlternateLineChanged = true;
            }  

            this.AlternateLineID = updates.AlternateLineID;
            this.AlternateLineOdds = updates.AlternateLineOdds;
            this.AlternateLineTypeID = updates.AlternateLineTypeID;
            this.AlternateLineFractOdds = updates.AlternateLineFractOdds;
        }

        this.FractOddsDividend = updates.FractOddsDividend;
        this.FractOddsDivisor = updates.FractOddsDivisor;
        this.FractionalOdds = updates.FractionalOdds;

        this.processHighlightUpdates(updates, firstcall);
        this.processLineClosed();
        this.processDanger(updates);

        // For Virtual Racing EW lines are placed like regular ones,
        // so we don't need enabled each way in betslip
        if (this.EventTypeID == 396)
        {
            var placeTermsID = updates.PlaceTermsID || this.PlaceTermsID;
            this.disableEachWay();
            this.PlaceTermsID = placeTermsID;
        }
        else if (updates.IsEachWayEnabled != undefined && updates.IsEachWayEnabled)
        {
            this.IsEachWayEnabled = true;
            this.PlaceTermsID = updates.PlaceTermsID;
            this.EachWayTermsID = updates.EachWayTermsID;
            this.ReleatedToID = updates.ReleatedToID;

            this.EachWayMaxStake = updates.EachWayMaxStake;
            this.EachWayMaxStakeCombo = updates.EachWayMaxStakeCombo;
            this.EachWayComboBetIsEnabled = updates.EachWayComboBetIsEnabled;
            this.EachWayMinBet = updates.EachWayMinBet;

            this.EachWayFractOddsDividend = updates.EachWayFractOddsDividend;
            this.EachWayFractOddsDivisor = updates.EachWayFractOddsDivisor;
            this.EachWayFractionalOdds = updates.EachWayFractionalOdds;
            this.EachWayAmericanOdds = updates.EachWayAmericanOdds;

            if (this.OddsTermsID != updates.OddsTermsID)
            {
                this.OddsTermsID = updates.OddsTermsID;
                BetSlip.recalcEachWay(this.ViewKey);
            }
            else
            {
                this.OddsTermsID = updates.OddsTermsID;
            }
        }
        else if (updates.IsEachWayEnabled != undefined && !updates.IsEachWayEnabled)
        {
            this.disableEachWay();
        }
        if (updates.TeamID != undefined)
        {
            this.TeamID = updates.TeamID;
        }
        this.WinToteID = updates.WinToteID;
        this.PlaceToteID = updates.PlaceToteID;

        //UPDATE MULTI LINE ITEM
        this.processMultiLineUpdate(updates);
    },

    processHighlightUpdates: function(updates, firstcall)
    {
        BaseSlipSelection.prototype.processHighlightUpdates.call(this, updates, firstcall);

        this.Updated = this.processQAParametersUpdate(updates, firstcall) || this.Updated;

    },

    processQAParametersUpdate: function(updates, firstcall)
    {
        var qaParameter1Updated = updates.QAParameter1 != undefined && updates.QAParameter1 != this.QAParameter1;
        var qaParameter2Updated = updates.QAParameter2 != undefined && updates.QAParameter2 != this.QAParameter2;

        this.QAParameter1 = updates.QAParameter1;
        this.QAParameter2 = updates.QAParameter2;

        return qaParameter1Updated || qaParameter2Updated;
    },

    isLineClosed: function ()
    {
        return BaseSlipSelection.prototype.isLineClosed.call(this) && !this.isSPSelection() && !this.isToteEvent();
    },

    disableEachWay: function ()
    {
        this.EachWayIncluded = false;
        this.IsEachWayEnabled = false;
        this.PlaceTermsID = -1;
        this.OddsTermsID = -1;
        this.EachWayTermsID = -1;
        this.ReleatedToID = -1;
    },

    shouldIncludeEWInPick: function ()
    {
        var isInBetSlip = HashtableOf.indexOf(BetSlip.Selections, this.ViewKey) >= 0;
        return this.EachWayIncluded && !isInBetSlip;
    },

    findRelatedMultiLineItems: function ()
    {
        var multiLineItems = [];
        for (var idx in BetSlip.Selections)
        {
            var sel = BetSlip.Selections[idx];
            if ((sel instanceof MultiLineItem) &&
                (Array.indexOf(sel.Selections, this.ViewKey) >= 0))
            {
                multiLineItems.push(sel);
            }
        }

        return multiLineItems;
    },

    processMultiLineUpdate: function (updates)
    {
        var multiLineItems = this.findRelatedMultiLineItems();
        for (var i in multiLineItems)
        {
            var item = multiLineItems[i];
            if (item.BetType == 11 && updates.ForecastMaxBet > 0)
                item.maxBet = updates.ForecastMaxBet;

            if (item.BetType == 12 && updates.TricastMaxBet > 0)
                item.maxBet = updates.TricastMaxBet;

            if (item.BetType == 21 && updates.FirstFourMaxBet > 0)
                item.maxBet = updates.FirstFourMaxBet;

            if (updates.MultiLineMinBet)
                item.minBet = updates.MultiLineMinBet;
        }
    },

    clearUpdatedFlags: function ()
    {
        this.Updated = false;
        this.UpdatedOdds = false;
    },

    serialize: function ()
    {
        return $.extend({}, BaseSlipSelection.prototype.serialize.call(this),
            {
                BetType: 7,
                Live: false,
                Odds: this.Odds,
                LineTypeName: this.LineTypeName,
                BetTypeID: this.BetTypeID,
                LineTypeID: this.LineTypeID,
                EventName: this.EventName,
                LineName: this.LineName,
                AsianLineName: this.AsianLineName,
                MappedLineTypeID: this.MappedLineTypeID,
                SplitTypeID: this.SplitType,
                EachWayIncluded: this.EachWayIncluded,
                EachWaySingleIncluded: this.EachWaySingleIncluded,
                EachWayMultiplesIncluded: this.EachWayMultiplesIncluded,
                IsEachWayEnabled: this.IsEachWayEnabled,
                PlaceTermsID: this.PlaceTermsID,
                OddsTermsID: this.OddsTermsID,
                EachWayTermsID: this.EachWayTermsID,
                EWOdds: this.EWOdds,
                EWDeposit: this.EWDeposit,
                EWGain: this.EWGain,
                IsEachWaySelection: this.IsEachWaySelection,
                TeamID: this.TeamID,
                FractOddsDividend: this.FractOddsDividend,
                FractOddsDivisor: this.FractOddsDivisor,
                FractionalOdds: this.FractionalOdds,
                AlternateLineID: this.AlternateLineID,
                AlternateLineOdds: this.AlternateLineOdds,
                AlternateLineTypeID: this.AlternateLineTypeID,
                AlternateLineFractOdds: this.AlternateLineFractOdds,
                CastSelection: this.CastSelection,
                VisibleCastSelection: this.VisibleCastSelection,
                Swapped: this.Swapped,
                EventDate: this.EventDate,
                SISEventID:this.SISEventID,
                GBSEventID: this.GBSEventID,
                FeedEventId:this.FeedEventId
            });
    },

    getFormatedOddsEW: function (oddsStyle)
    {
        var alternativeOddsStyle = oddsStyle || BetSlip.getAlternativeOddsStyle();
        return Odds.convertFormat(this.EWOdds, this.EventTypeID, this.BetTypeID, undefined, alternativeOddsStyle, this.BetType, this.BranchID, this.EachWayFractionalOdds);
    },

    getGainEW: function ()
    {
        if (this.isSPSelection()) return 0;

        var fodd = this.EWOdds > 0 ? (this.EWOdds / 100 + 1) : (1 - 100 / this.EWOdds);
        if (typeof BetSlip != "undefined" && BetSlip.BaseOddsRoundingMode)
        {
            fodd = BetMath.roundDecimalOdds(fodd, BetSlip.BaseOddsRoundingMode, !BetSlip.ForceTwoDigitsForOddsRounding);
        }

        return fodd * this.EWDeposit;
    },

    getEWOdds: function ()
    {
        this.recalcEWOdds();
        return this.EWOdds;
    },

    recalcEWOdds: function (backSafe)
    {
        if (!this.IsEachWayEnabled)
            return;

        if (this.isSPSelection())
        {   //Selection is SP - no odds
            this.EWOdds = 0;
        }
        else if (this.hasEWOddsFromServer())
        {   //Have EW odds from server - use them
            this.EWOdds = this.EachWayAmericanOdds;
        }
        else
        {   //No EW odds from server - calculate them
            var fodd = this.Odds > 0 ? (this.Odds / 100 + 1) : (1 - 100 / this.Odds);
            var cPortionOdds = (fodd - 1) / this.OddsTermsID + 1;
            this.EWOdds = Odds.decimalToAmerican(BetMath.roundDecimalOdds(cPortionOdds, BetSlip.BaseOddsRoundingMode, !BetSlip.ForceTwoDigitsForOddsRounding));
        }

        if (backSafe)
        {
            var currentOdds = this.getFormatedOddsEW();
            this.EWOdds = -1;
            return currentOdds;
        }
    },

    recalcEWGain: function (deposit)
    {
        if (!this.IsEachWayEnabled)
            return;

        this.recalcEWOdds();
        this.EWDeposit = deposit;
        if (this.isSPSelection())
        {
            this.EWGain = 0;
        }
        else
        {
            this.EWGain = deposit * BetMath.roundDecimalOdds(Odds.convertFromAmericanToDecimal(this.EWOdds, true), BetSlip.BaseOddsRoundingMode, !BetSlip.ForceTwoDigitsForOddsRounding);
        }
    },

    hasEWOddsFromServer: function ()
    {
        return (typeof this.EachWayAmericanOdds != "undefined" && this.EachWayAmericanOdds != 0);
    },

    getUniPostRequest: function (deposit)
    {
        return [
            this.ViewKey,            // 0 SlotID
            this.EventID,            // 1 EventID
            this.EventTypeID,        // 2 EventTypeID
            this.LineID,             // 3 LineID
            this.LineTypeID,         // 4 LineTypeID // FIX YOUR BULLSHITS
            this.BetType,            // 5 BetTypeID
            this.LineGroupID,        // 6 LineGroupID
            this.BetSide,            // 7 BetSide
            this.Points,             // 8 Points
            this.Odds,               // 9 Odds
            deposit,                 // 10 deposit
            this.Score1,             // 11 Score1
            this.Score2,             // 12 Score2
            this.IsBuyPoints,        // 13 IsBuyPoints
            this.BuyPointsPoints,    // 14 BuyPointsPoints
            this.BuyPointsOdds,      // 15 BuyPointsOdds
            this.EachWayIncluded,    // 16 IsEWIncluded
            this.IsEachWayEnabled,   // 17 IsEachWayEnabled
            this.PlaceTermsID,       // 18 PlaceTermsID
            this.OddsTermsID,        // 19 OddsTermsID
            this.EachWayTermsID,     // 20 EachWayTermsID
            this.EWOdds,             // 21 EWOdds
            this.EWDeposit,          // 22 EWDeposit
            this.EWGain,             // 23 EWGain
            (currentOddStyle == OddStyle.FRACTIONAL ? FractionalOddsConverter.encodeFraction(this.getFormatedOdds()) : this.getFormatedOdds() * 1)
                + ":" + this.getActualOddsStyleID(), //24 client odds
            this.Authorization,      // 25 Authorization
            "",
            "",
            this.CastSelection,
            this.BetSubTypeID,
            this.VisibleCastSelection// 30 Placed as single bet
        ];
    },

    getPickedLine: function ()
    {
        var pickedLine = "";

        var team1PosName = this.Team1Name + getTeamNameExtension(this);
        var team2PosName = this.Team2Name + getTeamNameExtension(this);

        if ((this.SplitType == 6) || (this.SplitType == 24) || (this.EventTypeID == 93))
        {
            if (this.LineTypeID == 13)
            {
                pickedLine += team1PosName + ", ";
            }
            else
            {
                pickedLine += team2PosName + ", ";
            }
        }

        if (this.EventTypeID == 161)
        {
            if (this.LineTypeID == 1)
            {
                pickedLine += team1PosName;
            }
            else if (this.LineTypeID == 3)
            {
                pickedLine += team2PosName;
            }
            else
            {
                pickedLine += this.LineName;
            }
        }
        else if (isFastMarket(this.SplitType) || isTennisFastMarket(this.SplitType))
        {
            var fastMarket = SplitTypeFactory.Create(this.SplitType); //new FastMarket(this.SplitType, this.EventTypeName);
            var result = fastMarket.buildPickLine(this);
            pickedLine += result;
        }
        else
        {
            if (this.IsTeamSwapEnabled)
            {
                var swapManager = new QAEventTeamSwap(this.EventTypeID);
                swapManager.swapLineName(this);
            }
            pickedLine += (typeof isAsianView !== 'undefined' && isAsianView ? this.AsianLineName : this.LineName) + getTeamNameExtension(this);
        }

        return pickedLine;
    },

    getScore: function ()
    {
        if (!this.Live || this.MasterEventID == 0 || !this.showScore())
        {
            return "";
        }

        var score1 = !this.IsTeamSwapEnabled ? this.Score1 : this.Score2;
        var score2 = !this.IsTeamSwapEnabled ? this.Score2 : this.Score1;

        // If it is a tennis - format result
        if (this.BranchID == 6)
        {
            if (score1 == undefined || score2 == undefined)
            {
                score1 = 0;
                score2 = 0;
            }
            else
            {
                var score = new TennisScore(score1.toString(), score2.toString());
                score1 = !this.IsTeamSwapEnabled ? score.ScoreSetsPlayer1 : score.ScoreSetsPlayer2;
                score2 = !this.IsTeamSwapEnabled ? score.ScoreSetsPlayer2 : score.ScoreSetsPlayer1;
            }
        }

        return score1 + ":" + score2;
    },

    getTeamNames: function ()
    {
        var team1PosName = !this.IsTeamSwapEnabled ? this.Team1Name : this.Team2Name;
        var team2PosName = !this.IsTeamSwapEnabled ? this.Team2Name : this.Team1Name;

        team1PosName += getTeamNameExtension(this);
        team2PosName += getTeamNameExtension(this);

        var teamNames = {
            firstTeam: team1PosName,
            secondTeam: team2PosName
        }

        return teamNames;
    },

    getEventName: function (eventName)
    {
        if (typeof eBranches.SoccerAggregates !== "undefined" &&
            this.BranchID === eBranches.SoccerAggregates)
        {
            return this.EventName || eventName;
        }

        if (this.EventTypeID == 8)
        {
            var evName = eventName || this.EventName,
                leagueNameIncluded = evName.indexOf(this.LeagueName) !== -1;

            return this.LeagueName && !leagueNameIncluded
                ? "{0} - {1}".format(this.LeagueName, evName)
                : evName;
        }
        if (this.MasterEventID)
        {
            return this.getValidQAEventName(eventName);
        }
        if ((this.BranchID == constHorseRacingBranchID || this.BranchID == constGreyHoundRacingBranchID) && (this.LineTypeID !=18 && this.LineTypeID!=19))
        {
            var date = Date.fromISO(this.EventDate);
            date = (this.BranchID == constHorseRacingBranchID && UseAustralianHorseRacingView) ? Data.getLocalTime(date) : Data.getUkTime(date);
        	return this.LeagueName + ": " + date.toStringEx("HH:mm");
        }

        if (!eventName)
        {
            eventName = this.EventName;
        }

        if (eventName &&
            typeof eventName == "string" &&
            eventName.indexOf($string("shoppingCart").LineType) == -1)
        {
            if (this.UseEventName && eventName.indexOf(this.LeagueName) == -1)
            {
                return this.LeagueName + " - " + eventName;
            }
                
            return eventName;
        }

        return this.LeagueName;
    },

    getValidQAEventName: function(eventName)
    {
        var teamNames = this.getTeamNames();
        if (this.UseEventName && eventName)
        {
            return eventName;
        }
        else if (teamNames.firstTeam && teamNames.secondTeam)
        {
            return "{0} - {1}".format(teamNames.firstTeam, teamNames.secondTeam);
        }
    },

    getLineEventTypeName: function ()
    {
        var lineEventTypeName = "";

        var teamNames = this.getTeamNames();

        if (this.EventTypeID == 8)
        {
           // keep blank line
        }
        else if (isFastMarket(this.SplitType) || isTennisFastMarket(this.SplitType))
        {
            var fastMarket = SplitTypeFactory.Create(this.SplitType);
            var description = fastMarket.buildEventNameForBet(this.LineTypeName, this.EventTypeName, this);
            lineEventTypeName += description;
        }
        else if (this.EventTypeID == 396)
        {
            if (this.LineTypeID == 22)
            {
                lineEventTypeName += $dict.bs("Win");
            }
            else if (this.LineTypeID == 23)
            {
                lineEventTypeName += Bets.getPlaceString(this.PlaceTermsID);
            }
        }
        else if (this.EventTypeID == 89)
        {
            lineEventTypeName += this.LineTypeName;
        }
        else if (this.EventTypeID == 82)
        {
            lineEventTypeName += this.EventTypeName;
        }
        else if (Array.indexOf([214, 215, 216, 217, 218, 154, 158], this.EventTypeID) != -1)
        {
            lineEventTypeName += this.EventTypeName;
        }
        else if ((this.EventTypeID == RacingEventTypes.RaceCard) && (this.LineTypeID == 20))
        {
            lineEventTypeName += this.LineTypeName;
        }
        else if (this.EventTypeID == RacingEventTypes.RaceCard)
        {
            lineEventTypeName += this.EventTypeName;
        }
        else if (this.EventTypeID === RacingEventTypes.AUHRTote)
        {
            lineEventTypeName = "Win";
        }
        else if (this.EventTypeID === RacingEventTypes.AUHRTotePlaceOnly)
        {
            lineEventTypeName = "Place";
        }
        else if (this.EventTypeID == RacingEventTypes.BettingWithoutFav || this.EventTypeID == RacingEventTypes.BettingWithoutTwoFav ||
            this.EventTypeID == RacingEventTypes.PlaceOnly || this.EventTypeID == RacingEventTypes.MatchBetting)
        {
            lineEventTypeName += "{0} - {1}".format(this.EventTypeName, this.LineTypeName);
        }
        else if (this.EventTypeID == 760)
        {
            lineEventTypeName += $dict.bs("TrapMarket");
        }
        else if (this.LineTypeName != this.EventTypeName && this.LineTypeName.indexOf($string("shoppingCart").LineType) == -1)
        {
            lineEventTypeName += this.EventTypeName;
        }
        else if (Array.indexOf([251, 253, 255, 260, 263, 265, 268], this.EventTypeID) != -1)
        {
            var strQAEventTypeName = this.EventTypeName.replace('Team A', teamNames.firstTeam);
            lineEventTypeName += strQAEventTypeName;
        }
        else if (Array.indexOf([252, 254, 256, 261, 264, 266, 269], this.EventTypeID) != -1)
        {
            var strQAEventTypeName = this.EventTypeName.replace('Team B', teamNames.secondTeam);
            lineEventTypeName += strQAEventTypeName;
        }
        else if (!this.MasterEventID)
        {
            if (this.EventTypeName && this.LineTypeName.indexOf($string("shoppingCart").LineType) != -1)
            {
                lineEventTypeName += this.EventTypeName;
            }
            else
            {
                lineEventTypeName += this.EventTypeName + " - " + this.LineTypeName;
            }
        }
        else
        {
            lineEventTypeName += this.EventTypeName;
        }

        return lineEventTypeName;
    },

    getMaxBet: function ()
    {
        var eachWayIncluded = IsUKBetSlip ? this.EachWaySingleIncluded : this.EachWayIncluded;
        if (eachWayIncluded && this.EachWayMaxStake > 0)
        {
            return this.EachWayMaxStake;
        }
        return this.maxBet;
    },

    isEachWayInComboEnabled: function()
    {
        if (typeof this.EachWayComboBetIsEnabled !== "undefined")
            return this.EachWayComboBetIsEnabled;

        return this.IsEachWayEnabled;
    }

}, TQASelection);


function addQAOdd(masterEventID, eventID, lineID, Odds, eventTypeId, isPreloaded, leagueId, betId, lineTypeID, qaParameter1, qaParameter2)
{
    var selObj = new QASlipSelection(masterEventID, eventID, lineID, Odds, eventTypeId, leagueId, lineTypeID);
	selObj.BetID = betId;
	qaParameter1 && (selObj.QAParameter1 = qaParameter1);
	qaParameter2 && (selObj.QAParameter2 = qaParameter2);
    BetSlip.add(selObj, isPreloaded);
    return selObj;
}

window.QASlipSelection = QASlipSelection;
window.addQAOdd = addQAOdd;
