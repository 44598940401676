function SystemSPSlipMode()
{
    ComboSPSlipMode.call(this);

    this.Order = 2;
    this.ID = "system";
    this.Name = $dict.bs("SystemBetTab");
    this.TabClass = "system betting_slip_nbs";

    this.MinSelections = 3;
    this.MaxSelections = BetSlip.maxSelectionsNumber;
    this.MaxParallelSystems = 64;
    this.NamedSystemLimit = 8;
    this.ActivationRank = 5;
    this.SystemKey = 0;
    this.SystemDeposit = 0;
    this.PurchaseTypeID = SPPurchaseType.System;
    this.EachWayIncluded = false;
    this.NamedSystemVariants = [];
    this.NamedSystemOdds = [];
    this.NamedSystemEWOdds = [];
    this.SystemNames = [];
    this.HasPreviewMode = true;
    this.OriginalStake = 0;
    this.NamedSystemCalculatedOdds = [];
    this.NamedSystemCalculatedOddsEW = [];
}

$.extend(SystemSPSlipMode.prototype, ComboSPSlipMode.prototype,
{
    activate: function ()
    {
        this.Active = true;
        this.HasBeenActivated = true;
    },

    deactivate: function ()
    {
        this.Active = false;

        // Clear the current error if the current tab is disabled and navigated away from.
        // This is done so that if the tab is enabled again and navigated to then the same error would not be present there again
        if (!this.canPlaceBet())
            this.CurrentError = false;
    },

    canActivate: function ()
    {
        if (BetSlip.CheckForCastSelectionIncluded())
            return 0;

        var ComboGroups = this.getSelectionGroups(false);
        var grpCount = Array.getLength(ComboGroups);

        if (grpCount >= this.MinSelections)
        {
            return this.ActivationRank;
        }
        else if (grpCount < this.MinSelections && this.HasBeenActivated)
        {
            // This check stops the user from being automatically navigated away from the system tab if they uncheck some selections
            // so that less than 3 selections on the system remain marked. This can happen because the selections are updated on some period of time
            // after which BetSlip.autoSelectMode() (which calls this method) is called to determine the current tab.
            // Here we check whether the total number of checked and unchecked is at least 3.
            // ------------------------------------------------------------------------------------------------------------------------
            // In NBS we always want to include as many selections as possible, including those that cannot form combos with each other.
            // We need to check available combo groups, not selections 

            var groupsInBetSlip = Array.getLength(this.getSelectionGroups(true));
            return groupsInBetSlip >= this.MinSelections ? this.ActivationRank : 0;
        }
        else
        {
            return 0;
        }
    },

    selectionRemoved: function (item)
    {
        var groupKey = item.getComboGroupKey();
        if (this.SelectionsInCombo[groupKey])
        {
            this.SelectionsInCombo[groupKey].remove(item.ViewKey);
            if (Array.getLength(this.SelectionsInCombo[groupKey]) == 0)
                delete this.SelectionsInCombo[groupKey];
        }

        this.removeSelectionCountError();

        // If the user removes the last selection from the slip in the slip act as if the tab has never been opened, i.e. as if the slip was cleared
        if (Array.getLength(BetSlip.Selections) == 0)
        {
            this.HasBeenActivated = false;
        }

        if (!this.Active) return;

        this.updateView();
        BetSlip.updateBSFooter();
    },

    isComboSystemOrTeaserBet: function ()
    {
        return true;
    },

    clear: function ()
    {
        this.SystemDeposit = 0;
        this.OriginalStake = 0;
        this.SystemKey = 0;
        this.EachWayIncluded = false;
        this.StakeUpdatedByUser = false;

        ComboSPSlipMode.prototype.clear.call(this);
    },

    getSelectionGroups: function (includeAll)
    {
        var selections = includeAll ? Array.getValues(BetSlip.Selections) : this.getSelectionsInCombo();

        // populate selection groups
        var selectionGroups = [];
        for (var i in selections)
        {
            var selection = selections[i];
            // Limit the virtual sports selection to not activate system tab
            if (!includeAll && selection.IsBanker)
            {
                continue;
            }
            var groupKey = selection.getComboGroupKey();
            if (typeof selectionGroups[groupKey] == 'undefined')
            {
                selectionGroups[groupKey] = [];
            }
            selectionGroups[groupKey].push(selection);
        }

        return selectionGroups;
    },

    getNumberOfBets: function (systemKey)
    {
        if (!systemKey)
        {
            if (this.SystemDeposit == 0)
                return 0;
            systemKey = this.SystemKey;
        }

        var ComboGroups = this.getSelectionGroups();
        var groupCount = Array.getLength(ComboGroups);
        var bets = 0;
        var hasSingles = this.isSystemWithSingleBets(systemKey);

        if (systemKey < groupCount)
        {   //One variant (doubles, trebles, 4folds etc)
            bets = this.Variants[systemKey];
        }
        else
        {   //Named system - all variants, possibly with singles      
            bets = this.getNamedSystemSize(ComboGroups, systemKey);
        }

        if (this.EachWayIncluded)
            bets *= 2;

        return bets;
    },

    getNamedSystemSize: function (comboGroups, systemKey)
    {
        var nbrGroups = Array.getLength(comboGroups);
        var size = 0;
        var weights = [];
        for (var i = 0; i < nbrGroups; i++)
        {
            weights.push(1);
        }

        var defVar = BetMath.sumOfProducts(weights);

        for (var i = 2; i <= nbrGroups; i++)
        {
            size += defVar[i];
        }

        if (systemKey > nbrGroups)
            size += nbrGroups;

        var nbrSystems = this.getNumberOfParallelSystems();

        size *= nbrSystems;

        return size;
    },

    getNumberOfParallelSystems: function ()
    {
        var nbrSystems = 1;
        var comboGroups = this.getSelectionGroups();

        for (var idx in comboGroups)
        {
            nbrSystems *= Array.getLength(comboGroups[idx]);
        }

        return nbrSystems;
    },

    isSystemWithSingleBets: function (systemKey)
    {
        if (!systemKey)
            systemKey = this.SystemKey;

        var ComboGroups = this.getSelectionGroups();
        return (systemKey > Array.getLength(ComboGroups));
    },

    getTotalDeposit: function ()
    {
        return this.SystemDeposit * this.getNumberOfBets();
    },

    getTotalGain: function ()
    {
        BetSlip.lastUpdate = new Date();

        var ComboGroups = this.getSelectionGroups();
        var grpCount = Array.getLength(ComboGroups);
        var systemDeposit = UserInfo.TaxProvider.applyTaxToDeposit(this.SystemDeposit);

        var totalGain = 0;
        var hasSingles = this.isSystemWithSingleBets();

        if (this.SystemKey < grpCount)
        {   //One variant
            totalGain = this.calcComboGain(parseInt(this.SystemKey), systemDeposit);
        }
        else
        {   //All variants, possibly with singles
            totalGain = this.calcNamedSystemGain(parseInt(this.SystemKey), systemDeposit, false, grpCount);
        }

        var roundingMode = typeof BetSlip != "undefined" && BetSlip.GainRoundingMode || 0;
        var roundedGain = GainUtil.round(totalGain, roundingMode, 6);

        return roundedGain;
    },
    calculateLoyaltyPoints: function ()
    {
        return LoyaltyProgramCalculations.calculationsForSystem();
    },

    getTotalGainWithBonuses: function ()
    {
        BetSlip.lastUpdate = new Date();

        var ComboGroups = this.getSelectionGroups();
        var grpCount = Array.getLength(ComboGroups);
        var systemDeposit = UserInfo.TaxProvider.applyTaxToDeposit(this.SystemDeposit);

        var totalGainWithBonuses = 0;
        var hasSingles = this.isSystemWithSingleBets();

        if (this.SystemKey < grpCount)
        {   //One variant
            if (ComboBonusProvider.ComboBonuses && ComboBonusProvider.ComboBonuses[this.SystemKey] && !this.FreeBets[this.SystemKey] && !this.SelectedEWVariants[this.SystemKey])
            {
                totalGainWithBonuses = this.calcGain(ComboBonusProvider.ComboBonuses[this.SystemKey].ComboBonusOdds, systemDeposit);
            }
            else
            {
                totalGainWithBonuses = this.calcComboGain(parseInt(this.SystemKey), systemDeposit);
            }
        }
        else
        {   //All variants, possibly with singles      
            totalGainWithBonuses = this.calcNamedSystemGain(parseInt(this.SystemKey), systemDeposit, false, grpCount);
        }

        var roundingMode = typeof BetSlip != "undefined" && BetSlip.GainRoundingMode || 0;
        var roundedGain = GainUtil.round(totalGainWithBonuses, roundingMode);

        return roundedGain;
    },

    getGainPerBetType: function ()
    {
        var betGain = Object.create(null);
        betGain[0] = this.getTotalGainWithBonuses();

        return betGain;
    },

    getMaxGainCoef: function ()
    {
        var ComboGroups = this.getSelectionGroups();
        var grpCount = Array.getLength(ComboGroups);

        var totalOdds = 0;
        if (this.SystemKey < grpCount)
        {   //One variant
            totalOdds = this.calcGain(this.Odds[this.SystemKey], 1);
        }
        else
        {   //All variants, possibly with singles      
            totalOdds = this.calcGain(this.NamedSystemOdds[this.SystemKey], 1);
        }

        var numberOfBets = this.EachWayIncluded ? this.getNumberOfBets() / 2 : this.getNumberOfBets();
        return totalOdds - numberOfBets;
    },

    updateView: function ()
    {
        if (!this.Active) return;

        var __html = [];

        var containerElement = document.getElementById("bet-slip-container");
        if (containerElement)
        {
            this.buildInnerView(__html);
            containerElement.innerHTML = __html.join("");
        }

        // update options
        this.updateViewOptions();
        this.deselectStakeDropdown();

    },

    updateViewOptions: function ()
    {
        var optionsHtml = "",
            optionsElement = document.getElementById("system_options") || document.getElementById("system-options"),
            activeElementID = (document.activeElement && document.activeElement.tagName && document.activeElement.tagName.toLowerCase() === 'input') ? document.activeElement.id : "",
            activeElementValue = (document.activeElement && document.activeElement.tagName && document.activeElement.tagName.toLowerCase() === 'input') ? document.activeElement.value : "";
        var shouldFocusElement = document.activeElement && typeof (document.activeElement.getBoundingClientRect) == 'function' ? document.activeElement.getBoundingClientRect() : null;

        if (optionsElement && typeof this.buildOptions == "function")
        {
            optionsHtml = this.buildOptions(Array.getValues(BetSlip.Selections));
            if (optionsElement) {
                optionsElement.outerHTML = optionsHtml;
            }
        }

        this.setFocusOnStakeBox(activeElementID, activeElementValue, shouldFocusElement);
    },

    updateSelection: function (selection)
    {
        var selectionElement = document.getElementById("sce_" + selection.ViewKey);
        if (selectionElement)
        {
            selectionElement.innerHTML =  this.buildSelectionInnerView(selection);
        }        
    },

    updateSelectionData: function (selection)
    {
        var oddsElement = document.getElementById("cOddsToWhow_" + selection.ViewKey),
        pointsElement = document.getElementById("cPoints_" + selection.ViewKey);

        this.setLastSelectionUpdateProps(selection);
        this.setElementsBlinking(selection.ViewKey);

        if (oddsElement) oddsElement.innerHTML = this.formatSelectionOddsForSlip(selection);
        
        if (selection.BetTypeID != 1 && selection.SplitType != 4 && pointsElement)
        {
            var pts = selection.IsBuyPoint ? selection.BuyPointsPoints : selection.Points;
            
            disableAsianStyleForBetSlip = (disableAsianStyleForBetSlip && isAsianView);
            pointsElement.innerHTML = selection.BetTypeID == 2 ? Odds.formatPoints(pts, undefined, undefined, undefined, disableAsianStyleForBetSlip) : Odds.formatPoints(pts, true, undefined, undefined, disableAsianStyleForBetSlip);
        }
    },

    serialize: function ()
    {
        var serialObj = ComboSPSlipMode.prototype.serialize.call(this);

        serialObj.SystemKey = this.SystemKey;
        serialObj.SystemDeposit = this.SystemDeposit;
        serialObj.OriginalStake = this.OriginalStake;
        serialObj.EachWayIncluded = this.EachWayIncluded;

        return serialObj;
    },

    deserialize: function (data)
    {
        this.SystemKey = data.SystemKey;
        this.SystemDeposit = data.SystemDeposit;
        this.OriginalStake = data.OriginalStake;
        this.EachWayIncluded = data.EachWayIncluded;

        ComboSPSlipMode.prototype.deserialize.call(this, data);
    },

    calcVariants: function ()
    {
        this.BankersCount = this.getBankersCount(BetSlip.CurrentMode.getSelectionGroups(true));
        ComboSPSlipMode.prototype.calcVariants.call(this);

        var ComboGroups = this.getSelectionGroups();
        var grpCount = Array.getLength(ComboGroups);

        this.NamedSystemOdds = [];
        this.NamedSystemEWOdds = [];

        this.NamedSystemOdds[grpCount] = 0;
        this.NamedSystemOdds[grpCount + 1] = 0;
        this.NamedSystemEWOdds[grpCount] = 0;
        this.NamedSystemEWOdds[grpCount + 1] = 0;

        this.NamedSystemVariants = this.getUniqueSelectionCombinations(Array.getValues(ComboGroups));
        if (BetSlip.CalcEachCombinationIndividualy) {
            this.calculateNamedSystemsIndividualy(grpCount)
        }
        else {
            this.calculateNamedSystems(grpCount);
        }

        this.fillSystemNames(grpCount, this.BankersCount);
    },
    
    addBankersOdds: function (selectionsGroups)
    {
        var bankerGroups = this.getBankersGroups(selectionsGroups, false, true);
        var bankersCombinations = this.getUniqueSelectionCombinations(Array.getValues(bankerGroups));
        var initialOdds = this.Odds.slice();
        var isInitialUpdate = true;
        for (var cIdx = 0; cIdx < Array.getLength(bankersCombinations); cIdx++)
        {
            var combination = bankersCombinations[cIdx];
            var updatedOdds = this.getUpdatedOdds(initialOdds, combination);
            if (!updatedOdds)
            {
                continue;   
            }
            this.updateOddsWithBankersOdds(updatedOdds, isInitialUpdate);
            isInitialUpdate = false;
        }
    },

    getUpdatedOdds: function (initialOdds, combination)
    {
        var updatedOdds = initialOdds.slice();        
        var bankerOdds = this.getBankerOdds(combination);
        if (bankerOdds > 1)
        {
            for (var oIdx = 1; oIdx < Array.getLength(updatedOdds) ; oIdx++)
            {
                updatedOdds[oIdx] *= bankerOdds;
                updatedOdds[oIdx] = this.floorDecimal(updatedOdds[oIdx]);
            }
            return updatedOdds;
        }
        return null;
    },

    updateOddsWithBankersOdds: function (updatedOdds, isInitialUpdate)
    {
        if (isInitialUpdate)
        {
            this.Odds = updatedOdds;
        }
        else
        {
            for (var odsIdx = 1; odsIdx < Array.getLength(updatedOdds); odsIdx++)
            {
                this.Odds[odsIdx] += updatedOdds[odsIdx];
            }
        }
    },

    getBankersGroups: function (selectionGroups, combinedOnly, maxOddsOnly) // In case of multyway bet we use max odds from master event
    {
        var bankersGroups = [];
        for (var key in selectionGroups)
        {
            if (selectionGroups.hasOwnProperty(key))
            {
                var group = selectionGroups[key];
                if (maxOddsOnly)
                {
                    group = [group.reduce(function (x, y) { return x.Odds > y.Odds ? x : y; })];
                }
                if (combinedOnly && Array.getLength(group) > 1 || group.some(function (s) { return s.IsBanker; }))
                {
                    bankersGroups[key] = group;
                }
            }
        }
        return bankersGroups;
    },

    getBankerOdds: function (selections)
    {
        var odds = 1;
        for (var sIdx in selections)
        {
            if (selections.hasOwnProperty(sIdx))
            {
                var selection = selections[sIdx];
                if (selection.IsBanker)
                {
                    odds *= selection.getRealOdd(BetSlip.BaseOddsRoundingMode);
                }
            }
        }
        return odds;
    },

    addBankersVariants: function ()
    {
        var bankerGroups = this.getBankersGroups(this.getSelectionGroups(true), false);
        var groupLenth = 1;
        for (var groupKey in bankerGroups)
        {
            if (bankerGroups.hasOwnProperty(groupKey))
            {
                groupLenth *= Array.getLength(bankerGroups[groupKey]);
            }
        }
        var variants = this.Variants;
        for (var vIdx in variants)
        {
            if (variants.hasOwnProperty(vIdx))
            {
                variants[vIdx] *= groupLenth;
            }
        }
    },

    initBetNames: function()
    {
        this.SystemNames = [];
    },

    getSystemBetNames: function ()
    {
        return this.SystemNames;
    },

    fillSystemNames: function (nbrGroups, bankersCount)
    {
        if (!nbrGroups) return;

        this.SystemNames = [];

        if (bankersCount > 0)
        {
            this.fillSystemNamesForBankers(nbrGroups, bankersCount);
        }
        else
        {
            this.fillSystemNamesForNotBankers(nbrGroups);
        }        
    },

    fillSystemNamesForBankers: function (nbrGroups, bankersCount)
    {
        for (var key = 1; key <= nbrGroups; key++)
        {
            this.SystemNames[key] = bankersCount.toFixed() + "B+" + key + "/" + nbrGroups;
        }
    },

    fillSystemNamesForNotBankers: function(nbrGroups) 
    {
        for (var key = 2; key <= nbrGroups + 1; key++)
        {
            if (key < nbrGroups)
            {                   
                this.SystemNames[key] = key + "/" + nbrGroups;
            }
            else
            {
                var dictKey = "SystemNames_" + nbrGroups + "_" + key;
                var systemName = $dict.bs(dictKey);
                if (systemName == dictKey)
                {//This system name is not in dictionary
                    systemName == "";
                }
                this.SystemNames[key] = systemName;
            }
        }
    },

    calcSystemVariantOdds: function (lines, isEachWay)
    {
        if (Array.getLength(lines) == 0)
            return 0;

        var oarray = [];

        for (var lidx in lines)
        {
            var line = lines[lidx];
            var odds = line.getRealOdd(BetSlip.BaseOddsRoundingMode);
            if (!odds && line.RealOrPresumptiveOdds)
            {
                odds = BetMath.roundDecimalOdds(line.RealOrPresumptiveOdds, BetSlip.BaseOddsRoundingMode, !BetSlip.ForceTwoDigitsForOddsRounding);
            }

            if (isEachWay && line.IsEachWayEnabled && line.getRealOddEW) 
            {
                odds = line.getRealOddEW(BetSlip.BaseOddsRoundingMode);
                if (!odds && line.RealOrPresumptiveOdds)
                {
                    odds = BetMath.roundDecimalOdds(line.RealOrPresumptiveOdds, BetSlip.BaseOddsRoundingMode, !BetSlip.ForceTwoDigitsForOddsRounding);
                }
            }
                
            oarray.push(odds);
        }

        var systemOdds = BetMath.sumOfProducts(oarray);

        this.applyComboOddsRounding(systemOdds);

        return systemOdds;
    },

    getUniqueSelectionCombinations: function (selectionGroups)
    {
        var result = [];
        var innerIndexes = [];
        var maxIdx = Array.getLength(selectionGroups) - 1;

        for (var i = 0; i <= maxIdx; i++)
        {
            innerIndexes[i] = 0;
        }

        while (innerIndexes[maxIdx] < Array.getLength(selectionGroups[maxIdx]))
        {
            var combination = [];

            for (var i = 0; i <= maxIdx; i++)
            {
                var idx = innerIndexes[i];
                combination.push(selectionGroups[i][idx]);
            }

            result.push(combination);

            innerIndexes[0]++;

            for (var i = 0; i < maxIdx; i++)
            {
                if (innerIndexes[i] == Array.getLength(selectionGroups[i]))
                {
                    innerIndexes[i] = 0;
                    innerIndexes[i + 1]++;
                }
            }
        }

        return result;
    },

    removeSelectionCountError: function ()
    {
        // Remove message "You need to make at least 3 selections applicable for system to place a bet!" if now there are three
        var ComboGroups = this.getSelectionGroups();
        var grpCount = Array.getLength(ComboGroups);
        if (this.CurrentError == $dict.bs("SelectionsInSystem") && grpCount >= 3)
        {
            this.CurrentError = false;
        }
    },

    getOddsSum: function ()
    {
        var lines = this.getSelections();
        var result = 0;
        for (var lIdx in lines) {
            var line = lines[lIdx];
            var EUodds = this.CheckForEachWayIncluded() && ComboSPSlipMode.isEachWayEnabled(line) && line.getRealOddEW
                ? line.getRealOddEW()
                : line.getRealOdd();

            result += EUodds - 1;
        }

        return result;
    },

    getComboMaxBet: function (lines)
    {
        if (!lines)
        {
            lines = this.getSelections();
        }
        
        var maxBetComboFromRisk = this.getComboMaxBetFromMaxRisk(lines);

        var maxBetSystem;
        var singlesMaxBet; // used in case there are single in the currently selected system option

        var totalOddsSum = this.getOddsSum();
        var ewIncluded = this.CheckForEachWayIncluded();
        var getRealOdds = function (line) {
            return ewIncluded && ComboSPSlipMode.isEachWayEnabled(line) && line.getRealOddEW ? line.getRealOddEW() : line.getRealOdd();
        };

        var maxSystemBetOts = 0;

        for (var key in lines)
        {
            var line = lines[key];
            var currMaxBetCombo = ewIncluded ? line.MaxStakeComboEachWay : line.MaxBetSystem;
            if (!maxBetSystem)
            {
                maxBetSystem = currMaxBetCombo;
            }
            else if (line.MaxBetCombo < maxBetSystem)
            {
                maxBetSystem = currMaxBetCombo;
            }

            if (maxSystemBetOts == 0 || line.MaxBetCombo < maxSystemBetOts)
            {
                maxSystemBetOts = line.MaxBetCombo;
            }

            if (!singlesMaxBet || line.maxBet * 1 < singlesMaxBet) 
            {
                singlesMaxBet = line.maxBet * 1;
            }
            
            if (totalOddsSum > 0 && line.SingleSelectionRisk > 0 && line.MaxRisk > 0 && getRealOdds(line) > 0)
            {
                var maxRiskComboFromSSR = line.SingleSelectionRisk * line.MaxRisk / ((getRealOdds(line) - 1) / totalOddsSum);

                var divider = this.getMaxGainCoef();
                var maxBetFromSSR = divider > 0 ? Math.floor(maxRiskComboFromSSR / divider) : 0;
                if ((maxBetFromSSR > 0 && maxBetFromSSR < maxBetSystem) || maxBetSystem == 0)
                {
                    maxBetSystem = maxBetFromSSR;
                }
            }
        }

        //  If there are single bets in the currently selected system option
        if (this.isSystemWithSingleBets())
        {
            maxBetSystem = (maxBetSystem <= singlesMaxBet ? maxBetSystem : singlesMaxBet);
        }

        if ((maxBetComboFromRisk > 0 && maxBetComboFromRisk < maxBetSystem) || !maxBetSystem)
        {
            maxBetSystem = maxBetComboFromRisk;
        }

        if (!maxBetSystem) {
            // fallback ots
            maxBetSystem = maxSystemBetOts;
        }

        if (maxBetSystem > 0 && BetSlip.StakeRounding == BetSlip.StakeRoundingMethods.ZeroDecimalPoints)
        {
            maxBetSystem = Math.floor(maxBetSystem);
        } else
        {
            maxBetSystem = Math.floor(maxBetSystem * 100) / 100;
        }

        return maxBetSystem;
    },

    getComboMaxBetFromMaxRisk: function (linesSafe)
    {
	    var maxRiskCombo = this.CheckForEachWayIncluded()
            ? Array.getMinValue(linesSafe, function (line) { return line.MaxRiskComboEachWay > 0; }, function (line) { return line.MaxRiskComboEachWay; })
            : Array.getMinValue(linesSafe, function (line) { return line.MaxRiskSystem > 0; }, function (line) { return line.MaxRiskSystem; });

	    var gainDivider = this.getMaxGainCoef();
        var result = maxRiskCombo && gainDivider > 0
            ? (maxRiskCombo / gainDivider)/* * (1 - ewGainPercentage) */
            : 0;

        return Math.floor(result * 10) / 10;
    },

    hasVariantsDeposit: function ()
    {
        return (this.SystemDeposit != null && this.SystemDeposit > 0)
    },

    check: function ()
    {
        var Lines = this.getSelections();

        if (Array.getLength(Lines) < this.MinSelections)
        {
            this.setError($dict.bs("SelectionsInSystem"));
            return false;
        }

        if (Array.getLength(Lines) > this.MaxSelections)
        {
            return false;
        }

        // get min bet
        var mx = this.getComboMinBet(Lines);

        if (typeof this.SystemDeposit != "undefined" && this.SystemDeposit > 0)
        {
            if (this.SystemDeposit > 0 && this.SystemDeposit < mx) // check min bet
            {
                this.setError($dict.bs("MinimalBet").concat(" "+ MoneyFormat.format(mx)));
                return false;
            }
        }

        var fd = this.getTotalDeposit();

        if (fd <= 0)
        {
            this.setError($dict.bs("InvalidBet"));
            return false;
        }

        if (!BetSlip.SkipClientUserBalanceCheckForCombos && fd > UserInfo.current.getBalance())
        {
            if (typeof (QuickDepositBlock) != "undefined")
            {
                QuickDepositBlock.showDepositPopupIfNeed();
            }
            this.setError($dict.bs("NotEnoughFunds"));
            return false;
        }

        return true;
    },

    getComboPostRequest: function (selection)
    {
        //for live lines we also need danger and score
        var isLive = selection.Live ? selection.Live : false;
        var isDanger = selection.Danger ? selection.Danger : false;
        var score1 = selection.Score1 ? selection.Score1 : 0;
        var score2 = selection.Score2 ? selection.Score2 : 0;
        var betSide = selection.BetSide ? selection.BetSide : 0;

        var oddStyleIDFromClient = BetSlip.getAlternativeOddsStyle() || currentOddStyle;
        var oddsFromClient = (oddStyleIDFromClient == OddStyle.FRACTIONAL ? FractionalOddsConverter.encodeFraction(selection.getFormatedOdds()) : selection.getFormatedOdds() * 1);
        var qaParameter2 = selection.QAParameter2 == -1 ? 0 : selection.QAParameter2;
        var isEWIncluded = selection.BetType == 7 && selection.EachWayIncluded ? true : false;
        var ReleatedToID = -1;
        if (isEWIncluded)
        {
            var ReleatedToID = selection.ReleatedToID;
        }

        return [selection.LineGroupID,
                selection.LineID,
                selection.Odds,
                selection.Points,
                selection.ComboRate,
                selection.MasterEventID,
                selection.LeagueID,
                selection.MaxBetCombo,
                selection.EventID,
                selection.BetType == 7 ? selection.BetType : selection.BetTypeID,
                isLive,
                isDanger,
                score1,
                score2,
                betSide,
                false,
                undefined,
                undefined,
                oddsFromClient,
                undefined,
                undefined,
                qaParameter2].join(",");
    },

    placeBets: function ()
    {
		if (BaseSPSlipMode.prototype.isGeolocationInProgress.call(this))
		{
			return;
		}

        if (this.IsBetInProcess) return;

        this.setBetInProcess(true, true);

        if (!this.check())
        {
            this.setBetInProcess(false, true);
            return;
        }

        var totals = BetSlip.getFooterTotals();
        if (UserInfo.TaxProvider.isUserUnderTax() && BetSlip.validatePurchaseWithCountryTaxes(totals.Taxes, this.getTotalDeposit()))
        {
            this.setBetInProcess(false, true);
            this.setError($dict.bs("CountryTaxInvalidOdds"));
            return;
        }

        var lines = [];
        comboBonuses = {};

        var selectionsInSystem = this.getSelections();

        for (var key in selectionsInSystem)
        {
            var selection = selectionsInSystem[key];
            if (!selection.Valid || selection.Danger)
            {
                this.setError($dict.bs("SystemEventDanger"));
                this.setBetInProcess(false, true);
                return;
            }

            lines.push(selection);
        }

        if (ComboBonusProvider.ComboBonuses != null)
        {
            comboBonuses = ComboBonusProvider.ComboBonuses;
        }

        var ref = this;
        BetSlip.disablePlaceBetButton();

        if (Modes.showComboNotAllowedMessage(this))
        {
            var selections = BetSlip.Selections.slice();
            var purchaseDeposit = {}, purchaseKey = {};
            purchaseDeposit[this.SystemKey] = this.SystemDeposit * 1;
            purchaseKey[this.SystemKey] = true;

            var purchasesInformation = [];
            purchasesInformation.push(this.formatPurchaseRequest(lines, null, null, null, null, purchaseDeposit, purchaseKey, null, null, comboBonuses));

            BettingPageMethods.placeMultiPurchase(purchasesInformation,
                function (res)
                {
                    var purchases = eval(res);
                    ref.placePurchaseCallBack(purchases, selections);
                },
                this.placePurchaseCallBackError,
                this
            );
        } else
        {
            BettingPageMethods.placeSinglePurchase(this.formatPurchaseRequest(lines, null, null, null, null, this.SystemDeposit, this.SystemKey, null, null, comboBonuses),
                function (res)
                {
                    var purchases = [eval(res)];
                    ref.placePurchaseCallBack(purchases);
                },
                this.placePurchaseCallBackError,
                this
            );
        }

        // Suppress an unexpected JS error on updateView() so that it cannot stop the "bet in process" state
        try { this.updateView(); } catch (e) { if (console && console.log) console.log(e); }
    },

    parsePlaceBets: function (result, keepPreviousPurchase)
    {
        this.clearErrors();

        var bets = [];
        var selections = [];
        
        ComboBonusProvider.addRecalculatedComboBonuses(result.ComboBonuses);

        switch (result.Status)
        {
            case 1: { this.StakesUpdatedByUserForDropDown[0] = false; }
            case 3: //Waiting
                {
                    this.StakeUpdatedByUser = false;
                    var selectionsInCombo = this.getSelections();
                    var altOddStyle = this.getAlternativeOddsStyle();

                    SPPurchaseBuilder.createSystemSlipPurchase(result, selectionsInCombo, keepPreviousPurchase, false, altOddStyle, this.SystemKey);

                    this.Deposit = 0;
                    this.VariationsDeposits = [];
                    this.Variants = [];
                    this.SystemDeposit = 0;
                    this.OriginalStake = 0;
                    this.SystemKey = 0;

                    UserInfo.updateBalance();

                    break;
                }
            case 2: //Rejected
            case -10: // generic error
                {
                    this.setPurchaseError(result);
                    break;
                }
            case -1: //Selections Error
                {
                    for (var sIdx in result.Selections)
                    {
                        var line = result.Selections[sIdx];
                        var selection = Array.find(BetSlip.Selections, function (item) { return item.ViewKey == line.ViewKey; });
                        if (!selection) continue;

                        if (!line.IsEachWaySelection) selection.processBetUpdate(line);

                        if (line.Closed == 1)
                        {
                            line.ErrorText = $dict.bs("LineClosed");
                            this.CurrentError = $dict.bs("SystemEventDanger");
                        }
                        if (selection.UpdatedOdds) line.ErrorText = $dict.bs("OddsChanged");
                        if (selection.UpdatedPoints) line.ErrorText = $dict.bs("PointsChanged");
                        if (selection.UpdatedOdds && selection.UpdatedPoints) line.ErrorText = $dict.bs("AllChanged");

                        //selection.Error = line.ErrorText;
                        selection.Error = this.translateBetSlipError(line, selection, false);

                        this.updateSelection(selection);
                        this.updateSelectionData(selection);
                    }

                    break;
                }
            case -2: //Bets Error
                {
                    var bet = result.Bets[0];
                    this.CurrentError = this.translateBetSlipError(bet, null, true);

                    break;
                }
            case -4: //Betting limit error
                {
                    var errorText = $string('General').BettingLimitHit;
                    errorText = errorText.replace('$VALUE$', UserInfo.current.toUserFormatedCurrencyString(result.RemainingBettingLimit));
                    this.setError(errorText);
                    BetSlip.sendBetLimitsErrorToJSApi(result);
                    break;
                }
            case -18:
                {
                    var errorText = this.getVerificationErrorMessage(result.ErrorCode);
                    this.setError(errorText);
                    break;
                }
        }
    },

    getOdds: function ()
    {
        var out = { americanOdd: 0 };
        var fodd = this.getRate(undefined, out);
        return Odds.convert(out.americanOdd, undefined, undefined, true, this.getAlternativeOddsStyle());
    },

    clearDeposits: function ()
    {
        var basket = this.getSelections();
        var ll = Array.getLength(basket);
        for (var i in this.VariationsDeposits)
        {
            if (i >= ll) delete this.VariationsDeposits[i];
        }
        if (ll < 2)
            this.Deposit = 0;
        this.SystemDeposit = 0;
        this.OriginalStake = 0;
    },

    setDefaultSystemStake: function (options)
    {
        var isUpdatedByUser = !!this.StakesUpdatedByUserForDropDown[0];
        if (!(typeof UKSPSlipMode != "undefined" && BetSlip.CurrentMode instanceof UKSPSlipMode) && !isUpdatedByUser) {   
            options.deposit = BetSlip.getUserCurrency().DefaultStakeForSystems || BetSlip.getCurrencyWithStakeValues().DefaultStakeForSystems || 0;
            this.SystemDeposit = parseInt(options.deposit);
        }
        else {
            options.deposit = options.deposit || 0;
        }

        return options.deposit;
    },

    rebuildSystemStake: function (fromStakeBox)
    {        
        if (this.IsBetInProcess) return;

        var element = document.getElementById("stakebox_system"), st = this.SystemDeposit;
        if (fromStakeBox && element)
        {
            st = BetSlip.SanitizeNumberString(element.value);
            if (isNaN(st)) st = 0;
            st = BetSlip.RoundToStakeRounding(st, element);
            this.SystemDeposit = st;
        }

        var sysKeyElement = document.querySelector("input[name='systemType']:checked");
        var key = sysKeyElement ? sysKeyElement.value : "";
        

        this.StakesUpdatedByUserForDropDown[0] = true;
        if (!key)
            return;

        this.SystemKey = key;
        var extraWinningRow = document.getElementById("extraWinningsRow");
        var bonusWrapper = document.getElementById("comboBonusIconWrapper");
        var bonusMessageWrapper = document.getElementById("comboAdditionalMsgWrapper");

        if (BetSlipUtil.showComboBonusInfo(this, key))
        {
            //In mobile Responsive template there are few combo bonus wrappers next to each radio button
            if (bonusWrapper != null)
            {
                var hasLiveSelections = Array.find(Lines, function (ln) { return ln.Live == true }) != null;
                var hasEachWay = BetSlip.CheckForEachWayEnabled(key);
                var hasMaxBetLabel = this.shouldShowMaxbetButtonForMultiSelections(hasLiveSelections);
                var hasBreakLine = (hasMaxBetLabel || hasEachWay);
                bonusWrapper.innerHTML = TComboBonusPresenter.buildView.buildMultiOptions(key, hasBreakLine, 'default');
            }

            bonusMessageWrapper.innerHTML = TComboBonusPresenter.buildComboBonusMessage(key);
            
            BetSlipUtil.showNAForRelatedSelections()
                ? extraWinningRow.classList.add("isHidden", "hidden")
                : extraWinningRow.classList.remove("isHidden", "hidden");
        }
        else
        {
            if (bonusWrapper != null)
            {
                bonusWrapper.innerHTML = "";
                bonusMessageWrapper.innerHTML = "";
            }
            
            extraWinningRow.classList.add("isHidden", "hidden");
        }   

        BetSlip.updateBSFooter();
        BetSlip.saveState();
    },

    getEachWayTotalGain: function ()
    {
        BetSlip.lastUpdate = new Date();

        var ComboGroups = this.getSelectionGroups();
        var grpCount = Array.getLength(ComboGroups);
        var ewGain = 0;
        var systemDeposit = this.SystemDeposit;
        var hasSingles = this.isSystemWithSingleBets();

        systemDeposit = UserInfo.TaxProvider.applyTaxToDeposit(systemDeposit);

        if (this.SystemKey < grpCount)
        {   //One variant
            ewGain = this.calcComboGain(parseInt(this.SystemKey), systemDeposit, true);
        }
        else
        {   //All variants, possibly with singles           
            ewGain = this.calcNamedSystemGain(parseInt(this.SystemKey), systemDeposit, true, grpCount);
        }

        var roundingMode = typeof BetSlip != "undefined" && BetSlip.GainRoundingMode || 0;
        var roundedGain = GainUtil.round(ewGain, roundingMode);

        return roundedGain;
    },  

    //createInitialBets: function ()
    //{
    //    betGroups = [];
    //    var selections = this.getSelections();
    //
    //    var line_keys = Array.getKeys(selections);
    //
    //    var ew_line_keys = null;
    //    var ewids = null;
    //
    //    if (Array.getLength(this.EWSelections) > 0) ew_line_keys = Array.getKeys(this.EWSelections);
    //
    //    var index = 0;
    //    //! important
    //    if (this.Deposit)
    //    {
    //        this.VariationsDeposits[Array.getLength(selections)] = this.Deposit * 1;
    //    }
    //
    //    for (var vkey in this.VariationsDeposits)
    //    {
    //        if (this.VariationsDeposits[vkey] == null || this.VariationsDeposits[vkey] == 0)
    //        {
    //            continue;
    //        }
    //
    //        var ids = generateSubGroups(line_keys, vkey);
    //        var deposit = this.VariationsDeposits[vkey];
    //        var bets = [];
    //
    //        for (var kk in ids)
    //        {
    //            var variant = [];
    //            var variant_ids = ids[kk];
    //            for (var i in variant_ids) variant.push(selections[variant_ids[i]]);
    //
    //            var sbet = this.createBet(variant, deposit, vkey);
    //            betGroups[index] = sbet;
    //            index = index + 1;
    //        }
    //
    //        if (ew_line_keys) ewids = generateSubGroups(ew_line_keys, vkey);
    //        if (ewids)
    //        {
    //            for (var kk in ewids)
    //            {
    //                var variant = [];
    //                var variant_ids = ewids[kk];
    //                for (var i in variant_ids) variant.push(this.EWSelections[variant_ids[i]]);
    //
    //                var ewBet = this.createBet(variant, deposit, vkey);
    //                ewBet.IsEWBet = true;
    //                betGroups[index] = ewBet;
    //                index = index + 1;
    //            }
    //        }
    //    }
    //    return betGroups;
    //},

    CheckForEachWayIncluded: function (key)
    {
        return this.EachWayIncluded;
    },

    CheckForEachWayEnabled: function (key)
    {
        if (typeof (BetSlipRegulations) !== 'undefined' && BetSlipRegulations.HideEachWay)
        {
            return false;
        }

        var selections = this.getSelectionsInCombo();
        for (var idx in selections)
        {
            var sel = selections[idx];
            if (ComboSPSlipMode.isEachWayEnabled(sel))
                return true;
        }

        return false;
    },

    updateEachWay: function (cbox)
    {
        this.EachWayIncluded = cbox.checked;

        this.toggleEachWayIncluded(this.EachWayIncluded);

        BetSlip.updateView();
        BetSlip.updateBSFooter();
    },

    GetEachWayData: function ()
    {
        if (!this.EachWayIncluded) return null;

        var res = new Object();
        var oddsArr = [];
        var selVarArr = [];
        for (var key in this.EWOdds)
        {
            oddsArr[key] = this.EWOdds[key];
            selVarArr[key] = true;
        }

        for (var key in this.NamedSystemEWOdds)
        {
            oddsArr[key] = this.NamedSystemEWOdds[key];
            selVarArr[key] = true;
        }

        res["Odds"] = oddsArr;
        res["SelectedVariants"] = selVarArr;

        return res;
    },

    isEnabledForItem: function (item)
    {
        return !!item.SystemBetIsEnabled;
    },

    calculateNamedSystems: function(grpCount)
    {
        for (var vidx in this.NamedSystemVariants)
        {
            var variant = this.NamedSystemVariants[vidx];
            var odds = this.calcSystemVariantOdds(variant);

            var totalOdds = 0;

            for (var i = 2; i < Array.getLength(odds) ; i++)
            {
                totalOdds += BetMath.roundDecimalOdds(odds[i], BetSlip.ComboOddsRoundingMode, !BetSlip.ForceTwoDigitsForOddsRounding);                
            }

            this.NamedSystemOdds[grpCount] += totalOdds;
            this.NamedSystemOdds[grpCount + 1] += totalOdds + BetMath.roundDecimalOdds(odds[1], BetSlip.ComboOddsRoundingMode, !BetSlip.ForceTwoDigitsForOddsRounding);            

            if (this.CheckForEachWayIncluded())
            {
                var ewOdds = this.calcSystemVariantOdds(variant, true);

                var totalEWOdds = 0;

                for (var i = 2; i < Array.getLength(ewOdds) ; i++)
                {
                    totalEWOdds += BetMath.roundDecimalOdds(ewOdds[i], BetSlip.ComboOddsRoundingMode, !BetSlip.ForceTwoDigitsForOddsRounding);                     
                }

                this.NamedSystemEWOdds[grpCount] = totalEWOdds;
                this.NamedSystemEWOdds[grpCount + 1] = totalEWOdds + BetMath.roundDecimalOdds(ewOdds[1], BetSlip.ComboOddsRoundingMode, !BetSlip.ForceTwoDigitsForOddsRounding);
            }
        }
    },
    
    getSingleTotalReturn: function(stake) {
        stake = stake == undefined ? 1 : stake;
        var selections = this.getSelectionsInCombo();
        allSingleGain = 0,
            allSingleGainEW = 0,
            roundingMode = typeof BetSlip != "undefined" && BetSlip.GainRoundingMode || 0,
            keys = Object.keys(selections);
        for (i = 0; i < keys.length; i++) {
            var value = sbMathOperations.Multiplication(selections[keys[i]].getRealOdd(BetSlip.BaseOddsRoundingMode), stake, 6);
                allSingleGain = sbMathOperations.Аccumulation(allSingleGain,
                    GainUtil.round(value, roundingMode, 6), 6);

            if (selections[keys[i]].getRealOddEW) {
                allSingleGainEW = sbMathOperations.Аccumulation(allSingleGainEW,
                     GainUtil.round(selections[keys[i]].getRealOddEW(BetSlip.BaseOddsRoundingMode) * stake, roundingMode, 6), 6);
            } else {
                allSingleGainEW = sbMathOperations.Аccumulation(allSingleGainEW, value, 6);
            }
        }

        return { eachway: allSingleGainEW, allSingle: allSingleGain }
    },

    calculateNamedSystemsIndividualy: function (grpCount) {
        var totalOdds = 0;
        var totalEWOdds = 0;
        var keys = Object.keys(this.calculatedOdds)
        for (var i = 0; i < keys.length; i++) {
            for (var j = 2; j < grpCount; j++) {
                totalOdds = sbMathOperations.Аccumulation(totalOdds, this.calculatedOdds[keys[i]].odds[j], 6);
            }
            if (this.CheckForEachWayIncluded()) {
                totalEWOdds = sbMathOperations.Аccumulation(totalEWOdds, this.calculatedOddsEW[keys[i]].odds[i], 6);
            }
        }
        if (!this.NamedSystemCalculatedOdds[grpCount] || totalOdds != this.NamedSystemCalculatedOdds[grpCount].odds) {
            this.NamedSystemCalculatedOdds = [];
            this.NamedSystemCalculatedOddsEW = [];
            this.NamedSystemCalculatedOdds[grpCount] = {};
            this.NamedSystemCalculatedOddsEW[grpCount] = {};
            this.NamedSystemCalculatedOdds[grpCount + 1] = {};
            this.NamedSystemCalculatedOddsEW[grpCount + 1] = {};
            this.NamedSystemCalculatedOdds[grpCount].odds = totalOdds;
            this.NamedSystemCalculatedOddsEW[grpCount].odds = totalEWOdds;
            var singleReturn = this.getSingleTotalReturn();
            this.NamedSystemCalculatedOdds[grpCount + 1].odds = sbMathOperations.Аccumulation(totalOdds, singleReturn.allSingle, 6);
            this.NamedSystemCalculatedOddsEW[grpCount + 1].odds = sbMathOperations.Аccumulation(totalEWOdds, singleReturn.eachway, 6);
        }
    },

    calcNamedSystemGain: function (key, deposit, isEachway, groupsCount) {
        deposit = deposit || 0;
        if(BetSlip.CalcEachCombinationIndividualy && typeof sbCombinationCalculations != 'undefined')
        {
            var namedSystemProp = isEachway ? "NamedSystemCalculatedOddsEW" : "NamedSystemCalculatedOdds";
            if(this[namedSystemProp] && this[namedSystemProp][key] && this[namedSystemProp][key].stake == deposit)
            {
                return this[namedSystemProp][key].gain;
            }

            var totalGain = 0;
            var prop = isEachway ? "calculatedOddsEW" : "calculatedOdds";
            for (var i = 2; i <= groupsCount; i++) {
                var calculatedSystemKeys = Object.keys(this[prop]);
                totalGain = sbMathOperations.Аccumulation(totalGain, this.calcComboGain(i, deposit, isEachway), 6); 
                
            }
            var totalSingleGain = isEachway ? this.getSingleTotalReturn(deposit).eachway : this.getSingleTotalReturn(deposit).allSingle;

          	this[namedSystemProp][groupsCount] = this[namedSystemProp][groupsCount] || {};
            this[namedSystemProp][groupsCount + 1] =  this[namedSystemProp][groupsCount + 1] || {};
            this[namedSystemProp][groupsCount].gain = totalGain;
            this[namedSystemProp][groupsCount].stake = deposit;
            this[namedSystemProp][groupsCount + 1].gain = sbMathOperations.Аccumulation(totalGain, totalSingleGain, 6);
            this[namedSystemProp][groupsCount + 1].stake = deposit;
            return this[namedSystemProp][key].gain;
        }
       
        return this.calcGain(isEachway ? this.NamedSystemEWOdds[this.SystemKey] : this.NamedSystemOdds[key], deposit);
    }
}, TSystemSPSLipMode);

SystemSPSlipMode.SetComboMaxBet = function ()
{
    if (BetSlip.CurrentMode instanceof SystemSPSlipMode)
    {
        if (BetSlip.CurrentMode.IsBetInProcess) return;

        var comboMaxBet = BetSlip.CurrentMode.getComboMaxBet();
        var element = document.getElementById("stakebox_system");

        if (element) element.value = BetSlip.getStakeBoxValue(comboMaxBet);
        BetSlip.CurrentMode.rebuildSystemStake(true);
    }
};

//function generateSubGroups(in_group, groupSize)
//{
//    var subGroups = [];
//
//    if (groupSize == 1)
//    {
//        for (var groupElemKey in in_group)
//        {
//            subGroups.push([in_group[groupElemKey]]);
//        }
//    }
//    else
//    {
//        for (var i = 0; i < in_group.length; i++)
//        {
//            var tmpGroups = generateSubGroups(in_group.slice(i + 1), groupSize - 1);
//
//            for (var j in tmpGroups)
//            {
//                var tmpGroup = tmpGroups[j];
//                tmpGroup.push(in_group[i]);
//                subGroups.push(tmpGroup);
//            }
//        }
//    }
//    return subGroups;
//}

function rebuildSystemStake(fromStakeBox)
{
    if (BetSlip.CurrentMode instanceof SystemSPSlipMode)
    {
        BetSlip.CurrentMode.rebuildSystemStake(fromStakeBox);
    }
}
function setSystemStakeUpdatedByUser()
{
    var defaultStake = BetSlip.getCurrencyWithStakeValues().DefaultStakeForSystems;
    BetSlip.CurrentMode.StakeUpdatedByUser = BetSlip.CurrentMode.SystemDeposit != defaultStake;
    BetSlip.CurrentMode.deselectStakeDropdown();
}
function rebuildSystemStakeDropDown(fromStakeBox)
{
   
    var dropdown = document.getElementById("stakedropdown");

    var element = document.getElementById("stakebox_system");
    element.value = dropdown.options[dropdown.selectedIndex].value;
    BetSlip.CurrentMode.rebuildSystemStake(fromStakeBox);
    if (BetSlip.isDefaultStakeAndStakeValuesAvailable())
    {
        setSystemStakeUpdatedByUser();
    }
}
function rebuildSystemStakeOnChange(fromStakeBox)
{
    var mode = BetSlip.CurrentMode;
    var element = document.getElementById("stakebox_system");
    if (BetSlip.isDefaultStakeAndStakeValuesAvailable())
    {
        setSystemStakeUpdatedByUser();
    }
    var st = BetSlip.SanitizeNumberString(element.value);
    BetSlip.RoundToStakeRounding(st, element);
    mode.rebuildSystemStake(fromStakeBox);
}

window.SystemSPSlipMode = SystemSPSlipMode;
window.rebuildSystemStake = rebuildSystemStake;
window.setSystemStakeUpdatedByUser = setSystemStakeUpdatedByUser;
window.rebuildSystemStakeDropDown = rebuildSystemStakeDropDown;
window.rebuildSystemStakeOnChange = rebuildSystemStakeOnChange;