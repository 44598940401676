var SelectionHelper = (function ()
{
    var api = {};

    api.Constants = {
        Draw: $string("General").Draw,
        Tie: $string("General").Tie,
        NoGoals: $string("General").NoGoals,
        Over: $string("General").Over,
        Under: $string("General").Under
    };
    /* regular */

    function getPickedPoints(pointsToReduce)
    {
        var points = this.Points;

        if (this.IsBuyPoint && (typeof BetSlip != "undefined") && (BetSlip.CurrentMode.ID == "single" || BetSlip.CurrentMode.ID == "combo" || BetSlip.CurrentMode.ID == "system"))
        {
            points = this.BuyPointsPoints;
        }

        if (pointsToReduce)
        {
            points += (this.BetTypeID == 3 && this.BetSide == 1) ? -pointsToReduce : pointsToReduce;
        }

        var hasPoints = this.BetTypeID != 1 && this.SplitType != 4;
        if (!hasPoints)
        {
            return;
        }
        
        if(this.BetTypeID == 1 && (this.EventTypeID == 206 || this.EventTypeID == 207)) // ML
        {
            return points > 0 ? "+" + points  : points;
        }

        if (this.BetTypeID == 2 && this.SplitType != 8 && this.SplitType != 9 && this.EventTypeID != 195)  // HC
        {
            return Odds.formatPoints(points);
        }

        if (this.BetTypeID == 3) // OU
        {
            return Odds.formatPoints(points, this.BetSide == 1, this.BetSide == 3);
        }
    }

    function getPickLineRegular(showNameExtension, pointsToReduce, excludePoints, isYourBet)
    {
        var pickedTeam = this.getTeamNameToInclude(!showNameExtension) + " " + this.getPickedTeam(false, showNameExtension, isYourBet);
        
        var pickedPoints = "";
        if (!excludePoints)
        {
            pickedPoints = this.getPickedPoints(pointsToReduce);
        }

        return pickedTeam + (pickedPoints ? " : " + pickedPoints : "");
    }

    function shouldIncludeTeamNameInPick()
    {
        if (this.BetTypeID != 3 || this.SplitType != 6 || this.TeamMappingID == 0)
        {
            return false;
        }

        var eventTypeIDs =
              [
                7, 18, 257, 299, 301, 302, 338, 339, 340, 381, 382, 383, 384, 385, 386, 387, 389, 416, 430, 443, 
                463, 478, 479, 480, 481, 550, 551, 552, 553, 554, 555, 556, 639, 640, 641, 642, 677, 678, 679, 680, 
                681, 682, 683, 737, 759, 771, 774, 778, 780, 791, 794, 797, 799, 809, 820, 821, 822, 825, 826, 827, 
                828, 835, 845, 852, 853, 854, 899, 900, 901, 902, 907, 908, 909, 910, 911, 912, 913, 914, 951, 952, 
                953, 954, 959, 960, 961, 962, 981, 1011, 1024, 1026, 1032, 1033, 1035, 1037, 1058, 1060, 1065, 1165, 1245, 
                1246, 1247, 1248, 1255, 1266, 1268, 1291, 1292, 1293, 1358, 1445, 1446, 1508, 1655, 1656, 1698, 1788, 1850, 
                1905, 1906, 1907, 1929, 1934, 1948, 1981, 2066, 2357, 2375, 2379, 2599, 2600, 2898, 2899, 2913, 2914, 2918, 
                2943, 3569, 3570
              ];

        if (eventTypeIDs.indexOf(this.EventTypeID) != -1)
        {
            return true;
        }

        return false;
    }

    function getTeamNameToInclude(doNotShowNameExtension)
    {
        if (!this.shouldIncludeTeamNameInPick())
            return "";

        var team1Name = this.Team1Name + (doNotShowNameExtension ? "" : getTeamNameExtension(this));
        var team2Name = this.Team2Name + (doNotShowNameExtension ? "" : getTeamNameExtension(this));

        return this.TeamMappingID == 1 ? team1Name : team2Name;
    }

    function getPickedTeam(showAsNumber, showNameExtension, isYourBet)
    {
        var team1Name = (!this.IsTeamSwapEnabled ? this.Team1Name : this.Team2Name) + (showNameExtension ? getTeamNameExtension(this) : "");
        var team2Name = (!this.IsTeamSwapEnabled ? this.Team2Name : this.Team1Name) + (showNameExtension ? getTeamNameExtension(this) : "");
        var drawStr = $string("General").Draw;

        var betSide = this.BetSide;
        if (this.IsTeamSwapEnabled && this.BetTypeID != 3 && (betSide == 1 || betSide == 3)) {
            betSide = betSide == 1 ? 3 : 1;
        }

        if (this.BetTypeID == 1) // ML
        {
            if (showAsNumber) {
                team1Name = this.IsTeamSwapEnabled ? "2" : "1";
                team2Name = this.IsTeamSwapEnabled ? "1" : "2";
                drawStr = "X";
            }

            if (this.EventTypeID == 206 || this.EventTypeID == 207) {
                return ([, team1Name, $string("General").Tie + " " + team1Name, team2Name])[betSide];
            }

            if (this.EventTypeID == 19 || this.EventTypeID == 20) {
                return ([, team1Name, $string("General").NoGoals, team2Name])[betSide];
            }

            return ([, team1Name, drawStr, team2Name])[betSide];
        }

        if (this.BetTypeID == 2)  // HC
        {
            return ([, team1Name, , team2Name])[betSide];
        }

        // OU
        return (isYourBet? [, "Over", , "Under"] : [, $string("General").Over, , $string("General").Under])[betSide];
    }

    /* qa */

    function getPickLineQA(showNameExtension, pointsToReduce)
    {
        var team1PosName = this.Team1Name + (showNameExtension ? " " + getTeamNameExtension(this) : "");
        var team2PosName = this.Team2Name + (showNameExtension ? " " + getTeamNameExtension(this) : "");

        var result = "";

        /*((this.EventTypeID==154 || this.EventTypeID==155 || this.EventTypeID==93) ? ((this.LineTypeID==13)? this.Team1Name:this.Team2Name) + ", ":"")*/
        if ((this.SplitType == 6) || (this.SplitType == 24))
        {
            if (this.LineTypeID == 13)
            {
                result += (team1PosName + ", ");
            }
            else
            {
                result += (team2PosName + ", ");
            }
        }

        if (this.EventTypeID == 161)
        {
            if (this.LineTypeID == 1)
            {
                result += team1PosName;
            }
            else if (this.LineTypeID == 3)
            {
                result += team2PosName;
            }
            else
            {
                result += this.LineName;
            }
        }
        else if (this.EventTypeID == 711)
        {
            if (this.LineTypeID == 13)
            {
                result += (team1PosName + "- ");
            }
            else if (this.LineTypeID == 15)
            {
                result += (team2PosName + "- ");
            }

            result += this.LineName + (showNameExtension ? " " + getTeamNameExtension(this) : "");
        }
        else if (this.IsTeamSwapEnabled && (this.EventTypeID == 60 || this.EventTypeID == 310))
        {
            var currentLineName = this.LineName.split(":").reverse().join(":");
            result += currentLineName;
        }
        else if (isFastMarket(this.SplitType))
        {
            var fastMarket = new FastMarket(this.SplitType, this.EventTypeName);
            var description = fastMarket.getPickLine(this);
            result += description;
        }
        else
        {
            if (this.BranchID == constHorseRacingBranchID)
            {
                result += this.EventName + " - ";
            }

            result += this.LineName + (showNameExtension ? " " + getTeamNameExtension(this) : "");

        }

        return result;
    }

    /* PulseBet */

    function getPulseBetPickLine()
    {
        var pbPhrase = $dict.ob("YourPulseBet"),
            startPeriod = this.QAParameter1,
            endPeriod = startPeriod + this.QAParameter2,
            startMinutes = Math.floor(startPeriod / 60),
            startSeconds = startPeriod - startMinutes * 60,
            startTime = formatPeriod(startMinutes, startSeconds),
            endMinutes = Math.floor(endPeriod / 60),
            endSeconds = endPeriod - endMinutes * 60,
            endTime = formatPeriod(endMinutes, endSeconds);

        return pbPhrase.format(startTime, endTime);
    }

    function formatPeriod(minutes, seconds)
    {
        var formattedMinutes = minutes,
            formattedSeconds = seconds;

        if (minutes < 10)
        {
            formattedMinutes = "0" + minutes;
        }
        if (seconds < 10)
        {
            formattedSeconds = "0" + seconds;
        }

        return formattedMinutes + ":" + formattedSeconds;
    }

    api.create = function(selection)
    {
        var helper =
            {
                EventTypeID: selection.EventTypeID,
                SplitType: selection.SplitType,
                LineTypeID: selection.LineTypeID,
                BranchID: selection.BranchID,
                BetTypeID: selection.BetTypeID,
                Team1Name: selection.Team1Name,
                Team2Name: selection.Team2Name,
                EventTypeName: selection.EventTypeName,
                EventName: selection.EventName,
                IsTeamSwapEnabled: selection.IsTeamSwapEnabled,
                LineTypeName: selection.LinetypeName,

                getPickLine: selection.IsRegular ? getPickLineRegular : getPickLineQA,
                getPulseBetPickLine: getPulseBetPickLine
            };

        helper.Consts = ((typeof selection.Consts) == "undefined" || selection.Consts == null) ? api.Constants : selection.Consts;

        if (selection.IsRegular)
        {
            helper.TeamMappingID = selection.TeamMappingID;
            helper.Points = selection.Points;
            helper.IsBuyPoint = selection.IsBuyPoint;
            helper.BuyPointsPoints = selection.BuyPointsPoints;
            helper.BetSide = selection.BetSide;
            helper.shouldIncludeTeamNameInPick = shouldIncludeTeamNameInPick;
            helper.getPickedPoints = getPickedPoints;
            helper.getTeamNameToInclude = getTeamNameToInclude;
            helper.getPickedTeam = getPickedTeam;
        }
        else
        {
            helper.QAParameter1 = selection.QAParameter1;
            helper.QAParameter2 = selection.QAParameter2;
            helper.LineName = selection.LineName;
        }

        return helper;
    }

    return api;
}());

window.SelectionHelper = SelectionHelper;