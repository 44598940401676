﻿function RegularSlipSelection(masterEventID, eventID, lineGroupID, lineID, Odds, Points, eventTypeId, favTeam, leagueId, lineTypeID)
{
    BaseSlipSelection.call(this, masterEventID, eventID, lineID, eventTypeId, favTeam, leagueId, lineTypeID);

    this.IsRegular = true;
    this.LineGroupID = lineGroupID;
    this.Points = Points;
    this.InitializeOdds(Odds);
    this.BetType = 1;

    this.BuyPoints = null;
    this.IsBuyPoint = false;
    this.BuyPointsOdds;
    this.BuyPointsPoints;
    this.BuyPointSelectedIndex = null;

    this.Consts = SelectionHelper.Constants;
}

$.extend(RegularSlipSelection.prototype, BaseSlipSelection.prototype,
{
    getUpdateRequest: function ()
    {
        var buyPoints = "";
        if (this.BuyPoints)
        {
            buyPoints = this.BuyPoints.join(";");
        }
        return [
            !!this.IsMultiLine,
            this.ViewKey,
            this.EventID,
            this.LineGroupID,
            this.LineID,
            this.Points,
            this.Odds,
            buyPoints,
            this.IsBuyPoint,
            this.BuyPointsOdds,
            this.BuyPointsPoints,
            BetSlip.isAsianMode & BetSlip.EnableAsianStyleMinMax
        ];
    },

    getPickLine: function (showNameExtension, pointsToReduce, isYourBet)
    {
        var sel = $.extend({}, this)
        sel.IsRegular = true;

        var helper = SelectionHelper.create(sel);
        return helper.getPickLine(showNameExtension, pointsToReduce, undefined, isYourBet);
    },

    getPostRequest: function (deposit)
    {
        var res = [
          this.ViewKey,     //0
          this.EventID,     //1
          this.LineGroupID, //2
          this.LineID,      //3
          this.Points,      //4
          this.Odds,        //5
          deposit,          //6
          this.IsBuyPoint,  //7
          this.BuyPointsOdds,//8
          this.BuyPointsPoints,//9
          //10 - actual adds, the odds that we are actually showing to the client : actual odd style id
          (currentOddStyle == OddStyle.FRACTIONAL ? FractionalOddsConverter.encodeFraction(this.getFormatedOdds()) : this.getFormatedOdds() * 1)
              + ":" + this.getActualOddsStyleID()
        ];

        return res;
    },

    getRealOdd: function (roundingMode)
    {
        var current_odds;
        if (this.IsBuyPoint)
        {
            current_odds = this.BuyPointsOdds;
        }
        else
        {
            current_odds = this.Odds;
        }
        var dd = Math.floor(((current_odds > 0 ? (current_odds / 100 + 1) : (1 - 100 / current_odds)) * 1000).toPrecision(12)) / 1000 * this.ComboRate;
        if (dd < 1.001) dd = 1.001;

        if (roundingMode)
        {
            dd = BetMath.roundDecimalOdds(dd, roundingMode, !BetSlip.ForceTwoDigitsForOddsRounding);
        }

        return dd;
    },

    // Odds are usually retrieved using the global currentOddsStyle variable. If an explicit odds-style is specified
    // or the current mode of the betslip is specifying an alternative odds-style, use them instead.
    getFormatedOdds: function (oddsStyle)
    {
        var alternativeOddsStyle = oddsStyle || BetSlip.getAlternativeOddsStyle();
        if (this.IsBuyPoint && BetSlip.CurrentMode.ID != "teaser")
        {
            var formattedOdds = Odds.convertFormat(this.BuyPointsOdds, this.EventTypeID, this.BetTypeID, undefined, alternativeOddsStyle, this.BetType, this.BranchID);
        }
        else
        {
            var formattedOdds = Odds.convertFormat(this.Odds, this.EventTypeID, this.BetTypeID, undefined, alternativeOddsStyle, this.BetType, this.BranchID);
        }

        if (BetSlip.BaseOddsRoundingMode && !isNaN(formattedOdds * 1))
        {
            formattedOdds = Odds.format(BetMath.roundDecimalOdds(formattedOdds * 1, BetSlip.BaseOddsRoundingMode, !BetSlip.ForceTwoDigitsForOddsRounding)) + "";
        }

        return formattedOdds;

    },

    // Returns the odd style that the selection is actually seen in by the user. This may not be the current odd style
    // as there are requirements for example when the user is in an Asian odd style that they should see the odds on
    // the combo tab in decimal. Also is a requirement that when in Asian odd style the user sees some events on the
    // single tab in Decimal rather than in the current Asian odd style (for instance Money line on Soccer events).
    // See Odds.convertFormat(), Odds.convert() and Odds.showDecimalInsteadOfAsian() in Odds.js
    getActualOddsStyleID: function ()
    {
        var alternativeOddsStyle = BetSlip.getAlternativeOddsStyle();
        var oddsStyleID = Odds.showDecimalInsteadOfAsian(this.BetType, this.EventTypeID, this.BetTypeID, this.BranchID) ? OddStyle.EUROPEAN : currentOddStyle;
        oddsStyleID = alternativeOddsStyle ? alternativeOddsStyle : oddsStyleID;
        return oddsStyleID;
    },  

    isSplit: function ()
    {
        if (this.BetTypeID == 1) return false;
        if (!this.Points) return false;
        var diff = Math.abs(this.Points - Math.floor(this.Points));

        return (diff == 0.25 || diff == 0.75);
    },

    isBuyPointsSelection: function ()
    {
        return !this.Live && this.BuyPoints && Array.getLength(this.BuyPoints) > 0;
    },

    processBetUpdate: function (updates)
    {
        var firstcall = !this.Initialized;
        BaseSlipSelection.prototype.processBetUpdate.call(this, updates);

        if (!this.Valid || updates.length == 0)
            return;

        if (firstcall)
        {
            this.LineTypeName = updates.LineTypeName;
            this.BetTypeID = updates.BetTypeID;  // BetTypeID is actually LineTypeID
            this.LineTypeID = updates.LineTypeID;
            this.BetSide = updates.BetSide;
            this.TeamMappingID = updates.TeamMappingID;

            //***********Separating dependency between client and event types***********
            if (specialsID[this.EventTypeID])
                this.SplitType = specialsID[this.EventTypeID];
            else
                this.SplitType = updates.SplitTypeID;
            //***********END***********
        }

        if (typeof updates.EventName != "undefined") this.EventName = updates.EventName;

        if (updates.comboRate)
        {
            this.ComboRate = updates.comboRate;
        }

        this.processHighlightUpdates(updates, firstcall);
        this.processLineClosed();
        this.processDanger(updates);
        
        this.BuyPoints = updates.BuyPoints;

        if (this.IsBuyPoint)
        {
            var exist = false;
            for (var key in updates.BuyPoints)
            {
                if (this.BuyPointsPoints == updates.BuyPoints[key].Points)
                {
                    this.Updated = this.UpdatedOdds = this.CheckIfOddsUpdated(updates.BuyPoints[key].Odds, undefined, firstcall, true);
                    this.BuyPointsOdds = updates.BuyPoints[key].Odds;
                    exist = true;
                }
            }
            if (!exist)
            {
                this.BuyPointsOdds = null;
                this.BuyPointsPoints = null;
                this.IsBuyPoint = false;
            }
        }
        if (updates.IsSameEventInComboAllowed)
        {
            this.IsSameEventInComboAllowed = updates.IsSameEventInComboAllowed;
        }
    },

    processHighlightUpdates: function(updates, firstcall)
    {
        BaseSlipSelection.prototype.processHighlightUpdates.call(this, updates, firstcall);

        this.Updated = this.processPointsUpdate(updates, firstcall) || this.Updated;
    },

    processPointsUpdate: function (updates, firstcall)
    {
        this.UpdatedPoints = false;
        if (updates.Points != undefined)
        {
            if (this.CheckIfPointsUpdated(updates.Points))
            {
                this.PreviousPoints = this.Points;
                this.Points = updates.Points;
                // Prevent over/under points from blinking when the selection is just added to the betslip
                this.UpdatedPoints = !firstcall || this.IsRestored;

                if ((this.UpdatedPoints || this.LineClosed) && BetSlip.config.DisallowModifiedFastMarketBets)
                {
                    this.closeLine();
                }
            }
        }
        return this.UpdatedPoints;
    },

    CheckIfPointsUpdated: function(serverPoints)
    {
        return typeof serverPoints != "undefined" && serverPoints != this.Points;
    },

    clearUpdatedFlags: function ()
    {
        this.Updated = false;
        this.UpdatedOdds = false;
        this.UpdatedPoints = false;
    },

    serialize: function ()
    {
        return $.extend({}, BaseSlipSelection.prototype.serialize.call(this),
           {
               BetType: 1,
               Live: false,
               LineGroupID: this.LineGroupID,
               Points: this.Points,
               Odds: this.Odds,
               comboRate: this.ComboRate,
               LineTypeName: this.LineTypeName,
               EventName: this.EventName,
               BetTypeID: this.BetTypeID,
               LineTypeID: this.LineTypeID,
               BetSide: this.BetSide,
               TeamMappingID: this.TeamMappingID,
               SplitTypeID: this.SplitType,
               IsBuyPoint: this.IsBuyPoint,
               BuyPoints: this.BuyPoints,
               BuyPointsOdds: this.BuyPointsOdds,
               BuyPointsPoints: this.BuyPointsPoints,
               BuyPointSelectedIndex: this.BuyPointSelectedIndex,
               IsTeamSwapEnabled: this.IsTeamSwapEnabled
           });
    },

    getUniPostRequest: function (deposit)
    {
        return [
            this.ViewKey,            // 0 SlotID
            this.EventID,            // 1 EventID
            this.EventTypeID,        // 2 EventTypeID
            this.LineID,             // 3 LineID
            this.LineTypeID,         // 4 LineTypeID // FIX YOUR BULLSHITS
            this.BetType,            // 5 BetTypeID
            this.LineGroupID,        // 6 LineGroupID
            this.BetSide,            // 7 BetSide
            this.Points,             // 8 Points
            this.Odds,               // 9 Odds
            deposit,                 // 10 deposit
            this.Score1,             // 11 Score1
            this.Score2,             // 12 Score2
            this.IsBuyPoints,        // 13 IsBuyPoints
            this.BuyPointsPoints,    // 14 BuyPointsPoints
            this.BuyPointsOdds,      // 15 BuyPointsOdds
            this.EachWayIncluded,    // 16 IsEWIncluded
            this.IsEachWayEnabled,   // 17 IsEachWayEnabled
            this.PlaceTermsID,       // 18 PlaceTermsID
            this.OddsTermsID,        // 19 OddsTermsID
            this.EachWayTermsID,     // 20 EachWayTermsID
            this.EWOdds,             // 21 EWOdds
            this.EWDeposit,          // 22 EWDeposit
            this.EWGain,             // 23 EWGain
            (currentOddStyle == OddStyle.FRACTIONAL ? FractionalOddsConverter.encodeFraction(this.getFormatedOdds()) : this.getFormatedOdds() * 1)
                + ":" + this.getActualOddsStyleID(), //24 client odds
            this.Authorization,      // 25 Authorization
        ];
    },

    getScore: function()
    {
        if(!this.Live || !this.showScore())
        {
            return "";
        }
        
        var score1 = !this.IsTeamSwapEnabled ? this.Score1 : this.Score2;
        var score2 = !this.IsTeamSwapEnabled ? this.Score2 : this.Score1;

        // If it is a tennis - format result
        if (this.BranchID == 6)
        {
            if (score1 == undefined || score2 == undefined)
            {
                score1 = 0;
                score2 = 0;
            }
            else
            {
                var score = new TennisScore(score1.toString(), score2.toString());
                score1 = !this.IsTeamSwapEnabled ? score.ScoreSetsPlayer1 : score.ScoreSetsPlayer2;
                score2 = !this.IsTeamSwapEnabled ? score.ScoreSetsPlayer2 : score.ScoreSetsPlayer1;
            }
        }

        return score1 + ":" + score2;
    },

    getTeamNames: function()
    {
        var firstTeam = !this.IsTeamSwapEnabled ? this.Team1Name : this.Team2Name;
        var secondTeam = !this.IsTeamSwapEnabled ? this.Team2Name : this.Team1Name;     

        var teamNames = {
            firstTeam: firstTeam,
            secondTeam: secondTeam
        }

        return teamNames;
    },

    getPickedTeam: function (showAsNumber, showNameExtension)
    {
        var helper = SelectionHelper.create(this);
        return helper.getPickedTeam(showAsNumber, showNameExtension);
    },
    
    getTeamNameToInclude: function (doNotShowNameExtension)
    {
        return SelectionHelper.prototype.getTeamNameToInclude.call(this, doNotShowNameExtension);
    },

    getPointsToShow: function(pointsToReduce, isPurchase)
    {      
        var points = this.Points;
        var available_points = true;
        var hasPoints = this.BetTypeID != 1 && this.SplitType != 4;
        disableAsianStyleForBetSlip = (disableAsianStyleForBetSlip && isAsianView);   

        if (BetSlip.CurrentMode.ID == "single" || BetSlip.CurrentMode.ID == "combo" || (BetSlip.CurrentMode.ID == "ukslip" && !BetSlip.CurrentMode.isTeaserOn))
        {
            points = this.IsBuyPoint ? this.BuyPointsPoints : this.Points;
            available_points = !(Array.getLength(this.BuyPoints) > 0 && !isPurchase);
        }
        else if (BetSlip.CurrentMode.ID == "teaser" || BetSlip.CurrentMode.ID == "system" || (BetSlip.CurrentMode.ID == "ukslip" && BetSlip.CurrentMode.isTeaserOn))
        {
            points = this.Points;
            available_points = true;
        }
        
        isPurchase = !!isPurchase;
        if(isPurchase)
        {
            available_points = true;
        }

        if (pointsToReduce)
        {
            points = (this.BetTypeID == 3 && this.BetSide == 1) ? points - pointsToReduce : points + pointsToReduce;
        }

        if(this.BetTypeID == 1) // ML
        {
            if(this.EventTypeID == 206 || this.EventTypeID == 207 )
            {
                return points > 0 ? "+" + points  : points;
            }
        }
        else if(this.BetTypeID == 2)  // HC
        {
            if(hasPoints && available_points)
            {
                if (this.SplitType != 8 && this.SplitType != 9 && this.EventTypeID != 195)
                {
                    return Odds.formatPoints(points, undefined, undefined, undefined, disableAsianStyleForBetSlip);
                }
            }
        }
        else
        {
            if(hasPoints && available_points)
            {
                return Odds.formatPoints(points, this.BetSide==1, this.BetSide==3, undefined, disableAsianStyleForBetSlip);
            }
        }

        return null;
    },

    getLineEventTypeName: function()
    {
        var selectionBuilderClass = SplitTypeFactory.Create(this.SplitType);
        var LineEventTypeName = selectionBuilderClass.getLineEventTypeName(this);

        if (this.Live)
        {
            // when Live Betting string is missing from the lineEventName, then add it in the current language, get it from CMS unislip dictionary
            var liveBettingNotTranslated = "Live Betting";
            var liveBettingTranslated = $dict.bs("LiveBetting") && $dict.bs("LiveBetting").length > 0 ? $dict.bs("LiveBetting") : liveBettingNotTranslated;

            if (LineEventTypeName.indexOf(liveBettingNotTranslated) < 0 && LineEventTypeName.indexOf(liveBettingTranslated) < 0)
            {
                LineEventTypeName = LineEventTypeName + ' ' + liveBettingTranslated;
            }
        }

        return LineEventTypeName;
    }
}, TRegularSelection);

function addOdd(masterEventID, eventID, lineGroupID, lineID, Odds, Points, eventTypeId, isFavTeam, isPreloaded, leagueId, betId, lineTypeID, rowtypeId)
{
    var selObj = new RegularSlipSelection(masterEventID, eventID, lineGroupID, lineID, Odds, Points, eventTypeId, isFavTeam, leagueId, lineTypeID);
    selObj.BetID = betId;
    selObj.BetTypeID = lineTypeID;
    selObj.BetSide = rowtypeId;
    BetSlip.add(selObj, isPreloaded);
    return selObj;
}

function addOdd3(masterEventID, eventID, LineGroupID, lineTypeID, rowtypeid, Odds, Points, lineId, eventTypeId, favTeam, isPreloaded, leagueId, betId) {
    var selObj = new RegularSlipSelection(masterEventID, eventID, LineGroupID, lineId, Odds, Points, eventTypeId, favTeam, leagueId, lineTypeID);
    selObj.BetTypeID = lineTypeID;
    selObj.BetSide = rowtypeid;
    selObj.BetID = betId;
    BetSlip.add(selObj, isPreloaded);
}

function addOdd2(masterEventID, eventID, line, oddsIndex, eventTypeId)
{
    if (line == null) return false;

    var lineGroupID = line.ID;
    var lineID = line.ML.ML1_ID;

    var odds = null;
    switch (oddsIndex)
    {
        case "1":
            odds = (line.ML == null) ? null : line.ML.ML1_Odds;
            break;

        case "2":
            odds = (line.ML == null) ? null : line.ML.ML2_Odds;
            break;

        case "3":
            odds = (line.ML == null) ? null : line.ML.ML3_Odds;
            break;
    }
    var Points = 0;

    BetSlip.add(new RegularSlipSelection(masterEventID, eventID, lineGroupID, lineID, odds, Points, eventTypeId));
    return false;
}

window.RegularSlipSelection = RegularSlipSelection;
window.addOdd = addOdd;
window.addOdd2 = addOdd2;
window.addOdd3 = addOdd3;