function CombinatorSPSlipMode() {
    "use strict";

    ComboSPSlipMode.call(this);

    this.Order = 4;
    this.ID = "combinator";
    this.Name = $dict.bs("CombinatorTab");
    this.TabClass = "combi betting_slip_nbs";
    this.MinSelections = 2;

    this.PurchaseTypeID = SPPurchaseType.Combinator;
    this.CombinatorGroups = [];
    this.GroupOdds = [];
    this.MinGroupCode = 65; // ASCII "A"
    this.CombinatorGroups[String.fromCharCode(this.MinGroupCode)] = [];
    this.MaxGroupCode = 76; // ASCII "L"
    this.BankerGroupCode = BankerGroupCode; // ASCII "T"
    this.WasDeactivated = false;
    this.HasPreviewMode = true;
}

$.extend(CombinatorSPSlipMode.prototype, ComboSPSlipMode.prototype,
{
    /*globals BetSlipRegulations, BetSlip */
    /* jshint maxcomplexity: 10 */
    activate: function ()
    {
        "use strict";

        if (this.WasDeactivated || !this.HasBeenActivated)
        {
            this.spreadSelectionsIntoGroups();
        }

        ComboSPSlipMode.prototype.activate.call(this);
    },

    canActivate: function ()
    {
        "use strict";

        var combinatorGroups = this.getSelectionGroups(false);
        var combinatorGroupsLen = Array.getLength(combinatorGroups);

        if (combinatorGroupsLen >= this.MinSelections)
        {
            return this.ActivationRank;
        }
        else if (!BetSlip.SwitchBackToSingleMode && ((combinatorGroupsLen === 1 && this.HasBeenActivated) || (this.HasBeenActivated
            && this instanceof CombinatorSPSlipMode && typeof (BetSlipRegulations) !== "undefined" && BetSlipRegulations.IsItalian)))
        {
            return this.ActivationRank;
        }
        else if (!BetSlip.SwitchBackToSingleMode && combinatorGroupsLen === 0 && Array.getLength(BetSlip.Selections) > 0 && this.HasBeenActivated)
        {
            return this.ActivationRank;
        }
        else
        {
            return 0;
        }
    },

    deactivate: function ()
    {
        "use strict";

        this.WasDeactivated = true;
        BaseSPSlipMode.prototype.deactivate.call(this);
    },

    getSelectionsInCombo: function ()
    {
        "use strict";

        var selections = [];
        var ref = this;
        this.CombinatorGroups.each(function (group) {
            group.each(function (event) {
                event.each(function (oddViewKey) {
                    var selection = Array.find(BetSlip.Selections, function (el) { return el.ViewKey === oddViewKey; });
                    if (selection && !ref.ExcludedSelections[oddViewKey] && !ref.excludeSuspended(selection)) {
                        selections[selection.ViewKey] = selection;
                    }
                });
            });
        });

        return selections;
    },

    /* jshint maxdepth:3 */
    getSortedViewKeysForGroup: function (groupName)
    {
        "use strict";
        var viewKeys = [];
        var groupArray = this.CombinatorGroups[groupName];
        var notInitializedGroupSelections = this.getNotInitializedGroupSelections(groupName);

        Object.keys(notInitializedGroupSelections).forEach(function (keyNotInit) {
            viewKeys.push(notInitializedGroupSelections[keyNotInit].ViewKey);
        });

        Object.keys(groupArray).forEach(function (groupKey) {
            Object.keys(groupArray[groupKey]).forEach(function (key) {
                viewKeys.push(groupArray[groupKey][key]);
            });
        });

        return Array.mergeSort(viewKeys);
    },

    selectionAdded: function (item)
    {
        "use strict";

        this.updateStatusIfExcluded(item);
        this.addOrUpdateSelection(item);
        ComboSPSlipMode.prototype.selectionAdded.call(this, item);
    },

    updateStatusIfExcluded: function (item)
    {
        "use strict";

        if (!this.Active)
        {
            return;
        }

        var error = this.getComboAddError(item, null);

        if (item.Initialized && (!item.Error || this.isNonCombinableError(item.Error)))
        {
            item.Error = error ? error : undefined;
            item.ComboBetIsEnabled = item.Error ? 0 : 1;
            item.Danger = !!item.Error;
        }
       
        this.ExcludedSelections[item.ViewKey] = !!error;

        return error;
    },

    selectionUpdated: function (item, initcall)
    {
        "use strict";

        var error = this.updateStatusIfExcluded(item);

        if (!error) 
        {
            if (!this.CombinatorGroups.any(function (group) {
                return group.any(function (event) {
                    return event.any(function (odd) {
                        return odd === item.ViewKey;
            });
            });
            }) && initcall) {
                var currentSelection = BetSlip.Selections[item.ViewKey];
                if (currentSelection)
                {
                    this.addOrUpdateSelection(currentSelection);
                }
                
            }
        }
        this.updateBetNames();
        this.updateGroupOddsView(item);

        ComboSPSlipMode.prototype.selectionUpdated.call(this, item, initcall);
    },

    updateGroupOddsView: function(item)
    {
        "use strict";

        if (!this.Active)
        {
            return;
        }

        var elementId = "gsce_" + item.CombinatorGroup;
        var groupOddsLineElement = document.getElementById(elementId);
        if (groupOddsLineElement)
        {
            var spanId = "betSlip-group-sum_" + item.CombinatorGroup;
            var spanElement = document.getElementById(spanId);

            if (spanElement)
            {
                var newGroupOdds = Odds.format(this.getGroupOdd(item.CombinatorGroup));
                spanElement.textContent = newGroupOdds;
            }
        }
    },

    /* jshint maxcomplexity: 12 */
    selectionRemoved: function (item, isReplacelment)
    {
        "use strict";

        var groupKey = item.getComboGroupKey();
        if (this.CombinatorGroups[item.CombinatorGroup])
        {
            var selectionsKeys = this.CombinatorGroups[item.CombinatorGroup][groupKey];
            if (selectionsKeys)
            {
                selectionsKeys.remove(item.ViewKey);

                if (Array.getLength(selectionsKeys) === 0)
                {
                    delete this.CombinatorGroups[item.CombinatorGroup][groupKey];
                }
            }
            if (!isReplacelment || Array.getLength(this.getSelectionsInCombo()) === 0)
            {
                this.regularizeGroup(item.CombinatorGroup);
                this.shiftBankerGroup();
                this.updateBetNames();
            }
        }

        ComboSPSlipMode.prototype.selectionRemoved.call(this, item);
    },

    getAvailableCombinatorGroups: function ()
    {
        "use strict";
        var selectionsWithoutBanker = this.getSelectionsWithoutBanker();
        var lengthWithoutBanker = Array.getLength(this.getCombinatorGroupsWithoutBanker());
        var shift = lengthWithoutBanker - 1;

        if (Array.getLength(this.getSelectionsInCombo()) > lengthWithoutBanker)
        {
            shift += 1;
        }

        var end = Math.min(this.MinGroupCode + shift, this.MaxGroupCode);

        var groups = [];

        for (var i = this.MinGroupCode; i <= end; i += 1)
        {
            var groupName = String.fromCharCode(i);
            groups.push(groupName);
        }

        var numberOfSelectionsWithoutBanker = Array.getLength(selectionsWithoutBanker);

        if (numberOfSelectionsWithoutBanker > 1 || numberOfSelectionsWithoutBanker < Array.getLength(BetSlip.Selections))
        {
            var groupT = String.fromCharCode(this.BankerGroupCode);
            groups.unshift(groupT);
        }

        return groups;
    },
    
    getOdds: function (oddStyle)
    {
        "use strict";

        if (oddStyle == OddStyle.EUROPEAN)
        {
            return BetMath.roundDecimalOdds(this.Odds[this.Odds.length - 1], OddsRoundingStyle.ROUND, true);
        }
        var americanOdds = Odds.convertFromDecimal(this.Odds[this.Odds.length - 1], OddStyle.AMERICAN);
        var odds = Odds.convert(americanOdds, undefined, undefined, true, typeof oddStyle != "undefined" ? oddStyle : this.getAlternativeOddsStyle());
        if (typeof (oddStyle) != "undefined" && (oddStyle == OddStyle.HONGKONG || oddStyle == OddStyle.MALAY || oddStyle == OddStyle.INDO))
        {
            odds = BetMath.roundDecimalOdds(odds, OddsRoundingStyle.ROUND, true);
        }
        return odds;
    },

    updateCombinatorGroups: function (selectbox, viewKey) {
        "use strict";

        var currentSelection = BetSlip.Selections[viewKey];
        var key = currentSelection.getComboGroupKey();
        var groupName = currentSelection.CombinatorGroup;
        if (this.CombinatorGroups[groupName][key])
        {
            Array.removeOneByValue(this.CombinatorGroups[groupName][key], viewKey);
            if (Array.getLength(this.CombinatorGroups[groupName][key]) === 0)
            {
                delete this.CombinatorGroups[groupName][key];
                this.updateBetNames();
            }
        }

        currentSelection.CombinatorGroup = selectbox.value;

        this.addOrUpdateSelection(currentSelection);
        this.regularizeGroup(groupName);
        this.shiftBankerGroup();

        BetSlip.saveState();
        this.updateView();
        BetSlip.updateBSFooter();
    },

    /* jshint maxdepth:5 */
    shiftBankerGroup: function ()
    {
        "use strict";
        var self = this;
        var firstGroupName = String.fromCharCode(this.MinGroupCode);
        var bankerGroupName = String.fromCharCode(this.BankerGroupCode);
        var numberOfSelectionsWithoutBanker = Array.getLength(this.getSelectionsWithoutBanker());

        if (numberOfSelectionsWithoutBanker === 0 && this.CombinatorGroups[bankerGroupName])
        {
            Object.keys(this.CombinatorGroups[bankerGroupName]).forEach(function (groupKey) {
                if (self.CombinatorGroups[bankerGroupName])
                {
                    Object.keys(self.CombinatorGroups[bankerGroupName][groupKey]).forEach(function (keyBanker) {
                        var viewKeyBanker = self.CombinatorGroups[bankerGroupName][groupKey][keyBanker];
                        var currentSelectionBanker = BetSlip.Selections[viewKeyBanker];
                        self.selectionRemoved(currentSelectionBanker, false);

                        currentSelectionBanker.CombinatorGroup = firstGroupName;
                        self.addOrUpdateSelection(currentSelectionBanker);
                    });
                }
            });
        }
    },

    regularizeGroup: function (groupName)
    {
        "use strict";

        if (Array.getLength(this.CombinatorGroups[groupName]) === 0)
        {
            if (!this.isNextGreaterCombinatorGroupExisting(groupName))
            {
                if (String.fromCharCode(this.MinGroupCode) !== groupName)
                {
                    delete this.CombinatorGroups[groupName];
                    this.updateBetNames();
                }
            }
            else
            {
                this.squeezeCombinatorGroups(groupName);
            }
        }
    },


    /* jshint maxdepth:5 */
    squeezeCombinatorGroups: function (lastUntouchableGroupName)
    {
        "use strict";

        var nextGroupName = lastUntouchableGroupName;

        while (this.isNextGreaterCombinatorGroupExisting(nextGroupName))
        {
            var prevGroupName = nextGroupName;
            nextGroupName = this.getNextGreaterGroupName(nextGroupName);
            this.CombinatorGroups[prevGroupName] = this.CombinatorGroups[nextGroupName];

            for (var groupKey in this.CombinatorGroups[prevGroupName])
            {
                if (!this.CombinatorGroups[prevGroupName].hasOwnProperty(groupKey))
                {
                    continue;
                }

                for (var key in this.CombinatorGroups[prevGroupName][groupKey])
                {
                    if (!this.CombinatorGroups[prevGroupName][groupKey].hasOwnProperty(key))
                    {
                        continue;
                    }

                    var viewKey = this.CombinatorGroups[prevGroupName][groupKey][key];
                    var currentSelection = BetSlip.Selections[viewKey];
                    currentSelection.CombinatorGroup = prevGroupName;
                }
            }
        }
        if (String.fromCharCode(this.MinGroupCode) !== nextGroupName)
        {
            delete this.CombinatorGroups[nextGroupName];
            this.updateBetNames();
        }
    },

    isNextGreaterCombinatorGroupExisting: function (currentGroupName)
    {
        "use strict";

        var nextGreaterGroupName = this.getNextGreaterGroupName(currentGroupName);
        if (nextGreaterGroupName !== null)
        {
            return Array.getKeys(this.CombinatorGroups).contains(nextGreaterGroupName) ? true : false;
        }

        return false;
    },

    isGroupCodeValid: function (code)
    {
        "use strict";

        if ((code >= this.MinGroupCode && code <= this.MaxGroupCode) || code === this.BankerGroupCode)
        {
            return true;
        }

        return false;
    },

    getNextGreaterGroupName: function (currentGroupName)
    {
        "use strict";

        var currentGroupCode = currentGroupName.charCodeAt(0);
        var nextGroupCode = currentGroupCode + 1;

        if (this.isGroupCodeValid(nextGroupCode))
        {
            return String.fromCharCode(nextGroupCode);
        }

        return null;
    },

    getCombinatorGroupsWithoutBanker: function ()
    {
        "use strict";

        var bankerGroupName = String.fromCharCode(this.BankerGroupCode);
        var groupNames = Array.getKeys(this.CombinatorGroups);
        if (groupNames.contains(bankerGroupName))
        {
            var index = Array.indexOf(groupNames, bankerGroupName);
            delete groupNames[index];
        }

        return groupNames;
    },


    getSelectionsWithoutBanker: function ()
    {
        "use strict";
        var bankerGroupName = String.fromCharCode(this.BankerGroupCode);
        var selections = [];
        var selectionsInCombinator = this.getSelectionsInCombo();

        Object.keys(selectionsInCombinator).forEach(function (selectionKey) {
            if (selectionsInCombinator[selectionKey].CombinatorGroup !== bankerGroupName)
            {
                selections[selectionKey] = selectionsInCombinator[selectionKey];
            }
        });

        return selections;
    },

    getNotInitializedGroupSelections: function (combinatorGroupName)
    {
        "use strict";
        var selections = [];
        var selectionsInCombinator = this.getSelectionsInCombo();
        Object.keys(selectionsInCombinator).forEach(function (selectionKey) {
            var selection = selectionsInCombinator[selectionKey];
            if (!selection.Initialized && selection.CombinatorGroup === combinatorGroupName)
            {
                selections[selectionKey] = selection;
            }
        });
        return selections;
    },

    combinationsAreAllowed: function (item)
    {
        "use strict";
        return this.isEnabledForItem(item) && !this.getComboAddError(item) && !this.ExcludedSelections[item.ViewKey];
    },

    isEnabledForItem: function (item)
    {
        return !!item.ComboBetIsEnabled && item.Initialized;
    },

    /* jshint maxcomplexity: 10 */
    addOrUpdateSelection: function (item)
    {
        "use strict";

        if (this.combinationsAreAllowed(item))
        {
            if (!item.CombinatorGroup) {
                var groupNames = this.getCombinatorGroupsWithoutBanker();
                item.CombinatorGroup = Array.last(groupNames);
            }

            var groupName = item.CombinatorGroup;

            if (!Array.getKeys(this.CombinatorGroups).contains(groupName))
            {
                this.CombinatorGroups[groupName] = [];
            }

            var groupKey = item.getComboGroupKey();

            if (!this.CombinatorGroups[groupName][groupKey])
            {
                this.CombinatorGroups[groupName][groupKey] = [];
            }

            if (!this.CombinatorGroups[groupName][groupKey].contains(item.ViewKey))
            {
                this.CombinatorGroups[groupName][groupKey].push(item.ViewKey);
                this.updateBetNames();
            }

            this.removeSelectionCountError();
        }
        this.updateView();
    },

    initBetNames: function ()
    {
        "use strict";
        var self = this;
        this.BetNameTemplates = [];
        ComboSPSlipMode.prototype.initBetNames.call(this, "CombinatorNames_", 1);
        Object.keys(this.ComboNames).forEach(function (comboNamesKey) {
            self.BetNameTemplates[comboNamesKey] = self.ComboNames[comboNamesKey];
        });
    },

    /*jshint camelcase: false */
    updateBetNames: function ()
    {
        "use strict";
        var groupsCount = this.countGroupsWithoutSuspendedSelectionsAndBanker();
        for (var key = 1; key <= BetSlip.maxSelectionsNumber; key += 1)
        {
            this.ComboNames[key] = this.BetNameTemplates[key].replace("$GROUPS_COUNT$", groupsCount);
        }
    },

    serialize: function ()
    {
        "use strict";

        var base = ComboSPSlipMode.prototype.serialize.call(this);

        base.MinGroupCode = this.MinGroupCode;
        base.MaxGroupCode = this.MaxGroupCode;
        base.BankerGroupCode = this.BankerGroupCode;
        base.CombinatorGroup = this.CombinatorGroup;
        base.Type = this.Type;
        return base;
    },

    deserialize: function (data)
    {
        "use strict";

        ComboSPSlipMode.prototype.deserialize.call(this, data);

        this.MinGroupCode = data.MinGroupCode;
        this.MaxGroupCode = data.MaxGroupCode;
        this.BankerGroupCode = data.BankerGroupCode;
        this.CombinatorGroup = data.CombinatorGroup;
        this.Type = data.Type;
    },

    /* jshint maxcomplexity: 17 */
    /* jshint maxstatements: 45 */
    calcVariants: function ()
    {
        "use strict";
        var self = this;
        var inlines = this.getSelectionsInCombo();
        if (Array.getLength(inlines) === 0)
        {
            return;
        }

        var maxCombinatorKey = Array.getLength(this.Variants) - 1;
        var combinatorGroups = this.CombinatorGroups;
        var groupOdds = this.GroupOdds = [];

        this.populateGroupOdds(combinatorGroups, groupOdds);

        var regularGroupsOdds = [];
        var bankerGroupName = String.fromCharCode(this.BankerGroupCode);

        Object.keys(groupOdds).forEach(function (groupName) {
            if (groupName !== bankerGroupName)
            {
                regularGroupsOdds.push(groupOdds[groupName]);
            }
        });

        var countArray = regularGroupsOdds.select(function () { return 1; });
        this.Variants = BetMath.sumOfProducts(countArray);

        var bankerOdd = 1;
        if (typeof groupOdds[bankerGroupName] !== "undefined")
        {
            bankerOdd = groupOdds[bankerGroupName];
        }

        this.Odds = BetMath.sumOfProducts(regularGroupsOdds);
        for(var i = 0; i < this.Odds.length; i += 1)
        {
            this.Odds[i] *= bankerOdd;
        }

        // clean any deposits for no longer available variants
        Object.keys(this.VariationsDeposits).forEach(function (key) {
            if (!self.Variants[key])
            {
                delete self.VariationsDeposits[key];
            }
        });

        if (!this.Variants[maxCombinatorKey])
        {
            // remove the flag that the user updated the combo amount for the current max combo key
            if (this.StakesUpdatedByUserForDropDown && BetSlip.isDefaultStakeAndStakeValuesAvailable()) { //Combo slip stake drop-downs
                this.StakesUpdatedByUserForDropDown[maxCombinatorKey] = null;
            }

            if (this.StakesUpdatedByUser)
            { //Combo slip free bet drop-down
                this.StakesUpdatedByUser[maxCombinatorKey] = null;
            }

            if (this.FreeBets)
            { // remove FreeBet for the current max combo key
                this.FreeBets[maxCombinatorKey] = null;
            }
        }
    },

    getGroupOdd: function (combinatorGroupName)
    {
        "use strict";

        var out = { americanOdd: 0 };
        this.getGroupRate(combinatorGroupName, out);
        return Odds.convert(out.americanOdd, undefined, undefined, true, undefined);
    },

    getGroupRate: function (combinatorGroupName, out)
    {
        "use strict";
        this.calcVariants();
        var groupOdd = this.GroupOdds[combinatorGroupName];
        this.countRateByUSOdds(groupOdd, out);
    },

    /* jshint maxparams: 5 */
    /* jshint maxdepth:6 */
    populateGroupOdds: function (combinatorGroups, groupOdds)
    {
        "use strict";
        var self = this;
        Object.keys(combinatorGroups).forEach(function (group) {
            var groupOdd = 1;
            var onlySuspended = true;
            Object.keys(combinatorGroups[group]).forEach(function (i) {
                Object.keys(combinatorGroups[group][i]).forEach(function (j) {
                    var viewKey = combinatorGroups[group][i][j];
                    var line = BetSlip.Selections[viewKey];
                    if (line && !self.excludeSuspended(line))
                    {
                        onlySuspended = false;
                        var EUodds = line.getRealOdd(OddsRoundingStyle.FLOOR);
                        if (EUodds)
                        {
                            groupOdd *= EUodds;
                        } else if (line.RealOrPresumptiveOdds)
                        {
                            groupOdd *= line.RealOrPresumptiveOdds;
                        }
                    }
                });
            });
            if (!onlySuspended)
            {
                groupOdds[group] = groupOdd;
            }
        });
    },

    excludeSuspended: function (selection)
    {
        "use strict";
        if (!selection)
        {
            return true;
        }

        return selection.Danger;
    },

    /* jshint maxdepth:5 */
    countGroupsWithoutSuspendedSelectionsAndBanker: function ()
    {
        "use strict";
        var groupNames = [];
        var self = this;
        var bankerGroupName = String.fromCharCode(this.BankerGroupCode);
        var combinatorGroups = this.CombinatorGroups;
        Object.keys(combinatorGroups).forEach(function (group) {
            Object.keys(combinatorGroups[group]).forEach(function (i) {
                Object.keys(combinatorGroups[group][i]).forEach(function (j) {
                    var viewKey = combinatorGroups[group][i][j];
                    var line = BetSlip.Selections[viewKey];
                    if (line && !self.excludeSuspended(line) && !groupNames.contains(group) && group !== bankerGroupName)
                    {
                        groupNames.push(group);
                    }
                });
            });
        });

        return Array.getLength(groupNames);
    },

    /* jshint maxparams: 11 */
    formatPurchaseRequest: function (linesArray, singleDeposits, comboDeposits, teaserDeposits, teaserTypeID, systemDeposit, systemKey, multiLineData, freeBets, comboBonuses)
    {
        "use strict";

        return ComboSPSlipMode.prototype.formatPurchaseRequest.call(this, linesArray, null, null, null, null, null, null, null, freeBets, comboBonuses, comboDeposits);
    },

    calcGain: function (odds, deposit)
    {
        "use strict";
        if (!odds || !deposit)
        {
            return 0;
        }

        return BetMath.roundDecimalOdds(odds, OddsRoundingStyle.ROUND, true) * deposit;
    },

    getTotalGain: function ()
    {
        "use strict";

        return ComboSPSlipMode.prototype.getTotalGain.call(this, 1);
    },

    getTotalGainWithBonuses: function ()
    {
        "use strict";

        return this.getTotalGain();
    },

    getMaxGainCoef: function (variantKey)
    {
        "use strict";

        return ComboSPSlipMode.prototype.getMaxGainCoef.call(this, variantKey, 1);
    },

    updateViewOptions: function ()
    {
        "use strict";

        ComboSPSlipMode.prototype.updateViewOptions.call(this, "combinator-options");
    },
    setComboMaxBet: function ()
    {
        "use strict";

        ComboSPSlipMode.prototype.setComboMaxBet.call(this, "combinator-options");
    },

    check: function ()
    {
        "use strict";

        return ComboSPSlipMode.prototype.check.call(this, "SelectionsInCombinator");
    },
    clearErrors: function ()
    {
        "use strict";

        ComboSPSlipMode.prototype.clearErrors.call(this);
    },

    placeBets: function ()
    {
        "use strict";

        ComboSPSlipMode.prototype.placeBets.call(this, 1);
    },

    CheckForEachWayEnabled: function (key)
    {
        "use strict";
        return ComboSPSlipMode.prototype.CheckForEachWayEnabled.call(this, key, 1);
    },

    getEachWayTotalGain: function ()
    {
        "use strict";
        return ComboSPSlipMode.prototype.getEachWayTotalGain.call(this, 1);
    },

    clear: function ()
    {
        "use strict";

        this.CombinatorGroups = [];
        this.CombinatorGroups[String.fromCharCode(this.MinGroupCode)] = [];
        ComboSPSlipMode.prototype.clear.call(this);
    },

    spreadSelectionsIntoGroups: function ()
    {
        "use strict";

        var ref = this;
        Object.keys(this.CombinatorGroups).sort().forEach(function (groupName) {
            var isBankerGroup = groupName == String.fromCharCode(ref.BankerGroupCode);
            var group = ref.CombinatorGroups[groupName];
            if (groupName < String.fromCharCode(ref.MaxGroupCode) || isBankerGroup)
            {
                var itemNames = Object.keys(group);
                // leave one selection in a current group and move the rest to the next one;
                // or take everything if it is a banker group
                for (var i = isBankerGroup ? 0 : 1; i < itemNames.length; i += 1)
                {
                    var nextGroupName = ref.GetNextFreeOrLastGroupName(groupName);
                    ref.MoveItem(itemNames[i], group, nextGroupName);
                }
            }
        });

        ref.regularizeGroup(String.fromCharCode(ref.BankerGroupCode));
    },

    GetNextFreeOrLastGroupName: function (groupName)
    {
        "use strict";

        var nextGroupName = groupName != String.fromCharCode(this.BankerGroupCode) ? groupName : "A";

        while (this.isNextGreaterCombinatorGroupExisting(nextGroupName))
        {
            nextGroupName = this.getNextGreaterGroupName(nextGroupName);
        }

        var nextGreaterGroupName = this.getNextGreaterGroupName(nextGroupName);

        if (nextGreaterGroupName)
        {
            nextGroupName = nextGreaterGroupName;
        }

        return nextGroupName;
    },

    GetGroup: function (groupName)
    {
        "use strict";

        if (typeof (this.CombinatorGroups[groupName]) === "undefined")
        {
            this.CombinatorGroups[groupName] = [];
        }

        return this.CombinatorGroups[groupName];
    },

    MoveItem: function (itemName, fromGroup, toGroupName)
    {
        "use strict";

        var toGroup = this.GetGroup(toGroupName);
        toGroup[itemName] = fromGroup[itemName];
        BetSlip.Selections[fromGroup[itemName][0]].CombinatorGroup = toGroupName;
        delete fromGroup[itemName];
    },
    
    setFreeBet: function()
    {
    	//disabled for Combinator
    },

    isUnderMinBetRegulation: function(deposit, numberOfBets, isEachWay)
    {
        "use strict";

        var depositWithEW = (isEachWay ? deposit * 2 : deposit) * numberOfBets;

        if (BetSlipRegulations.hasOwnProperty("MinimumCombinatorBetStake") && depositWithEW < this.getRegulationCombinatorMinBetStake())
        {
            this.setError($dict.bs("RegulationMinCombinatorBetStake").format("{0}{1}".format(BetSlip.UserCurrencyCode, MoneyFormat.format(this.getRegulationCombinatorMinBetStake()))));
            return true;
        }
    },

    validateMinBet: function()
    {
        "use strict";

        var minBet = this.getComboMinBet(this.getSelectionsInCombo()),
            variantDeposit = 0,
            variantionKeys = Object.keys(this.VariationsDeposits),
            depositKey;

        for (var key = 0; key < variantionKeys.length; key += 1)
        {
            depositKey = variantionKeys[key];

            if (this.VariationsDeposits[depositKey] === 0 || this.VariationsDeposits[depositKey] === null)
            {
                continue;
            }

            if (this.VariationsDeposits[depositKey] > 0) // check min bet
            {
                // for combinator we must validate with all bets in variant not just stakebox value

                variantDeposit = this.VariationsDeposits[depositKey] * this.Variants[depositKey];
                if (variantDeposit < minBet)
                {
                    this.setError($dict.bs("MinimalBet").concat(" " + MoneyFormat.format(minBet)));
                    return false;
                }

                if (typeof (BetSlipRegulations) !== "undefined" && BetSlipRegulations.isActive)
                {
                    if (BetSlipRegulations.IsItalian && this.ComboNoCombinations && this.Variants[depositKey] !== 1 && this.VariationsDeposits[depositKey] > 0)
                    {
                        return false;
                    }

                    if (this.isUnderMinBetRegulation(this.VariationsDeposits[depositKey], this.Variants[depositKey], this.SelectedEWVariants[depositKey]))
                    {
                        return false;
                    }
                }

                if (this.FreeBets[depositKey])
                {
                    var realMoneyDeposit = (this.VariationsDeposits[depositKey] - this.FreeBets[depositKey].Amount) * this.Variants[depositKey];
                    if (realMoneyDeposit > 0 && realMoneyDeposit < minBet)
                    {
                        this.setError($dict.bs("MinimalBetOverFree").concat(" " + MoneyFormat.format(minBet)));
                        return false;
                    }
                }
            }
        }

        return true;
    },

    isComboBonusAvailable: function ()
    {
        return false;
    }

}, TCombinatorSPSlipMode);

window.CombinatorSPSlipMode = CombinatorSPSlipMode;