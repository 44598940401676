﻿function RegularLiveSlipSelection(masterEventID, eventID, LineGroupID, lineTypeID, rowtypeid, Odds, Points, Score1, Score2, CheckedForCombo, eventTypeId, isFavTeam, leagueId)
{
    RegularSlipSelection.call(this, masterEventID, eventID, LineGroupID, 0, Odds, Points, eventTypeId, isFavTeam, leagueId, lineTypeID);

    this.BetTypeID = lineTypeID;
    this.BetSide = rowtypeid;
    this.Score1 = Score1;
    this.Score2 = Score2;
    this.Live = true;
}

$.extend(RegularLiveSlipSelection.prototype, RegularSlipSelection.prototype,
{
    getUpdateRequest: function ()
    {
        return [
            !!this.IsMultiLine,
            this.ViewKey,
            this.EventID,
            this.EventType,
            this.LineGroupID,
            this.BetTypeID,
            this.BetSide,
            this.Points,
            this.Odds,
            this.Score1,
            this.Score2,
            BetSlip.isAsianMode & BetSlip.EnableAsianStyleMinMax
        ];
    },

    getPostRequest: function (deposit)
    {
        var res = [
            this.ViewKey, //0
            this.EventID, //1
            this.EventType, //2
            this.LineGroupID, //3
            this.BetTypeID, //4
            this.BetSide, //5
            this.Points, //6
            this.Odds, //7
            deposit, //8
            this.Score1, //9
            this.Score2, //10
            //11 - actual adds, the odds that we are actually showing to the client : actual odd style id
            (currentOddStyle == OddStyle.FRACTIONAL ? FractionalOddsConverter.encodeFraction(this.getFormatedOdds()) : this.getFormatedOdds() * 1)
                + ":" + this.getActualOddsStyleID()
        ];

        return res;
    },

    processBetUpdate: function (init, firstcall)
    {
        this.Valid = true;

        RegularSlipSelection.prototype.processBetUpdate.call(this, init);
    },

    serialize: function ()
    {
        return $.extend({}, RegularSlipSelection.prototype.serialize.call(this),
           {
               Live: true,
               IsDanger: this.Danger,

               comboRate: this.ComboRate,
               ScoreChanged: [this.Score1, this.Score2]
           });
    },

    equals: function (cb)
    {
        if (this.LineID && cb.LineID)
            return BaseSlipSelection.prototype.equals.call(this, cb);

        if (!cb.Live) return false;
        return cb.EventID == this.EventID && cb.BetSide == this.BetSide && cb.BetTypeID == this.BetTypeID && cb.Countable == this.Countable && cb.LineGroupID == this.LineGroupID;
    },

    getUniPostRequest: function (deposit)
    {
        return [
            this.ViewKey,            // 0 SlotID
            this.EventID,            // 1 EventID
            this.EventTypeID,        // 2 EventTypeID
            this.LineID,             // 3 LineID
            this.BetTypeID,          // 4 LineTypeID // FIX YOUR BULLSHITS
            this.BetType,            // 5 BetTypeID
            this.LineGroupID,        // 6 LineGroupID
            this.BetSide,            // 7 BetSide
            this.Points,             // 8 Points
            this.Odds,               // 9 Odds
            deposit,                 // 10 deposit
            this.Score1,             // 11 Score1
            this.Score2,             // 12 Score2
            this.IsBuyPoints,        // 13 IsBuyPoints
            this.BuyPointsPoints,    // 14 BuyPointsPoints
            this.BuyPointsOdds,      // 15 BuyPointsOdds
            this.EachWayIncluded,    // 16 IsEWIncluded
            this.IsEachWayEnabled,   // 17 IsEachWayEnabled
            this.PlaceTermsID,       // 18 PlaceTermsID
            this.OddsTermsID,        // 19 OddsTermsID
            this.EachWayTermsID,     // 20 EachWayTermsID
            this.EWOdds,             // 21 EWOdds
            this.EWDeposit,          // 22 EWDeposit
            this.EWGain,             // 23 EWGain
            (currentOddStyle == OddStyle.FRACTIONAL ? FractionalOddsConverter.encodeFraction(this.getFormatedOdds()) : this.getFormatedOdds() * 1)
                + ":" + this.getActualOddsStyleID(), //24 client odds
            this.Authorization,      // 25 Authorization
        ];
    }

});

function addOddLive(masterEventID, eventID, LineGroupID, litetypeid, rowtypeid, Odds, Points, eventTypeId, favTeam, isPreloaded, leagueId, lineId, betId)
{
    var selObj = new RegularLiveSlipSelection(masterEventID, eventID, LineGroupID, litetypeid, rowtypeid, Odds, Points, 0, 0, null, eventTypeId, favTeam, leagueId);
    selObj.BetID = betId;
    if (!isNaN(lineId)) 
    {
        selObj.LineID = lineId || 0;
    }

    BetSlip.add(selObj, isPreloaded);
    return selObj;
}

window.RegularLiveSlipSelection = RegularLiveSlipSelection;
window.addOddLive = addOddLive;