function ComboNoCombinationSingleSPSlipMode()
{
    ComboNoCombinationSPSlipMode.call(this);

    this.ID = "comboNoCombinationSingle";
    this.Name = $dict.bs("ComboBetNoCombinationsTab");
    this.PurchaseTypeID = SPPurchaseType.StraightCombo;

    this.MinSelections = 1;
    this.Order = 1;
    this.Deposits = [];
    this.StakeUpdatedByUser = [];
    this.ShowLastLineError = true;
    this.IsFreeBetFromSingleBet = false;
    this.FreeBet = {};
}

$.extend(ComboNoCombinationSingleSPSlipMode.prototype, ComboNoCombinationSPSlipMode.prototype,
{
    addItem: function (item)
    {
        if (item.CastSelection && !item.VisibleCastSelection)
        {
            return;
        }

        var groupKey = item.getComboGroupKey();
        if (!this.SelectionsInCombo[groupKey])
        {
            this.SelectionsInCombo[groupKey] = [];
        }

        this.SelectionsInCombo[groupKey].push(item.ViewKey);
        this.removeSelectionCountError();
        this.updateView();
    },

    isSingleMode: function ()
    {
        var comboGroups = this.getSelectionGroups(false);
        return Array.getLength(comboGroups) <= 1;
    },

    canActivate: function ()
    {
        return this.ActivationRank;
    },

    initBetNames: function (betNamePrefix)
    {
        ComboSPSlipMode.prototype.initBetNames.call(this, betNamePrefix, this.getStartIndex());
    },

    getComboAddError: function (selection, returnSelection)
    {
        if (this.isSingleMode() && Array.getLength(this.ExcludedSelections) === 0)
        {
            return false;
        }

        return ComboSPSlipMode.prototype.getComboAddError.call(this, selection, returnSelection);
    },

    getComboMaxBet: function (lines, variantKey)
    {
        if (this.isSingleMode())
        {
            var minValue;
            for (var key in BetSlip.Selections)
            {
                var selection = BetSlip.Selections[key];
                var maxBet = SingleSPSlipMode.prototype.getMaxBet.call(this, selection.ViewKey);
                if (!minValue || maxBet < minValue)
                {
                    minValue = maxBet;
                }
            }

            return minValue;
        }
        else
        {
            return ComboSPSlipMode.prototype.getComboMaxBet.call(this, lines, variantKey);
        }
    },

    getAvailableFreeBets: function (selections, comboKey)
    {
        if (this.isSingleMode())
        {
            var selection = Array.first(selections);
            var freeBets = SingleSPSlipMode.prototype.getAvailableFreeBets.call(this, selection, true);
            this.IsFreeBetFromSingleBet = true;
            this.FreeBets = freeBets;
            if (!(this.FreeBet.hasOwnProperty(comboKey) && this.FreeBet[comboKey] !== undefined && this.FreeBet[comboKey] != null))
            {
                this.FreeBet[comboKey] = null;
            }
            return freeBets;
        }
        else
        {
            if (this.IsFreeBetFromSingleBet)
            {
                this.FreeBets = [];
                this.IsFreeBetFromSingleBet = false;
            }

            return ComboSPSlipMode.prototype.getAvailableFreeBets.call(this, selections, comboKey);
        }
    },
    getFreeBetDeposit: function ()
    {
        if (this.isSingleMode())
        {
            var freeBetDeposit = 0;
            for (var comboKey in this.FreeBet)
            {
                var freeBet = this.FreeBet[comboKey];
                if (!freeBet) continue;

                var variantDepposit = this.VariationsDeposits[comboKey];
                freeBetDeposit += freeBet.GetFreeBetDeposit(variantDepposit);
            }
            return freeBetDeposit;
        }
        else
        {
            return ComboSPSlipMode.prototype.getFreeBetDeposit.call(this);
        }
    },

    getRiskFreeBetDeposit: function ()
    {
        if (this.isSingleMode())
        {
            var freeBetHolder = this.FreeBet,
                variationsDeposits = this.VariationsDeposits,
                freeBet, comboKey, deposit;

            // There supposed to be only one free bet here, but its key can differ.
            // So we loop through the keys and return the deposit of the first found item.
            for (comboKey in freeBetHolder)
            {
                freeBet = freeBetHolder[comboKey];
                if (freeBet)
                {
                    deposit = variationsDeposits[comboKey];
                    return freeBet.getRiskFreeBetDeposit(deposit);
                }
            }
            return 0;
        }
        else
        {
            return ComboSPSlipMode.prototype.getRiskFreeBetDeposit.call(this);
        }       
    },

    setFreeBet: function (selections, comboKey)
    {
        if (this.isSingleMode())
        {
            if (Array.getLength(selections) > 0)
            {
                var bonusId = selections.value * 1;
                this.FreeBet[comboKey] = UserInfo.current.FreeBets[bonusId];
            }

            this.setSingleFreeBet(selections, comboKey);
        }
        else
        {
            ComboSPSlipMode.setFreeBet(selections, comboKey);
        }
    },

    setSingleFreeBet: function(select, viewKey)
    {
        var mode = BetSlip.CurrentMode;
        if (!select) return;

        var bonusID = select.value * 1;
        var selection = Array.first(mode.getSelectionsInCombo());
        if (!selection) return;

        var freeBet = UserInfo.current.FreeBets[bonusID];
        if (freeBet)
        {
            if (Array.find(BetSlip.Selections, function (sel) { return (sel.FreeBet != null && sel.ViewKey !== selection.ViewKey && sel.FreeBet.BonusID === freeBet.BonusID); })) return;

            if (freeBet.IsAllowedForLine(selection, selection.BetType))
            {
                selection.FreeBet = freeBet;
                mode.setFreeBetStake(viewKey, freeBet);
                this.FreeBet[viewKey] = freeBet;
            }
        }
        else if (bonusID === 0)
        {
            selection.FreeBet = null;
            this.FreeBet[viewKey] = null;
            mode.setFreeBetStake(viewKey);
        }

        BetSlip.updateView();
        BetSlip.updateBSFooter();
        BetSlip.saveState();
    },

    setFreeBetStake: function (viewKey, freeBet)
    {
        ComboSPSlipMode.prototype.setVariantFreeBetStake.call(this, viewKey, freeBet);
    },

    placeBets: function (startIndex)
    {
        if (this.isSingleMode())
        {
            this.parsePlaceBets = SingleSPSlipMode.prototype.parsePlaceBets;
            this.PurchaseTypeID = SPPurchaseType.Single;

            for (var key in BetSlip.Selections)
            {
                var selection = BetSlip.Selections[key];
                this.Deposits[selection.ViewKey] = this.VariationsDeposits[1];
                this.StakeUpdatedByUser[selection.ViewKey] = false;
            }

            SingleSPSlipMode.prototype.placeBets.call(this);
        }
        else
        {
            this.parsePlaceBets = ComboSPSlipMode.prototype.parsePlaceBets;
            this.PurchaseTypeID = SPPurchaseType.StraightCombo;

            ComboSPSlipMode.prototype.placeBets.call(this, startIndex);
        }
    },

    selectionAdded: function (item)
    {
        var selectionsInCombo = this.getSelectionsInCombo();
        if (Array.getLength(selectionsInCombo) === 1)
        {
            for (var i in selectionsInCombo)
            {
                var error = ComboSPSlipMode.prototype.getComboAddError.call(this, selectionsInCombo[i]);
                if (error)
                {
                    ComboSPSlipMode.prototype.proceedComboAddError.call(this, selectionsInCombo[i]);
                }
            }
        }

        this.addItem(item);
        if (!this.Active)
        {
            return;
        }

        this.updateView();
    },

    selectionRemoved: function (item)
    {
        if (this.isSingleMode() && Array.getLength(this.ExcludedSelections) <= 2)
        {
            this.ExcludedSelections = [];
        }
        else
        {
            delete this.ExcludedSelections[item.ViewKey];
        }

        return ComboSPSlipMode.prototype.selectionRemoved.call(this, item);
    },

    IsMaxBetButtonEnabled: function ()
    {
        if (this.isSingleMode())
        {
            for (var key in BetSlip.Selections)
            {
                var selection = BetSlip.Selections[key];
                var isAllowed = SingleSPSlipMode.prototype.IsMaxBetButtonEnabled.call(this, selection);
                if (isAllowed)
                {
                    return true;
                }
            }

            return false;
        }
        else
        {
            return ComboSPSlipMode.prototype.IsMaxBetButtonEnabled.call(this);
        }
    },

    setError: function (errorMessage)
    {
        BaseSPSlipMode.prototype.setError.call(this, errorMessage, true);
    },

    getStartIndex: function ()
    {
        //For ComboNoCombination it should be 1, because single selection can be placed
        return 1;
    },

    check: function (errorMessageKey)
    {
        return ComboSPSlipMode.prototype.check.call(this, "SelectionsInComboNoCombination");
    },

    showComboNotAllowedMessage: function ()
    {
        return false;
    },

    proceedComboAddError: function (item)
    {
        if (!this.isSingleMode() || Array.getLength(this.ExcludedSelections) > 0)
        {
            return ComboSPSlipMode.prototype.proceedComboAddError.call(this, item);
        }
    },

    updateEachWay: function (key)
    {
        if(this.isSingleMode())
        {
            var item = BetSlip.Selections[key];
            var ewIncluded = item.EachWaySingleIncluded;

            if (item.BetType == 7) 
            {
                item.EachWayIncluded = !ewIncluded;
                item.EachWaySingleIncluded = !ewIncluded;
            }

            var cDep = this.VariationsDeposits[key];
            if (cDep >= 0)
            {
                this.recalcEachWay(key);
            }

            BetSlip.updateView();
            BetSlip.saveState();
            BetSlip.updateBSFooter();
        }
        else
        {
            ComboSPSlipMode.prototype.updateEachWay.call(this, key);
        }
    },

    recalcEachWay: function (key)
    {
        if (this.isSingleMode())
        {
            var item = BetSlip.Selections[key];
            if (!item) return;
            if (item.BetType != 7 && !item.EachWaySingleIncluded) return;

            var currentDeposit = this.VariationsDeposits[key];

            if (item.isSPSelection()) {
                item.EWOdds = 0;
                item.EWGain = 0;
            }
            else
            {
                item.recalcEWGain(currentDeposit);
            }
        }
        else
        {
            ComboSPSlipMode.prototype.recalcEachWay.call(this);
        }
    },

    CheckForEachWayIncluded: function (key)
    {
        if (this.isSingleMode())
        {
            for (var idx in BetSlip.Selections)
            {
                var item = BetSlip.Selections[idx];
                if (item.BetType == 7 && item.EachWayIncluded && (typeof key === "undefined" || idx == key))
                {
                    return true;
                }
            }
            return false;
        }
        else
        {
            return ComboNoCombinationSPSlipMode.prototype.CheckForEachWayIncluded.call(this, key);
        }
    },

    getEachWayTotalGain: function ()
    {
        if (this.isSingleMode())
        {
            if (Array.getLength(this.VariationsDeposits) == 0) return 0;
            var eachWayTotalGain = 0;
            var currentDeposit = 0;

            for (var idx in BetSlip.Selections)
            {
                var item = BetSlip.Selections[idx];

                if (item.BetType != 7) continue;
                if (item.EachWaySingleIncluded == false) continue;

                currentDeposit = this.VariationsDeposits[item.ViewKey];

                if (typeof (currentDeposit) == "undefined" || currentDeposit <= 0) continue;

                if (item.isSPSelection())
                {
                    item.EWDeposit = currentDeposit;
                    return 0;
                }

                currDeposit = UserInfo.TaxProvider.applyTaxToDeposit(currentDeposit);
                item.recalcEWGain(currentDeposit);

                eachWayTotalGain += item.EWGain;
            }

            var roundedGain = Math.floor((eachWayTotalGain * 100).toFixed(4)) / 100;
            return roundedGain;
        }
        else
        {
            return ComboSPSlipMode.prototype.getEachWayTotalGain.call(this);
        }
    },

    getCurrentDeposit: function (deposit)
    {
        return UserInfo.TaxProvider.applyTaxToDeposit(deposit);
    }

}, TComboNoCombinationSingleSPSlipMode);

window.ComboNoCombinationSingleSPSlipMode = ComboNoCombinationSingleSPSlipMode;