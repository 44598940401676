function ComboSPSlipMode()
{
    BaseSPSlipMode.call(this);

    this.Deposit = 0;
    this.VariationsDeposits = [];
    this.Variants = [];
    this.Odds = [];
    this.SelectionsInCombo = [];
    this.ExcludedSelections = [];
    this.StakesUpdatedByUser = [];
    this.StakesUpdatedByUserForDropDown = [];
    this.SetFromComboPromotion = 0;
    this.Order = 2;
    this.ID = "combo";
    this.Name = $dict.bs("ComboBetTab");
    this.TabClass = "combo betting_slip_nbs";
    
    this.EWOdds = [];
    this.FreeBets = [];

    this.HasBeenActivated = false;

    this.ComboNoCombinations = false;
    this.MaxPermutationsPerBet = 3500;

    this.MinSelections = 2;
    this.ActivationRank = 20;
    this.PurchaseTypeID = SPPurchaseType.Combo;
    this.NonCombinableStates = {};
    this.isGeneratedCombo = false;
    this.OriginalStake = 0;
    this.populateNonCombinableStates();
    this.calculatedOdds = {};
    this.calculatedOddsEW = {};
}

$.extend(ComboSPSlipMode.prototype, BaseSPSlipMode.prototype,
{
    activate: function ()
    {
        this.Active = true;
		this.HasBeenActivated = true;
    },

    canActivate: function ()
    {
        //if (BetSlip.CheckForCastSelectionIncluded())
        //    return 0;

        var comboGroups = BetSlip.SwitchBackToSingleMode ? this.getSelectionGroups(true) : this.getSelectionGroups(false);
        var comboGroupsLen = Array.getLength(comboGroups);

        this.MinSelections = (typeof (BetSlipRegulations) !== "undefined") && BetSlipRegulations.hasOwnProperty('MinComboSelections') && !this.ComboNoCombinations ?
            BetSlipRegulations.MinComboSelections :
            this.MinSelections;

        if (comboGroupsLen >= this.MinSelections)
        {
            return this.ActivationRank;
        }
        else if (!BetSlip.SwitchBackToSingleMode && this.HasBeenActivated && comboGroupsLen >= 1 && comboGroupsLen < this.MinSelections)
        {
            // Stay on combo if the user removes one selection
            return this.ActivationRank;
        }
        else if (!BetSlip.SwitchBackToSingleMode && comboGroupsLen == 0 && Array.getLength(BetSlip.Selections) > 0 && this.HasBeenActivated)
        {
            // This check stops the user from being automatically navigated away from the combo tab if they uncheck some selections
            // so that no selections on the combo remain marked. This can happen because the selections are updated on some period of time
            // after which BetSlip.autoSelectMode() (which calls this method) is called to determine the current tab.
            return this.ActivationRank;
        }
        else
        {
            return 0;
        }
    },

    canMakeGroups: function ()
    {
        var comboGroups = this.getSelectionGroups(false);
        var comboGroupsLen = Array.getLength(comboGroups);

        if (comboGroupsLen >= this.MinSelections)
        {
            return true;
        }
        else
        {
            return false;
        }
    },
    isComboSystemOrTeaserBet: function ()
    {
        return true;
    },

    getStartIndex: function ()
    {
        //For Combo it should be 2, because single selection cannot be placed
        return 2;
    },

    canPlaceBet: function ()
    {
        var selInCombo = this.getSelectionsViewKeysInCombo();
        return (Array.getLength(selInCombo) >= this.MinSelections);
    },

    deselectStakeDropdown: function (key)
    {
        if (!BetSlip.isDefaultStakeAndStakeValuesAvailable()) return;
        if (key)
        {
            var dropDown = document.getElementById("dropdown" + key);
            if (dropDown && dropDown.selectedIndex != -1)
            {
                dropDown.options[dropDown.selectedIndex].selected = false;
                dropDown.value = 0;
                dropDown.selectedIndex = -1;
            }
        }
        else
        {
            for (var i = 2 ; i <= this.Variants.length; i++)
            {
                var dropDown = document.getElementById("dropdown" + i);
                if (dropDown && dropDown.selectedIndex != -1)
                {
                    dropDown.options[dropDown.selectedIndex].selected = false;
                    dropDown.value = 0;
                    dropDown.selectedIndex = -1;
                }
            }
        }
    },

    rebuildSelections: function ()
    {
        this.SelectionsInCombo = [];
        for (var i in BetSlip.Selections)
        {
            var item = BetSlip.Selections[i];
            this.addItem(item);
        }

        if (!this.Active) return;

        this.updateView();
    },

    addItem: function (item)
    {
        if (item.CastSelection && !item.VisibleCastSelection) return;
        if (this.isEnabledForItem(item) && !this.getComboAddError(item) && !this.ExcludedSelections[item.ViewKey])
        {
            var groupKey = item.getComboGroupKey();

            if (!this.SelectionsInCombo[groupKey])
                this.SelectionsInCombo[groupKey] = [];

            this.SelectionsInCombo[groupKey].push(item.ViewKey);

            this.removeSelectionCountError();
        }
        this.updateView();
    },

    selectionAdded: function (item)
    {
        this.addItem(item);

        if (!this.Active) return;

        this.updateView();
    },

    selectionUpdated: function (item, initcall)
    {
        var err = this.getComboAddError(item);
        if (!err)
        {
            if (!this.SelectionsInCombo.any(function (array) { return array.any(function (element) { return element == item.viewKey; }); }) && initcall)
            {
                var groupKey = item.getComboGroupKey();
                var arr = this.SelectionsInCombo[groupKey];
                if (!arr) arr = this.SelectionsInCombo[groupKey] = [];
                if (!arr.contains(item.ViewKey)) arr.push(item.ViewKey);

                this.removeSelectionCountError();
            }
        }
        else
        {
            this.proceedComboAddError(item);
        }

        if (!this.Active) return;

        if (BetSlip.CheckForEachWayIncluded() && item.BetType == 7 && ComboSPSlipMode.isEachWayEnabled(item))
        {
            item.EachWayIncluded = true;
            item.EachWayMultiplesIncluded = true;
            this.recalcEachWay();
        }

        if (initcall)
        {
            this.updateView();
        }
        else if (item.Updated || !item.Valid)
        {
            // updateSelection() redraws the selection so if the selection is updated we need to redraw it
            // otherwise old odds or points would remain unchanged on the screen. Then updateSelectionData()
            // would make those odds or points highlighted and blinking.
            this.updateSelection(item);
            this.updateSelectionData(item);
        }
        else
            this.updateSelectionData(item);

        this.updateViewOptions();
        BetSlip.updateBSFooter();
    },

    proceedComboAddError: function (item)
    {
        this.ExcludedSelections[item.ViewKey] = true;
    },

    calculateLoyaltyPoints: function ()
    {
        return LoyaltyProgramCalculations.calculationsForCombo();
    },

    focusOnStakeBox: function ()
    {
        var selectionsCount = Array.getLength(this.getSelectionsInCombo()),
            stakeElement = document.getElementById('stakebox_' + selectionsCount);

        stakeElement && stakeElement.focus();
    },

    manageNoComboSelectionFlags: function ()
    {   //The isNoComboAllowed is used only to apply highlighting to certain selections where combo options are limited
        BetSlip.Selections.each(function (s) { s.isNoComboAllowed = false; });
        var ComboGroups = this.getSelectionGroups();
        for (var key in ComboGroups)
        {
            var cgroup = ComboGroups[key];
            if (Array.getLength(cgroup) > 1)
            {
                for (var key2 in cgroup)
                {
                    cgroup[key2].isNoComboAllowed = true;
                }
            }
            else
            {
                for (var key3 in cgroup)
                {
                    cgroup[key3].isNoComboAllowed = false;
                }
            }
        }

        var outsideSelections = this.getSelectionsOutsideCombo();
        for (var i in outsideSelections)
        {
            var sel = outsideSelections[i];
            var noComboSel = this.getComboAddError(sel, true);
            if (!noComboSel || sel.equals(noComboSel)) continue;

            sel.isNoComboAllowed = true;
            noComboSel.isNoComboAllowed = true;
        }
    },

    selectionRemoved: function (item)
    {
        var groupKey = item.getComboGroupKey();
        if (this.SelectionsInCombo[groupKey])
        {
            this.SelectionsInCombo[groupKey].remove(item.ViewKey);
            if (Array.getLength(this.SelectionsInCombo[groupKey]) == 0)
                delete this.SelectionsInCombo[groupKey];
        }

        this.calcVariants();

        // Clear deposit if the user removes a selection and the selections in the combo tab become less than 2
        // and also remove unnecessary stakes from the VariationDeposits array which is used when placing a bet
        this.clearDeposits();

        // Remove message "Please enter stake" when there is only 1 selection remaining in the betslip
        var selInCombo = this.getSelectionsViewKeysInCombo();
        if (Array.getLength(selInCombo) == 1 && this.CurrentError == $dict.bs("InvalidBet"))
        {
            this.CurrentError = false;
        }

        // Remove message "One of the events you have selected is suspended or invalid!" if all remaining selections are valid
        if (this.CurrentError == $dict.bs("ComboEventDanger") && Array.find(this.getSelectionsInCombo(), function (sel) { return !(sel.Valid && !sel.Error); }) == null)
        {
            this.CurrentError = false;
        }

        // If the user removes the last selection from the slip in the slip act as if the tab has never been opened, i.e. as if the slip was cleared
        if (Array.getLength(BetSlip.Selections) == 0)
        {
            this.HasBeenActivated = false;
        }

        if (!this.Active) return;

        //clear ew
        if (BetSlip.CheckForEachWayIncluded()) this.recalcEachWay();

        this.updateView();
    },

    clear: function ()
    {
        this.VariationsDeposits = [];
        this.Variants = [];
        this.Deposit = 0;
        this.Odds = [];
        this.SelectionsInCombo = [];
        this.ExcludedSelections = [];
        this.Errors = [];
        this.FreeBets = [];

        this.CurrentError = false;
        this.SetFromComboPromotion = 0;
        this.SelectedEWVariants = [];
        this.EWVariants = [];
        this.EWOdds = [];
        this.HasBeenActivated = false;
        this.IsBetInProcess = false;
        this.StakesUpdatedByUser = [];
        this.StakesUpdatedByUserForDropDown = [];
        this.OriginalStake = 0;

        this.updateView();
        BetSlip.updateBSFooter();
    },

    deselectStakeDropdown: function (key)
    {
        if (!BetSlip.isDefaultStakeAndStakeValuesAvailable()) return;
        if (key)
        {
            var dropDown = document.getElementById("dropdown" + key);
            if (dropDown && dropDown.selectedIndex != -1)
            {
                dropDown.options[dropDown.selectedIndex].selected = false;
                dropDown.value = 0;
                dropDown.selectedIndex = -1;
            }
        }
        else
        {
            for (var i = 2 ; i <= this.Variants.length; i++)
            {
                var dropDown = document.getElementById("dropdown" + i);
                if (dropDown && dropDown.selectedIndex != -1)
                {
                    dropDown.options[dropDown.selectedIndex].selected = false;
                    dropDown.value = 0;
                    dropDown.selectedIndex = -1;
                }
            }
        }
    },

    getSelectionsInCombo: function ()
    {
        var selections = [];
        var ref = this;
        this.SelectionsInCombo.each(function (group)
        {
            group.each(function (viewKey)
            {
                var selection = Array.find(BetSlip.Selections, function (el) { return el.ViewKey == viewKey; });
                if (selection && !ref.ExcludedSelections[viewKey])
                {
                    selections[selection.ViewKey] = selection;
                }
            });
        });

        return selections;
    },

    getSelections: function ()
    {
        return this.getSelectionsInCombo();
    },

    getSelectionsOutsideCombo: function ()
    {
        var selections = [];
        for (var viewKey in BetSlip.Selections)
        {
            var sel = BetSlip.Selections[viewKey];

            if (this.ExcludedSelections[viewKey])
                selections.push(sel);
        }

        return selections;
    },

    getSelectionsViewKeysInCombo: function ()
    {
        var viewkeys = [];
        var selections = this.getSelectionsInCombo();
        selections.each(function (selection)
        {
            viewkeys.push(selection.ViewKey);
        });

        return viewkeys;
    },

    showComboNotAllowedMessage: function ()
    {
        return BetSlip.Selections.any(function (sel) { return sel.isNoComboAllowed == true; });
    },

    isComboAllowed: function ()
    {
        return !BetSlip.Selections.any(function (sel) { return sel.isNoComboAllowed == true; });
    },

    setDeposit: function (viewKey, deposit)
    {
        if (this.IsBetInProcess) return;

        if (typeof deposit === "string")
        {
            deposit = BetSlip.SanitizeNumberString(deposit);
        }
        if (isNaN(deposit))
        {
            deposit = 0;
        }

        this.VariationsDeposits[viewKey] = deposit;

        BetSlip.updateBSFooter();
        BetSlip.saveState();

        var toReturnValue = this.getComboVariantReturn(viewKey) || '',
          returnBox = document.getElementById("combo_return_value_{0}".format(viewKey));

        if (returnBox)
        {
            returnBox.innerHTML = toReturnValue;
        }
    },

    getCurrentDeposit: function (deposit)
    {
        return UserInfo.TaxProvider.applyTaxToDeposit(deposit);
    },

    setDefaultStake: function (options)
    {
        var isComboUpdatedByUser = BetSlip.CurrentMode.StakesUpdatedByUser.any(function (stake) { return stake; })
        var isSetByFreeBet = this.FreeBets[options.key] instanceof Object;
        if (!isSetByFreeBet && !isComboUpdatedByUser && BetSlip.CurrentMode.Variants && BetSlip.CurrentMode.Variants.length - 1 == options.key)
        {
            options.deposit = BetSlip.getUserCurrency().DefaultStakeForMultiples || BetSlip.getCurrencyWithStakeValues().DefaultStakeForMultiples || 0;
        }
        else if (!isComboUpdatedByUser && !isSetByFreeBet)
        {
            options.deposit = 0;
        }
        else
        {
            options.deposit = options.deposit || 0;
        }

        return options.deposit;
    },

    getNumberOfBets: function (varKey, excludeEW)
    {
        var d = 0;

        if (varKey)
        {
            d += this.Variants[varKey];
            if (!excludeEW && this.SelectedEWVariants[varKey] == true && this.EWVariants[varKey])
                d += this.EWVariants[varKey];
        }
        else
        {
            for (var key in this.VariationsDeposits)
            {
                if (!this.VariationsDeposits[key]) continue;
                d += this.Variants[key];
                if (!excludeEW && this.SelectedEWVariants[key] == true && this.EWVariants[key])
                    d += this.EWVariants[key];
            }
        }
        return d;
    },

    getTotalDeposit: function ()
    {
        var totalDeposit = 0,
            numberOfBets = 0;

        for (var key in this.VariationsDeposits)
        {
            numberOfBets = IsUKBetSlip ? ComboSPSlipMode.prototype.getNumberOfBets.call(this, key) : this.getNumberOfBets(key);
            totalDeposit += this.VariationsDeposits[key] * numberOfBets;
        }

        return totalDeposit;
    },

    getRiskFreeBetDeposit: function ()
    {
        var sum = 0;

        if (IsUKBetSlip)
        {
            sum += SingleSPSlipMode.prototype.getRiskFreeBetDeposit.call(this);
        }

        for (var ck in this.FreeBets)
        {
            var freeBet = this.FreeBets[ck];
            if (!freeBet) continue;

            var dep = this.VariationsDeposits[ck];
            sum += freeBet.getRiskFreeBetDeposit(dep);
        }

        return sum;
    },

    getGainPerBetType: function (startIndex)
    {
        if (!startIndex)
        {
            startIndex = this.getStartIndex();
        }

        var betTypesGain = {};
        var currDeposit = 0;
        var extraWinnings = 0;
        var freebetAmount = 0;

        for (var key in this.VariationsDeposits)
        {
            if (key < startIndex) continue;

            currDeposit = this.VariationsDeposits[key];
            currDeposit = UserInfo.TaxProvider.applyTaxToDeposit(currDeposit);
            extraWinnings = this.isComboBonusAvailable(key) ? ComboBonusProvider.ComboBonuses[key].AdditionalOdds * currDeposit : 0;
            freebetAmount = this.FreeBets[key] && !this.FreeBets[key].IsRiskFreeBet ? this.FreeBets[key].Amount : 0;

            betTypesGain[key] = this.calcComboGain(parseInt(key), currDeposit) + extraWinnings - freebetAmount;
        }

        return betTypesGain;
    },

    getTotalGain: function (startIndex)
    {
        if (!startIndex)
        {
            startIndex = this.getStartIndex();
        }

        BetSlip.lastUpdate = new Date();

        var totalGain = 0;
        var currDeposit = 0;
        var roundingMode = typeof BetSlip != "undefined" && BetSlip.GainRoundingMode || 0;

        for (var key in this.VariationsDeposits)
        {
            if (key < startIndex) continue;

            currDeposit = UserInfo.TaxProvider.applyTaxToDeposit(this.VariationsDeposits[key]);

            totalGain += this.calcComboGain(parseInt(key) , currDeposit);
        }
        var roundedGain = GainUtil.round(totalGain, roundingMode, 6);

        return roundedGain;
    },

    getTotalGainWithBonuses: function (startIndex)
    {
        if (!startIndex)
        {
            startIndex = 2;
        }

        BetSlip.lastUpdate = new Date();

        var totalGainWithBonuses = 0;
        var currDeposit = 0;
        var roundingMode = typeof BetSlip != "undefined" && BetSlip.GainRoundingMode || 0;

        for (var key in this.VariationsDeposits)
        {
            if (key < startIndex) continue;

            currDeposit = UserInfo.TaxProvider.applyTaxToDeposit(this.VariationsDeposits[key]);

            if (this.isComboBonusAvailable(key) && !this.FreeBets[key] && !this.SelectedEWVariants[key])
            {
                totalGainWithBonuses += GainUtil.round(ComboBonusProvider.ComboBonuses[key].ComboBonusOdds * currDeposit, roundingMode);
            }
            else
            {
                totalGainWithBonuses += this.calcComboGain(parseInt(key), currDeposit);
            }
        }

        var roundedGain = GainUtil.round(totalGainWithBonuses, roundingMode);

        return roundedGain;
    },

    getMaxGainCoef: function (variantKey, startIndex)
    {
        if (!startIndex)
        {
            startIndex = 2;
        }

        var result = 0,
            numOfBets = 0;

        for (var key in this.Variants)
        {
            if (key < startIndex) continue;
            numOfBets = IsUKBetSlip ? ComboSPSlipMode.prototype.getNumberOfBets.call(this, key, true) : this.getNumberOfBets(key, true);
            var gain = this.calcGain(this.Odds[key], 1) - numOfBets;
            if ((!variantKey || variantKey == key)
                && (!result || gain > result))
            {
                result = gain;
            }
        }


        return result;
    },

    getMaxReturnCoef: function (variantKey)
    {
        return this.getMaxGainCoef(variantKey) + this.getNumberOfBets(variantKey, true);
    },

    updateView: function ()
    {
        if (!this.Active) return;

        var __html = [], containerElement = document.getElementById("bet-slip-container");
        if (containerElement)
        {
            this.buildInnerView(__html);
            containerElement.innerHTML = __html.join("");
        }

        // update options
        this.updateViewOptions();

        BetSlip.updateScrollbar();
    },

    updateViewOptions: function (optionsBlockId)
    {
        if (!optionsBlockId)
        {
            optionsBlockId = "combo-options";
        }
        var optionsHtml = "", optionsElement = document.getElementById(optionsBlockId) || document.getElementById("combo_options"),
            activeElementID = (document.activeElement && document.activeElement.tagName && document.activeElement.tagName.toLowerCase() === 'input') ? document.activeElement.id : "",
            activeElementValue = (document.activeElement && document.activeElement.tagName && document.activeElement.tagName.toLowerCase() === 'input') ? document.activeElement.value : "";
        var shouldFocusElement = document.activeElement && typeof (document.activeElement.getBoundingClientRect) == 'function' ? document.activeElement.getBoundingClientRect() : null;

        if (optionsElement)
        {
            if (typeof this.buildOptions == "function")
            {
                optionsHtml = this.buildOptions(Array.getValues(BetSlip.Selections));
                if (optionsHtml) {
                    optionsElement.outerHTML = optionsHtml;
                }
            }

            QuickStake.isAvailable() && QuickStake.bindButtonsClickFunctionality();
        }

        this.deselectStakeDropdown();

        this.setFocusOnStakeBox(activeElementID, activeElementValue, shouldFocusElement);
    },

    getSelectionGroups: function (includeAll)
    {
        if (includeAll)
        {
            var selections = Array.getValues(BetSlip.Selections);
            for (var i = 0; i < selections.length; i += 1)
            {
                var cannotCombine = (selections[i].VisibleCastSelection === false && selections[i].CastSelection) ||
                    selections[i] instanceof MultiLineItem 
                if (cannotCombine)
                {
                    delete selections[i];
                }
            }
        }
        else
        {
            var selections = this.getSelectionsInCombo();
        }
        // populate selection groups
        var selectionGroups = [];
        for (var i in selections)
        {
            var selection = selections[i];
            var groupKey = selection.getComboGroupKey();
            if (typeof selectionGroups[groupKey] == 'undefined')
            {
                selectionGroups[groupKey] = [];
            }
            selectionGroups[groupKey].push(selection);
        }

        return selectionGroups;
    },

    updateSelectionData: function (selection)
    {
        var oddsElement = document.getElementById("cOddsToWhow_" + selection.ViewKey),
            pointsElement = document.getElementById("cPoints_" + selection.ViewKey),
            comboSingleOddsElement = document.getElementById("comboSingleOdds");

        this.setLastSelectionUpdateProps(selection);
        this.setElementsBlinking(selection.ViewKey);

        if (selection.UpdatedOdds && comboSingleOddsElement)
        {
            comboSingleOddsElement.innerHTML = Odds.format(this.getOdds(), BetSlip.getAlternativeOddsStyle());
        }

        if (selection.BetTypeID != 1 && selection.SplitType != 4)
        {
            var pts = selection.IsBuyPoint ? selection.BuyPointsPoints : selection.Points;
            if (oddsElement) oddsElement.innerHTML = this.formatSelectionOddsForSlip(selection);

            if (typeof (pts) != "undefined" && pointsElement)
            {
                disableAsianStyleForBetSlip = (disableAsianStyleForBetSlip && isAsianView);
                pointsElement.innerHTML = selection.BetTypeID == 2 ? Odds.formatPoints(pts, undefined, undefined, undefined, disableAsianStyleForBetSlip) : Odds.formatPoints(pts, true, undefined, undefined, disableAsianStyleForBetSlip);
            }
        }

        this.removeOverlayFromLiveSelection(selection);
    },

    serialize: function ()
    {
        return {
            ID: this.ID,
            Deposit: this.Deposit,
            VariationsDeposits: this.VariationsDeposits,
            ExcludedSelections: this.ExcludedSelections,
            HasBeenActivated: this.HasBeenActivated,
            FreeBets: this.serializeFreeBets(),
            StakesUpdatedByUser: this.StakesUpdatedByUser,
            StakesUpdatedByUserForDropDown: this.StakesUpdatedByUserForDropDown,
            EWVariants: this.EWVariants,
            SelectedEWVariants: this.SelectedEWVariants,
            OriginalStake: this.OriginalStake
        };
    },

    deserialize: function (data)
    {
        this.ID = data.ID;
        this.Deposit = data.Deposit;
        this.VariationsDeposits = data.VariationsDeposits;
        if (typeof this.VariationsDeposits == "undefined") this.VariationsDeposits = [];
        this.Variants = data.Variants;
        if (typeof this.Variants == "undefined") this.Variants = [];
        this.HasBeenActivated = data.HasBeenActivated;
        this.ExcludedSelections = data.ExcludedSelections;
        if (typeof this.ExcludedSelections == "undefined") this.ExcludedSelections = [];
        this.FreeBets = this.deserializeFreeBets(data.FreeBets);
        this.StakesUpdatedByUser = data.StakesUpdatedByUser;
        this.StakesUpdatedByUserForDropDown = data.StakesUpdatedByUserForDropDown;
        this.EWVariants = data.EWVariants;
        if (typeof this.EWVariants == "undefined") this.EWVariants = [];
        this.SelectedEWVariants = data.SelectedEWVariants;
        if (typeof this.SelectedEWVariants == "undefined") this.SelectedEWVariants = [];
        this.OriginalStake = data.OriginalStake;
        if (typeof this.OriginalStake == "undefined") this.OriginalStake = 0;

        this.rebuildSelections();
    },

    calcVariants: function ()
    {
        var inlines = this.getSelectionsInCombo();
        if (Array.getLength(inlines) == 0)
        {
            this.Variants = [];
            this.cleanDeposits();
            return;
        }
        
        var maxComboKey = Array.getLength(this.Variants) - 1;

        var eachWayEnabled = inlines.any(function (ln) { return ComboSPSlipMode.isEachWayEnabled(ln) });

        var comboGroups = this.getSelectionGroups();

        var countArray = comboGroups.select(function (grp) { return Array.getLength(grp); });
        var oddsArray = [];
        var oddsArrayEW = [];
        this.calculateOdds(comboGroups, eachWayEnabled);
        for (var i in comboGroups)
        {
            var lines = comboGroups[i];
            var sum = 0;
            var sumEW = 0;

            for (var lIdx in lines)
            {
                var line = lines[lIdx];
                var EUodds = line.getRealOdd(BetSlip.BaseOddsRoundingMode);
                sum += EUodds;

                if (!EUodds && line.RealOrPresumptiveOdds)
                    sum += BetMath.roundDecimalOdds(line.RealOrPresumptiveOdds, BetSlip.BaseOddsRoundingMode, !BetSlip.ForceTwoDigitsForOddsRounding);

                if (eachWayEnabled)
                {
                    var ewOdds = ComboSPSlipMode.isEachWayEnabled(line) && line.getRealOddEW ? line.getRealOddEW(BetSlip.BaseOddsRoundingMode) : EUodds;
                    sumEW += ewOdds;
                    if (!ewOdds && line.RealOrPresumptiveOdds)
                        sumEW += BetMath.roundDecimalOdds(line.RealOrPresumptiveOdds, BetSlip.BaseOddsRoundingMode, !BetSlip.ForceTwoDigitsForOddsRounding);
                }
            }

            oddsArray.push(sum);
            oddsArrayEW.push(sumEW);
        }              

        this.Variants = BetMath.sumOfProducts(countArray);

        if (typeof this.addBankersVariants === "function" && this.BankersCount > 0)
        {
            this.addBankersVariants();
        }

        this.Odds = BetMath.sumOfProducts(oddsArray);

        if (typeof this.addBankersOdds === "function" && this.BankersCount > 0)
        {
            this.addBankersOdds(this.getSelectionGroups(true));
        }
        
        this.applyComboOddsRounding(this.Odds);

        // clean any deposits for no longer available variants
        this.cleanDeposits();

        if (eachWayEnabled)
        {
            this.EWVariants = BetMath.sumOfProducts(countArray);
            this.EWOdds = BetMath.sumOfProducts(oddsArrayEW);
        }
        else
        {
            this.EWVariants = [];
            this.EWOdds = [];
        }

        if (!this.Variants[maxComboKey])
        {
            // remove the flag that the user updated the combo amount for the current max combo key
            if (this.StakesUpdatedByUserForDropDown && BetSlip.isDefaultStakeAndStakeValuesAvailable())
            {   //Combo slip stake drop-downs
                this.StakesUpdatedByUserForDropDown[maxComboKey] = null;
            }

            if (this.StakesUpdatedByUser)
            {   //Combo slip free bet drop-down
                this.StakesUpdatedByUser[maxComboKey] = null;
            }

            if (this.FreeBets)
            {   // remove FreeBet for the current max combo key
                this.FreeBets[maxComboKey] = null;
            }
        }
    },

    cleanDeposits: function()
    {
        for (var key in this.VariationsDeposits)
        {
            if (!this.Variants[key])
                delete this.VariationsDeposits[key];
        }
    },

    calcEWVariants: function ()
    {   //For backward compatibility
        this.calcVariants();
    },

    applyComboOddsRounding: function (comboOddsArray)
    {
        if (BetSlip.ComboOddsRoundingMode)
        {
            for (var oidx in comboOddsArray)
            {
                comboOddsArray[oidx] = BetMath.roundDecimalOdds(comboOddsArray[oidx], BetSlip.ComboOddsRoundingMode, !BetSlip.ForceTwoDigitsForOddsRounding);
            }
        }
    },

    initBetNames: function (betNamePrefix, startIndex)
    {
        if (!betNamePrefix)
        {
            betNamePrefix = "ComboNames_";
        }
        if (!startIndex)
        {
            startIndex = this.getStartIndex();
        }

        if (!this.ComboNames)
        {
            this.ComboNames = [];
            for (var key = startIndex; key <= BetSlip.maxSelectionsNumber; key++)
            {
                var dictKey = betNamePrefix + key;
                var comboName = $dict.bs(dictKey);
                if (comboName == dictKey)
                {   //Combo name is not in dictionary
                    comboName = "";
                }

                this.ComboNames[key] = comboName;
            }
        }
    },

    // Decimal style odds must be displayed when the current odds-style is Malaysian odds-style or Indo
    getAlternativeOddsStyle: function ()
    {
        if (currentOddStyle == OddStyle.MALAY || currentOddStyle == OddStyle.INDO || 
            (currentOddStyle == OddStyle.HONGKONG && !UseHKOddsStyleForAllMarkets))
            return OddStyle.EUROPEAN;

        return currentOddStyle;
    },

    getBetNames: function ()
    {
        return this.ComboNames;
    },

    getOddsSum: function ()
    {
        var lines = this.getSelectionsInCombo();
        var result = 0;
        for (var lIdx in lines)
        {
            var line = lines[lIdx];
            var EUodds = this.CheckForEachWayIncluded() && ComboSPSlipMode.isEachWayEnabled(line) && line.getRealOddEW
                ? line.getRealOddEW()
                : line.getRealOdd();

            result += EUodds - 1;
        }

        return result;
    },

    getMaxBetComboFromSSR: function (linesSafe, variantKey)
    {
        var totalOddsSum = this.getOddsSum();
        if (totalOddsSum > 0)
        {
            var ewIncluded = this.CheckForEachWayIncluded(variantKey);
            var getRealOdds = function (line)
            {
                return ewIncluded && ComboSPSlipMode.isEachWayEnabled(line) && line.getRealOddEW ? line.getRealOddEW() : line.getRealOdd();
            };

            var maxRiskComboFromSSR = Array.getMinValue(linesSafe
                , function (line) { return line.SingleSelectionRisk > 0 && line.MaxRisk > 0 && getRealOdds(line) > 0; }
                , function (line) { return line.SingleSelectionRisk * line.MaxRisk / ((getRealOdds(line) - 1) / totalOddsSum); })

            var divider = this.getMaxGainCoef(variantKey);    //this.getTotalOdds() - this.getTotalCountOfBets();
            return divider > 0 ? Math.floor(maxRiskComboFromSSR / divider) : 0;
        }

        return 0;
    },

    getComboMaxBet: function (lines, variantKey)
    {
        var linesSafe = lines || this.getSelectionsInCombo();
        var maxBetComboFromRisk = this.getComboMaxBetFromMaxRisk(linesSafe, variantKey);
        var maxBetComboFromMaxReturn = this.getComboMaxBetFromRegulationMaxReturn(linesSafe, variantKey);
        var maxBetProperty = this.isComboVariant(variantKey) ? 'MaxBetSystem' : 'MaxBetCombo';

        var maxBetCombo = this.CheckForEachWayIncluded(variantKey)
            ? Array.getMinValue(linesSafe, function (line) { return line.MaxStakeComboEachWay > 0; }, function (line) { return line.MaxStakeComboEachWay; })
            : Array.getMinValue(linesSafe, function (line) { return line[maxBetProperty] > 0; }, function (line) { return line[maxBetProperty]; });

        var maxBetComboFromSSR = this.getMaxBetComboFromSSR(linesSafe, variantKey);
        maxBetCombo = this.getArrayMinNonZeroValue([maxBetCombo, maxBetComboFromRisk, maxBetComboFromMaxReturn]);

        if (maxBetCombo > 0 && BetSlip.StakeRounding == BetSlip.StakeRoundingMethods.ZeroDecimalPoints)
        {
            maxBetCombo = Math.floor(maxBetCombo);
        }
        else
        {
            maxBetCombo = Math.floor(maxBetCombo * 100) / 100;
        }

        if (!maxBetCombo)
        {
            // fallback ots
            maxBetCombo = Array.getMinValue(linesSafe, function (line) { return line[maxBetProperty] > 0; }, function (line) { return line[maxBetProperty]; });
        }

        if (typeof (BetSlipRegulations) !== "undefined")
        {
            if (!this.isComboVariant(variantKey) && BetSlipRegulations.hasOwnProperty('StraightComboStakeIncrement'))
            {
                var increment = BetSlipRegulations.StraightComboStakeIncrement;
                maxBetCombo = this.applyStakeIncrement(maxBetCombo, increment);
            }
        }

        return maxBetCombo;
    },

    getComboMaxBetFromMaxRisk: function (linesSafe, variantKey)
    {
        var maxRiskProperty = this.isComboVariant(variantKey) ? 'MaxRiskSystem' : 'MaxRiskCombo';
        var maxRiskCombo = this.CheckForEachWayIncluded(variantKey)
            ? Array.getMinValue(linesSafe, function (line) { return line.MaxRiskComboEachWay > 0; }, function (line) { return line.MaxRiskComboEachWay; })
            : Array.getMinValue(linesSafe, function (line) { return line[maxRiskProperty] > 0; }, function (line) { return line[maxRiskProperty]; });

        var gainDivider = this.getMaxGainCoef(variantKey); //this.getTotalOdds() - this.getTotalCountOfBets();
        var maxBetComboFromRisk = maxRiskCombo && gainDivider > 0
            ? (maxRiskCombo / gainDivider)/* * (1 - ewGainPercentage) */
            : 0;

        //var variantsCount = this.getVariantsCount() || 1;
        return Math.floor(maxBetComboFromRisk /** variantsCount*/ * 10) / 10;
    },

    getComboMaxBetFromRegulationMaxReturn: function (linesSafe, variantKey)
    {
        if (typeof (BetSlipRegulations) === "undefined") return 0;

        var regulationComboMaxReturn = BetSlipRegulations.hasOwnProperty('ComboMaxReturn') ? BetSlipRegulations.ComboMaxReturn : 0;
        var regulationSystemMaxReturn = BetSlipRegulations.hasOwnProperty('SystemMaxReturn') ? BetSlipRegulations.SystemMaxReturn : 0;
        if (regulationComboMaxReturn == 0 && regulationSystemMaxReturn == 0) return 0;

        var maxComboOdd = 1;
        var biggestOddsComboLines = linesSafe.sort(function (a, b) { return b.Odds - a.Odds }).slice(0, variantKey);
        for (var i in biggestOddsComboLines)
        {
            maxComboOdd *= Odds.convertFromAmericanToDecimal(biggestOddsComboLines[i].Odds, true);
        }
        var maxComboBet = regulationComboMaxReturn > 0 ? regulationComboMaxReturn / maxComboOdd : 0;

        var isSystem = this.isComboVariant(variantKey);
        var returnCoef = this.getMaxReturnCoef(variantKey);
        var maxSystemBet = regulationSystemMaxReturn > 0 && isSystem ? regulationSystemMaxReturn / returnCoef : 0;

        maxReturn = Math.floor(this.getArrayMinNonZeroValue([maxComboBet, maxSystemBet]) * 10) / 10;

        return maxReturn;
    },

    isComboVariant: function (variantKey)
    {
        variantKey = variantKey * 1;
        return variantKey >= 2 && this.Variants && this.Variants[variantKey] > 1;
    },

    getSplitCount: function (basket)
    {
        var res = 0;
        var Lines = basket ? basket : this.getSelectionsInCombo();

        for (var key in Lines)
        {
            if (Lines[key].isSplit()) res++;
        }

        return res;
    },

    isLiveCombo: function (lines)
    {
        if (!lines)
        {
            lines = this.getSelectionsInCombo();
        }

        for (var key in lines)
        {
            if (lines[key].Live)
            {
                return true;
            }
        }

        return false;
    },

    hasVariantsDeposit: function ()
    {
        for (var key in this.VariationsDeposits)
        {
            if (this.VariationsDeposits[key] != null && this.VariationsDeposits[key] != 0) return true;
        }

        return false;
    },

    /*
    * Returns true if all lines in the combo are allowed for waiting bets.
    * Returns false if at least one line is not allowed for waiting bets.
    */
    getComboWaitingAllowed: function (lines)
    {
        if (!lines)
        {
            lines = this.getSelectionsInCombo();
        }

        if (Array.getLength(lines) == 0)
        {
            return false;
        }

        for (var key in lines)
        {
            if (!lines[key].waitingAllowed)
            {
                return false;
            }
        }

        return true;
    },

    getRate: function (lines, out)
    {
        var Lines = lines || this.getSelectionsInCombo();
        var rate = 1;

        for (var key in Lines) rate *= Math.floor((Lines[key].getRealOdd(BetSlip.BaseOddsRoundingMode, !BetSlip.ForceTwoDigitsForOddsRounding) * 1000).toPrecision(12)) / 1000;

        return this.countRateByUSOdds(rate, out);
    },

    countRateByUSOdds: function (rate, out)
    {
        if (BetSlip.ComboOddsRoundingMode)
        {
            rate = BetMath.roundDecimalOdds(rate, BetSlip.ComboOddsRoundingMode, !BetSlip.ForceTwoDigitsForOddsRounding);
        }
        else
        {
            rate = this.floorDecimal(rate);
        }

        var rateByUSOdds = Odds.decimalToAmerican(rate);
        if (out)
        {
            out.americanOdd = rateByUSOdds;
        }

        rateByUSOdds = Math.floor(((rateByUSOdds > 0 ? (rateByUSOdds / 100 + 1) : (1 - 100 / rateByUSOdds)) * 1000).toPrecision(12)) / 1000;

        if (rate <= rateByUSOdds)
        {
            rateByUSOdds = rate;
        }
        else
        {
            rateByUSOdds = Odds.decimalToAmerican(rate) + 1;
            if (out)
            {
                out.americanOdd = rateByUSOdds;
            }
            rateByUSOdds = Math.floor(((rateByUSOdds > 0 ? (rateByUSOdds / 100 + 1) : (1 - 100 / rateByUSOdds)) * 1000).toPrecision(12)) / 1000;
        }


        if (BetSlip.ComboOddsRoundingMode)
        {
            return BetMath.roundDecimalOdds(rateByUSOdds, BetSlip.ComboOddsRoundingMode, !BetSlip.ForceTwoDigitsForOddsRounding);
        }
        else
        {
            return this.floorDecimal(rateByUSOdds);
        }
    },

    check: function (errorMessageKey)
    {
        if (!errorMessageKey)
        {
            errorMessageKey = "SelectionsInCombo";
        }

        var Lines = this.getSelectionsInCombo();

        if (Array.getLength(Lines) < this.MinSelections)
        {
            this.setError($dict.bs(errorMessageKey));
            return false;
        }

        if (!this.validateMinBet())
        {
            return false;
        }

        var freeBetDeposit = this.getFreeBetDeposit(),
            fd = this.getTotalDeposit();

        if (fd <= 0)
        {
            this.setError($dict.bs("InvalidBet"));
            return false;
        }

        if ((!BetSlip.IsInEditMode) && (!BetSlip.SkipClientUserBalanceCheckForCombos && (fd - freeBetDeposit) > UserInfo.current.getBalance()))
        {
            if (typeof (QuickDepositBlock) != "undefined")
            {
                QuickDepositBlock.showDepositPopupIfNeed();
            }
            this.setError($dict.bs("NotEnoughFunds"));
            return false;
        }

        if (!this.validateRegulations())
        {
            return false;
        }

        return true;
    },

    validateMinBet: function ()
    {
        // overridden in CombinatorSPSlipMode
        var mx = this.getComboMinBet(this.getSelectionsInCombo(), true);

        for (var i in this.VariationsDeposits)
        {
            if (this.VariationsDeposits[i] === 0 || this.VariationsDeposits[i] === null) continue;

            if (typeof (BetSlipRegulations) !== "undefined")
            {
                if (BetSlipRegulations.IsItalian && this.ComboNoCombinations && this.Variants[i] !== 1 && this.VariationsDeposits[i] > 0)
                {
                    return false;
                }

                if (this.isUnderMinBetRegulation(this.VariationsDeposits[i], this.Variants[i], this.SelectedEWVariants[i]))
                {
                    return false;
                }
            }

            if (this.VariationsDeposits[i] > 0 && this.VariationsDeposits[i] < mx) // check min bet
            {
                this.setError($dict.bs("MinimalBet").concat(" " + MoneyFormat.format(mx)));
                return false;
            }

            if (this.FreeBets[i])
            {
                var realMoneyDeposit = this.VariationsDeposits[i] - this.FreeBets[i].Amount;
                if (realMoneyDeposit > 0 && realMoneyDeposit < mx)
                {
                    this.setError($dict.bs("MinimalBetOverFree").concat(" " + MoneyFormat.format(mx)));
                    return false;
                }
            }
        }

        return true;
    },

    validateItalianRegulation: function ()
    {
        if (typeof (BetSlipRegulations) === "undefined" || !BetSlipRegulations.IsItalian) return true;

        var hasAtLeastOnePermutation = false;

        for (var i in this.VariationsDeposits)
        {
            if (this.Variants[i] > 1 && this.VariationsDeposits[i])
            {
                hasAtLeastOnePermutation = true;
            }

            if (BetSlipRegulations.hasOwnProperty('PermutationStakeIncrement') || BetSlipRegulations.hasOwnProperty('StraightComboStakeIncrement'))
            {
                if (!this.validateStakeIncrement(this.VariationsDeposits[i], i, true)) return false;
            }
        }

        if (!this.ComboNoCombinations && BetSlipRegulations.hasOwnProperty('ComboWithCombinationtsMustHasPermutation')
            && BetSlipRegulations.ComboWithCombinationtsMustHasPermutation == true && !hasAtLeastOnePermutation)
        {
            this.setError($dict.bs("PlaceAtLeastOnePermutation"));
            return false;
        }

        if (BetSlipRegulations.IsItalian)
        {
            if (this.ComboNoCombinations && !this.isComboAllowed())
            {
                this.setError($dict.bs("NoStraightCombo"))
                return false;
            }
        }

        var fullDeposit = this.getTotalDeposit();

        if (BetSlipRegulations.hasOwnProperty('MinimumPurchaseStake') && fullDeposit < BetSlipRegulations.MinimumPurchaseStake)
        {
            this.setError($dict.bs("MinimumPurchaseStake").format("{0}{1}".format(BetSlip.UserCurrencyCode, BetSlip.formatMoneyWithRounding(BetSlipRegulations.MinimumPurchaseStake))));
            return false;
        }

        return true;
    },

    validateRegulations: function ()
    {
        var result;
        result = this.validateItalianRegulation() && this.validateCzechRegulations();
        return result;
    },

    validateCzechRegulations: function ()
    {
        if (typeof (BetSlipRegulations) !== "undefined" && BetSlipRegulations.isActive)
        {
            var fullDeposit = this.getTotalDeposit();
            var totalGain = this.getTotalGainWithBonuses() + this.getEachWayTotalGain();

            if (BetSlipRegulations.hasOwnProperty('MinimumPurchaseStake') && fullDeposit < BetSlipRegulations.MinimumPurchaseStake)
            {
                this.setError($dict.bs("UnderMinTotalCost"));
                return false;
            }

            if (BetSlipRegulations.hasOwnProperty('MaximumPurchaseWin') && this.isOverMaxWinRegulation(totalGain, fullDeposit))
            {
                this.setError($dict.bs("RegulationsMaxWin").format("{0}{1}".format(BetSlip.UserCurrencyCode, MoneyFormat.format(this.getRegulationMaximumPurchaseWin()))));
                return false;
            }
        }

        return true;
    },

    validateStakeIncrement: function (value, viewKey, updateView)
    {
        var increment = 0,
            message;

        if (!this.ComboNoCombinations)
        {
            increment = BetSlipRegulations.PermutationStakeIncrement || 0;
            message = $dict.bs("SystemStakeIncrement").format("{0}{1}".format(BetSlip.UserCurrencyCode, BetSlip.formatMoneyWithRounding(increment)));
        }
        else
        {
            increment = BetSlipRegulations.StraightComboStakeIncrement || 0;
            message = $dict.bs("StraightComboStakeIncrement").format("{0}{1}".format(BetSlip.UserCurrencyCode, BetSlip.formatMoneyWithRounding(increment)));
        }

        var isValid = (increment == 0) || (value % increment) === 0;
        this.validateStake(isValid, message, viewKey, updateView);

        return isValid;
    },

    isUnderMinBetRegulation: function (deposit, numberOfBets, isEachWay)
    {
        var depositWithEW = (isEachWay ? deposit * 2 : deposit) * numberOfBets;

        if (BetSlipRegulations.hasOwnProperty("MinimumComboBetStake") && (numberOfBets == 1 || (numberOfBets == 2 && isEachWay)) && depositWithEW < this.getRegulationComboMinBetStake())
        {
            this.setError($dict.bs("RegulationMinComboBetStake").format("{0}{1}".format(BetSlip.UserCurrencyCode, MoneyFormat.format(this.getRegulationComboMinBetStake()))));
            return true;
        }

        if (BetSlipRegulations.hasOwnProperty("MinimumSystemBetStake") && numberOfBets > 2 && depositWithEW < this.getRegulationSystemMinBetStake())
        {
            this.setError($dict.bs("RegulationMinSystemBetStake").format("{0}{1}".format(BetSlip.UserCurrencyCode, MoneyFormat.format(this.getRegulationSystemMinBetStake()))));
            return true;
        }
    },

    getComboPostRequest: function (selection)
    {
        //for live lines we also need danger and score
        var isLive = selection.Live ? selection.Live : false;
        var isDanger = selection.Danger ? selection.Danger : false;
        var score1 = selection.Score1 ? selection.Score1 : 0;
        var score2 = selection.Score2 ? selection.Score2 : 0;
        var betSide = selection.BetSide ? selection.BetSide : 0;

        var oddStyleIDFromClient = selection.getActualOddsStyleID();
        var oddsFromClient = (oddStyleIDFromClient == OddStyle.FRACTIONAL ? FractionalOddsConverter.encodeFraction(selection.getFormatedOdds()) : selection.getFormatedOdds() * 1);
        var qaParameter2 = selection.QAParameter2 == -1 ? 0 : selection.QAParameter2;
        var isEWIncluded = selection.BetType == 7 && selection.EachWayIncluded ? true : false;
        var ReleatedToID = -1;
        if (isEWIncluded)
        {
            var ReleatedToID = selection.ReleatedToID;
        }

        return [selection.LineGroupID,                                              //0
                selection.LineID,                                                   //1
                selection.Odds,                                                     //2
                selection.Points,                                                   //3
                selection.ComboRate,                                                //4
                selection.MasterEventID,                                            //5
                selection.LeagueID,                                                 //6
                selection.MaxBetCombo,                                              //7
                selection.EventID,                                                  //8
                selection.BetType == 7 ? selection.BetType : selection.BetTypeID,   //9
                isLive,                                                             //10
                isDanger,                                                           //11
                score1,                                                             //12
                score2,                                                             //13
                betSide,                                                            //14
                qaParameter2,                                                       //15
                selection.IsBuyPoint,                                               //16
                selection.BuyPointsOdds,                                            //17
                selection.BuyPointsPoints,                                          //18
                oddsFromClient,                                                     //19
                oddStyleIDFromClient,                                               //20
                isEWIncluded,                                                       //21
                ReleatedToID,                                                       //22
        ].join(",");
    },

    validateMaxBet: function ()
    {
        var deposits = Array.getValues(this.VariationsDeposits);
        var comboMaxBet = this.getComboMaxBet();

        for (var i = 0; i < deposits.length; i++)
        {
            var deposit = deposits[i];

            if (deposit > comboMaxBet)
            {
                this.setError($dict.bs("MaximalBetForCombo").format(MoneyFormat.format(Bets.roundMin(comboMaxBet))), true);
                return false;
            }
        }

        return true;
    },

    placeBets: function (startIndex)
    {
		if (BaseSPSlipMode.prototype.isGeolocationInProgress.call(this))
		{
			return;
		}

        if (!startIndex)
        {
            startIndex = this.getStartIndex();
        }

        if (this.IsBetInProcess) return;

        this.setBetInProcess(true, true);

        if (!this.check())
        {
            this.setBetInProcess(false, true);
            return;
        }

        var totals = BetSlip.getFooterTotals();
        if (UserInfo.TaxProvider.isUserUnderTax() && BetSlip.validatePurchaseWithCountryTaxes(totals.Taxes, this.getTotalDeposit()))
        {
            this.setBetInProcess(false, true);
            this.setError($dict.bs("CountryTaxInvalidOdds"));
            return;
        }

        if (this.SetFromComboPromotion)
        {
            Data.sendToGoogleAnalytics("Combo of The Day", "Bet Slip", this.Deposit);
            this.SetFromComboPromotion = 0;
        }

        var lines = [];
        var comboBonuses = {};
        
        var isLiveCombo = false;

        var selectionsInCombo = this.getSelectionsInCombo();
        var oddStyleIDFromClient = BetSlip.getAlternativeOddsStyle() || currentOddStyle;

        var ewIncluded = this.CheckForEachWayIncluded();

        if (ComboBonusProvider.ComboBonuses != null)
        {
            comboBonuses = ComboBonusProvider.ComboBonuses;
        }

        for (var key in selectionsInCombo)
        {
            if (!selectionsInCombo[key].Valid || selectionsInCombo[key].Danger)
            {
                this.setError($dict.bs("ComboEventDanger"));
                this.setBetInProcess(false, true);
                return;
            }

            if (selectionsInCombo[key].Live)
            {
                isLiveCombo = true;
            }

            selectionsInCombo[key].IsBanker = false;
            lines.push(selectionsInCombo[key]);
        }

        var deps = Array.findAll(this.VariationsDeposits, function (v) { return (v && v != 0); });
        var deposits = [];
        for (var i = startIndex; i < deps.length; i++)
            deposits[i] = deps[i] ? deps[i] : null;

        var freeBets = this.getComboFreeBets();

        var ref = this;

        BetSlip.disablePlaceBetButton();

        if (BetSlip.PlaceComboBetsWithMultiplePurchases)
        {
            var purchasesInformation = [];

            for (var key in deposits)
            {
                var current = [];
                if (deposits[key])
                {
                    current[key] = deposits[key];
                    purchasesInformation.push(this.formatPurchaseRequest(lines, null, current, null, null, null, null, null, freeBets, comboBonuses));
                }
            }

            BettingPageMethods.placeMultiPurchase(purchasesInformation,
               function (res)
               {
                   var purchases = eval(res);
                   var comboSelections = ref.getSelectionsInCombo();
                   ref.placePurchaseCallBack(purchases, comboSelections);
               },
               this.placePurchaseCallBackError,
               this
           );
        }
        else
        {
            BettingPageMethods.placeSinglePurchase(this.formatPurchaseRequest(lines, null, deposits, null, null, null, null, null, freeBets, comboBonuses),
                function (result)
                {
                    var purchases = [eval(result)];
                    ref.placePurchaseCallBack(purchases);
                },
			    this.placePurchaseCallBackError,
                this
		    );
        }



        // Suppress an unexpected JS error on updateView() so that it cannot stop the "bet in process" state
        try { this.updateView(); } catch (e) { if (console && console.log) console.log(e); }
    },

    clearErrors: function ()
    {
        this.CurrentError = false;

        var selections = this.getSelectionsInCombo();
        for (var key in selections)
        {
            selections[key].Error = false;
        }
    },

    removeSelectionCountError: function ()
    {
        // Remove message "You need to make at least 2 selections applicable for combo to place a bet!" if now there are two
        var selInCombo = this.getSelectionsViewKeysInCombo();
        if (this.CurrentError == $dict.bs("SelectionsInCombo") && Array.getLength(selInCombo) >= 2)
        {
            this.CurrentError = false;
        }
    },
    
    parsePlaceBets: function (result, keepPreviousPurchase, selectionsInCombo)
    {
        this.clearErrors();
        var bets = [];
        var selections = [];

        ComboBonusProvider.addRecalculatedComboBonuses(result.ComboBonuses);
        
        var selectionsInCombo = selectionsInCombo || this.getSelectionsInCombo();
        switch (result.Status)
        {
            case 1: { this.StakesUpdatedByUserForDropDown = []; }//Accepted
            case 3: //Waiting
                {
                    for (var k in result.Bets)
                    {
                        this.SaveReservedFreeBetsInStorage(result.Bets[k].FreeBetID);
                    }
                    this.StakesUpdatedByUserForDropDown = [];

                    var altOddStyle = this.getAlternativeOddsStyle();

                    SPPurchaseBuilder.createComboSlipPurchase(result,
                        selectionsInCombo,
                        keepPreviousPurchase,
                        false,
                        altOddStyle);

                    this.Deposit = 0;
                    this.VariationsDeposits = [];
                    this.OriginalStake = 0;

                    UserInfo.updateBalance();

                    break;
                }
            case 2: //Rejected
            case -10:
                {
                    this.setPurchaseError(result);
                    break;
                }
            case -1: //Selections Error
                {
                    var errorsHelper = new BetSlipErrorMessagesHelper();
                    for (var sIdx in result.Selections)
                    {
                        var line = result.Selections[sIdx];
                        var selection = Array.find(BetSlip.Selections, function (item) { return item.ViewKey == line.ViewKey; });
                        if (!selection) continue;

                        if (!line.IsEachWaySelection) selection.processBetUpdate(line);

                        if (line.Closed == 1)
                        {
                            line.ErrorText = $dict.bs("LineClosed");
                            this.CurrentError = $dict.bs("ComboEventDanger");
                            if (selection.Live)
                            {
                                selection.Valid = false;
                                selection.LineClosed = true;
                            }
                        }

                        var showError = (!BetSlip.isAlertMessageEnabled || (BetSlip.isAlertMessageEnabled && !selection.UpdatedOdds && !selection.UpdatedPoints))
                        if (showError)
                        {
                            selection.UpdatedOdds && (line.ErrorText = $dict.bs("OddsChanged"));
                            selection.UpdatedPoints && (line.ErrorText = $dict.bs("PointsChanged"));
                            selection.UpdatedOdds && selection.UpdatedPoints && (line.ErrorText = $dict.bs("AllChanged"));

                            //selection.Error = line.ErrorText;
                            selection.Error = this.translateBetSlipError(line, selection, false);
                        }

                        this.updateSelection(selection);
                        this.updateSelectionData(selection);

                        errorsHelper.AlertIfSelectionUpdated(selection);
                    }

                    break;
                }
            case -2: //Bets Error
                {
                    var bet = result.Bets[0];
                    this.CurrentError = this.translateBetSlipError(bet, null, true);

                    break;
                }
            case -4: //Betting limit error
                {
                    var errorText = $string('General').BettingLimitHit;
                    errorText = errorText.replace('$VALUE$', UserInfo.current.toUserFormatedCurrencyString(result.RemainingBettingLimit));
                    this.setError(errorText);
                    BetSlip.sendBetLimitsErrorToJSApi(result);
                    break;
                }
            case -18:
                {
                    var errorText = this.getVerificationErrorMessage(result.ErrorCode);
                    this.setError(errorText);
                    break;
                }
        }
    },

    // Adding a purchase remove the selections from the slip, so if there are any selections in the betslip then
    // they had not been checked before placing the bet so we try to add unchecked selections to combo tab after purchase
    // Please, see addPurchase() method in UniSlip.js
    addUncheckedAfterPurchase: function ()
    {
        var selInCombo = this.getSelectionsViewKeysInCombo();
        for (var viewKey in BetSlip.Selections)
        {
            var err = this.getComboAddError(BetSlip.Selections[viewKey]);
            if (!err && Array.indexOf(selInCombo, viewKey) == -1)
            {
                this.ExcludedSelections[viewKey] = false;
                this.addItem(BetSlip.Selections[viewKey]);
            }
        }
    },

    getOdds: function (oddStyle)
    {
        var out = { americanOdd: 0 };
        var fodd = this.getRate(undefined, out);
        if (oddStyle == OddStyle.EUROPEAN)
        {
            return fodd;
        }
        return Odds.convert(out.americanOdd, undefined, undefined, true, typeof oddStyle != "undefined" ? oddStyle : this.getAlternativeOddsStyle());
    },

    IsSelectionDisabled: function (viewKey)
    {
        var cbox = document.getElementById("combo_check_" + viewKey);
        if (cbox != null)
            return cbox.checked;
        return false;
    },

    IsSelectionInCombo: function (selection)
    {
        if (this.ExcludedSelections[selection.ViewKey] && !selection.VisibleCastSelection) return false;

        var selections = this.getSelectionsInCombo();
        if (Array.find(selections, function (sel) { return sel.ViewKey == selection.ViewKey; }))
            return true;
        return false;
    },

    getComboAddError: function (selection, returnSelection, basket)
    {
        if ((!this.isEnabledForItem(selection)) || (selection.CastSelection && !selection.VisibleCastSelection))
            return returnSelection ? selection : this.NonCombinableStates.SelectionNotAllowedInCombo;

        basket = basket || this.getSelectionsInCombo();

        for (var i in basket)
        {
            var bSelection = basket[i];
            if (selection.equals(bSelection))
                continue;

            var selEventGroupID = 1 * (typeof selection.EventGroupID == "function" ? selection.EventGroupID() : selection.EventGroupID);
            var bSelEventGroupID = 1 * (typeof bSelection.EventGroupID == "function" ? bSelection.EventGroupID() : bSelection.EventGroupID);

            var isSelectionOutright = selection.isOutright();

            if (bSelection.Initialized && !isSelectionOutright && selection.MasterEventID == bSelection.MasterEventID)
            {
                // Combo event grouping allows for different event types (for example FT and Corners) to be combined in a combo.
                // Group 0 cannot be combined with any other group including itself. Event types belonging to different groups (except 0) can be combined.
                // For NBS we only need to check if we are comboing group 0 with any other group in the same event. The rest is factored into the ComboGroupKey.
                if (selEventGroupID != bSelEventGroupID && (selEventGroupID == 0 || bSelEventGroupID == 0))
                {
                    return returnSelection ? bSelection : this.NonCombinableStates.NoSameGroupInCombo;
                }

                // If evengroups are identical we make sure we cannot combine events like FullTime ML with FirstToScore.
                // Otherwise these selections will be assigned different combokeys and which means they can be combined which is wrong
                if (selEventGroupID == bSelEventGroupID &&
                    selection.getSameEventComboKey() != bSelection.getSameEventComboKey() && (selection.getSameEventComboKey() == 0 || bSelection.getSameEventComboKey() == 0))
                {
                    return returnSelection ? bSelection : this.NonCombinableStates.NoSameGroupInCombo;
                }
            }

            if (bSelection.Initialized && (selection.BetType == 7 || bSelection.BetType == 7))  // 7 for QA bets
            {
                var isHorseRacing = (selection.BranchID == constHorseRacingBranchID && bSelection.BranchID == constHorseRacingBranchID),
                    isGreyhounds = (selection.BranchID == constGreyHoundRacingBranchID && bSelection.BranchID == constGreyHoundRacingBranchID),
                    isVirtual = (selection.BranchID == bSelection.BranchID && VirtualSports.isBranchVirtual(selection.BranchID) &&
                                 selection.EventTypeID == bSelection.EventTypeID && selection.EventTypeID == 396);

                if (isHorseRacing)
                {
                    if (bSelection.LineName == selection.LineName && selection.LineTypeID != 26 && selection.LineTypeID != 28 &&
                        bSelection.LineTypeID != 26 && bSelection.LineTypeID != 28) //Exception for Favorite and 2nd Favorite
                    {
                        return returnSelection ? bSelection : this.NonCombinableStates.NoSameHorseCombo;
                    }
                }
                else if (isVirtual || isGreyhounds)
                {
                    if ((bSelection.TeamID != -1 && selection.TeamID != -1) && bSelection.TeamID == selection.TeamID &&
                        selection.LineTypeID != 26 && selection.LineTypeID != 28 && bSelection.LineTypeID != 26 && bSelection.LineTypeID != 28) //Exception for Favorite and 2nd Favorite
                    {
                        return returnSelection ? bSelection : this.NonCombinableStates.NoSameHorseCombo;
                    }
                }
                else if ((isSelectionOutright || bSelection.isOutright()) && // QA bet for a League
                      (bSelection.LeagueID == selection.LeagueID && !selection.IsEachWaySelection))   // are for same league
                {
                    return returnSelection ? bSelection : this.NonCombinableStates.NoSameLeagueQACombo;
                }
            }
        }


        //if (this.getRate() * selection.getRealOdd() > MaxComboOdd)
        //    return returnSelection ? selection : this.NonCombinableStates.MaxComboOddsExceeded;

        return false;
    },

    clearDeposits: function ()
    {
        for (var i in this.VariationsDeposits)
        {
            if (!this.Variants[i] || this.Variants[i] == 0) delete this.VariationsDeposits[i];
        }
    },

    toggleComboSelection: function (viewKey, cbox)
    {
        if (cbox.checked)
        {
            var selection = BetSlip.Selections[viewKey];
            if (!selection) return;

            var err = this.getComboAddError(selection);
            if (err)
            {
                this.ExcludedSelections[viewKey] = true;
            }
            else
            {
                this.ExcludedSelections[viewKey] = false;
            }
        }
        else
        {
            this.ExcludedSelections[viewKey] = true;
        }

        this.rebuildSelections();
        BetSlip.updateCartBets();
    },

    setComboMaxBet: function (optionsBlockId)
    {
        if (!optionsBlockId)
        {
            optionsBlockId = "combo-options";
        }


        if (BetSlip.CurrentMode instanceof ComboSPSlipMode ||
            (typeof ComboNoCombinationSingleSPSlipMode != "undefined" && BetSlip.CurrentMode instanceof ComboNoCombinationSingleSPSlipMode) ||
            (typeof ComboNoCombinationSPSlipMode != "undefined" && BetSlip.CurrentMode instanceof ComboNoCombinationSPSlipMode) ||
            (typeof CombinatorSPSlipMode != "undefined" && BetSlip.CurrentMode instanceof CombinatorSPSlipMode))
        {
            var comboMaxBet, i, len, key, element,
                combobetInputFields = document.querySelectorAll("div#" + optionsBlockId + " input[rel=combobetInputFields]");

            for (i = 0, len = combobetInputFields ? combobetInputFields.length : 0; i < len; i++)
            {
                element = combobetInputFields[i];
                key = element.id.split("stakebox_")[1];
                comboMaxBet = BetSlip.CurrentMode.getComboMaxBet(null, key);
                element.value = BetSlip.getStakeBoxValue(comboMaxBet);
                BetSlip.CurrentMode.setDeposit(key, comboMaxBet);
                if (BetSlip.isDefaultStakeAndStakeValuesAvailable())
                {
                    this.StakesUpdatedByUserForDropDown[key] = true;

                    this.deselectStakeDropdown(key);
                }

                // add a flag for the current combo key that the user updated the combo amount
                this.StakesUpdatedByUser[key] = true;
            }
        }
    },

    updateEachWay: function (ComboKey)
    {
        if (ComboKey)
        {
            this.SelectedEWVariants[ComboKey] = !this.SelectedEWVariants[ComboKey];
        }

        this.toggleEachWayIncluded();

        BetSlip.updateView();
        BetSlip.updateBSFooter();
    },

    toggleEachWayIncluded: function (ewIncluded)
    {
        if (typeof ewIncluded == "undefined")
            ewIncluded = this.CheckForEachWayIncluded();

        var selections = this.getSelectionsInCombo();
        for (var sIdx in selections)
        {
            var sel = selections[sIdx];
            if (ComboSPSlipMode.isEachWayEnabled(sel))
            {
                sel.EachWayIncluded = ewIncluded;
                sel.EachWayMultiplesIncluded = ewIncluded;
            }
        }
    },

    getEachWayTotalGain: function (startIndex)
    {
        if (!startIndex)
        {
            startIndex = this.getStartIndex();
        }

        BetSlip.lastUpdate = new Date();

        var ewGain = 0;
        var currDeposit = 0;
        var roundingMode = typeof BetSlip != "undefined" && BetSlip.GainRoundingMode || 0;

        for (var key in this.VariationsDeposits)
        {
            if (key < startIndex || !this.SelectedEWVariants || !this.SelectedEWVariants[key]) continue;

            currDeposit = this.VariationsDeposits[key];
            currDeposit = UserInfo.TaxProvider.applyTaxToDeposit(currDeposit);

            ewGain += this.calcComboGain(parseInt(key), currDeposit, true);
        }

        var roundedGain = GainUtil.round(ewGain, roundingMode);
        return roundedGain;
    },

    recalcEachWay: function ()
    {
        for (var vk in BetSlip.Selections)
        {
            var item = BetSlip.Selections[vk];
            item.recalcEWOdds();
        }

        BetSlip.updateView();
        BetSlip.updateBSFooter();
    },

    IsMaxBetButtonEnabled: function ()
    {
        if (typeof (BetSlipRegulations) !== "undefined" && BetSlipRegulations.IsItalian)//disabled for NetBetIt
        {
            return false;
        }

        return this.shouldShowMaxbetButtonForMultiSelections(this.isLiveCombo());
    },

    CheckForEachWayIncluded: function (key)
    {
        if (typeof (BetSlipRegulations) !== "undefined" && BetSlipRegulations.hasOwnProperty('HideEachWay') && BetSlipRegulations.HideEachWay)
        {
            return false;
        }

        if (key)
        {
            return !!this.SelectedEWVariants[key];
        }

        for (var idx in this.SelectedEWVariants)
        {
            if (this.SelectedEWVariants[idx] == true)
                return true;
        }
        return false;
    },

    CheckForEachWayEnabled: function (key, startIndex)
    {
        if (!startIndex)
        {
            startIndex = this.getStartIndex();
        }

        if (typeof (BetSlipRegulations) !== "undefined" && BetSlipRegulations.hasOwnProperty('HideEachWay') && BetSlipRegulations.HideEachWay)
        {
            return false;
        }

        if (key)
            return (this.EWVariants[key] > 0);

        for (var idx in this.EWVariants)
        {
            if (idx < startIndex) continue;
            if (this.EWVariants[idx] > 0)
                return true;
        }
        return false;
    },

    GetEachWayData: function ()
    {
        var res = new Object();
        res["Odds"] = this.EWOdds;
        res["SelectedVariants"] = this.SelectedEWVariants;
        return res;
    },

    getAvailableFreeBets: function (selections, comboKey)
    {
        var freeBets = [];
        var balance = 0;
        if (UserInfo.current && UserInfo.current.isBalancesInitiated())
        {
            freeBets = UserInfo.current.FreeBets;
            balance = UserInfo.current.getBalance();
        }
        else
        {
            freeBets = UserInfo.getFreeBetsFromStorage();
        }

        if (!selections) selections = this.getSelectionsInCombo();
        var tokens = [];

        for (var i in freeBets)
        {
            var freeBet = freeBets[i];
            var reservedFreeBets = this.getReservedFreeBets();
            var notAvailableFreeBets = Array.find(reservedFreeBets.ReservedFreeBets, function (e)
            {
                return e == freeBet.BonusID;
            });
            if (notAvailableFreeBets) continue;
            //Avoid duplicating free bets in slip
            var allowed = true;
            for (var idx in this.FreeBets)
            {
                if (idx == comboKey || !this.FreeBets[idx]) continue;
                if (this.FreeBets[idx].BonusID == freeBet.BonusID ||
                    Array.find(BetSlip.getSelectedPulseFreeBets(), function (bonusID) { return bonusID == freeBet.BonusID; }))
                {
                    allowed = false;
                    break;
                }
            }
            if (!allowed) continue;

            if (!BetSlip.SkipClientUserBalanceCheckForSingles && freeBet.IsRiskFreeBet && balance < freeBet.Amount)
            {
                continue;
            }

            if (freeBet.IsAllowedForComboBet(selections, this.Odds[comboKey]))
                tokens.push(freeBet);
        }

        return tokens;
    },

    getFreeBetDeposit: function ()
    {
        var fbd = 0;
        for (var ck in this.FreeBets)
        {
            var freeBet = this.FreeBets[ck];
            if (!freeBet) continue;

            var dep = this.VariationsDeposits[ck];
            fbd += freeBet.GetFreeBetDeposit(dep);
        }
        return fbd;
    },

    // serializes FreeBets
    serializeFreeBets: function ()
    {
        var freeBets = [];
        for (var i in this.FreeBets)
            freeBets[i] = this.FreeBets[i] ? this.FreeBets[i].serialize() : this.FreeBets[i];

        return freeBets;
    },

    // deserializes FreeBets
    deserializeFreeBets: function (data)
    {
        var freeBets = [];

        if (!data) return freeBets;

        for (var i in data)
            freeBets[i] = data[i] ? new BonusInfo(data[i]) : data[i];

        return freeBets;
    },

    // fills combo stake with free bet amount
    setVariantFreeBetStake: function (key, freeBet)
    {
        this.StakesUpdatedByUser[key] = true;

        var amount = freeBet ? freeBet.Amount : 0;

        if (amount && amount > 0 && BetSlip.StakeRounding == BetSlip.StakeRoundingMethods.ZeroDecimalPoints && amount.toString().indexOf('.') > -1)
        {
            amount = Math.floor(amount);
        }

        var stakeElement = document.getElementById("stakebox_" + key);
        if (stakeElement)
        {
            stakeElement.value = BetSlip.getStakeBoxValue(amount);
        }

        this.setDeposit(key, amount);
    },

    CheckForSPIncluded: function ()
    {
        var selections = this.getSelectionsInCombo();
        return (selections.any(function (sel) { return sel.isSPSelection(); }));
    },

    CheckForNoOddsSelectionIncluded: function ()
    {
        var selections = this.getSelectionsInCombo();
        return (selections.any(function (sel) { return sel.Odds == 0; }));
    },

    getComboFreeBets: function ()
    {
        //Filter out null/undefined members
        var freeBets = Array.findAll(this.FreeBets, function (fb) { return (fb); });

        return freeBets;
    },

    validateStake: function (isValueValid, error, viewKey, updateView)
    {
        if (!isValueValid)
        {
            this.Errors[viewKey] = error;
            this.setError(error, updateView);
            return false;
        }
        else if (this.checkForErrors(viewKey))
        {
            this.Errors[viewKey] = false;
            this.clearErrors();
            if (updateView)
            {
                this.updateView();
            }
            return true;
        }
    },

    hasStraightCombo: function ()
    {
        if (BetSlipRegulations && BetSlipRegulations.IsItalian && !this.isComboAllowed() && this.ComboNoCombinations && BetSlip.getSelectionsCount() > this.getStartIndex())
        {
            return false;
        }

        return true;
    },

    isUnderMinSelections: function (count)
    {
        if (BetSlipRegulations && BetSlipRegulations.hasOwnProperty('MinComboSelections'))
        {
            return count < BetSlipRegulations.hasOwnProperty.MinComboSelections;
        }

        return count < this.MinSelections;
    },

    clearStraightComboDeposit: function ()
    {
        var len = this.VariationsDeposits.length,
            key = len - 1;

        if (this.VariationsDeposits[key] && this.VariationsDeposits[key] !== 0)
        {
            this.setDeposit(key, 0);
        }
    },

    isEnabledForItem: function (item)
    {
        return !!item.ComboBetIsEnabled;
    },

    populateNonCombinableStates: function ()
    {
        var states = ["SelectionNotAllowedInCombo", "NoSameGroupInCombo", "NoSameHorseCombo", "NoSameLeagueQACombo", "MaxComboOddsExceeded"];
        for (var i = 0; i < states.length; i++)
        {
            var key = states[i];
            this.NonCombinableStates[key] = $dict.bs(key);
        }
    },

    isNonCombinableError: function (error)
    {
        "use strict";

        var states = this.NonCombinableStates;
        var result = false;
        Object.keys(states).forEach(function (k)
        {
            if (states[k] === error)
            {
                result = true;
            }
        });
        return result;
    },

    setIsGeneratedCombo: function (value)
    {
        this.isGeneratedCombo = value;
    },

    formatPurchaseRequest: function()
    {
        var purchase = BaseSPSlipMode.prototype.formatPurchaseRequest.apply(this, arguments);
        purchase.isGeneratedCombo = this.isGeneratedCombo;
        return purchase;
    },

    getComboVariantReturn: function (comboKey)
    {
        var totalGain = 0;
        var variantSelections = this.getSelectionsInCombo();
        var freeBet = this.FreeBets[comboKey];
        var roundingMode = typeof BetSlip != "undefined" && BetSlip.GainRoundingMode || 0;

        if (variantSelections.any(function (sel) { return sel.isSPSelection(); }) && this.VariationsDeposits[comboKey] > 0)
        {
            return $dict.bs("NotAvailable");
        }

        var currentDeposit = this.getCurrentDeposit(this.VariationsDeposits[comboKey]);        
        totalGain += this.calcComboGain(parseInt(comboKey), currentDeposit);

        if (this.isComboBonusAvailable(comboKey) && !freeBet && !this.SelectedEWVariants[comboKey])
        {
            totalGain += Math.floor(ComboBonusProvider.ComboBonuses[comboKey].AdditionalOdds * currentDeposit * 100) / 100;
        }

        if (this.SelectedEWVariants[comboKey])
        {
            totalGain += this.calcComboGain(parseInt(comboKey), this.VariationsDeposits[comboKey], true);
        }

        if (freeBet)
        {
            var dep = this.VariationsDeposits[comboKey];
            var freeBetContribution = freeBet.GetFreeBetDeposit(dep) - freeBet.getRiskFreeBetDeposit(dep);
            totalGain = totalGain - freeBetContribution;
        }

        totalGain = totalGain.toFixed(12);
        var roundedGain = GainUtil.round(totalGain, roundingMode);

        return Bets.formatCurrencyStake(roundedGain, UserInfo.current ? BetSlip.UserCurrencyCode: "");
    },

    rebuildVariantStakeSP: function (key)
    {
        var mode = BetSlip.CurrentMode;
        var element = document.getElementById("stakebox_" + key), st;
        if (element)
        {
            var initialElementValueLength = element.value.length;
            var initialCursorPosition = mode.getElementCursorPosition(element);
        }

        if ((mode instanceof ComboSPSlipMode || (typeof ComboNoCombinationSingleSPSlipMode != "undefined" && mode instanceof ComboNoCombinationSingleSPSlipMode) || (typeof ComboNoCombinationSPSlipMode != "undefined" && mode instanceof ComboNoCombinationSPSlipMode) || (typeof CombinatorSPSlipMode != "undefined" && mode instanceof CombinatorSPSlipMode)) && element)
        {
            st = BetSlip.SanitizeNumberString(element.value);
            if (isNaN(st))
            {
                st = 0;
            }

            st = BetSlip.RoundToStakeRounding(st, element);

            mode.setDeposit(key, st);

            if (BetSlip.isDefaultStakeAndStakeValuesAvailable())
            {
                mode.StakesUpdatedByUserForDropDown[key] = true;

                mode.deselectStakeDropdown(key);
            }

            // add a flag for the current combo key that the user updated the combo amount
            mode.StakesUpdatedByUser[key] = true;
        }

        initialCursorPosition && mode.setCursorAtPosition(element, initialCursorPosition + (element.value.length - initialElementValueLength));
    },

    isComboBonusAvailable: function (key)
    {
        return ComboBonusProvider.ComboBonuses && ComboBonusProvider.ComboBonuses[key];
    },

    calculateOdds: function (comboGroups, eachWayEnabled)
    {
        if(BetSlip.CalcEachCombinationIndividualy && typeof sbCombinationCalculations != 'undefined')
        {
            var isComplexSet = function (set) { return set.length > 1 };
            var complexSets = calcDifferentStraightCombinations(comboGroups);
            var simpleSets = complexSets.any(isComplexSet) ? sbCombinationCalculations.prepareSets([], complexSets) : [complexSets];
            if(complexSets.any(isComplexSet))
            {
                for (var i = 0; i < simpleSets.length; i++) {
                    for (var j = 0; j < simpleSets[i].length; j++) {
                        simpleSets[i][j] = [simpleSets[i][j]];
                    }
                }
            }
            if(eachWayEnabled)
            {
                var simpleEWSets = calcDifferentStraightCombinations(comboGroups, eachWayEnabled);
                simpleEWSets = simpleEWSets.any(isComplexSet) ? sbCombinationCalculations.prepareSets([], simpleEWSets) : [simpleEWSets];
                this.calculateOddsArrays(simpleEWSets, eachWayEnabled);
            }

            this.calculateOddsArrays(simpleSets, false);
        }
    },

    calculateOddsArrays: function(simpleSets, isEachway)
    {
        var calcProp = isEachway ? "calculatedOddsEW" : "calculatedOdds";
        var compare = function (x, y) {
            // Case:
            //  When the user selects several odds for a single event (multi-selection should be enabled for the environment)
            //  And adds to the betslip in Combo mode an EachWay event selection =>
            // Result: simpleSets will contain array of numbers instead of array of arrays
            return Array.isArray(x) && Array.isArray(y)
                ? x.equals(y, function (s, d) { return s == d })
                : x === y;
        };
        var shouldResetArrays = Object.keys(this[calcProp]).length != simpleSets.length;
        shouldResetArrays && (this[calcProp] = {});
        for (var i = 0; i < simpleSets.length; i++)
        {
            if (!this[calcProp]) {
                this[calcProp] = {};
            }

            if (!this[calcProp][i] || !this[calcProp][i].simpleSet.equals(simpleSets[i], compare))
            {
               
                this[calcProp][i] = {
                    simpleSet: simpleSets[i],
                    odds: {},
                    stake: {},
                    gain: {}
                };
            }
        } 
    },

    calcComboGain: function (key, deposit, isEachWay)
    {
        if(BetSlip.CalcEachCombinationIndividualy && typeof sbCombinationCalculations != 'undefined')
        {
            var sets = isEachWay ? this.calculatedOddsEW : this.calculatedOdds;
            
            var keys = Object.keys(sets);
            var gain = 0;
            var roundingMode = typeof BetSlip != "undefined" && BetSlip.GainRoundingMode || 0;
            var sumFunc = function (odd) { return GainUtil.round(sbMathOperations.Multiplication(roundDecimalOdds(odd), deposit, 6), roundingMode, 6) };
            for (var i = 0; i < keys.length; i++) {
                if (deposit == 0) {
                   return 0;
                }
                if (sets[keys[i]].stake[key] == deposit) {
                    gain = sbMathOperations.Аccumulation(gain, sets[keys[i]].gain[key], 6);
                    continue;
                }

                 var setGain = sbCombinationCalculations.processSet(key,
                    sets[keys[i]].simpleSet, 
                    sumFunc);
                gain = sbMathOperations.Аccumulation(gain, setGain, 6);
                
                if (!sets[keys[i]]) {
                    sets[keys[i]].gain = {};
                    sets[keys[i]].stake = {};
                }
                
                sets[keys[i]].gain[key] = setGain;
                sets[keys[i]].stake[key] = deposit;

            }
            return gain;
        }

        return !isEachWay ? this.calcGain(this.Odds[key], deposit) : this.calcGain(this.EWOdds[key], deposit);
    }

}, TComboSPSlipMode);

function roundDecimalOdds(odds) {
    return BetMath.roundDecimalOdds(parseFloat(odds.toFixed(12)), BetSlip.ComboOddsRoundingMode, !BetSlip.ForceTwoDigitsForOddsRounding);
}

function calcDifferentStraightCombinations(comboGroups, isEachway) {
    var keys = Object.keys(comboGroups);
    var complexSets = [];
    for (var i = 0; i < keys.length; i++) {
        for (var j = 0; j < comboGroups[keys[i]].length; j++) {
            !complexSets[i] && (complexSets[i] = []);
            complexSets[i][j] = isEachway && comboGroups[keys[i]][j].IsEachWayEnabled && comboGroups[keys[i]][j].getRealOddEW ? comboGroups[keys[i]][j].getRealOddEW(BetSlip.BaseOddsRoundingMode) :
                comboGroups[keys[i]][j].getRealOdd(BetSlip.BaseOddsRoundingMode);
        }
    }

    return complexSets;
}

function rebuildVariantStakeSP(key)
{
    if (typeof BetSlip.CurrentMode.rebuildVariantStakeSP === "function")
    {
        BetSlip.CurrentMode.rebuildVariantStakeSP(key);
    }
}

function rebuildVariantStakeDropDown(key)
{
    var dropdown = document.getElementById("dropdown" + key);
    var element = document.getElementById("stakebox_" + key);
    element.value = dropdown.options[dropdown.selectedIndex].text;

    rebuildVariantStakeSP(key);
}

ComboSPSlipMode.isEachWayEnabled = function (ln)
{
    return (typeof ln.isEachWayInComboEnabled === "function") ? ln.isEachWayInComboEnabled() : ln.IsEachWayEnabled == true;
};

ComboSPSlipMode.isExcludedSelectionHighlighted = function (selection)
{

    return !selection.ComboBetIsEnabled && (BetSlip.isCombinatorMode(BetSlip.CurrentMode) || (BetSlip.isComboNoCombinationSingleMode() && BetSlip.getSelectionsCount() > 1)) && selection.Initialized;
};

ComboSPSlipMode.setFreeBet = function (select, comboKey)
{
    var mode = BetSlip.CurrentMode;
    if ((typeof ComboSPSlipMode != "undefined" || typeof ComboNoCombinationSPSlipMode != "undefined" || typeof ComboNoCombinationSingleSPSlipMode != "undefined")
     && !(BetSlip.isCombinatorMode(mode) || mode instanceof ComboSPSlipMode || mode instanceof ComboNoCombinationSPSlipMode || mode instanceof ComboNoCombinationSingleSPSlipMode)) return;

    if (mode.Variants[comboKey] != 1)
    {   //Only allow for combos with 1 permutation
        mode.FreeBets[comboKey] = null;
        BetSlip.updateView();
        return;
    }

    var bonusID = select.value * 1;

    var freeBet = UserInfo.current.FreeBets[bonusID];
    if (freeBet)
    {
        for (var idx in mode.FreeBets)
        {
            if (idx == comboKey || !mode.FreeBets[idx]) continue;
            if (mode.FreeBets[idx].BonusID == freeBet.BonusID) return;
        }

        if (typeof ComboNoCombinationSPSlipMode != "undefined" && mode instanceof ComboNoCombinationSPSlipMode &&
            typeof mode.isSingleMode === "function" && mode.isSingleMode())
        {
            var selection = Array.first(BetSlip.Selections);
            if (freeBet.IsAllowedForLine(selection, selection.BetType))
            {
                mode.FreeBets[comboKey] = freeBet;
                mode.setVariantFreeBetStake(comboKey, freeBet);
                selection.FreeBet = freeBet;
            }
        }
        else
        {
            if (freeBet.IsAllowedForComboBet(mode.getSelectionsInCombo(), mode.Odds[comboKey]))
            {
                mode.FreeBets[comboKey] = freeBet;
                mode.setVariantFreeBetStake(comboKey, freeBet);
            }
        }
    }
    else if (bonusID == 0)
    {
        mode.FreeBets[comboKey] = null;
        mode.setVariantFreeBetStake(comboKey);
    }

    BetSlip.updateView();
    BetSlip.updateBSFooter();
    BetSlip.saveState();
}
window.calcDifferentStraightCombinations = calcDifferentStraightCombinations;
window.roundDecimalOdds = roundDecimalOdds;
window.ComboSPSlipMode = ComboSPSlipMode;
window.rebuildVariantStakeSP = rebuildVariantStakeSP;
window.rebuildVariantStakeDropDown = rebuildVariantStakeDropDown;
includeExtension("/JSComponents/Data/UniSlip/Modes/ComboSPSlipMode.ext.js");