﻿var TSPUKPurchase = (function () {
    "use strict";
    var api = {}; // use 'api' only to define extension properties and always 'this' inside functions

    var getSortedBets = function (bets)
    {
        var res = Array.getValues(bets);
        res.sort(
            chainSort(

                function (b1, b2)
                {
                    if (b1.VariationType > b2.VariationType) 
                    {
                        return 1;
                    }
                    if (b1.VariationType < b2.VariationType) 
                    {
                        return -1;
                    }
                    return 0;
                },

                function (b1, b2)
                {
                    if (b1.IsEWBet && b2.IsEWBet) 
                    {
                        return 0;
                    }
                    return b1.IsEWBet ? 1 : -1;
                }
            )
        );

        return res;
    };

    var cloneSPSingleBet = function (bet, selection, isEachWay)
    {
        selection = $.extend(Object.create(QASlipSelection.prototype), selection);
        selection.EachWayIncluded = !!isEachWay;

        /*globals SPSingleBet */
        return new SPSingleBet($.extend({ Stake: bet.Deposit }, bet), selection, bet.Status, bet.WaitingBetID);
    };

    // Override for updateView function to redraw the whole BetSlip view instead of using buildInnerView for the currently updated purchase
    api.updateView = function ()
    {
        TUniSlip.buildView();
        TUniSlip.updatePurchaseActions(this);
    };

    // Required by TPurchases.js
    api.getLinesDescription = function ()
    {
        return Purchases.getUKLinesDescription(this);
    };

    api.getBets = function ()
    {
        var keys = Object.keys(this.Bets),
                   bet,
                   i,
                   len,
                   result = [];

        for (i = 0, len = keys.length; i < len; i += 1) 
        {
            bet = this.Bets[keys[i]];

            if (bet instanceof SPSingleBet && bet.Selection.EachWaySingleIncluded) {
                // Clone each-way selection as a separate bet
                var realPart = Modes.isNBS() ? cloneSPSingleBet(bet, bet.Selection) : cloneSingleBet(bet, bet.Selection);
                realPart.Selection.EachWaySingleIncluded = false;
                var eachWayPart = Modes.isNBS() ? cloneSPSingleBet(bet, bet.Selection, true) : cloneSingleBet(bet, bet.Selection);
                eachWayPart.FreeBetStake = 0;

                result.push(realPart, eachWayPart);
            }
            else
            {

                result.push(bet);
            }
        }

        result = getSortedBets(result);

        return result;
    };

    // Required by TPurchases.js
    // Required only for combos
    api.updateContext = function (context)
    {
        return Purchases.updateComboContext(this, context);
    };

    // Required by TPurchases.js
    api.getTitle = function ()
    {
        return $dict.bs("UKBetsPurchase");
    };

    // Required by Data/UniSlip/*Purchase.js functions
    api.getStatusClass = function ()
    {
        return "";
    };

    return api;
}());

window.TSPUKPurchase = TSPUKPurchase;