﻿var TTeaserBet = (function ()
{
    "use strict";
    var api = {}; // use 'api' only to define extension properties and always 'this' inside functions

    // Required by TPurchases.js
    api.getDeposit = function ()
    {
        return Purchases.getTeaserBetDeposit(this);
    };

    // Required by TPurchases.js
    api.getBetTitle = function ()
    {
        return Purchases.getBetTitle(this);
    };

    // Required by TPurchases.js
    api.getReturn = function ()
    {
        return Purchases.getTeaserBetReturn(this);
    };

    api.isDepositUpdated = function ()
    {
    };

    // Required by TPurchases.js
    api.getOdds = function ()
    {
        return Purchases.getTeaserBetOdds(this);
    };

    // Required by TPurchases.js
    api.getDescription = function ()
    {
        return Purchases.getTeaserBetDescription(this);
    };

    // Required by TPurchases.js
    api.getStatusClass = function ()
    {
        return Purchases.getBetStatusClass(this);
    };

    return api;
}());

window.TTeaserBet = TTeaserBet;