﻿function BaseSlipSelection(masterEventID, eventID, lineID, eventTypeId, isFavTeam, leagueId, lineTypeID)
{
    this.MasterEventID = masterEventID;
    this.EventID = eventID;
    this.LineID = lineID;
    this.LineTypeID = lineTypeID;

    this.Valid = true;
    this.Final = false;
    this.minBet = 0;
    this.Updated = false;
    this.Countable = true;
    this.StatusUpdate = new Date();
    this.FreeBet = null;

    // Suspended and closed lines are automatically removed after some period of time if 
    // they still remain suspended/invalid. See deleteSuspendedAndClosed() in UniSlip.js
    this.DeleteTimeSet = new Date(0);

    this.FirstCall = true;
    this.Initialized = false;

    this.LiveGroupID = 0;
    this.PreLiveGroupID = 0;
    this.LastStatus = 0;
    this.ComboRate = 1;
    this.CombinatorGroup = null;
    this.IsFavTeam = isFavTeam;
    this.Type = eventTypeId;
    this.LeagueID = leagueId;

    this.IsBanker = false;
    this.IsAddedFromActionBetting = false;
}

BaseSlipSelection.prototype =
{
    EventGroupID: function() {
        return this.Live ? this.LiveGroupID : this.PreLiveGroupID;
    },

    processBetUpdate: function (updates)
    {
        this.LastStatus = updates.Status;
        this.UpdatedLineClosed = false;
        
        if (updates == -1)
        {
            this.Valid = false;
            this.Final = true;
            this.LastUpdate = new Date();
            this.ErrorText = this.Error = $dict.bs("LineClosed");
            if (this.Live)
            {                
                this.LineClosed = true;
            }
            
            this.UpdatedLineClosed = true;
            this.Initialized = true;
            this.Updated = false;

            return;
        }

        if (!this.Initialized)
        {
            this.BranchID = updates.BranchID;
            this.BranchName = updates.BranchName;

            this.LeagueID = updates.LeagueID;
            this.LeagueName = updates.LeagueName;

            this.EventTypeID = updates.EventTypeID;
            this.EventTypeName = this.EventTypeID == 341 ? updates.LineTypeID == 19 || updates.LineTypeID == 18 ? $string("General").Antepost + " " + $string("BettingHistory").Win : $string("BettingHistory").Win
														: updates.EventTypeName; // RaceCard

            this.OppositeEventTypeID = updates.OppositeEventTypeID;

            this.Team1Name = updates.Team1Name;
            this.Team2Name = updates.Team2Name;

            this.LiveGroupID = updates.LiveGroupID;
            this.PreLiveGroupID = updates.PreLiveGroupID;

            // the "updates" object could contain Valid and/or Error properties if this method is called from 
            // restore selections, i.e. if the page is refreshed or the user navigates to a different page
            if (typeof updates.Valid != "undefined") this.Valid = updates.Valid;
            if (typeof updates.Error != "undefined") this.Error = updates.Error;
        }

        this.EventDate = updates.EventDate || this.EventDate;

        var isStakeboxRoundNumberEnabled = BetSlip.ShowFullNumberForMinMaxBetValue && BetSlip.StakeRounding === BetSlip.StakeRoundingMethods.ZeroDecimalPoints;
        this.maxBet = isStakeboxRoundNumberEnabled ? Math.floor(updates.maxBet || this.maxBet) : (updates.maxBet || this.maxBet);
        this.minBet = isStakeboxRoundNumberEnabled ? Math.ceil(updates.minBet || this.minBet) : (updates.minBet || this.minBet);
        this.waitingAllowed = updates.waitingAllowed || this.waitingAllowed;
        this.MaxBetCombo = updates.MaxBetCombo || this.MaxBetCombo;
        this.MaxBetSystem = updates.MaxBetSystem || this.MaxBetSystem;
        this.MaxBetTeaser = updates.MaxBetTeaser || this.MaxBetTeaser;
        this.MaxRisk = updates.MaxRisk || this.MaxRisk;
        this.MaxRiskCombo = updates.MaxRiskCombo || this.MaxRiskCombo;
        this.MaxRiskSystem = updates.MaxRiskSystem || this.MaxRiskSystem;
        this.MaxRiskTeaser = updates.MaxRiskTeaser || this.MaxRiskTeaser;
        this.MaxRiskComboEachWay = updates.MaxRiskComboEachWay || this.MaxRiskComboEachWay;
        this.MaxStakeComboEachWay = updates.MaxStakeComboEachWay || this.MaxStakeComboEachWay;
        this.SingleSelectionRisk = updates.SingleSelectionRisk || this.SingleSelectionRisk;
        this.RealOrPresumptiveOdds = updates.RealOrPresumptiveOdds || this.RealOrPresumptiveOdds;
        this.MaxBetForCustomer = updates.MaxBetForCustomer || this.MaxBetForCustomer;
        this.SingleBetIsEnabled = updates.SingleBetIsEnabled || this.SingleBetIsEnabled;
        this.ComboBetIsEnabled = typeof updates.ComboBetIsEnabled != "undefined" ? updates.ComboBetIsEnabled : this.ComboBetIsEnabled;
        this.SystemBetIsEnabled = typeof updates.SystemBetIsEnabled != "undefined" ? updates.SystemBetIsEnabled : this.SystemBetIsEnabled;
        this.TeaserBetIsEnabled = updates.TeaserBetIsEnabled || this.TeaserBetIsEnabled;
        this.IsTeamSwapEnabled = updates.IsTeamSwapEnabled || this.IsTeamSwapEnabled;
        this.Team1Name = updates.Team1Name || this.Team1Name;
        this.Team2Name = updates.Team2Name || this.Team2Name;
        this.LineTypeName = updates.LineTypeName || this.LineTypeName;
        this.Initialized = true;
        this.FirstCall = this.FirstCall && BetSlip.getUpdateCartBetsRunning();
    },

    processHighlightUpdates: function (updates, firstcall)
    {
        this.Updated = false;
        this.Updated = this.processOddsUpdate(updates, firstcall) || this.Updated;
        this.Updated = this.processScoreUpdate(updates, firstcall) || this.Updated;
    },

    isLineClosed: function ()
    {
        return this.Odds == 0;
    },

    closeLine: function ()
    {
        this.Valid = false;
        this.Updated = false;
        this.LineClosed = true;
        this.Error = $dict.bs("LineClosed");
    },

    processLineClosed: function()
    {
        this.UpdatedLineClosed = false;
        if (this.isLineClosed())
        {
            this.UpdatedLineClosed = true;
            this.closeLine();
        }
        else if (!BetSlip.config.DisallowModifiedFastMarketBets)
        {
            this.LineClosed = false;
        }

        // Remove "Line closed" message if the selection is opened
        if (!this.LineClosed && this.Error && this.Error == $dict.bs("LineClosed"))
        {
            this.UpdatedLineClosed = true;
            this.Error = undefined;
            this.Valid = true;
        }

        this.Updated = this.UpdatedLineClosed || this.Updated;
		return this.UpdatedLineClosed;
    },

    processDanger: function(updates)
    {
        if (!this.Live)
        {
            return;
        }

        this.UpdatedDanger = false;
        if (this.Danger != updates.IsDanger)
        {
            this.UpdatedDanger = true;
        }

		if (updates.IsDanger)
		{
			this.Valid = false,
            this.Danger = true,
            this.Error = $dict.bs("Danger");
        }
        else
        {
            this.Danger = false;
        }

        // Remove "Suspended" message if the selection's "Danger" has been lifted
        if (!this.Danger && this.Error && this.Error == $dict.bs("Danger"))
        {
            this.Error = undefined;
            this.Valid = true;
        }

        this.Updated = this.UpdatedDanger || this.Updated;
		return this.UpdatedDanger;
    },

    processOddsUpdate: function (updates, firstcall)
    {
        this.UpdatedOdds = false;
        if (updates.Odds != undefined)
        {
            if (Odds.shouldRound(this.EventTypeID, this.BetTypeID, this.BranchID))
            {
                updates.Odds = Odds.round(updates.Odds);
            }

            this.UpdatedOdds = this.CheckIfOddsUpdated(updates.Odds, undefined, firstcall);

            if (this.Odds != updates.Odds)
            {
                this.PreviousOdds = this.Odds;
                this.AcceptingOdds = firstcall ? updates.Odds : this.Odds;
                this.Odds = updates.Odds;
            }
        }
        else if (firstcall && Odds.shouldRound(this.EventTypeID, this.BetTypeID, this.BranchID) && this.Odds != 0 && this.Odds != Odds.round(this.Odds))
        {
            // Make sure we make the client "rounding" even if updates.Odds is undefined. It will be undefined 
            // if the odds sent with updateRegularCartItems() method is the same as the server odd.
            this.Odds = Odds.round(this.Odds);
        }
        return this.UpdatedOdds;
    },

    CheckIfOddsUpdated: function (serverOdds, serverFractOdds, firstcall, isBuyPoints)
    {
        var clientOdds = (isBuyPoints ? this.BuyPointsOdds : this.Odds);
        var clientFractOdds = this.FractionalOdds;

        if (!serverOdds || clientOdds == serverOdds) return false;

        //Since it's unclear if clientOdds is american or currentOddStyle we need to check for both cases
        var cOdd = Odds.convert(clientOdds, this.EventTypeID, this.BetTypeID, true, undefined, this.BetType, this.BranchID, clientFractOdds);
        var sOdd = Odds.convert(serverOdds, this.EventTypeID, this.BetTypeID, true, undefined, this.BetType, this.BranchID, serverFractOdds);

        if (BetSlip.BaseOddsRoundingMode && !isNaN(sOdd))
        {
            sOdd = BetMath.roundDecimalOdds(sOdd, BetSlip.BaseOddsRoundingMode, !BetSlip.ForceTwoDigitsForOddsRounding);
        }

        if (Data.compare(cOdd, sOdd) == 0 || Data.compare(clientOdds, sOdd) == 0)
        {
            return false;
        }
        else
        {
            return true;
        }
    },

    processScoreUpdate: function(updates, firstcall)
    {
        if(!this.Live)
        {
            return false;
        }

        this.UpdatedScore = false;
        if (updates.ScoreChanged)
        {
            this.Valid = firstcall || !Bets.isBranchThatSuspendsLiveBets(this.BranchID);
            this.Danger = !this.Valid;
            if (!this.Valid) this.Error = $dict.bs("Danger");
            this.UpdatedScore = !firstcall;
            this.Score1 = updates.ScoreChanged[0];
            this.Score2 = updates.ScoreChanged[1];
        }

        return this.UpdatedScore;
    },

    showScore: function()
    {
        return (this.BranchID != 22 && this.BranchID != 59) || !UseExtendedCricketScoreBoard;
    },

    // Should the selection show a "To Win" box instead of a "Stake" box
    isToWin: function ()
    {
        return (typeof (BetSlip.CurrentMode.isToWin) == "function" && BetSlip.CurrentMode.isToWin(this));
    },

    getGuiUpdateObject: function ()
    {
        return { BranchID: this.BranchID, MasterEventID: this.MasterEventID, EventID: this.EventID };
    },

    checkForRequest: function ()
    {
        return this.waitingAllowed && this.EventTypeID != 39 && !this.Live;
    },

    blinkDublicate: function ()
    {
        var element = document.getElementById("sce_" + this.ViewKey);
        if (element)
        {
            element.classList.add("highlight");
            setTimeout((function (el)
            {
                return function() { el.classList.remove("highlight"); }
            }(element)), 5000);
        }
    },

    serialize: function ()
    {
        return {
            ViewKey: this.ViewKey,
            Valid: this.Valid,
            Error: this.Error,
            Final: this.Final,
            MasterEventID: this.MasterEventID,
            EventID: this.EventID,
            LineID: this.LineID,

            Initialized: this.Initialized,

            BranchID: this.BranchID,
            BranchName: this.BranchName,

            LeagueID: this.LeagueID,
            LeagueName: this.LeagueName,

            EventTypeID: this.EventTypeID,
            EventTypeName: this.EventTypeName,

            Team1Name: this.Team1Name,
            Team2Name: this.Team2Name,

            LiveGroupID: this.LiveGroupID,
            PreLiveGroupID: this.PreLiveGroupID,

            maxBet: this.maxBet,
            waitingAllowed: this.waitingAllowed,
            minBet: this.minBet,
            MaxBetCombo: this.MaxBetCombo,
            MaxBetForCustomer: this.MaxBetForCustomer,
            SingleBetIsEnabled: this.SingleBetIsEnabled,
            ComboBetIsEnabled: this.ComboBetIsEnabled,
            TeaserBetIsEnabled: this.TeaserBetIsEnabled,
            FreeBet: this.FreeBet ? this.FreeBet.serialize() : null,
            StakeUpdatedByUser: this.StakeUpdatedByUser,
            IsTeamSwapEnabled: this.IsTeamSwapEnabled,
            MaxRisk: this.MaxRisk,
            MaxRiskCombo: this.MaxRiskCombo,
            MaxRiskComboEachWay: this.MaxRiskComboEachWay,
            MaxStakeComboEachWay: this.MaxStakeComboEachWay,
            SingleSelectionRisk: this.SingleSelectionRisk,
            RealOrPresumptiveOdds: this.RealOrPresumptiveOdds,
            CombinatorGroup: this.CombinatorGroup,
            Type: this.Type,
            IsFavTeam: this.IsFavTeam,
            LineClosed: this.LineClosed,
            LeagueID: this.LeagueID,
            BetID: this.BetID,
            SystemBetIsEnabled: this.SystemBetIsEnabled,
            EventDate: this.EventDate
        };
    },

    equals: function (cb)
    {
        return (this.LineID == cb.LineID || this.LineID == cb.AlternateLineID || this.AlternateLineID == cb.LineID);
    },

    getGain: function (deposit)
    {
        var fodd;
        if (this.IsBuyPoint)
        {
            fodd = this.BuyPointsOdds > 0 ? (this.BuyPointsOdds / 100 + 1) : (1 - 100 / this.BuyPointsOdds);
        }
        else
        {
            fodd = this.Odds > 0 ? (this.Odds / 100 + 1) : (1 - 100 / this.Odds);
        }

        if (typeof BetSlip != "undefined" && BetSlip.BaseOddsRoundingMode)
        {
            fodd = BetMath.roundDecimalOdds(fodd, BetSlip.BaseOddsRoundingMode, !BetSlip.ForceTwoDigitsForOddsRounding);
            var gain = (Math.round(fodd * 1000) * deposit) / 1000;
        }
        else
        {
            var gain = (fodd * deposit).toFixed(4);
        }

        var roundingMode = typeof BetSlip != "undefined" && BetSlip.GainRoundingMode || 0;
       
        return GainUtil.round(gain, roundingMode);
    },

    // If the selection is a "to win" selection and the current odd style is MALAY and the Malay odd is negative,
    // we calculate the stake from the "to win" value the user entered in a very simple way: since a negative Malay odd
    // means "how much i risk to win 1 dollar", for the odd -0.860 for instance, you need to risk 0.86 dollars to win 1 dollar,
    // or risk 8.6 dollars to win 10 dollars, 86 to win 100, and so on. Hence, [stake = (-malay_odd * to_win)]. This is done,
    // because if we use the decimal odd, we need to use the formula [stake = to_win / (dec_odd - 1)] and when dividing the stake
    // for the same odd and to_win = 100 the stake will be calculated as 85.95.... which is nowhere near 86
    getDepositFromToWin: function (toWin)
    {
        var fodd;
        if (this.isToWin())
        {
            // Use the odd that we are actually showing to the customer
            if (currentOddStyle == OddStyle.MALAY && (fodd = this.getFormatedOdds().toStrippedNumberString() * 1) < 0)
            {
                return (-fodd * toWin).toFixed(4) * 1;
            }
        }

        if (this.IsBuyPoint)
        {
            fodd = this.BuyPointsOdds > 0 ? (this.BuyPointsOdds / 100 + 1) : (1 - 100 / this.BuyPointsOdds);
        }
        else
        {
            fodd = this.Odds > 0 ? (this.Odds / 100 + 1) : (1 - 100 / this.Odds);
        }
        if (fodd == 1)
            return toWin;

        return deposit = (toWin / (fodd - 1)).toFixed(4) * 1;
    },

    getDepositFromReturn: function (maxReturn)
    {
        var fodd;

        if (this.IsBuyPoint) {
            fodd = this.BuyPointsOdds > 0 ? (this.BuyPointsOdds / 100 + 1) : (1 - 100 / this.BuyPointsOdds);
        }
        else {
            fodd = this.Odds > 0 ? (this.Odds / 100 + 1) : (1 - 100 / this.Odds);
        }

        return deposit = (maxReturn / fodd).toFixed(4) * 1;
    },

    // "To win" value should be calculated from deposit only if the customer entered a
    // stake in decimal for example and then switched to Malay odds style
    getToWinFromDeposit: function (deposit)
    {
        if (this.isToWin())
        {
            var precision = 4;
            var americanOdds = this.IsBuyPoint ? this.BuyPointsOdds : this.Odds;

            if (currentOddStyle == OddStyle.MALAY && this.getFormatedOdds().toStrippedNumberString() * 1  < 0) {
                return Bets.getWinFromStakeForNegativeAsianOdds(deposit, americanOdds, OddStyle.MALAY, precision);
            }

            if (Odds.convertFromAmericanToDecimal(americanOdds) == 1) {
                return 0;
            }

            return Bets.getWinFromStakeForNegativeAsianOdds(deposit, americanOdds, OddStyle.INDO, precision);
        }
    },

    isSPSelection: function (odds)
    {
        var lOdds = odds || this.Odds;

        return Lines.isTypeSP(this.LineTypeID);
    },
    isToteEvent: function()
    {
        return this.EventTypeID === RacingEventTypes.AUHRTote || this.EventTypeID === RacingEventTypes.AUHRTotePlaceOnly;
    },

    isCastSelection: function ()
    {
        return BetTypes.IsForeCast(this.BetType) || BetTypes.IsTriCast(this.BetType);
    },

    isBuyPointsSelection: function ()
    {
        return false;
    },

    InitializeOdds: function (initOdds)
    {
        //It is possible we create selections by using fractional odds which are interpreted as a number i.e. "7/2" interpreted as 3.5
        //In this case the odds blink in the betslip on first load since the system believes they were updated, even if they were not.
        if (currentOddStyle == OddStyle.FRACTIONAL && initOdds < 100 && initOdds > 0)
        {   //To avoid this situation, convert the 'calculated' fraction back into American
            initOdds = Odds.decimalToAmerican(initOdds + 1);
        }

        this.Odds = initOdds;
        this.AcceptingOdds = initOdds;
    },

    getToteOddsAbbreviation: function ()
    {
        var toteTypeId;
        var result = "";
        if (this.EventTypeID === RacingEventTypes.AUHRTote)
            toteTypeId = this.WinToteID;
        else if (this.EventTypeID === RacingEventTypes.AUHRTotePlaceOnly)
            toteTypeId = this.PlaceToteID;
        switch (toteTypeId)
        {
            case RacingToteTypes.BestTote:
                result = $dict.bs("BestTote");
                break;
            case RacingToteTypes.MidTote:
                result = $dict.bs("MidTote");
                break;
            case RacingToteTypes.BestToteOrSP:
                result = $dict.bs("BestToteOrSP");
                break;
        }
        return result;
    },

    getOddsAbbreviation: function () {
        if (this.Odds != 0)
            return this.getOddsToShow();
        var result = $dict.bs("SP");
        if (UseAustralianHorseRacingView)
        {
            result = this.getToteOddsAbbreviation() || result;
        }
        return result;
    },

    getOddsToShow: function(oddStyleID)
    {
        if (this.isSPSelection() || (this.isTotesSelection() && !this.Odds))
        {
            if (this.isTotesSelection())
            {
                return this.getToteOddsAbbreviation();
            }
            else
            {
                return $dict.bs("SP");
            }
        }
        else
        {
            if (this.IsBuyPoint)
            {
                return Odds.convertFormat(this.BuyPointsOdds, this.EventTypeID, this.BetTypeID, undefined, oddStyleID, this.BetType, this.BranchID, this.FractionalOdds);
            }
            else if ((this.BetType == 11 || this.BetType == 12) && this.Odds == 0)
            {

            }
            else
            {
                return Odds.convertFormat(this.Odds, this.EventTypeID, this.BetTypeID, undefined, oddStyleID, this.BetType, this.BranchID, this.FractionalOdds);
            }
        }

        return "";
    },

    getComboGroupKey: function ()
    {
        var keyBase = "{0}_{1}_{2}";
        var grpID = this.EventGroupID();
        var evtID = this.MasterEventID ? this.MasterEventID : this.EventID;
        var seKey = this.getSameEventComboKey();

        return keyBase.format(grpID, evtID, seKey);
    },

    getSameEventComboKey: function ()
    {
        // IsSameEventInComboAllowed is configured for the American sports configuration. American sports configuration
        // allows combo bets for certain leagues (for example, Basketball, NBA) to be placed with O/U+Spread or O/U+ML, only for FT odds
        var sameEventComboKey = 0;

        if (this.MasterEventID &&
            this.IsSameEventInComboAllowed && // selections' leagues support placing bet on same event
            this.BetType == 1 && // regular, not QA
            this.EventTypeID == 0) // FT selection
        {
            if (this.BetTypeID == 1 || this.BetTypeID == 2) //ML, Spread
                sameEventComboKey = 1;

            if (this.BetTypeID == 3) // O/U
                sameEventComboKey = 3;
        }

        return sameEventComboKey;
    },

    getEWOdds: function ()
    {
        return 0;
    },

    recalcEWGain: function()
    {
        return 0;
    },

    recalcEWOdds: function ()
    {
        return 0;
    },

    getYourBetFormattedForPurchase: function (pointsToReduce)
    {
        if (this.IsMultiLine) return;

        var dummyLine = $.extend({}, this, BaseSlipSelection.YourBetFormatTokens);

        var yourBet = dummyLine.getPickLine(false, pointsToReduce, true);
        return yourBet;
    },

    isTotesSelection: function() {
        return this.EventTypeID === RacingEventTypes.AUHRTote || this.EventTypeID === RacingEventTypes.AUHRTotePlaceOnly;
    },

    compareCombinatorGroup: function (secondSelection, isDescending) {
        return Data.compareByCombinatorGroup(this.CombinatorGroup, secondSelection.CombinatorGroup, isDescending);
    },

    /**
     * Returns if selection is outright.
     * The critetia is MasterEventID === 0 and EventID must be filled.
     * @returns {Boolean}
     */
    isOutright: function ()
    {
        return this.MasterEventID === 0 && this.EventID;
    }
};

BaseSlipSelection.YourBetFormatTokens = {

    Team1Name: "{$Team1Name}",
    Team2Name: "{$Team2Name}", 
    EventName: "{$EventName}", 
    LineName: "{$LineName}", 
    EventTypeName: "{$EventTypeName}", 
    LinetypeName: "{$LineTypeName}", 
    LeagueName: "{$LeagueName}", 
    BranchName: "{$BranchName}",

    Consts: {
        Draw: "{$Dict_Draw}",
        Tie: "{$Dict_Tie}",
        NoGoals: "{$Dict_NoGoals}",
        Over: "{$Dict_Over}",
        Under: "{$Dict_Under}"
    }
};

BaseSlipSelection.deserializeSelection = function (data)
{
    var freeBet = data.FreeBet ? new BonusInfo(data.FreeBet) : null;
    var stakeUpdatedByUser = data.StakeUpdatedByUser;
 
    if (data.BetType == 1 && data.Live)
    {
        var item = new RegularLiveSlipSelection(data.MasterEventID, data.EventID, data.LineGroupID, data.BetTypeID, data.BetSide, data.Odds, data.Points, data.ScoreChanged[0], data.ScoreChanged[1], null, data.Type);
        item.FreeBet = freeBet;
        item.StakeUpdatedByUser = stakeUpdatedByUser;
        item.IsTeamSwapEnabled = data.IsTeamSwapEnabled;
        item.CombinatorGroup = data.CombinatorGroup;
        item.Type = data.Type;
        item.IsFavTeam = data.IsFavTeam;
        item.LineClosed = data.LineClosed;
        item.LeagueID = data.LeagueID;
        item.BetID = data.BetID;
        item.LineID = data.LineID;
        return item;
    }

    if (data.BetType == 1)
    {
        if (data.IsBuyPoint)
        {
            var item = new RegularSlipSelection(data.MasterEventID, data.EventID, data.LineGroupID, data.LineID, data.Odds, data.Points, null, data.Type,null,null,data.LineTypeID);
            item.IsBuyPoint = data.IsBuyPoint;
            item.BuyPointsOdds = data.BuyPointsOdds;
            item.BuyPointsPoints = data.BuyPointsPoints;
            item.BuyPointSelectedIndex = data.BuyPointSelectedIndex;
            item.FreeBet = freeBet;
            item.StakeUpdatedByUser = stakeUpdatedByUser;
            item.IsTeamSwapEnabled = data.IsTeamSwapEnabled;
            item.CombinatorGroup = data.CombinatorGroup;
            item.Type = data.Type;
            item.IsFavTeam = data.IsFavTeam;
            item.LeagueID = data.LeagueID;
            item.BetID = data.BetID;
            return item;
        }
        else
        {
            var item = new RegularSlipSelection(data.MasterEventID, data.EventID, data.LineGroupID, data.LineID, data.Odds, data.Points, null, data.Type);
            item.FreeBet = freeBet;
            item.StakeUpdatedByUser = stakeUpdatedByUser;
            item.IsTeamSwapEnabled = data.IsTeamSwapEnabled;
            item.CombinatorGroup = data.CombinatorGroup;
            item.Type = data.Type;
            item.IsFavTeam = data.IsFavTeam;
            item.LeagueID = data.LeagueID;
            item.BetID = data.BetID;
            return item;
        }
    }

    if (data.BetType == 7 && data.Live)
    {
        var item = new QALiveSlipSelection(data.MasterEventID, data.EventID, data.LineID, data.Odds, data.ScoreChanged[0], data.ScoreChanged[1], null, data.Type, data.LineTypeID);
        item.FreeBet = freeBet;
        item.StakeUpdatedByUser = stakeUpdatedByUser;
        item.IsTeamSwapEnabled = data.IsTeamSwapEnabled;
        item.EachWaySingleIncluded = data.EachWayIncluded;
        item.CombinatorGroup = data.CombinatorGroup;
        item.Type = data.Type;
        item.IsFavTeam = data.IsFavTeam;
        item.LeagueID = data.LeagueID;
        item.BetID = data.BetID;
        return item;
    }

    if (data.BetType == 7)
    {
        var item = new QASlipSelection(data.MasterEventID, data.EventID, data.LineID, data.Odds, data.Type, data.LineTypeID);
        item.CastSelection = data.CastSelection;

        if (data.IsEachWayEnabled && data.EachWayTermsID > 0)
        {
            item.EachWayIncluded = data.EachWayIncluded;
            item.EachWaySingleIncluded = data.EachWaySingleIncluded;
            item.EachWayMultiplesIncluded = data.EachWayMultiplesIncluded;
            item.IsEachWayEnabled = data.IsEachWayEnabled;
            item.PlaceTermsID = data.PlaceTermsID;
            item.OddsTermsID = data.OddsTermsID;
            item.EachWayTermsID = data.EachWayTermsID;
            item.EWOdds = data.EWOdds;
            item.EWDeposit = data.EWDeposit;
            item.EWGain = data.EWGain;
            item.EWGain = data.IsEachWaySelection;
        }

        item.EventDate = data.EventDate;
        item.FreeBet = freeBet;
        item.StakeUpdatedByUser = stakeUpdatedByUser;
        item.IsTeamSwapEnabled = data.IsTeamSwapEnabled;
        item.CombinatorGroup = data.CombinatorGroup;
        item.Swapped = data.Swapped;
        item.Type = data.Type;
        item.IsFavTeam = data.IsFavTeam;
        item.LeagueID = data.LeagueID;
        item.BetID = data.BetID;
        return item;
    }

    if (data.BetType == 11 || data.BetType == 12 || data.BetType == 21)
    {
        var item = new MultiLineItem(data.MasterEventID, data.EventID, data.LineID, data.Odds, data.Selections, data.Positions, data.EventDate, data.maxBet, data.BetType == 12, data.Type, null, VirtualSports.isBranchVirtual(data.BranchID))
        var positionsCount = 2;
        if (data.BetType == 12)
            positionsCount = 3;
        else if (data.BetType == 21)
            positionsCount = 4;
        var item = new MultiLineItem(data.MasterEventID, data.EventID, data.LineID, data.Odds, data.Selections, data.Positions, data.EventDate, data.maxBet, data.BetType == 12, positionsCount, null, VirtualSports.isBranchVirtual(data.BranchID))
        item.FreeBet = freeBet;
        item.StakeUpdatedByUser = stakeUpdatedByUser;
        if (data.PurchasedSelections)
        {
            item.PurchasedSelections = data.PurchasedSelections;
        }
        item.IsTeamSwapEnabled = data.IsTeamSwapEnabled;
        item.CombinatorGroup = data.CombinatorGroup;
        item.Type = data.Type;
        item.IsFavTeam = data.IsFavTeam;
        item.LeagueID = data.LeagueID;
        return item;
    }

    return false;
};

window.BaseSlipSelection = BaseSlipSelection;