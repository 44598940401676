function SingleSPSlipMode()
{
    BaseSPSlipMode.call(this);

    this.Deposits = [];
    this.ToWins = [];
    this.StakeUpdatedByUser = [];
    this.ActivationRank = 10;

    this.Order = 1;
    this.ID = "single";
    this.TabClass = "single betting_slip_nbs";
    this.Name = $dict.bs("SingleBetTab");
    this.PurchaseTypeID = SPPurchaseType.Single;
    this.ShowLastLineError = true;
}

$.extend(SingleSPSlipMode.prototype, BaseSPSlipMode.prototype,
{
    canActivate: function ()
    {
        if (BetSlip.KeepSingleTabForMultipleSelections)
        {
            return this.ActivationRank * 2;
        }
        return this.ActivationRank;
    },

    canPlaceBet: function ()
    {
        for (var i in this.Deposits)
            if (this.Deposits[i] > 0) return true;

        return false;
    },

    selectionAdded: function (item)
    {
        // Only for mobile
        var currency = BetSlip.getCurrencyWithStakeValues();

        if (currency)
        {
            if (item.CastSelection && !item.VisibleCastSelection)
            {
                this.Deposits[item.ViewKey] = 0;
            }
            else
            {
                this.Deposits[item.ViewKey] = currency.DefaultStake || 0;
            }
        }
        else
        {
            this.Deposits[item.ViewKey] = 0;
        }

        if (!this.Active) return;

        this.updateView();
        BetSlip.updateBSFooter();
    },

    selectionUpdated: function (item, initcall)
    {
        if (!this.Active) return;

        if (initcall)
        {
            this.updateSelection(item);
        }
        else if (item.Updated || !item.Valid || item.AlternateLineChanged)
        {
            // updateSelection() redraws the selection so if the selection is updated we need to redraw it
            // otherwise old odds or points would remain no changed on the screen. Then updateSelectionData()
            // would make those odds or points highlighted and blinking.
            this.updateSelection(item);
            this.updateSelectionData(item);
        }
        else
            this.updateSelectionData(item);

        BetSlip.updateBSFooter();
    },

    focusOnStakeBox: function (selection)
    {
        var stakeBox = document.getElementById('stake_' + selection.ViewKey);
        stakeBox && stakeBox.focus();
    },

    selectionRemoved: function (item)
    {
        delete this.Deposits[item.ViewKey];
        delete this.Errors[item.ViewKey];
        if (typeof (this.ToWins[item.ViewKey]) != "undefined")
            delete this.ToWins[item.ViewKey];

        if (!this.Active) return;

        this.updateView();
        BetSlip.updateBSFooter();
    },

    IsMaxBetButtonEnabled: function (selection)
    {
        if (typeof (BetSlipRegulations) !== "undefined" && BetSlipRegulations.IsItalian)//disabled for NetBetIt
        {
            return false;
        }

        var enabledForLiveSelections = BetSlip.ShowMaxBetButtonLive && selection.Live;
        var enabledForPrematchSelections = BetSlip.ShowMaxBetButtonPrematch && !selection.Live;
        var enabledForAsianView = Application.deviceType.isDesktop() && typeof (isAsianView) !== 'undefined' && isAsianView;

        return (enabledForLiveSelections || enabledForPrematchSelections) && selection.Valid && selection.maxBet >= 1 && (enabledForAsianView || !BetSlip.EnableAsianStyleMinMax) && UserInfo.current;
    },

    clear: function ()
    {
        this.Deposits = [];
        this.ToWins = [];
        this.Errors = [];
        this.AllBetsDeposit = 0;
        this.CurrentError = false;
        this.IsBetInProcess = false;
        this.FreeBet = null;

        if (!this.Active) return;

        this.updateView();
        BetSlip.updateBSFooter();
    },

    deselectStakeDropdown: function ()
    {
        if (!BetSlip.isDefaultStakeAndStakeValuesAvailable()) return;

        for (var idx in BetSlip.Selections)
        {
            var dropDown = document.getElementById("dropdown" + BetSlip.Selections[idx].ViewKey);
            if (dropDown && dropDown.selectedIndex != -1)
            {
                dropDown.options[dropDown.selectedIndex].selected = false;
                dropDown.selectedIndex = -1;
                dropDown.value = 0;
            }
        }
        var dropDownForAll = document.getElementById("dropdownForAllBets");
        if (dropDownForAll && dropDownForAll.selectedIndex != -1)
        {
            dropDownForAll.options[dropDownForAll.selectedIndex].selected = false;
            dropDownForAll.value = 0;
            dropDownForAll.selectedIndex = -1;
        }

    },

    setMaxBet: function (viewKey)
    {
        var deposit = this.getMaxBet(viewKey);
        if (!deposit)
        {
            return;
        }

        var setStakeForAllElement = document.getElementById("setStakeForAll"),
            stakeElement = document.getElementById("stake_" + viewKey);

        if (setStakeForAllElement) setStakeForAllElement.value = "";

        this.AllBetsDeposit = 0;

        this.Deposits[viewKey] = deposit;
        
        if (stakeElement)
        {
            stakeElement.value = BetSlip.getStakeBoxValue(deposit);
        }

        BetSlip.updateBSFooter();
        if (TUniSlip && TUniSlip.updateStakeboxValues)
        {
            TUniSlip.updateStakeboxValues(stakeElement);
        }

        this.setSingleReturnValue(viewKey, this.getSingleReturn(viewKey));
    },

    getMaxBet: function (viewKey)
    {
        viewKey *= 1;

        var deposit;

        if (!viewKey in this.Deposits) return;

        var sel = BetSlip.Selections[viewKey];
        if (!sel) return;

        if (typeof (sel.getMaxBet) != "undefined")
        {
            deposit = Math.floor(sel.getMaxBet() * 1);
        }
        else
        {
            deposit = Math.floor(sel.maxBet * 1);
        }

        var maxBetFromMaxReturn = SingleSPSlipMode.prototype.getSingleMaxBetFromRegulationMaxReturn.call(this, sel);
        if ((maxBetFromMaxReturn > 0 && maxBetFromMaxReturn < deposit) || !deposit)
        {
            deposit = maxBetFromMaxReturn;
        }

        if (!deposit) return;

        if (typeof (BetSlipRegulations) !== "undefined" && BetSlipRegulations.hasOwnProperty('SingleStakeIncrement'))
        {
            var increment = BetSlipRegulations.SingleStakeIncrement;
            deposit = this.applyStakeIncrement(deposit, increment);
        }

        return deposit;
    },

    setMaxWin: function (viewKey)
    {
        viewKey *= 1;

        if (!viewKey in this.Deposits) return;

        var sel = BetSlip.Selections[viewKey];
        if (!sel) return;

        var deposit = Math.floor(sel.maxBet * 1);

        var maxBetFromMaxReturn = SingleSPSlipMode.prototype.getSingleMaxBetFromRegulationMaxReturn.call(this, sel);
        if ((maxBetFromMaxReturn > 0 && maxBetFromMaxReturn < deposit) || !deposit)
        {
            deposit = maxBetFromMaxReturn;
        }

        var setStakeForAllElement = document.getElementById("setStakeForAll"),
            stakeElement = document.getElementById("stake_" + viewKey);

        if (setStakeForAllElement) setStakeForAllElement.value = "";
        this.AllBetsDeposit = 0;

        // Calculate profit win from the max bet (profit = gain - stake)
        this.Deposits[viewKey] = BetSlip.EnableAsianStyleMinMax ? sel.getDepositFromToWin(deposit) : deposit;
        var maxWin = sel.isToWin() ? sel.getToWinFromDeposit(deposit) : (sel.getGain(deposit) - deposit);
        var roundingMode = typeof BetSlip != "undefined" && BetSlip.GainRoundingMode || 0;
        maxWin = GainUtil.round(gain, roundingMode, 6);
        this.ToWins[viewKey] = BetSlip.EnableAsianStyleMinMax ? deposit : maxWin;
        if (stakeElement)
        {
            var displayValue = BetSlip.EnableAsianStyleMinMax ? deposit : maxWin;
            stakeElement.value = BetSlip.getStakeBoxValue(displayValue);
        }

        BetSlip.updateBSFooter();
    },

    getSingleMaxBetFromRegulationMaxReturn: function (selection)
    {
        var maxBet = 0;
        if (typeof BetSlipRegulations !== "undefined" && BetSlipRegulations.hasOwnProperty('SingleMaxReturn') && BetSlipRegulations.SingleMaxReturn > 0)
        {
            maxBet = Math.floor(selection.getDepositFromReturn(BetSlipRegulations.SingleMaxReturn) * 10) / 10;
        }
        return maxBet;
    },

    getNumberOfBets: function ()
    {
        var c = 0;

        for (var i in this.Deposits)
        {
            if (this.Deposits[i] <= 0) continue;
            var sel = BetSlip.Selections[i];
            if (!sel) continue;

            if (typeof (sel.getNumberOfBets) != "undefined")
            {
                c += sel.getNumberOfBets();
            }
            else
            {
                if (sel.Valid) c++;
                if (sel.BetType == 7 && sel.EachWaySingleIncluded) c++;
            }
        }

        return c;

    },

    getTotalDeposit: function ()
    {
        var totalDeposit = 0;
        var toWinsDepSum = 0; // the sum of the to win selections

        for (var i in this.Deposits)
        {
            if (this.Deposits[i] <= 0) continue;
            var sel = BetSlip.Selections[i];

            if (!sel)
            {
                continue;
            }

            if (sel.Valid)
            {
                if (typeof (sel.getDeposit) != "undefined")
                    totalDeposit += sel.getDeposit();
                else if (sel.isToWin())
                    toWinsDepSum += this.Deposits[i];
                else
                    totalDeposit += this.Deposits[i];
            }
            if (sel.BetType == 7 && sel.EachWaySingleIncluded) totalDeposit += this.Deposits[i];

        }

        // Do our rounding of the "to win" selections because we cannot trust the toStringEx function called on the result
        // because it calls on the other hand Number.toFixed(2) which does not round as expected in some cases
        toWinsDepSum = Math.round((toWinsDepSum * 100).toFixed(4)) / 100;

        return totalDeposit + toWinsDepSum;
    },

    getRiskFreeBetDeposit: function ()
    {
        var sum = 0;

        for (var i in this.Deposits)
        {
            var sel = BetSlip.Selections[i];
            if (sel && sel.FreeBet && sel.FreeBet.IsRiskFreeBet)
            {
                sum += sel.FreeBet.Amount;
            }
        }

        return sum;
    },

    getGainPerBetType: function ()
    {
        var currDeposit = 0;
        var betTypesGain = {};
        var counter = 1;

        for (var i in this.Deposits)
        {
            currDeposit = this.Deposits[i];
            if (this.Deposits[i] <= 0) continue;
            var sel = BetSlip.Selections[i];
            if (sel && sel.Valid)
            {
                if (sel.isSPSelection() || (sel.isCastSelection() && sel.Odds == 0)) return 0;

                this.recalcToWinSelections(i);

                currDeposit = UserInfo.TaxProvider.applyTaxToDeposit(this.Deposits[i]);

                if (sel.isToWin())
                {
                    betTypesGain[counter] = this.floorDecimal(currDeposit + this.ToWins[i]) -
                        (sel.FreeBet && !sel.FreeBet.IsRiskFreeBet ? sel.FreeBet.Amount : 0) +
                        (sel.EachWayIncluded ? sel.EWGain : 0);
                }
                else
                    betTypesGain[counter] = sel.getGain(currDeposit) -
                        (sel.FreeBet && !sel.FreeBet.IsRiskFreeBet ? sel.FreeBet.Amount : 0) +
                        (sel.EachWayIncluded ? sel.EWGain : 0);
            }
            counter++;
        }

        return betTypesGain;
    },

    getTotalGain: function ()
    {
        var totalGain = 0;
        var currDeposit = 0;

        for (var i in this.Deposits)
        {
            var depositValue = this.Deposits[i],
                sel = BetSlip.Selections[i];

            if (!this.isDepositValid(depositValue, sel)) continue;
            currDeposit = UserInfo.TaxProvider.applyTaxToDeposit(depositValue);

            if (sel && sel.Valid)
            {
                if (sel.isSPSelection() || (sel.isCastSelection() && sel.Odds == 0)) return 0;

                // If Decimal style is switched to Malay we would have to calculate the "to win" value from the deposit.
                // The check for this situation is checked here because getTotalGain is called from updateView on the 
                // event listener of changing the current odds style. If check is true then visaul update should be set to be pending. 
                // Updating the selection box here will not work because this method in this situation is called from a JS template method.
                this.recalcToWinSelections(i);

                if (sel.isToWin())
                {
                    totalGain += this.floorDecimal(currDeposit + this.ToWins[i]);
                }
                else
                    totalGain += sel.getGain(currDeposit);
            }
        }
        totalGain = totalGain.toFixed(12);
        var roundingMode = typeof BetSlip != "undefined" && BetSlip.GainRoundingMode || 0;
        var roundedGain = GainUtil.round(totalGain, roundingMode, 6);

        return roundedGain;
    },

    isDepositValid: function (depositValue, selection) {
        return depositValue > 0;
    },

    // Called to recalculate the to win values from the corresponding deposit value. Called if the user switches from let say Decimal or Asian odd style
    recalcToWinSelections: function (viewKey)
    {
        if (typeof viewKey == "undefined")
        {
            for (var i in this.Deposits)
            {
                this.recalcToWinSelections(i);
            }

            return;
        }

        if (this.ToWins[viewKey] && this.ToWins[viewKey] > 0)
        {
            return;
        }

        var sel = BetSlip.Selections[viewKey];
        if (!sel || sel.isSPSelection() || sel.isCastSelection())
        {
            return;
        }

        var toWinFromDeposit = sel.getToWinFromDeposit(this.Deposits[viewKey]);
        if (toWinFromDeposit)
        {
            this.ToWins[viewKey] = toWinFromDeposit;
            BetSlip.hasToUpdateToWinSelections = true;
        }
    },

    updateToWinSelections: function ()
    {
        for (var i in this.ToWins)
        {
            var sel = BetSlip.Selections[i],
                       stakeElement = document.getElementById("stake_" + i);

            if (sel && sel.isToWin() && stakeElement)
            {
                stakeElement.value = BetSlip.getStakeBoxValue(BetSlip.RoundToStakeRounding(this.ToWins[i]));
            }
            else
            {
                delete this.ToWins[sel.ViewKey];
            }
        }
    },

    setDeposit: function (viewKey, deposit)
    {
        if (typeof deposit === "string")
        {
            deposit = BetSlip.SanitizeNumberString(deposit);
        }

        if (isNaN(deposit))
        {
            deposit = 0;
        }

        deposit = BetSlip.RoundToStakeRounding(deposit);

        if (deposit > 0 && BetSlip.StakeRounding == BetSlip.StakeRoundingMethods.ZeroDecimalPoints)
        {
            deposit = Math.floor(deposit);
        }

        this.Deposits[viewKey] = deposit;
    },

    setStake: function (viewKey, element, deposit)
    {
        var deposit = deposit ? deposit : BetSlip.SanitizeNumberString(element.value),
        setStakeForAllElement = document.getElementById("setStakeForAll");

        if (setStakeForAllElement)
        {
            var initialElementValueLength = setStakeForAllElement.value.length;
            var initialCursorPosition = this.getElementCursorPosition(setStakeForAllElement);
        }

        if (isNaN(deposit))
        {
            deposit = 0;
        }

        deposit = BetSlip.RoundToStakeRounding(deposit, element);
        
        this.Deposits[viewKey] = deposit;
        this.Errors[viewKey] = false;

        this.deselectStakeDropdown();

        if (setStakeForAllElement) setStakeForAllElement.value = "";
        this.AllBetsDeposit = 0;

        // add a flag to the selection that the user updated stake amount
        var selection = BetSlip.Selections[viewKey];
        if (selection)
        {
            if (BetSlip.isDefaultStakeAndStakeValuesAvailable())
            {
                var defaultStake = BetSlip.getCurrencyWithStakeValues().DefaultStake;
                this.StakeUpdatedByUser[viewKey] = deposit != defaultStake;
                var dropDown = document.getElementById("dropdown" + viewKey);
                var stakeDropDownForAllStakes = document.getElementById("dropdownForAllBets");
                if (stakeDropDownForAllStakes && stakeDropDownForAllStakes.selectedIndex != -1)
                {
                    stakeDropDownForAllStakes.options[stakeDropDownForAllStakes.selectedIndex].selected = false;
                    stakeDropDownForAllStakes.selectedIndex = -1;
                    stakeDropDownForAllStakes.value = 0;
                }
                if (dropDown && dropDown.selectedIndex != -1)
                {
                    dropDown.options[dropDown.selectedIndex].selected = false;
                    dropDown.selectedIndex = -1;
                    dropDown.value = 0;
                }
            }
            selection.StakeUpdatedByUser = deposit > 0;
        }

        BetSlip.updateBSFooter();
        BetSlip.saveState();
        initialCursorPosition && this.setCursorAtPosition(setStakeForAllElement, initialCursorPosition + (setStakeForAllElement.value.length - initialElementValueLength));

        this.setSingleReturnValue(viewKey, this.getSingleReturn(viewKey));
    },

    calculateLoyaltyPoints: function ()
    {
        return LoyaltyProgramCalculations.calculationsForSingle();
    },
    validateCzechRegulations: function (deposit, viewKey)
    {
        if (typeof (BetSlipRegulations) !== "undefined" && BetSlipRegulations.isActive)
        {
            var sel = BetSlip.Selections[viewKey],
                depositWithEW,
                gain;

            if (sel && !sel.isSPSelection() && !(sel.isCastSelection() && sel.Odds == 0))
            {
                gain = 0;

                if (sel.EachWaySingleIncluded)
                {
                    depositWithEW = deposit * 2
                    sel.recalcEWGain(deposit);
                    gain = sel.EWGain;
                }
                else
                {
                    depositWithEW = deposit;
                }

                if (sel.isToWin())
                {
                    gain += this.floorDecimal(deposit + this.ToWins[viewKey]);
                }
                else
                {
                    gain += sel.getGain(deposit);
                }

            }
            else
            {
                gain = 0;
                depositWithEW = deposit;
            }

            if (BetSlip.PlaceSingleBetsWithMultiplePurchases)
            {
                var roundedGain = Math.floor((gain * 100).toFixed(4)) / 100;

                if (BetSlipRegulations.hasOwnProperty("MinimumPurchaseStake") && this.getRegulationMinPurchaseStake() > depositWithEW)
                {
                    this.setValidationError($dict.bs("UnderMinTotalCost"), viewKey);
                    return false;
                }

                if (BetSlipRegulations.hasOwnProperty("MaximumPurchaseWin") && this.isOverMaxWinRegulation(roundedGain, depositWithEW))
                {
                    this.setValidationError($dict.bs("RegulationsMaxWin").format("{0}{1}".format(BetSlip.UserCurrencyCode, MoneyFormat.format(this.getRegulationMaximumPurchaseWin()), viewKey)));
                    return false;
                }
            }

            if (BetSlipRegulations.hasOwnProperty("MinimumSingleBetStake") && depositWithEW < this.getRegulationSingleMinBetStake())
            {
                this.setValidationError($dict.bs("RegulationMinSingleBetStake").format("{0}{1}".format(BetSlip.UserCurrencyCode, MoneyFormat.format(this.getRegulationSingleMinBetStake()))), viewKey);
                return false;
            }
        }

        return true;
    },

    validateItalianRegulations: function (deposit, viewKey)
    {
        if (typeof (BetSlipRegulations) === "undefined" || !BetSlipRegulations.IsItalian || deposit == 0) return true;

        if (BetSlipRegulations.hasOwnProperty('SingleStakeIncrement'))
        {
            var stakeIncrement = BetSlipRegulations.SingleStakeIncrement;
            if (stakeIncrement > 0 && (deposit % stakeIncrement) != 0)
            {
                this.setValidationError($dict.bs("SingleStakeIncrement").format("{0}{1}".format(BetSlip.UserCurrencyCode, BetSlip.formatMoneyWithRounding(stakeIncrement))), viewKey);
                return false;
            }
        }

        if (BetSlipRegulations.hasOwnProperty('MinimumPurchaseStake'))
        {
            var minStake = BetSlipRegulations.MinimumPurchaseStake;
            if (minStake > 0 && deposit < minStake)
            {
                this.setValidationError($dict.bs("MinimumPurchaseStake").format("{0}{1}".format(BetSlip.UserCurrencyCode, BetSlip.formatMoneyWithRounding(minStake))), viewKey);
                return false;
            }
        }

        if (typeof TSlipShared !== 'undefined')
        {
            this.updateView()
        }

        return true;
    },

    validateRegulations: function (deposit, viewKey)
    {
        return this.validateItalianRegulations(deposit, viewKey) && this.validateCzechRegulations(deposit, viewKey);
    },

    setLastLineError: function (isShown)
    {
        this.ShowLastLineError = isShown;
    },

    setValidationError: function (error, viewKey)
    {
        if (viewKey)
        {
            this.Errors[viewKey] = error;
        }
        else
        {
            this.setError(error);
        }

        if (typeof TSlipShared !== 'undefined')
        {
            this.updateView()
        }
    },

    setToWin: function (viewKey, element)
    {
        viewKey *= 1;
        if (!viewKey in this.Deposits) return;

        var sel = BetSlip.Selections[viewKey];
        if (!sel) return;

        var deposit = 0;
        var toWinStr = element.value;
        var toWin = BetSlip.SanitizeNumberString(toWinStr);
        var setStakeForAllElement = document.getElementById("setStakeForAll");

        if (!isNaN(toWin))
        {
            toWin = BetSlip.RoundToStakeRounding(toWin, element);

            // When we calculate the stake based on the "to win" value that the user entered, 
            // we trim both stake and gain for "to win" selections and save them that way
            deposit = sel.getDepositFromToWin(toWin);
            this.ToWins[viewKey] = toWin;
        }

        this.Deposits[viewKey] = deposit;
        this.Errors[viewKey] = false;

        if (setStakeForAllElement) setStakeForAllElement.value = "";
        this.AllBetsDeposit = 0;

        BetSlip.updateBSFooter();
        BetSlip.saveState();

        this.setSingleReturnValue(viewKey, this.getSingleReturn(viewKey));
    },

    setStakeToAll: function ()
    {
        var setStakeForAllElement = document.getElementById("setStakeForAll");
        if (setStakeForAllElement)
        {
            var initialElementValueLength = setStakeForAllElement.value.length;
            var initialCursorPosition = this.getElementCursorPosition(setStakeForAllElement);
        }

        var deposit = new Number(setStakeForAllElement ? BetSlip.SanitizeNumberString(setStakeForAllElement.value) : 0);
        if (isNaN(deposit))
        {
            deposit = 0;
        }

        if (BetSlip.isDefaultStakeAndStakeValuesAvailable())
        {
            for (var selection in BetSlip.Selections)
            {
                var dropDown = document.getElementById("dropdown" + BetSlip.Selections[selection].ViewKey);
                if (dropDown && dropDown.selectedIndex != -1)
                {
                    dropDown.options[dropDown.selectedIndex].selected = false;
                    dropDown.selectedIndex = -1;
                    dropDown.value = 0;
                }
            }
        }

        deposit = BetSlip.RoundToStakeRounding(deposit, setStakeForAllElement);

        if (deposit > 0 && BetSlip.StakeRounding == BetSlip.StakeRoundingMethods.ZeroDecimalPoints)
        {
            deposit = Math.floor(deposit);
            if (setStakeForAllElement) setStakeForAllElement.value = BetSlip.getStakeBoxValue(deposit);
        }

        var depositElements = document.querySelectorAll(".singleDepositField"), i, len, k;

        if (!deposit)
        {

            for (i = 0, len = depositElements.length; i < len; i += 1)
            {
                depositElements[i].value = "";
            }
            for (var k in this.Deposits)
            {
                this.setSingleReturnValue(k);

                this.Deposits[k] = 0;
                this.StakeUpdatedByUser[k] = true;
            }
            for (var i in BetSlip.Selections) BetSlip.Selections[i].StakeUpdatedByUser = false;
            this.AllBetsDeposit = 0;
        }
        else
        {
            this.AllBetsDeposit = deposit;

            for (i = 0, len = depositElements.length; i < len; i += 1)
            {
                depositElements[i].value = BetSlip.getStakeBoxValue(deposit);
            }

            for (k in this.Deposits)
            {
                var sel = BetSlip.Selections[k];
                if (!sel || sel.CastSelection) continue;

                if (sel.isToWin())
                {
                    // If the selection is actually a showing a "to win" box, then calculate the deposit from the 
                    // value entered in the "set stake for all" field
                    var toWin = deposit;
                    this.Deposits[k] = sel.getDepositFromToWin(toWin);
                    this.ToWins[k] = toWin;
                }
                else
                {
                    this.Deposits[k] = deposit;
                }
                this.recalcEachWay(k);
                this.StakeUpdatedByUser[sel.ViewKey] = true;
                sel.StakeUpdatedByUser = true;

                this.setSingleReturnValue(k, this.getSingleReturn(k));
            }
            var dropDown = document.getElementById("dropdownForAllBets");
            if (dropDown && dropDown.selectedIndex != -1)
            {
                dropDown.options[dropDown.selectedIndex].selected = false;
                dropDown.value = 0;
                dropDown.selectedIndex = -1;
            }
            this.deselectStakeDropdown();

            this.AllBetsDeposit = deposit;
        }

        BetSlip.updateBSFooter();
        BetSlip.saveState();
        initialCursorPosition && this.setCursorAtPosition(setStakeForAllElement, initialCursorPosition + (setStakeForAllElement.value.length - initialElementValueLength));
    },

    setSingleReturnValue: function (viewKey, returnValue)
    {
        var singleToReturnElem = document.getElementById("single_return_value_{0}".format(viewKey));

        if (singleToReturnElem)
        {
            singleToReturnElem.innerHTML = returnValue || '';
        }
    },

    updateView: function ()
    {
        if (!this.Active) return;

        var __html = [], containerElement = document.getElementById("bet-slip-container"),
            activeElementID = (document.activeElement && document.activeElement.tagName && document.activeElement.tagName.toLowerCase() === 'input') ? document.activeElement.id : "",
            activeElementValue = (document.activeElement && document.activeElement.tagName && document.activeElement.tagName.toLowerCase() === 'input') ? document.activeElement.value : "";
        var shouldFocusElement = document.activeElement && typeof (document.activeElement.getBoundingClientRect) == 'function' ? document.activeElement.getBoundingClientRect() : null;
        if (containerElement)
        {
            this.buildInnerView(__html);
            containerElement.innerHTML = __html.join("");
            this.deselectStakeDropdown();

            this.setFocusOnStakeBox(activeElementID, activeElementValue, shouldFocusElement);
        }
        BetSlip.updateScrollbar();
    },

    updateSelectionData: function (selection)
    {
        if (!this.Active) return;

        var oddsElement = document.getElementById("cOddsToWhow_" + selection.ViewKey),
            pointsElement = document.getElementById("cPoints_" + selection.ViewKey);

        this.setLastSelectionUpdateProps(selection);
        this.setElementsBlinking(selection.ViewKey);
        this.setSingleReturnValue(selection.ViewKey, this.getSingleReturn(selection.ViewKey));

        if (selection.BetType == 1 && selection.BetTypeID != 1 && selection.SplitType != 4)
        {
            var pts = selection.IsBuyPoint ? this.isTeaserOn ? selection.Points : selection.BuyPointsPoints
                                           : selection.Points;
            if (oddsElement) oddsElement.innerHTML = this.formatSelectionOddsForSlip(selection);
            if (pointsElement)
            {
                disableAsianStyleForBetSlip = (disableAsianStyleForBetSlip && isAsianView);
                pointsElement.innerHTML = selection.BetTypeID == 2 ? Odds.formatPoints(pts, undefined, undefined, undefined, disableAsianStyleForBetSlip) : Odds.formatPoints(pts, true, undefined, undefined, disableAsianStyleForBetSlip);
            }
        }

        this.removeOverlayFromLiveSelection(selection);

        if (selection.UpdatedOdds)
        {
            var stakeWinElement = document.getElementById("stake_" + selection.ViewKey),
                previousSelection = $.extend({}, selection, { Odds: selection.PreviousOdds, PreviousOdds: null }),
                deposit = this.Deposits[selection.ViewKey] || 0,
                toWin = this.ToWins[selection.ViewKey] || 0;

            if (selection.isToWin() && !previousSelection.isToWin())
            {
                // If the odds are changed from positive to negative, the value from the stake box should be kept in the to-win box
                this.Deposits[selection.ViewKey] = selection.getDepositFromToWin(deposit);
            }
            else if (!selection.isToWin() && previousSelection.isToWin())
            {
                // If the odds are changed from negative to positive, the value from the to-win box should be kept in the stake box
                this.Deposits[selection.ViewKey] = toWin;
            }
            else if (selection.isToWin() && toWin > 0)
            {
                // When odds are changed on a to-win selection we should update its deposit, i.e. we should not change the to-win value the user has entered in the stake box
                this.Deposits[selection.ViewKey] = selection.getDepositFromToWin(toWin);
            }

            this.recalcToWinSelections(selection.ViewKey);
            var stakeWinElementValue = selection.isToWin() ? this.ToWins[selection.ViewKey] : this.Deposits[selection.ViewKey];
            if (stakeWinElement && stakeWinElementValue)
            {
                stakeWinElement.value = BetSlip.getStakeBoxValue(BetSlip.RoundToStakeRounding(stakeWinElementValue));
            }
        }
    },

    recalculateSelectionsStakeWin: function ()
    {
        BetSlip.Selections.forEach(function (selection)
        {
            var stakeElement = document.getElementById("stake_" + selection.ViewKey);
            if (!stakeElement)
            {
                return;
            }

            BetSlip.CurrentMode[selection.isToWin() ? "setToWin" : "setStake"](selection.ViewKey, stakeElement);
        });
    },

    serialize: function ()
    {
        var deps = {};
        var stakeUpdatedByUser = [];
        for (var i in this.Deposits) deps[i] = this.Deposits[i];
        for (var j in this.StakeUpdatedByUser) stakeUpdatedByUser[j] = this.StakeUpdatedByUser[j];
        return { ID: this.ID, Deposits: deps, StakeUpdatedByUser: stakeUpdatedByUser };
    },

    deserialize: function (data)
    {
        //this.Deposits = [];
        for (var i in data.Deposits) this.Deposits[i] = data.Deposits[i];
        for (var j in data.StakeUpdatedByUser) this.StakeUpdatedByUser[j] = data.StakeUpdatedByUser[j];
    },

    clearErrors: function ()
    {
        for (var i in this.Errors) this.Errors[i] = false;
        this.setError(false);
    },

    validateMaxBet: function ()
    {
        var selections = Array.getValues(BetSlip.Selections);
        var areErrorsFound = false;

        for (var i = 0; i < selections.length; i++)
        {
            selection = selections[i];
            var deposit = this.Deposits[selection.ViewKey];

            if (!selection.Valid) continue;

            if (!BetSlipValidation.validateMax(selection.maxBet, deposit, this.ToWins[selection.ViewKey], BetSlip.EnableAsianStyleMinMax))
            {
                var maxAmount = selection.isToWin() && !BetSlip.EnableAsianStyleMinMax ? selection.getToWinFromDeposit(selection.maxBet) : selection.maxBet;
                this.Errors[selection.ViewKey] = $dict.bs("MaxStakeForItem").format(Bets.roundMin(maxAmount));
                areErrorsFound = true;
            }
        }

        if (areErrorsFound)
        {
            this.updateView();
        }

        return !areErrorsFound;
    },

    placeBets: function ()
    {
		if (BaseSPSlipMode.prototype.isGeolocationInProgress.call(this))
		{
			return;
		}

        if (!this.Active) return;

        if (this.IsBetInProcess) return;

        this.setBetInProcess(true, true);

        var fd = this.getTotalDeposit();
        var totals = BetSlip.getFooterTotals();
        if (UserInfo.TaxProvider.isUserUnderTax() && BetSlip.validatePurchaseWithCountryTaxes(totals.Taxes, this.getTotalDeposit()))
        {
            this.setBetInProcess(false, true);
            this.setError($dict.bs("CountryTaxInvalidOdds"));
            return;
        }

        if (fd <= 0)
        {
            this.setBetInProcess(false, true);
            this.setError($dict.bs("InvalidBet"));
            return;
        }

        this.clearErrors();

        var lines = [];
        var deposits = [];
        var multiLineData = [];
        var freeBets = [];
        var totalDeposit = 0;

        // the total deposit, without any free bets
        var totalRealMoneyDeposit = 0;

        for (var key in BetSlip.Selections)
        {
            var item = BetSlip.Selections[key];
            var deposit = this.Deposits[key];

            if (!deposit || deposit == 0) continue;

            if (!item.Valid) continue;

            if (this.ExcludedSelections && this.ExcludedSelections[item.ViewKey])
            {
                continue;
            }

            if (!this.validateRegulations(deposit, key))
            {
                continue;
            }


            totalDeposit += deposit;

            var input = document.getElementById("stake_" + item.ViewKey);
            var inputValue;
            if (input)
            {
                inputValue = StakeInputParser.parse(input.value);
            }
            else
            {
                inputValue = StakeInputParser.parse(deposit.toString());
            }

            var minBet = (item.EachWayIncluded && item.EachWayMinBet) ? item.EachWayMinBet : item.minBet;
            var regulationMinBet = this.getRegulationMinBet();
            minBet = Boolean(regulationMinBet) && (regulationMinBet > minBet) ? regulationMinBet : minBet;

            if (!BetSlipValidation.validateMin(minBet, deposit, inputValue, BetSlip.EnableAsianStyleMinMax))
            {
                var minAmount = item.isToWin() && !BetSlip.EnableAsianStyleMinMax ? item.getToWinFromDeposit(minBet) : minBet;
                this.Errors[key] = $dict.bs("MinimalBet").concat(" " + MoneyFormat.format(Bets.roundMin(minAmount)));
                continue;
            }

            if (item.FreeBet)
            {
                var freeBetAmount = BetSlip.SanitizeNumberString(MoneyFormat.format(item.FreeBet.Amount));
                var realMoneyDeposit = deposit - freeBetAmount;
                if (realMoneyDeposit > 0 && realMoneyDeposit < minBet)
                {
                    var minAmount = item.isToWin() ? item.getToWinFromDeposit(minBet) : minBet;
                    this.Errors[key] = $dict.bs("MinimalBetOverFree").concat(" " + MoneyFormat.format(Bets.roundMin(minAmount)));
                    continue;
                }

                totalRealMoneyDeposit += Math.max(realMoneyDeposit, 0);
                freeBets[item.ViewKey] = item.FreeBet;
            }
            else
            {
                totalRealMoneyDeposit += deposit;
            }

            if (item instanceof MultiLineItem)
            {
                if (deposit > 0)
                {
                    multiLineData.push(item.formatPurchaseRequest(deposit));
                }
            }
            else
            {
                lines.push(item);
                deposits.push(deposit);
            }
        }

        if (!BetSlip.PlaceSingleBetsWithMultiplePurchases)
        {
            if (totalDeposit <= 0)
            {
                //totalDeposit can be 0 because of regulations min bet so we don't need to continue with placement
                this.setBetInProcess(false, true);
                return;
            }

            if (this.getRegulationMinPurchaseStake() > totalDeposit)
            {
                this.setBetInProcess(false, true);
                this.setError($dict.bs("UnderMinTotalCost"));
                return;
            }

            if (this.isOverMaxWinRegulation(this.getTotalGain() + this.getEachWayTotalGain(), totalDeposit))
            {
                this.setBetInProcess(false, true);
                this.setError($dict.bs("RegulationsMaxWin").format("{0}{1}".format(BetSlip.UserCurrencyCode, MoneyFormat.format(this.getRegulationMaximumPurchaseWin()))));
                return;
            }
        }

        if (!BetSlip.SkipClientUserBalanceCheckForSingles && totalRealMoneyDeposit > UserInfo.current.getBalance())
        {
            if (typeof (QuickDepositBlock) != "undefined")
            {
                QuickDepositBlock.showDepositPopupIfNeed();
            }
            this.setBetInProcess(false, true);
            this.setError($dict.bs("NotEnoughFunds"));
            return;
        }

        if ((lines.length == 0 && multiLineData.length == 0))
        {
            this.setBetInProcess(false);
            this.updateView();
            return;
        }

        if (multiLineData.length > 0)
        {
            //We always need cast selections, even without deposits
            for (var key in BetSlip.Selections)
            {
                var item = BetSlip.Selections[key];
                var deposit = this.Deposits[key];

                if (!deposit && item.CastSelection)
                {
                    var deps = this.Deposits;
                    var isInValidMultiLineItem = BetSlip.Selections.any(function (sel) { return (sel instanceof MultiLineItem && sel.Selections.contains(item.ViewKey) && deps[sel.ViewKey] > 0); });
                    if (isInValidMultiLineItem)
                    {
                        lines.push(item);
                        deposits.push(0);
                    }
                }
            }
        }

        var ref = this;

        BetSlip.disablePlaceBetButton();

        if (BetSlip.PlaceSingleBetsWithMultiplePurchases)
        {
            //Every single as separate purchase
            BettingPageMethods.placeMultiPurchase(this.formatMultiPurchaseRequest(lines, deposits, multiLineData, freeBets),
                function (res)
                {
                    var purchases = eval(res);
                    ref.placePurchaseCallBack(purchases);
                },
                this.placePurchaseCallBackError,
                this
            );
        }
        else
        {   //All bets as a single purchase
            BettingPageMethods.placeSinglePurchase(this.formatPurchaseRequest(lines, deposits, null, null, null, null, null, multiLineData, freeBets),
                function (res)
                {
                    var purchases = [eval(res)];
                    ref.placePurchaseCallBack(purchases);
                },
                this.placePurchaseCallBackError,
                this
            );
        }
        // Suppress an unexpected JS error on updateView() so that it cannot stop the "bet in process" state
        try { this.updateView(); } catch (e) { if (console && console.log) console.log(e); }
    },

    parsePlaceBets: function (result, keepPreviousPurchase)
    {
        var bets = [], setStakeForAllElement = document.getElementById("setStakeForAll");
        var selections = [];

        if (setStakeForAllElement) setStakeForAllElement.value = "";
        this.AllBetsDeposit = 0;

        if (result == -1) // User is not logged in
            return;

        switch (result.Status)
        {
            case 1:
                {//Accepted
                    for (selection in BetSlip.Selections)
                    {
                        this.StakeUpdatedByUser[selection.ViewKey] = false;
                    }
                }
            case 3: //Waiting
                {
                    for (var k in result.Bets)
                    {
                        BetSlip.CurrentMode.SaveReservedFreeBetsInStorage(result.Bets[k].FreeBetID);
                    }
                    SPPurchaseBuilder.createSingleSlipPurchase(result, BetSlip.Selections, keepPreviousPurchase, false)

                    UserInfo.updateBalance();

                    break;
                }
            case 2: //Rejected
            case -10: // generic error
                {
                    this.setPurchaseError(result);
                    break;
                }
            case -1: //Selections Error
                {
                    var errorsHelper = new BetSlipErrorMessagesHelper();
                    for (var sIdx in result.Selections)
                    {
                        var line = result.Selections[sIdx];
                        var selection = Array.find(BetSlip.Selections, function (item) { return item.ViewKey == line.ViewKey; });
                        if (!selection) continue;

                        this.Errors[selection.ViewKey] = null;

                        if (!line.IsEachWaySelection) selection.processBetUpdate(line);

                        this.updateSelection(selection);
                        this.updateSelectionData(selection);

                        var showError = (!BetSlip.isAlertMessageEnabled || (BetSlip.isAlertMessageEnabled && !selection.UpdatedOdds && !selection.UpdatedPoints))
                        if (showError)
                        {
                            selection.UpdatedOdds && (line.ErrorText = $dict.bs("OddsChanged"));
                            selection.UpdatedPoints && (line.ErrorText = $dict.bs("PointsChanged"));
                            selection.UpdatedOdds && selection.UpdatedPoints && (line.ErrorText = $dict.bs("AllChanged"));

                            var translatedError = this.translateBetSlipError(line, selection, false);
                            this.Errors[selection.ViewKey] = translatedError;

                            // if this is a QASelection, the error should be set on related MultiLineItems as well
                            if (selection instanceof QASlipSelection)
                            {
                                var mitems = selection.findRelatedMultiLineItems();
                                for (var i in mitems)
                                    this.Errors[mitems[i].ViewKey] = translatedError;
                            }
                        }

                        if (selection.Live && (line.Status == -2))
                        {
                            selection.Valid = false;
                            selection.LineClosed = true;
                            selection.Error = $dict.bs("LineClosed");
                        }

                        errorsHelper.AlertIfSelectionUpdated(selection);
                    }

                    break;
                }
            case -2: //Bets Error
                {
                    for (var bIdx in result.Bets)
                    {
                        var bet = result.Bets[bIdx];
                        var selID = bet.SelectionsMapped[0].SelectionID;
                        var line = Array.find(result.Selections, function (item) { return item.ID == selID; });
                        if (!line) continue;

                        var selection = Array.find(BetSlip.Selections, function (item) { return item.ViewKey == line.ViewKey; });
                        if (!selection) continue;

                        this.Errors[selection.ViewKey] = null;

                        if (!line.IsEachWaySelection) selection.processBetUpdate(line);

                        this.updateSelection(selection);
                        this.updateSelectionData(selection);

                        if (selection.UpdatedOdds) line.ErrorText = $dict.bs("OddsChanged");
                        if (selection.UpdatedPoints) line.ErrorText = $dict.bs("PointsChanged");
                        if (selection.UpdatedOdds && selection.UpdatedPoints) line.ErrorText = $dict.bs("AllChanged");
                        this.setError(this.translateBetSlipError(bet, selection, false));

                        if (selection.Live && (line.Status == -2))
                        {
                            selection.Valid = false;
                            selection.LineClosed = true;
                            selection.Error = $dict.bs("LineClosed");
                        }
                    }

                    break;
                }
            case -3: //Free bet error
                {
                    for (var idx in result.FreeBets)
                    {
                        var fBet = result.FreeBets[idx];
                        var viewKey = fBet.MappingKey;
                        var selection = BetSlip.Selections[viewKey];
                        if (!selection) continue;

                        this.Errors[selection.ViewKey] = this.translateBetSlipError(fBet, selection, false);
                        selection.FreeBet = null;
                    }

                    break;
                }
            case -4: //Betting limit error
                {
                    var errorText = $string('General').BettingLimitHit;
                    errorText = errorText.replace('$VALUE$', UserInfo.current.toUserFormatedCurrencyString(result.RemainingBettingLimit));
                    this.setError(errorText);
                    BetSlip.sendBetLimitsErrorToJSApi(result);
                    break;
                }
            case -18:
                {
                    var errorText = this.getVerificationErrorMessage(result.ErrorCode);
                    this.setError(errorText);
                    break;
                }
        }
    },

    setError: function (error)
    {
        var element = document.getElementById("SingleStateAllStakeBox"),
          paragraphElement;
        this.CurrentError = error;
        if (element)
        {
            if (!error)
            {
                element.style.display = "none";
            }
            else
            {
                element.style.display = "block";
                paragraphElement = element.getElementsByTagName("p")[0];
                if (paragraphElement) paragraphElement.innerHTML = error;
            }
        }
        BetSlip.updateScrollbar();
    },

    updateEachWay: function (viewKey)
    {
        var item = BetSlip.Selections[viewKey];

        if (item.BetType == 7)
        {
            if (item.EachWaySingleIncluded)
            {
                item.EachWayIncluded = false;
                item.EachWaySingleIncluded = false;
            }
            else
            {
                item.EachWayIncluded = true;
                item.EachWaySingleIncluded = true;
            }
        }

        var cDep = this.Deposits[viewKey];
        if (cDep >= 0)
        {
            this.recalcEachWay(viewKey);
        }

        BetSlip.updateView();
        BetSlip.saveState();
        BetSlip.updateBSFooter();
    },

    getEachWayTotalGain: function ()
    {
        if (Array.getLength(this.Deposits) == 0) return 0;
        var eachWayTotalGain = 0;
        var currentDeposit = 0;

        for (var idx in BetSlip.Selections)
        {
            var item = BetSlip.Selections[idx];

            if (item.BetType != 7) continue;
            if (item.EachWaySingleIncluded == false) continue;

            currentDeposit = this.Deposits[item.ViewKey];

            if (typeof (currentDeposit) == "undefined" || currentDeposit <= 0) continue;

            if (item.isSPSelection())
            {
                item.EWDeposit = currentDeposit;
                return 0;
            }

            currentDeposit = UserInfo.TaxProvider.applyTaxToDeposit(currentDeposit);
            item.recalcEWGain(currentDeposit);

            eachWayTotalGain += item.EWGain;
        }

        var roundingMode = typeof BetSlip != "undefined" && BetSlip.GainRoundingMode || 0;
        var roundedGain = GainUtil.round(eachWayTotalGain, roundingMode, 6);

        return roundedGain;
    },

    recalcEachWay: function (viewKey)
    {
        var item = BetSlip.Selections[viewKey];
        if (!item) return;
        if (item.BetType != 7 && !item.EachWaySingleIncluded) return;

        var currentDeposit = this.Deposits[item.ViewKey];

        if (item.isSPSelection())
        {
            item.EWOdds = 0;
            item.EWGain = 0;
        }
        else
        {
            item.recalcEWGain(currentDeposit);
        }
    },

    getAvailableFreeBets: function (selection, isAllowedDuplicates)
    {
        var freeBets = [];
        var balance = 0;
        if (UserInfo.current && UserInfo.current.isBalancesInitiated())
        {
            freeBets = UserInfo.current.FreeBets;
            balance = UserInfo.current.getBalance();
        }
        else
        {
            freeBets = UserInfo.getFreeBetsFromStorage();
        }

        var tokens = [];
        for (var i in freeBets)
        {
            var freeBet = freeBets[i];
            var reservedFreeBets = BetSlip.CurrentMode.getReservedFreeBets();
            var notAvailableFreeBets = Array.find(reservedFreeBets.ReservedFreeBets, function (e)
            {
                return e == freeBet.BonusID;
            });
            if (notAvailableFreeBets) continue;

            //Avoid duplicating free bets in selection
            if (!isAllowedDuplicates)
            {
                if (Array.find(BetSlip.Selections, function (sel) { return (sel.FreeBet && sel.ViewKey != selection.ViewKey && sel.FreeBet.BonusID == freeBet.BonusID); }) ||
                    Array.find(BetSlip.getSelectedPulseFreeBets(), function (bonusID) { return bonusID == freeBet.BonusID; }))
                    continue;
            }

            if (!BetSlip.SkipClientUserBalanceCheckForSingles && freeBet.IsRiskFreeBet && balance < freeBet.Amount)
            {
                continue;
            }

            if (freeBet.IsAllowedForLine(selection, selection.BetType))
                tokens.push(freeBet);
        }

        return tokens;
    },

    getFreeBetDeposit: function ()
    {
        var fbd = 0;
        for (var vk in BetSlip.Selections)
        {
            var sel = BetSlip.Selections[vk];
            if (!sel || !sel.FreeBet) continue;
            var dep = this.Deposits[vk];
            fbd += sel.FreeBet.GetFreeBetDeposit(dep);
        }
        return fbd;
    },

    setStakeToAllDropDown: function ()
    {
        var dropdown = document.getElementById("dropdownForAllBets");

        var element = document.getElementById("setStakeForAll");
        element.value = dropdown.options[dropdown.selectedIndex].text;
        BetSlip.CurrentMode.setStakeToAll();
    },
    // fills selection stake with free bet amount
    setFreeBetStake: function (viewKey, freeBet)
    {
        var stakeUpdatedByUser = false;
        var selection = BetSlip.Selections[viewKey];
        if (selection)
        {
            stakeUpdatedByUser = selection.StakeUpdatedByUser;
        }

        stakeUpdatedByUser = false; // comment to revert change

        // update deposit with free bet amount if not already populated
        if (!stakeUpdatedByUser)
        {
            var amount = freeBet ? freeBet.Amount : 0;
            var stakeElement = document.getElementById("stake_" + selection.ViewKey);
            if (stakeElement)
            {
                stakeElement.value = BetSlip.getStakeBoxValue(amount);
                this.setStake(viewKey, stakeElement);
            }
            selection.StakeUpdatedByUser = false;
        }
    },

    CheckForSPIncluded: function ()
    {
        var selections = BetSlip.Selections;
        var deposits = this.Deposits;
        return (selections.any(function (sel) { return (sel.isSPSelection() && deposits[sel.ViewKey] > 0); }));
    },

    CheckForNoOddsSelectionIncluded: function ()
    {
        var deposits = this.Deposits;
        return (BetSlip.Selections.any(function (sel) { return (sel.Odds == 0 && deposits[sel.ViewKey] > 0); }));
    },

    getSingleReturn: function (viewKey)
    {
        var totalGain = 0;

        var sel = BetSlip.Selections[viewKey];
        var currentDeposit = this.Deposits[viewKey] || 0;

        if (sel && sel.Valid)
        {
            if (currentDeposit <= 0) return 0;

            if (sel.isCastSelection() && sel.Odds == 0)
            {
                return $dict.bs("NotAvailable");
            }

            if (sel.isSPSelection())
            {
                sel.EWDeposit = currentDeposit;
                return $dict.bs("NotAvailable");
            }

            // If Decimal style is switched to Malay we would have to calculate the "to win" value from the deposit.
            // The check for this situation is checked here because getTotalGain is called from updateView on the 
            // event listener of changing the current odds style. If check is true then visaul update should be set to be pending. 
            // Updating the selection box here will not work because this method in this situation is called from a JS template method.
            this.recalcToWinSelections(viewKey);

            var currentDepositAfterTax = currentDeposit;

            if (sel.isToWin())
            {
                totalGain += this.floorDecimal(currentDeposit + this.ToWins[viewKey]);
            }
            else
            {
                currentDepositAfterTax = UserInfo.TaxProvider.applyTaxToDeposit(currentDeposit);
                totalGain += sel.getGain(currentDepositAfterTax);
            }

            if (sel.BetType == 7 && sel.EachWaySingleIncluded)
            {
                sel.recalcEWGain(currentDepositAfterTax);
                totalGain += sel.EWGain;
            }

            if (sel.FreeBet)
            {
                var freeBetContribution = sel.FreeBet.GetFreeBetDeposit(currentDeposit) - sel.FreeBet.getRiskFreeBetDeposit(currentDeposit);
                totalGain = totalGain - freeBetContribution;
            }
        }
        totalGain = totalGain.toFixed(12);
        var roundingMode = typeof BetSlip != "undefined" && BetSlip.GainRoundingMode || 0;
        var roundedGain = GainUtil.round(totalGain,roundingMode, 6);
        return Bets.formatCurrencyStake(roundedGain, UserInfo.current ? BetSlip.UserCurrencyCode: "");
    }

}, TSingleSPSlipMode);

SingleSPSlipMode.setFreeBet = function (select, viewKey)
{
    var mode = BetSlip.CurrentMode;
    if (typeof SingleSPSlipMode != "undefined" && !(mode instanceof SingleSPSlipMode) || !select) return;

    var bonusID = select.value * 1;

    var selection = BetSlip.Selections[viewKey];

    if (!selection) return;

    var freeBet = UserInfo.current.FreeBets[bonusID];
    if (freeBet)
    {
        if (Array.find(BetSlip.Selections, function (sel) { return (sel.FreeBet != null && sel.ViewKey != selection.ViewKey && sel.FreeBet.BonusID == freeBet.BonusID); })) return;

        if (freeBet.IsAllowedForLine(selection, selection.BetType))
        {
            selection.FreeBet = freeBet;
            mode.setFreeBetStake(viewKey, freeBet);
        }
    }
    else if (bonusID == 0)
    {
        selection.FreeBet = null;
        mode.setFreeBetStake(viewKey);
    }

    BetSlip.updateView();
    BetSlip.updateBSFooter();
    BetSlip.saveState();
}
SingleSPSlipMode.setStakeDropDown = function (viewKey, select)
{
    var element = document.getElementById("stake_" + viewKey);
    element.value = select.value * 1;
    BetSlip.CurrentMode.setStake(viewKey, element);
}

window.SingleSPSlipMode = SingleSPSlipMode;
includeExtension("/JSComponents/Data/UniSlip/Modes/SingleSPSlipMode.ext.js");
