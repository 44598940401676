﻿var BaseCashoutPurchaseBetDrawer =
{
    __hosts: [],

    registerHost: function (host)
    {
        'use strict';

        if (this.__hosts.indexOf(host) < 0)
        {
            this.__hosts.push(host);
        }
    },

    unregisterHost: function (host)
    {
        'use strict';

        var indexOfHost = this.__hosts.indexOf(host);
        if (indexOfHost >= 0)
        {
            this.__hosts.splice(indexOfHost, 1);
        }
    },

    getAllHosts: function()
    {
        // for backward compatibility add empty host only for WEB SITE
        return this.__hosts.concat([{ BlockUniqueID: '' }]);
    },

    getElementsFromAllHosts: function (elementId)
    {
        'use strict';

        var elements = [];
        var hosts = this.getAllHosts();
        for (var i in hosts)
        {
            var hostName = hosts[i].BlockUniqueID;
            var element = document.getElementById(hostName + elementId);
            if (element) {
                elements[hostName] = element;
            }
        }

        return elements;
    },

    foreachUIElement: function(elementId, func)
    {
        'use strict';

        var betElements = this.getElementsFromAllHosts(elementId);
        if (!Array.isEmpty(betElements))
        {
            for (var i in betElements)
            {
                var currentHost = this.__hosts.find(function (host)
                {
                    return host.BlockUniqueID === i;
                });

                func(betElements[i], currentHost);
            }
        }
    },

    foreachBetUIElement: function(bet, func)
    {
        this.foreachUIElement(bet.UIElementId, func);
    },

    redrawBet: function (bet, buildBet)
    {
        'use strict';

        var betElement = document.getElementById(bet.UIElementId);
        if (!betElement)
        {
            return false;
        }

        var __html = [];

        buildBet(__html, bet);

        var elementToInsertBefore = betElement.nextSibling;
        var purchaseElement = betElement.parentNode;
        purchaseElement.removeChild(betElement);

        if (elementToInsertBefore)
        {
            elementToInsertBefore.parentNode && elementToInsertBefore.parentNode.insertBefore(__html.join(''), elementToInsertBefore);
        }
        else
        {
            purchaseElement.insertAdjacentHTML('beforeend', __html.join(''));
        }

        return true;
    },

    removeBet: function (bet)
    {
        'use strict';

        if (!bet)
        {
            return false;
        }

        var betEl = document.getElementById(bet.UIElementId);
        if (!betEl)
        {
            return false;
        }

        var purchaseEl = betEl.parentNode;
        purchaseEl.removeChild(betEl);

        return true;
    },

    onCashoutAvailabilityChanged: function (bet, doshow) 
    {
        this.__hosts.forEach(function (host)
        {
            host.IsCashOutEnabled && typeof host.onCashoutAvailabilityChanged === 'function' && host.onCashoutAvailabilityChanged(bet, doshow);
        });

        this.showHideCashOutButton(bet, doshow);
    },

    showHideCashOutButton: function (bet, doshow)
    {
        'use strict';

        this.foreachBetUIElement(bet, function (betEl) 
        {
            if (doshow) 
            {
                betEl.classList.add(bet.getHasCashOutClassName());
            }
            else 
            {
                betEl.classList.remove(bet.getHasCashOutClassName());
            }
        });
    },

    showHideDangerNotification: function (bet, doshow)
    {
        'use strict';

        var dangerEl = document.getElementById(bet.HasCashOutDangerUIElementId);
        if (doshow)
        {
            if (!dangerEl)
            {
                var __html = [];
                bet.Purchase.buildCashOutDanger(__html, bet);
                var el = document.getElementById(bet.UIElementId);
                if (el)
                {
                    el.insertAdjacentHTML('beforeend', __html.join(''));
                }
            }
        }
        else
        {
            if (dangerEl)
            {
                dangerEl.parentNode.removeChild(dangerEl);
            }
        }
    },

    showHideErrorMessage: function (bet, doshow, buildBetError)
    {
        'use strict';

        var errorElement = document.getElementById(bet.ErrorUIElementId);
        if (doshow) {
            if (!errorElement)
            {
                var __html = [];
                buildBetError(__html, bet);

                var el = document.getElementById(bet.UIElementId);
                if (el)
                {
                    el.insertAdjacentHTML('beforeend', __html.join(''));
                }
            }
        }
        else
        {
            if (errorElement)
            {
                errorElement.parentNode.removeChild(errorElement);
            }
        }
    },

    updateCashOutButtonAmounts: function (bet, amountStr, percentageStr)
    {
        'use strict';

        this.foreachUIElement(bet.CashOutButtonPercentageUIElementId, function (percentageEl) {
            if (percentageEl) {
                percentageEl.style.left = percentageStr;
            }
        });

        this.foreachUIElement(bet.CashOutButtonAmountUIElementId, function (amountEl) {
            if (amountEl) {
                amountEl.textContent = amountStr;
            }
        });
    },

    refreshPartialCashoutAvailability: function(betId, purchaseId)
    {
        'use strict';

        var spMyBetsPurchase = SPMyBetsCache.getInstance().getPurchaseById(purchaseId);
        if (!spMyBetsPurchase)
        {
            return;
        }

        var spMyBetsBet = spMyBetsPurchase.Bets[betId];
        if (!spMyBetsBet)
        {
            return;
        }

        spMyBetsBet.LastPartialCashoutAmount = 0;

        var hosts = this.getAllHosts();
        for (var i in hosts)
        {
            var host = hosts[i].BlockUniqueID;
            var uiElements = getUIElements(betId, host, spMyBetsBet.UIElementId);
            if (uiElements && uiElements.btnElement)
            {
                if (spMyBetsBet.isPartialCashoutAllowed() && spMyBetsBet.CashOutStatus != SPPurchaseStatus.NewOffer)
                {
                    this.showCashoutButtons(uiElements);

                    //Refresh Bank My Stake
                    if (spMyBetsBet.CashoutSlider)
                    {
                        SPMyBetsPurchase.bankMyStakePanel.refresh(spMyBetsBet.CashoutSlider, spMyBetsBet, betId, purchaseId);
                    }
                }
                else
                {
                    this.hideCashOutButtons(uiElements);
                }
            }
        }
    },

    /* jshint maxcomplexity: 14, maxstatements: 36 */
    showHidePartialCashout: function (betId, purchaseId, toggle, host)
    {
        'use strict';

        var spMyBetsPurchase = SPMyBetsCache.getInstance().getPurchaseById(purchaseId);
        if (!spMyBetsPurchase)
        {
            return;
        }

        var spMyBetsBet = spMyBetsPurchase.Bets[betId];
        if (!spMyBetsBet)
        {
            return;
        }

        spMyBetsBet.LastPartialCashoutAmount = 0;
        if (spMyBetsBet.CashOutConfirmationState && toggle)
        {
            clearCashOutConfirmation(spMyBetsBet);
        }

        var i, hosts;

        if (!host)
        {
            host = '';
        }

        if (spMyBetsBet.isPartialCashoutAllowed() && spMyBetsBet.CashOutStatus != SPPurchaseStatus.NewOffer) {

            // if we are to show this host, all the rest should be hidden
            var hostToExpand = null;
            var hostsToCollapse = [];

            hosts = this.getAllHosts();
            for (i in hosts)
            {
                var currentHost = hosts[i].BlockUniqueID;
                var uiElements = getUIElements(betId, currentHost);
                if (uiElements && uiElements.btnElement)
                {
                    uiElements.btnElement.classList.remove('isHidden');

                    var isExpanded = uiElements.btnElement.classList.contains('expanded');
                    if (currentHost === host && !isExpanded)
                    {
                        hostToExpand = currentHost;
                    }
                    else
                    {
                        hostsToCollapse.push(currentHost);
                    }
                }
            }

            for (i in hostsToCollapse)
            {
                initCashOutSlider(spMyBetsBet, betId, purchaseId, true, hostsToCollapse[i]);
            }

            if (hostToExpand !== null)
            {
                initCashOutSlider(spMyBetsBet, betId, purchaseId, false, hostToExpand);
            }

            //Refresh Bank My Stake
            if (spMyBetsBet.CashoutSlider)
            {
                SPMyBetsPurchase.bankMyStakePanel.refresh(spMyBetsBet.CashoutSlider, spMyBetsBet, betId, purchaseId);
            }
        }
        else
        {
            hosts = this.getAllHosts();
            for (i in hosts)
            {
                var uiElementsToHide = getUIElements(betId, hosts[i].BlockUniqueID);
                if (uiElementsToHide && uiElementsToHide.btnElement)
                {
                    hideCashOutButtons(uiElementsToHide);
                }
            }
        }
    },

    hidePartialCashout: function (bet)
    {
        'use strict';
        var hosts = this.getAllHosts();
        for (var i in hosts)
        {
            var host = hosts[i].BlockUniqueID;
            var uiElements = getUIElements(bet.BetID, host);
            if (uiElements && uiElements.btnElement)
            {
                //var divId = 'myBets_partialCashoutDiv_' + bet.BetID;
                //var container = document.getElementById(divId);

                //hide and destroy slider
                toggleSlider(uiElements, false);
                destroySlider(bet);
            }
        }
    },


    redrawCashoutConfirmationState: function (bet, cashOutConfirmationState, isCashOutAllowed)
    {
        'use strict';

        var betId = bet.BetID;

        this.foreachUIElement('mainCashoutBtn_' + betId, function (cashOutbtnElement)
        {
            cashOutbtnElement.classList.remove(BaseCashoutPurchaseBetDrawer.getCashOutConfirmationErrorClass());
            cashOutbtnElement.classList.remove(BaseCashoutPurchaseBetDrawer.getCashOutConfirmationSuccessClass());

            if (cashOutConfirmationState)
            {
                var cls = isCashOutAllowed ?
                    BaseCashoutPurchaseBetDrawer.getCashOutConfirmationSuccessClass() :
                    BaseCashoutPurchaseBetDrawer.getCashOutConfirmationErrorClass();

                cashOutbtnElement.classList.add(cls);
            }
        });

        if (cashOutConfirmationState && !isCashOutAllowed)
        {
            clearTimeout(bet.CashOutConfirmationTimeoutID);
            if (!bet.CashOutConfirmationErrorTimeOutId)
            {
                bet.CashOutConfirmationErrorTimeOutId = setTimeout(
                    function (bet)
                    {
                        bet.CashOutConfirmationTimeoutID = null;
                        bet.CashOutConfirmationErrorTimeOutId = null;
                        bet.CashOutConfirmationState = false;
                        BaseCashoutPurchaseBetDrawer.redrawCashoutConfirmationState(bet, false, true);

                        this.foreachUIElement('mainCashoutBtn_' + bet.BetID, function (btnElement)
                        {
                            btnElement.classList.add('isHidden');
                        });

                    }.bind(this),

                    BetSlip.CashOutConfirmationTimeOut * 1000,
                    bet);
            }
        }

        var msg = cashOutConfirmationState ? $dict.bs('CashOutConfirmation') : $dict.bs('CashOut');
        if (cashOutConfirmationState && !isCashOutAllowed)
        {
            msg = $dict.bs('CashOutSuspended');
        }

        this.foreachUIElement('mainCashoutMsg_' + betId, function (msgElement)
        {
            msgElement.innerHTML = msg;
        });
    },

    getCashOutConfirmationSuccessClass: function ()
    {
        'use strict';

        return 'waiting';
    },

    getCashOutConfirmationErrorClass: function ()
    {
        'use strict';

        return 'error';
    },

    adjustPartialCashoutScroll: function (containerID)
    {
        'use strict';

        //For web UI.Scrollbar in My Bets only
        if (typeof BetSlip.myBetsScrollbar === 'undefined')
        {
            return false;
        }

        //if My Bets contains small amount of bets then myBetsScrollbar.container is not initialized and we should not do jump
        if (!BetSlip.myBetsScrollbar.container)
        {
            return false;
        }

        var container = document.getElementById(containerID);

        var winScrollTop = window.scrollY || document.documentElement.scrollTop;
        var containerTop = container.getBoundingClientRect().top + winScrollTop;
        var containerBottom = containerTop + container.offsetHeight;
        var scrollPanelTop = BetSlip.myBetsScrollbar.container.getBoundingClientRect().top + winScrollTop;
        var scrollPanelBottom = scrollPanelTop + BetSlip.myBetsScrollbar.container.offsetHeight;

        if (containerBottom > scrollPanelBottom)
        {
            BetSlip.myBetsScrollbar.jump((containerBottom - scrollPanelBottom) + BetSlip.myBetsScrollbar.delta + 10);
        }
    },

    hideCashOutButtons: function (uiElements)
    {
        hideCashOutButtons(uiElements);
    },

    showCashoutButtons: function (uiElements)
    {
        'use strict';
        uiElements.btnElement.classList.remove('isHidden');
    }
};

function toggleSlider(uiElements, doshow)
{
    'use strict';
    if (doshow)
    {
        uiElements.container.classList.remove('isHidden');
        uiElements.btnElement.classList.add('expanded');
    }
    else
    {
        uiElements.container.classList.add('isHidden');
        uiElements.btnElement.classList.remove('expanded');
    }
}

function destroySlider(spMyBetsBet)
{
    'use strict';
    if (spMyBetsBet.CashoutSlider)
    {
        spMyBetsBet.CashoutSlider.destroy();
        spMyBetsBet.CashoutSlider = null;
        return true;
    }
    return false;
}

function clearCashOutConfirmation(spMyBetsBet)
{
    'use strict';
    spMyBetsBet.CashOutConfirmationState = false;
    if (spMyBetsBet.CashOutConfirmationTimeoutID)
    {
        clearTimeout(spMyBetsBet.CashOutConfirmationTimeoutID);
        spMyBetsBet.CashOutConfirmationTimeoutID = null;
    }
    spMyBetsBet.CashOutConfirmationTimeoutID = null;
}

function initCashOutSlider(spMyBetsBet, betId, purchaseId, isExpanded, host)
{
    'use strict';
    var uiElements = getUIElements(betId, host);

    //Confirmation State
    BaseCashoutPurchaseBetDrawer.redrawCashoutConfirmationState(spMyBetsBet, spMyBetsBet.CashOutConfirmationState, true);

    if (!isExpanded) //show and init slider
    {
        toggleSlider(uiElements, true);

        destroySlider(spMyBetsBet);
        spMyBetsBet.CashoutSlider = new UISlider(spMyBetsBet.CashOutData.MinimalPartialCashoutAmount, spMyBetsBet.CashOutData.Amount, 'cashoutSlider');

        var cashoutSlider = spMyBetsBet.CashoutSlider;
        cashoutSlider.init(uiElements.divId);
        SPMyBetsPurchase.bankMyStakePanel.init(cashoutSlider);
        SPMyBetsPurchase.bankMyStakePanel.refresh(cashoutSlider, spMyBetsBet, betId, purchaseId);

        cashoutSlider.setValue(spMyBetsBet.CashOutData.Amount);
        cashoutSlider.registerChangeHandler(spMyBetsBet.onCashoutSliderChange, spMyBetsBet);
        spMyBetsBet.updateCashOutData(spMyBetsBet.CashOutData);

        BaseCashoutPurchaseBetDrawer.adjustPartialCashoutScroll(host + 'myBets_partialCashoutDiv_' + betId);
    }
    else //hide and destroy slider
    {
        toggleSlider(uiElements, false);
        var isSliderDestroyed = destroySlider(spMyBetsBet);
        if (isSliderDestroyed)
        {
            spMyBetsBet.updateCashOutData(spMyBetsBet.CashOutData);
        }
    }
}

function getUIElements(betId, host, betUIElemId)
{
    'use strict';
    var btnElement = document.getElementById(host + 'myBets_partialCashoutBtn_' + betId);
    var divId = host + 'myBets_partialCashoutDiv_' + betId;
    var container = document.getElementById(divId);
    if (betUIElemId) {
        var betUIElementId = document.getElementById(host + betUIElemId);
    }

    return { btnElement: btnElement, divId: divId, container: container, betUIElementId: betUIElementId };
}

function hideCashOutButtons(uiElements)
{
    'use strict';
    uiElements.btnElement.classList.add('isHidden');
    uiElements.btnElement.classList.remove('expanded');
    uiElements.container.classList.add('isHidden');
}

window.BaseCashoutPurchaseBetDrawer = BaseCashoutPurchaseBetDrawer;