var BetSlipStyles = (function ()
{
    var api = {}

    api.getPointsUpdatedClassInBetSlipSelection = function (mode, selection) {
        var updated = "";
        if (typeof mode.isSelectionPropUpdated == "function" && mode.isSelectionPropUpdated(selection.ViewKey, BetSlipSelectionPropCasesWithBlinking.Points)
            || selection.UpdatedPoints)
        {
            updated = "updated";
        }       

        return updated;
    };

    api.getOddsUpdatedClassInBetSlipSelection = function (mode, selection) {
        var updated = "";
        if (typeof mode.isSelectionPropUpdated == "function" && mode.isSelectionPropUpdated(selection.ViewKey, BetSlipSelectionPropCasesWithBlinking.Odds)) {
            updated = "updated";
        }
        else if (typeof mode.isSelectionOddUpdated == "function" && mode.isSelectionOddUpdated(selection)) {
            // used only in OBS
            updated = "updated";
        }

        return updated;
    };

    api.getScoreUpdatedClassInBetSlipSelection = function (mode, selection) {
        var updated = "";
        if (typeof mode.isSelectionPropUpdated == "function" && mode.isSelectionPropUpdated(selection.ViewKey, BetSlipSelectionPropCasesWithBlinking.Score)
            || selection.UpdatedScore)
        {
            updated = "updated";
        }     

        return updated;
    };

    api.getResponsiveMessageClass = function (unislip, isError) {
        var messageClass = "";
        if (BetSlip.isNotLoggedInMessage) {
            messageClass = "betSlip-message-login";
        }
        else {
            messageClass = isError ? "error betSlip-message-warning" : "success betSlip-message-loader";
        }

        return messageClass;
    };

    return api;
}());

window.BetSlipStyles = BetSlipStyles;