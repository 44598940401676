﻿function QALiveSlipSelection(masterEventID, eventID, lineID, Odds, Score1, Score2, eventTypeId, leagueId, lineTypeID)
{
    QASlipSelection.call(this, masterEventID, eventID, lineID, Odds, eventTypeId, leagueId, lineTypeID);

    this.Score1 = Score1;
    this.Score2 = Score2;
    this.Live = true;
}

$.extend(QALiveSlipSelection.prototype, QASlipSelection.prototype,
{

    getUpdateRequest: function ()
    {
        return [
            !!this.IsMultiLine,
            this.ViewKey,
            this.EventID,
            this.EventType,
            this.LineID,
            this.Odds,
            this.Score1,
            this.Score2
        ];
    },

    getPostRequest: function (deposit)
    {
        var res = [
            this.ViewKey,                           //0
            this.EventID,                           //1
            this.EventType,                         //2
            this.LineID,                            //3
            this.Odds,                              //4
            deposit,                                //5
            this.Score1,                            //6
            this.Score2,                            //7
            (currentOddStyle == OddStyle.FRACTIONAL ? FractionalOddsConverter.encodeFraction(this.getFormatedOdds()) : this.getFormatedOdds() * 1)
                + ":" + this.getActualOddsStyleID(),//8 - actual adds, the odds that we are actually showing to the client : actual odd style id
             0,                                     //9 - autorization
             (this.QAParameter2 == -1 ? 0 : this.QAParameter2), // 10 - qaParameter2 from client side
            this.EachWayIncluded,                   //11
            this.IsEachWayEnabled,                  //12
            this.PlaceTermsID,                      //13
            this.OddsTermsID,                       //14
            this.EachWayTermsID,                    //15
            this.EWOdds,                            //16
            this.EWDeposit,                         //17
            this.EWGain                             //18
        ];

        return res;
    },

    processBetUpdate: function (init, firstcall)
    {
        this.Valid = true;

        QASlipSelection.prototype.processBetUpdate.call(this, init);
    },

    processHighlightUpdates: function (updates, firstcall)
    {
        var previousQAParameter1 = this.QAParameter1;
        var previousQAParameter2 = this.QAParameter2;

        QASlipSelection.prototype.processHighlightUpdates.call(this, updates, firstcall);

        this.Updated = this.processFastMarketsUpdate(updates, firstcall, previousQAParameter1, previousQAParameter2) || this.Updated;
    },

    processFastMarketsUpdate: function (updates, firstcall, previousQAParameter1, previousQAParameter2)
    {
        if (firstcall)
        {
            return false;
        }

        if (typeof previousQAParameter1 == "undefined" && typeof previousQAParameter2 == "undefined")
        {
            return false;
        }

        if (isTennisFastMarket(this.SplitType))
        {
            var previousScore = new TennisFastMarket.ScoreInfo(previousQAParameter1);
            var newScore = new TennisFastMarket.ScoreInfo(this.QAParameter1);

            this.UpdatedMatchSet = previousScore.MatchSet != newScore.MatchSet;
            this.UpdatedSetGame = previousScore.SetGame != newScore.SetGame;
            this.UpdatedGamePoint = previousScore.GamePoint != newScore.GamePoint;

            var tennisMarketUpdated = this.UpdatedMatchSet || this.UpdatedSetGame || this.UpdatedGamePoint || this.LineClosed;

            if (tennisMarketUpdated && BetSlip.config.DisallowModifiedFastMarketBets) {
                this.closeLine();
            }

            return tennisMarketUpdated;
        }

        if (isFastMarket(this.SplitType))
        {
            var fastMarket = new FastMarket(this.SplitType);
            
            var previousIntervals = fastMarket.getFastMarketInterval(previousQAParameter2);
            var newIntervals = fastMarket.getFastMarketInterval(this.QAParameter2);

            this.UpdatedGameInterval = previousIntervals[0] != newIntervals[0] || previousIntervals[1] != newIntervals[1] || this.LineClosed;

            if (this.UpdatedGameInterval && BetSlip.config.DisallowModifiedFastMarketBets) {
                this.closeLine();
            }

            return this.UpdatedGameInterval;
        }

        return false;
    },

    serialize: function ()
    {
        return $.extend({}, QASlipSelection.prototype.serialize.call(this),
           {
               Live: true,
               IsDanger: this.Danger,

               comboRate: this.ComboRate,
               ScoreChanged: [this.Score1, this.Score2]
           });
    },
    getUniPostRequest: function (deposit)
    {
        return [
            this.ViewKey,             // 0 SlotID
            this.EventID,            // 1 EventID
            this.EventType,        // 2 EventTypeID fix your bullshit
            this.LineID,             // 3 LineID
            this.LineTypeID,        // 4 LineTypeID // FIX YOUR BULLSHITS
            this.BetType,            // 5 BetTypeID
            this.LineGroupID,        // 6 LineGroupID
            this.BetSide,            // 7 BetSide
            this.Points,             // 8 Points
            this.Odds,               // 9 Odds
            deposit,                 // 10 deposit
            this.Score1,             // 11 Score1
            this.Score2,             // 12 Score2
            this.IsBuyPoints,        // 13 IsBuyPoints
            this.BuyPointsPoints,    // 14 BuyPointsPoints
            this.BuyPointsOdds,      // 15 BuyPointsOdds
            this.EachWayIncluded,    // 16 IsEWIncluded
            this.IsEachWayEnabled,   // 17 IsEachWayEnabled
            this.PlaceTermsID,       // 18 PlaceTermsID
            this.OddsTermsID,        // 19 OddsTermsID
            this.EachWayTermsID,     // 20 EachWayTermsID
            this.EWOdds,             // 21 EWOdds
            this.EWDeposit,          // 22 EWDeposit
            this.EWGain,             // 23 EWGain
            (currentOddStyle == OddStyle.FRACTIONAL ? FractionalOddsConverter.encodeFraction(this.getFormatedOdds()) : this.getFormatedOdds() * 1)
                + ":" + this.getActualOddsStyleID(), //24 client odds
            this.Authorization,             // 25 Authorization
        ];
    },
});

function addOddQALive(masterEventID, eventID, lineID, Odds, eventTypeId, isPreloaded, leagueId, betId, lineTypeID, qaParameter1, qaParameter2)
{
    var selObj = new QALiveSlipSelection(masterEventID, eventID, lineID, Odds, 0, 0, eventTypeId, leagueId, lineTypeID);
	selObj.BetID = betId;
	qaParameter1 && (selObj.QAParameter1 = qaParameter1);
	qaParameter2 && (selObj.QAParameter2 = qaParameter2);
    BetSlip.add(selObj, isPreloaded);
    return selObj;
}

window.QALiveSlipSelection = QALiveSlipSelection;
window.addOddQALive = addOddQALive;