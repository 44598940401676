var BookedBets = (function (bookABetService)
{
    var loadBetButton, codeInput, errorBlock;
    var hiddenElementClassName = 'isHidden';
    var codeInputErrorClass = 'booked-bets-input-error';

    var loadBookedBet = function ()
    {
        initElements();
        var code = codeInput.value;
        if(code.length > 0)
        {
            loadBetButton.disabled = true;
            bookABetService.GetBookedBet(code, getBookedBetSuccessCallback, getBookedBetFailureCallback);
        }
    };

    var initElements = function ()
    {
        loadBetButton = document.getElementById('betslipBookedBetButton');
        codeInput = document.getElementById('betslipBookedBetInput');
        errorBlock = document.getElementById('betslipBookedBetError');
    };

    var getBookedBetSuccessCallback = function (rawResponse)
    {
        if (rawResponse)
        {
            var responseJson = JSON.parse(rawResponse);
            errorBlock.classList.add(hiddenElementClassName);
            codeInput.classList.remove(codeInputErrorClass);
            fillBetSlip(responseJson);
            codeInput.value = '';
        }
        else
        {
            showErrorMessage();
        }
    };

    var getBookedBetFailureCallback = function ()
    {
        showErrorMessage();
    };

    var showErrorMessage = function ()
    {
        loadBetButton.disabled = false;
        errorBlock.classList.remove(hiddenElementClassName);
        codeInput = document.getElementById('betslipBookedBetInput');
        codeInput.classList.add(codeInputErrorClass);
    };

    var hideErrorMessage = function ()
    {
        initElements();
        if (loadBetButton && errorBlock && codeInput)
        {
            loadBetButton.disabled = false;
            errorBlock.classList.add(hiddenElementClassName);
            codeInput.classList.remove(codeInputErrorClass);
        }
    };
  
    var fillBetSlip = function(betslipState)
    {
        BetSlip.CurrentMode.deactivate();
        BetSlip.setState(betslipState);
        BetSlip.selectSlipMode(betslipState.CurrentMode, true);
    };

    function clear()
    {
        hideErrorMessage();
        codeInput.value = '';
    };


    function clear()
    {
        hideErrorMessage();
        codeInput.value = '';
    };
  
    return {
        loadBookedBet: loadBookedBet,
        clear: clear
    };

})(BookABetService);

window.BookedBets = BookedBets;