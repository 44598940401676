﻿var TSinglePurchase = (function ()
{
    "use strict";
    var api = {}; // use 'api' only to define extension properties and always 'this' inside functions

    // Override for updateView function to redraw the whole BetSlip view instead of using buildInnerView for the currently updated purchase
    api.updateView = function ()
    {
        TUniSlip.buildView();
        TUniSlip.updatePurchaseActions(this);
    };

    // Required by TPurchases.js
    api.getBets = function ()
    {
        return Purchases.getSingleBets(this);
    };

    // Required by TPurchases.js
    api.getTitle = function ()
    {
        return $dict.bs("SingleBetsPurchase");
    };

    // Required by TPurchases.js
    api.getLinesDescription = function ()
    {
        return Purchases.getSingleLinesDescription(this);
    };

    // Required by Data/UniSlip/*Purchase.js functions
    api.getStatusClass = function ()
    {
        return "";
    };

    return api;
}());

window.TSinglePurchase = TSinglePurchase;