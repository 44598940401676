﻿var TSystemBet = (function ()
{
    "use strict";
    var api = {}; // use 'api' only to define extension properties and always 'this' inside functions

    // Required by TPurchases.js
    api.getDeposit = function (context)
    {
        return Purchases.getSystemBetDeposit(this, context);
    };

    // Required by TPurchases.js
    api.getBetTitle = function ()
    {
        return Purchases.getBetTitle(this);
    };

    // Required by TPurchases.js
    api.skip = function (context)
    {
        return Purchases.skipBet(this, context);
    };

    // Required by TPurchases.js
    api.getReturn = function (context)
    {
        return Purchases.getSystemBetReturn(this, context);
    };

    // Required by TPurchases.jss
    api.isDepositUpdated = function (context)
    {
        return context.VariationSummary.DepositUpdated;
    };

    // Required by TPurchases.js
    api.getOdds = function (context)
    {
        if (context.HasSP)
        {
            return $dict.bs("NotAvailable");
        }

        return Purchases.getSystemBetOdds(this, context);
    };

    // Required by TPurchases.js
    api.getStatusClass = function ()
    {
        return Purchases.getBetStatusClass(this);
    };

     // Required by TPurchases.js
    api.getDescription = function (context)
    {
        return Purchases.getSystemBetDescription(this, context);
    };

    api.getVariationSummary = function (context)
    {
        return Purchases.getSystemVariationSummary(this, context);
    };

    return api;
}());

window.TSystemBet = TSystemBet;