var BetSlipValidation = (function ()
{
    'use strict';
    /* jshint maxparams: 5 */
    var validateMin = function (min, stake, towin, isAsianStyle)
    {
        if (isAsianStyle && towin !== undefined)
        {
            return validateItemMin(min, towin) && stake >= 0.01;
        }
        else
        {
            return validateItemMin(min, stake);
        }
    };
    /* jshint maxparams: 3 */

    /* jshint maxparams: 5 */
    var validateMax = function (max, stake, towin, isAsianStyle)
    {
        if (isAsianStyle && towin !== undefined)
        {
            return validateItemMax(max, towin);
        }
        else
        {
            return validateItemMax(max, stake);
        }
    };
    /* jshint maxparams: 3 */

    /* jshint unused: true */
    var validateItemMinMax = function (min, max, entry)
    {
        return entry >= min && entry <= max;
    };
    /* jshint unused: false */

    var validateItemMin = function (min, entry)
    {
        return entry >= min;
    };

    var validateItemMax = function (max, entry)
    {
        return entry <= max;
    };

    return {
        validateMin: validateMin,
        validateMax: validateMax
    };
})();

window.BetSlipValidation = BetSlipValidation;