﻿$.extend(TennisFastMarket.prototype,
{
    getScoreHtml: function(score, id, viewKey, isUpdated)
    {
        var idAttr = typeof viewKey != "undefined" ? ('id="' + id + viewKey + '"') : "";
        var classAttr = isUpdated ? (' class ="updated"') : "";
        return '<span ' + idAttr + '' + classAttr +'>' + score + '</span>';
    },

    buildEventTypeTitle: function (lines, eventTypeName) 
    {
        lines = Array.getValues(lines);
        if (lines != null && lines.length > 0) 
        {
            var data = lines[0].QATypeParameter1;

            return this.ComposeName(data, eventTypeName);
        }
        else return eventTypeName;

    },

    buildPickLine: function (qaBet) 
    {
        if (this.TeamSplit) 
        {
            var team;
            // Temporary separation by LineTypeID
            if(qaBet.LineTypeID == 13) 
            {
                if (qaBet.Team1Name) 
                {
                    team = qaBet.Team1Name;
                }
                else 
                {
                    team = qaBet.HomeTeam;
                }
            }
            else if(qaBet.LineTypeID == 15) 
            {
                if (qaBet.Team2Name) 
                {
                    team = qaBet.Team2Name;
                }
                else 
                {
                    team = qaBet.AwayTeam;
                }
            }

            if (qaBet.LineName != undefined) 
            {
                return team + ', ' + qaBet.LineName;
            }

            //only in combo bets OBS
            return team + ', ' + qaBet.YourBet;
        }
        else if (qaBet.LineName) 
        {
            return qaBet.LineName;
        } 
        else 
        {
            return qaBet.YourBet;
        }
    },

    isNotPreBuildName: function(eventTypeName)
    {
        if (!eventTypeName) 
        {
            return true;
        }
        var lowerEventTypeName = eventTypeName.toLowerCase();
        return lowerEventTypeName.indexOf('{s}') > -1 || lowerEventTypeName.indexOf('{g}') > -1 || lowerEventTypeName.indexOf('{p}') > -1;
    },

    buildEventNameForBet: function (buildedEventTypeName, eventTypeName, qaLine)
    {
        if(typeof qaLine != 'undefined') 
        {
            var qaParameter1 = qaLine.QAParameter1 === undefined ? qaLine.QPar1 : qaLine.QAParameter1;
            if(typeof qaParameter1 != 'undefined') 
            {
                return this.ComposeName(qaParameter1, eventTypeName, qaLine);
            }
        }

        if (!this.isNotPreBuildName(buildedEventTypeName)) 
        {
            return buildedEventTypeName;
        }
    }

});