﻿var CashoutManager = (function ()
{
    'use strict';

    var isDownloadingCashOutData;
    var cashOutErrorHelper;

    function updateCashOutData(purchases, callback)
    {
        if (isDownloadingCashOutData)
        {
            return;
        }

        var purchaseIds = [];

        purchases.map(function(purchase) { purchaseIds.push(purchase.PurchaseID); });

        if (purchaseIds.length === 0)
        {
            return;
        }
        
        isDownloadingCashOutData = true;
        var requestString = purchaseIds.join('@');

        BettingHistoryPageMethods.getCashOutDataForPurchases(requestString,
            function (result)
            {
                if (result)
                {
                    isDownloadingCashOutData = false;
                    /* jshint evil: true */
                    return callback((new Function('return ' + result))());
                    /* jshint evil: false */
                }
            },

            function ()
            {
                isDownloadingCashOutData = false;
            }
        );
    }

    function getCashOutDataUpdate(cashoutData, customerCashOutFeePercentage)
    {
        var result = {};

        var length = cashoutData.length;
        for (var i = 0; i < length; i+=1)
        {
            var betsDataArr = cashoutData[i].CashOutData;
            if (betsDataArr)
            {
                // update amount according with user's fee
                for (var j in betsDataArr)
                {
                    if (betsDataArr.hasOwnProperty(j))
                    {
                        var data = betsDataArr[j];
                        if (data.IsAllowed && customerCashOutFeePercentage < 100)
                        {
                            var amountWithCustomerFee = data.Amount * customerCashOutFeePercentage / 100;
                            data.Amount = Math.floor(amountWithCustomerFee * 100) / 100;

                            // check if new amount is not below the minimum
                            data.IsAllowed = data.Amount >= data.MinimalAmount;
                        }
                    }
                }
            }

            result[cashoutData[i].ID] = betsDataArr;
        }

        return result;
    }

    function onCashOutRequestStatusChanged(sender, data, purchase)
    {
        if (purchase)
        {
            var amount = null;
            if (data.betdata)
            {
                amount = data.betdata.Amount;
            }

            purchase.changeBetCashOutStatus(sender.Bet.BetID, data.state, amount);
        }
    }

    function processCashoutResult(result, purchases)
    {
        var purchaseId = result.PurchaseID;

        var purchase = Array.find(purchases, function (e) { return e.PurchaseID === purchaseId; });
        if (!purchase)
        {
            return;
        }

        switch (result.Status)
        {
            case SPPurchaseStatus.Accepted:
            case SPPurchaseStatus.Waiting:
                {
                    purchase.changeLastRequestedCashoutAmount(result.BetID, result.Amount);
                    var cashoutPurchase = SPPurchaseBuilder.createCashOutPurchase(result, purchase, false, false);
                    if (result.Status === SPPurchaseStatus.Waiting)
                    {
                        cashoutPurchase.onStatusChanged['History'] = function (sender, data)
                        {
                            onCashOutRequestStatusChanged(sender, data, purchase);
                        };
                    }

                    break;
                }
            case SPPurchaseStatus.Declined:
                //Generic error
            case -10:
                {
                    setPurchaseError(purchase, result);
                    break;
                }
            default:
            {
                setPurchaseError(purchase, result);
                break;
            }
        }

        var cashOutStatus = result.Status;
        if (cashOutStatus !== SPPurchaseStatus.Accepted && cashOutStatus !== SPPurchaseStatus.Waiting)
        {
            cashOutStatus = SPPurchaseStatus.Declined;
        }

        purchase.changeBetCashOutStatus(result.BetID, cashOutStatus);
    }

    function setPurchaseError(purchase, result)
    {
        var errorMessage = cashOutErrorHelper.SetSingleBetSlipErrorMessage(result);
        purchase.setBetError(result.BetID, errorMessage);
    }

    function cashOutConfirmationCallback(purchase, bet)
    {
        bet.CashOutConfirmationTimeoutID = null;
        if (!bet.CashOutConfirmationState)
        {
            return;
        }
        bet.CashOutConfirmationState = false;
        bet.LastPartialCashoutAmount = 0;

        var eventArgs = { IsConfirmed: false, IsAllowed: true };
        executeEvents(bet.CashoutConfirmationStateChanged, bet, eventArgs);

        eventArgs =
            {
                Amount: bet.getCashOutAmountStr((UserInfo.current ? UserInfo.current.currencyStringFormat : null)),
                Percentage: bet.getCashOutPercentageStr()
            };

        executeEvents(bet.CashOutAmountChanged, bet, eventArgs);
    }

    function setCashOutConfirmation(purchase, bet)
    {
        bet.LastPartialCashoutAmount = bet.getPartialCashOutAmount();

        var eventArgs = { Hide: true };
        executeEvents(bet.PartialCashOutAvailabilityChanged, bet, eventArgs);

        bet.CashOutConfirmationState = true;

        eventArgs = { IsConfirmed: true, IsAllowed: true };
        executeEvents(bet.CashoutConfirmationStateChanged, bet, eventArgs);

        if (bet.CashOutConfirmationErrorTimeOutId)
        {
            clearTimeout(bet.CashOutConfirmationErrorTimeOutId);
        }

        bet.CashOutConfirmationTimeoutID = setTimeout(function() { cashOutConfirmationCallback(purchase, bet); }, BetSlip.CashOutConfirmationTimeOut * 1000, purchase, bet);
    }
    
    function cashOutBet(purchaseId, betId, purchases)
    {
        var purchase = Array.find(purchases, function (e) { return e.PurchaseID === purchaseId; });

        if (!purchase)
        {
            return false;
        }

        var bet = purchase.Bets[betId];
        if (!bet)
        {
             return false;
        }

        if (bet.CashOutConfirmationErrorTimeOutId)
        {
            return false;
        }

        if (!bet.CashOutStatus && !bet.CashOutConfirmationState && BetSlip.EnableCashOutConfirmation)
        {
            setCashOutConfirmation(purchase, bet);
            return false;
        }

        if (bet.CashOutConfirmationTimeoutID)
        {
            clearTimeout(bet.CashOutConfirmationTimeoutID);
            bet.CashOutConfirmationTimeoutID = null;
        }

        bet.CashOutConfirmationState = false;

        // make sure bet has no CashOutStatus
        // (not waiting or rejected or accepted)
        if (bet.CashOutStatus)
        {
            return false;
        }

        var amount = bet.getCashOutAmount();
        if (!amount)
        {
             return false;
        }

        if (BetSlip.EnableVisualLogger && typeof VisualLogger != 'undefined')
        {   //Log betslip HTML on cash out
            VisualLogger.saveBetSlipOnCashOut();
        }

        purchase.changeBetCashOutStatus(betId, SPPurchaseStatus.Waiting);

        var requestObject = prepareRequest(bet, purchaseId, betId, amount);

        (function (purchaseId, betId)
        {
            BettingPageMethods[requestObject.fName](requestObject.request,

                function (result)
                {
                    // User is not logged in
                    if (result == -1)
                    {
                        return;
                    }
                    /* jshint evil: true */
                    processCashoutResult((new Function('return ' + result))(), purchases);
                    /* jshint evil: false */
                },

                // server side exception
                function ()
                {
                    var purchase = Array.find(purchases, function (e) { return e.PurchaseID === purchaseId; });
                    if (purchase)
                    {
                        purchase.setBetError(betId, $dict.bs('CommunicationError'));
                    }
                }
            );
        })(purchaseId, betId);
    }

    function prepareRequest(bet, purchaseId, betId, amount)
    {
        var request =
            {
                purchaseId: purchaseId,
                betId: betId,
                userAmount: amount
            };

        var fName = '';
        if (bet.LastPartialCashoutAmount)
        {
            request.amount = bet.LastPartialCashoutAmount;
            fName = 'cashOutBetPartially';
        }
        else
        {
            fName = 'cashOutBet';
        }

        return { request: request, fName: fName };
    }

    function CashoutManager()
    {
        cashOutErrorHelper = new BetSlipErrorMessagesHelper();

        Object.defineProperties(this,
        {
            'UpdateCashOutData':
            {
                writable: false,
                enumerable: false,
                configurable: false,
                value: updateCashOutData
            },
            'CashOutBet':
            {
                writable: false,
                enumerable: false,
                configurable: false,
                value: cashOutBet
            },
            'GetCashOutDataUpdate':
            {
                writable: false,
                enumerable: false,
                configurable: false,
                value: getCashOutDataUpdate
            },
            'ProcessCashoutResult':
            {
                writable: false,
                enumerable: false,
                configurable: false,
                value: processCashoutResult
            }
        });

        return this;
    }

    return CashoutManager;
}());

window.CashoutManager = CashoutManager;