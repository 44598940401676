﻿var SPPurchaseType =
    {   //Only for purchases, for bets they are different
        Single: 1,
        Combo: 2,
        Teaser: 3,
        System: 4,
        StraightCombo: 5,
        Combinator: 6,

        UKPurchase: 10,
        CashOutSingle: 11,
        CashOutCombo: 12,
        PulseBet: 13,
        YourBet: 14
    };

window.SPPurchaseType = SPPurchaseType;