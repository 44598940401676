﻿//"Static" helper class to deal with NBS purchases
var SPPurchaseBuilder = new Object();

//Main Parameters:
//purchData - SPPurchase object from server
//selectionPool - bet slip selection objects used in the purchase - from BetSlip or web storage
//keepPreviousPurchase - should we clean other purchases in the bet slip
//isRestore - is the purchase being restored on page reload
SPPurchaseBuilder.createSingleSlipPurchase = function (purchData, selectionPool, keepPreviousPurchase, isRestore)
{   //For accepted, waiting or delayed purchases
    var waitingBetID = ((typeof (purchData.WBID) != 'undefined') ? purchData.WBID : null);
    var bets = [];
    var selections = [];

    for (var bIdx in purchData.Bets)
    {
        var bet = purchData.Bets[bIdx];
        if (typeof ConfigurationLoyaltyProgram !== "undefined" && ConfigurationLoyaltyProgram.isInitilized)
        {
            bet.LoyaltyPointsPromotions = BetSlipUtil.getBetLoyaltyPoints(purchData, bet);
        }
        if (bet.BetTypeID == BetTypes.ForeCast || bet.BetTypeID == BetTypes.TriCast || bet.BetTypeID == BetTypes.VirtualForeCast || bet.BetTypeID == BetTypes.VirtualTriCast || bet.BetTypeID == BetTypes.FirstFour)
        {   //Multi line bet
            var lines = [];
            for (var mIdx in bet.Mappings)
            {
                var sel = Array.find(selectionPool, function (item) { return item.ViewKey == bet.Mappings[mIdx].ViewKey; });
                if (sel) lines.push(sel);
            }

            var multiLineItem = Array.find(selectionPool, function (item)
            {
                if (!item.IsMultiLine) return false;

                return item.ViewKey == bet.MultiLineItemViewKey;
            });

            if (!multiLineItem) continue;

            var spBet = new SPMultiLineBet(bet, lines, multiLineItem, purchData.Status, waitingBetID);
            bets.push(spBet);

            for (var key in lines)
            {
                var line = lines[key];
                //if (!selections[multiLineItem.Selections[key]])
                if (!Array.find(selections, function (sel1) { return sel1.ViewKey == line.ViewKey; }))
                {
                    selections.push(lines[key]);
                }
            }
            selections.push(multiLineItem);
        }

        else
        {   //Single line bet

            // for now exclude related EW bets
            if (bet.IsEachWay)
            {                
                if (BetSlipUtil.isRelatedBet(purchData, bet))
                {
                    if (typeof ConfigurationLoyaltyProgram !== "undefined" && ConfigurationLoyaltyProgram.isInitilized)
                    {
                        BetSlipUtil.addLoyaltyPointsForEWBets(bets, bet);
                    }
                    continue;
                }   
            }

            var selection = Array.find(selectionPool, function (item) { return item.ViewKey == bet.Mappings[0].ViewKey; });
            if (!selection) continue;
            BetSlip.CurrentMode.Errors[selection.ViewKey] = null;

            var spBet = new SPSingleBet(bet, selection, purchData.Status, waitingBetID);

            // Update bet's BetsCount property if there is a related EW bet
            if (BetSlipUtil.shouldUpdateBetsCounter(purchData, bet))
            {
                spBet.BetsCount++;
            }

            bets.push(spBet);
            if (!Array.find(selections), function (sel) { return sel.ViewKey == selection.ViewKey; })
                selections.push(selection);
        }
    }

    if ((selections.length > 0) && (bets.length > 0))
    {
        var purchaseViewKey = BetSlip.getViewKey();
        var purchase = new SPPurchase(selections, bets, purchData.Status, purchaseViewKey, SPPurchaseType.Single, null, null, purchData.PurchaseID, waitingBetID, purchData.BonusAmountStake);
        SPPurchaseBuilder.addPurchaseAdditionalProperties(purchData, purchase);
        BetSlip.addPurchase(purchase, null, keepPreviousPurchase, isRestore);

        return purchase;
    }

    return null;
};

SPPurchaseBuilder.createComboSlipPurchase = function (purchData, selectionPool, keepPreviousPurchase, isRestore, altOddStyle, purchaseType)
{   //For accepted, waiting or delayed purchases
    var waitingBetID = ((typeof (purchData.WBID) != 'undefined') ? purchData.WBID : null);
    var bets = [];
    var selections = [];

    for (var sIdx in purchData.Selections)
    {   //find a matching selection
        var viewKey = purchData.Selections[sIdx].ViewKey;
        var selection = Array.find(selectionPool, function (item) { return item.ViewKey == viewKey; });
        selections.push(selection);
    }

    for (var bIdx in purchData.Bets)
    {
        var bet = purchData.Bets[bIdx];
        if (bet)
        {
            if (typeof ConfigurationLoyaltyProgram !== "undefined" && ConfigurationLoyaltyProgram.isInitilized)
            {
                bet.LoyaltyPointsPromotions = BetSlipUtil.getBetLoyaltyPoints(purchData, bet);
            }
            var spBet = new SPComboBet(bet, purchData.Status, waitingBetID);
            bets.push(spBet);
            if (spBet.BetType === BetTypes.Combinator)
            {
                purchaseType = SPPurchaseType.Combinator;
            }
        }
    }

    //add the purchase to the slip

    if ((selections.length > 0) && (bets.length > 0))
    {
        var purchaseViewKey = BetSlip.getViewKey();
        var purchase = new SPPurchase(selections, bets, purchData.Status, purchaseViewKey, purchaseType ? purchaseType : SPPurchaseType.Combo, null, altOddStyle, purchData.PurchaseID, waitingBetID, purchData.BonusAmountStake);
        SPPurchaseBuilder.addPurchaseAdditionalProperties(purchData, purchase);
        BetSlip.addPurchase(purchase, null, keepPreviousPurchase, isRestore);

        return purchase;
    }

    return null;
};

SPPurchaseBuilder.createSystemSlipPurchase = function (purchData, selectionPool, keepPreviousPurchase, isRestore, altOddStyle, sysKey)
{   //For accepted, waiting or delayed purchases
    var waitingBetID = ((typeof (purchData.WBID) != 'undefined') ? purchData.WBID : null);
    var bets = [];
    var selections = [];

    for (var sIdx in purchData.Selections)
    {   //find a matching selection
        var viewKey = purchData.Selections[sIdx].ViewKey;
        var selection = Array.find(selectionPool, function (item) { return item.ViewKey == viewKey; });
        selections.push(selection);
    }

    for (var bIdx in purchData.Bets)
    {
        var bet = purchData.Bets[bIdx];
        if (bet)
        {
            var betSelections = [];
            for (var mIdx in bet.Mappings)
            {
                var mapObj = bet.Mappings[mIdx];

                var mappedSel = Array.find(selections, function (sel) { return sel.ViewKey == mapObj.ViewKey; });
                if (mappedSel) betSelections.push(mappedSel);
            }

            var spBet = new SPSystemBet(bet, betSelections, purchData.Status, waitingBetID);
            if (typeof ConfigurationLoyaltyProgram !== "undefined" && ConfigurationLoyaltyProgram.isInitilized)
            {
                spBet.LoyaltyPointsPromotions = BetSlipUtil.getBetLoyaltyPoints(purchData, bet);
            }
            bets.push(spBet);
        }
    }

    //add the purchase to the slip
    if ((selections.length > 0) && (bets.length > 0))
    {
        var purchaseViewKey = BetSlip.getViewKey();
        var purchase = new SPPurchase(selections, bets, purchData.Status, purchaseViewKey, SPPurchaseType.System, sysKey, altOddStyle, purchData.PurchaseID, waitingBetID, purchData.BonusAmountStake);
        SPPurchaseBuilder.addPurchaseAdditionalProperties(purchData, purchase);
        BetSlip.addPurchase(purchase, null, keepPreviousPurchase, isRestore);

        return purchase;
    }

    return null;
};

SPPurchaseBuilder.createTeaserSlipPurchase = function (purchData, selectionPool, keepPreviousPurchase, isRestore)
{   //For accepted, waiting or delayed purchases
    var waitingBetID = ((typeof (purchData.WBID) != 'undefined') ? purchData.WBID : null);
    var bets = [];
    var selections = [];

    for (var sIdx in purchData.Selections)
    {   //find a matching selection
        var responseSelection = purchData.Selections[sIdx];
        var viewKey = responseSelection.ViewKey;
        var selection = Array.find(selectionPool, function (item) { return item.ViewKey == viewKey; });
        
        if (selection && responseSelection.Points != null && responseSelection.Points != undefined)
        {
            selection.Points = responseSelection.Points;
        }

        selections.push(selection);
    }

    for (var bIdx in purchData.Bets)
    {
        var bet = purchData.Bets[bIdx];
        if (bet)
        {
            var spBet = new SPTeaserBet(bet, selections, purchData.Status, waitingBetID);
            bets.push(spBet);
        }
    }

    //add the purchase to the slip
    if ((selections.length > 0) && (bets.length > 0))
    {
        var purchaseViewKey = BetSlip.getViewKey();
        var purchase = new SPPurchase(selections, bets, purchData.Status, purchaseViewKey, SPPurchaseType.Teaser, null, null, purchData.PurchaseID, waitingBetID, purchData.BonusAmountStake);
        SPPurchaseBuilder.addPurchaseAdditionalProperties(purchData, purchase);
//      var puchase = new SPPurchase(selections, bets, status, purchaseViewKey, purchaseTypeID, systemKey, alternativeOddsStyle, purchaseID);
        BetSlip.addPurchase(purchase, null, keepPreviousPurchase, null);

        return purchase;
    }

    return null;

};

SPPurchaseBuilder.createCashOutPurchase = function (purchData, mybetsPurchase, keepPreviousPurchase, isRestore)
{
    var bet = mybetsPurchase.Bets[purchData.BetID];
    if (!bet) return null;

    var purchaseTypeId;
    var cashOutBet;
    var waitingBetID = ((typeof (purchData.WBID) != 'undefined') ? purchData.WBID : null);

    switch (bet.BetTypeID)
    {
        case BetTypes.Regular:
        case BetTypes.QA:
        case BetTypes.VirtualRegular:
        case BetTypes.VirtualQA:
            {
                purchaseTypeId = SPPurchaseType.CashOutSingle;
                cashOutBet = new SPSingleCashOutBet(bet, bet.Selections[0], purchData.Status, waitingBetID);

                break;
            }

        case BetTypes.Combo:
        case BetTypes.VirtualCombo:
            {
                purchaseTypeId = SPPurchaseType.CashOutCombo;
                cashOutBet = new SPComboCashOutBet(bet, purchData.Status, waitingBetID);

                break;
            }

        default: { return; }
    }

    var purchaseViewKey = BetSlip.getViewKey();
    var purchase = new SPCashOutPurchase(bet.Selections, cashOutBet, purchData.Status, purchaseViewKey, purchaseTypeId, purchData.PurchaseID, waitingBetID, purchData.Amount, purchData.IsPartialCashOutRequest);
    SPPurchaseBuilder.addPurchaseAdditionalProperties(purchData, purchase);
    BetSlip.addPurchase(purchase, null, keepPreviousPurchase, isRestore);

    return purchase;
};

SPPurchaseBuilder.createUKPurchase = function (purchData, selectionPool, keepPreviousPurchase, isRestore, altOddStyle, systemKeys)
{
    //For accepted, waiting or delayed purchases
    var waitingBetID = ((typeof (purchData.WBID) != 'undefined') ? purchData.WBID : null);
    var bets = [];
    var selections = [];
    var sysKeys = Object.keys(systemKeys)

    for (var sIdx in purchData.Selections)
    {
        //find a matching selection
        var viewKey = purchData.Selections[sIdx].ViewKey;
        var selection = Array.find(selectionPool, function (item) { return item.ViewKey == viewKey; });
        if (selection)
        {
            selections.push(selection);
        }
    }

    for (var bIdx in purchData.Bets)
    {
        var bet = purchData.Bets[bIdx];
        if (bet)
        {
            switch (bet.BetTypeID)
            {
                case BetTypes.ForeCast:
                case BetTypes.TriCast:
                case BetTypes.VirtualForeCast:
                case BetTypes.VirtualTriCast:
                case BetTypes.FirstFour:
                    //Multi line bet
                    var lines = [];
                    for (var mIdx in bet.Mappings) {
                        var sel = Array.find(selectionPool, function (item) { return item.ViewKey == bet.Mappings[mIdx].ViewKey; });
                        if (sel) lines.push(sel);
                    }

                    var multiLineItem = Array.find(selectionPool, function (item) {
                        if (!item.IsMultiLine) return false;

                        return item.ViewKey == bet.MultiLineItemViewKey;
                    });

                    if (!multiLineItem) continue;

                    var spBet = new SPMultiLineBet(bet, lines, multiLineItem, purchData.Status, waitingBetID);
                    bets.push(spBet);

                    for (var key in lines) {
                        var line = lines[key];
                        //if (!selections[multiLineItem.Selections[key]])
                        if (!Array.find(selections), function (sel1) { return sel1.ViewKey == line.ViewKey; }) {
                            selections.push(lines[key]);
                        }
                    }
                    selections.push(multiLineItem);
                    break;
                case BetTypes.Combo:
                case BetTypes.VirtualCombo:
                case BetTypes.System:
                case BetTypes.VirtualSystem:
                    //combo and system bets
                    if (Array.find(sysKeys, function (sysKey) { return sysKey == bet.ComboSize && systemKeys[sysKey]; }) && bet.NumberOfBets > 1)
                    {
                        var betSelections = [];
                        for (var mIdx in bet.Mappings)
                        {
                            var mapObj = bet.Mappings[mIdx];

                            var mappedSel = Array.find(selections, function (sel) { return sel.ViewKey == mapObj.ViewKey; });
                            if (mappedSel) betSelections.push(mappedSel);
                        }

                        var spBet = new SPSystemBet(bet, betSelections, purchData.Status, waitingBetID);
                    }
                    else
                    {
                        var spBet = new SPComboBet(bet, purchData.Status, waitingBetID);
                    }

                    bets.push(spBet);
                    break;
                case BetTypes.Teaser:
                    //teaser bets
                    var spBet = new SPTeaserBet(bet, selections, purchData.Status, waitingBetID);
                    bets.push(spBet);
                    break;
                default:
                    //Single line bet

                    // for now exclude related EW bets
                    if (bet.IsEachWay)
                    {
                        if (BetSlipUtil.isRelatedBet(purchData, bet))
                        {
                            continue;
                        }   
                    }

                    var selection = Array.find(selectionPool, function (item) { return item.ViewKey == bet.Mappings[0].ViewKey; });
                    if (!selection) continue;
                    BetSlip.CurrentMode.Errors[selection.ViewKey] = null;

                    var spBet = new SPSingleBet(bet, selection, purchData.Status, waitingBetID);

                    // Update bet's BetsCount property if there is a related EW bet
                    if (BetSlipUtil.shouldUpdateBetsCounter(purchData, bet))
                    {
                        spBet.BetsCount++;
                    }

                    bets.push(spBet);
                    if (!Array.find(selections, function (sel) { return sel.ViewKey == selection.ViewKey; }))
                        selections.push(selection);
                    break;
            }
        }
    }

    if ((selections.length > 0) && (bets.length > 0))
    {
        var purchaseViewKey = BetSlip.getViewKey();
        var purchase = new SPPurchase(selections, bets, purchData.Status, purchaseViewKey, SPPurchaseType.UKPurchase, null, altOddStyle, purchData.PurchaseID, waitingBetID, purchData.BonusAmountStake);
        SPPurchaseBuilder.addPurchaseAdditionalProperties(purchData, purchase);
        BetSlip.addPurchase(purchase, null, keepPreviousPurchase, isRestore);

        return purchase;
    }
};

SPPurchaseBuilder.createPulseSlipPurchase = function (purchData, selectionPool, keepPreviousPurchase, isRestore) {   //For accepted, waiting or delayed purchases
    var waitingBetID = ((typeof (purchData.WBID) != 'undefined') ? purchData.WBID : null);
    var bets = [];

    for (var bIdx in purchData.Bets)
    {
        var bet = purchData.Bets[bIdx];
        var selection = Array.last(selectionPool);
        if (!selection) continue;
        
        var spBet = new SPSingleBet(bet, selection, purchData.Status, waitingBetID);
        spBet.BetType = BetTypes.PulseBet;
        bets.push(spBet);

    }
    
    if ((selectionPool.length > 0) && (bets.length > 0))
    {
        var purchaseViewKey = BetSlip.getViewKey();
        var purchase = new SPPurchase(selectionPool, bets, purchData.Status, purchaseViewKey, SPPurchaseType.PulseBet, null, null, purchData.PurchaseID, waitingBetID, purchData.BonusAmountStake);
        SPPurchaseBuilder.addPurchaseAdditionalProperties(purchData, purchase);
        BetSlip.addPurchase(purchase, null, keepPreviousPurchase, isRestore);

        return purchase;
    }

    return null;
};

SPPurchaseBuilder.createYourbetSlipPurchase = function (purchData, selectionPool, keepPreviousPurchase, isRestore, freeBet)
{   //For accepted, waiting or delayed purchases
    var waitingBetID = ((typeof (purchData.WBID) != 'undefined') ? purchData.WBID : null);
    var bets = [];

    for (var bIdx in purchData.Bets)
    {
        var bet = purchData.Bets[bIdx];
        var selection = Array.find(selectionPool, function (s) { return s.LineID === 0; });
        if (!selection) continue;

        var spBet = new SPSingleBet(bet, selection, purchData.Status, waitingBetID);
        spBet.BetType = BetTypes.YourBet;
        spBet.FreeBet = freeBet;
        bets.push(spBet);
    }

    var availableSelections = Array.findAll(selectionPool, function (s) { return s.LineID !== 0; });
    if ((availableSelections.length > 0) && (bets.length > 0))
    {
        var purchaseViewKey = BetSlip.getViewKey();
        var purchase = new SPPurchase(availableSelections, bets, purchData.Status, purchaseViewKey, SPPurchaseType.YourBet, null, null, purchData.PurchaseID, waitingBetID, purchData.BonusAmountStake);
        SPPurchaseBuilder.addPurchaseAdditionalProperties(purchData, purchase);
        BetSlip.addPurchase(purchase, null, keepPreviousPurchase, isRestore);

        return purchase;
    }

    return null;
};

SPPurchaseBuilder.addPurchaseAdditionalProperties = function(purchData, purchase) {
    ["Printout", "GBPDeposit", "ExternalTicketID"].each(function(propertyName)
    {
        if (purchData.hasOwnProperty(propertyName))
        {
            purchase[propertyName] = purchData[propertyName];
        }
    });
};

window.SPPurchaseBuilder = SPPurchaseBuilder;