function SelectionsMasterGroup(groupKey, viewKey) {
    this.GroupKey = groupKey;
    this.ViewKey = viewKey;
    this.IsBanker = false;
    this.Initialized = false;
    this.MarketGroups = [];
    this.Team1Name = null;
    this.Team2Name = null;
    this.BranchID = null;
    this.EventDate = null;

    this.selectionsViewKeys = [];
    this.uninitializedSelectionsViewKeys = [];
}

(function () {
    $.extend(SelectionsMasterGroup.prototype,
    {
        getMarketGroupsCount: function getMarketGroupsCount() {
            return Array.getLength(this.MarketGroups);
        },

        removeBetInsideGroup: function (betViewKey) {
            var marketGroup = this.MarketGroups[this.selectionsViewKeys[betViewKey]];
            if (Array.getLength(marketGroup.Selections) === 1) {
                this.removeBetMarketGroup(marketGroup.GroupKey);
            }
            delete marketGroup.Selections[betViewKey];
            delete this.selectionsViewKeys[betViewKey];
        },

        removeBetMarketGroup: function (groupKey) {
            var marketGroup = this.MarketGroups[groupKey];
            for (var idx in marketGroup.Selections) {
                var selection = marketGroup.Selections[idx];
                delete this.selectionsViewKeys[selection.ViewKey];
            }
            delete this.MarketGroups[groupKey];
        },

        initialize: function (selection) {
            this.Initialized = true;
            this.Team1Name = selection.Team1Name;
            this.Team2Name = selection.Team2Name;
            this.BranchID = selection.BranchID;
            this.EventDate = selection.EventDate;
            this.isLive = selection.Live;
            var isQa = isQaSelection(selection);
            var eventGroupKey = getMarketGroupKey(isQa, selection);
            this.selectionsViewKeys[selection.ViewKey] = eventGroupKey;
            delete this.uninitializedSelectionsViewKeys[selection.ViewKey];
            addSelectionToGroup(this, selection, isQa, eventGroupKey);
        },

        isEmpty: function () {
            return Array.getLength(this.uninitializedSelectionsViewKeys) === 0
                && Array.getLength(this.selectionsViewKeys) === 0;
        },

        removeUninitializedSelection: function (viewKey) {
            delete this.uninitializedSelectionsViewKeys[viewKey];
        },

        addUninitializedSelection: function (viewKey) {
            this.uninitializedSelectionsViewKeys[viewKey] = viewKey;
        },

        getAllSelectionsViewKeys: function () {
            return Object.keys(this.selectionsViewKeys).concat(Object.keys(this.uninitializedSelectionsViewKeys));
        },

        getInitializedSelectionsViewKeys: function () {
            return this.selectionsViewKeys.slice(0);
        },

        getUnInitializedSelectionsViewKeys: function () {
            return this.uninitializedSelectionsViewKeys.slice();
        },

        getInitializedSelectionsCount: function () {
            return Array.getLength(Object.keys(this.selectionsViewKeys));
        }

    });

    function isQaSelection(selection) {
        return selection instanceof QASlipSelection || selection instanceof QALiveSlipSelection;
    }

    function getMarketGroupKey(isQa, selection) {
        var eventID = selection.EventID;
        var betTypeID = selection.BetTypeID;
        var eventTypeID = selection.EventTypeID;
        return isQa ? eventID + "_" + eventTypeID : eventID + "_" + betTypeID;
    }

    function addSelectionToGroup(group, selection, isQa, eventGroupKey) {
        var marketGroup;

        if (!group.MarketGroups[eventGroupKey]) {
            if (isQa)
            {
                marketGroup = new QASelectionsMarketGroup();
            }
            else
            {
                marketGroup = new RegularSelectionsMarketGroup();
            }

            marketGroup.GroupKey = eventGroupKey;
            group.MarketGroups[eventGroupKey] = marketGroup;
        }

        var betTypeGroup = group.MarketGroups[eventGroupKey];
        betTypeGroup.Selections[selection.ViewKey] = selection;
    }
})();

window.SelectionsMasterGroup = SelectionsMasterGroup;