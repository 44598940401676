function UISlider(min, max, classes)
{
    'use strict';
    this.sliderContainer = {};
    this.slidePath = {};
    this.slideProgress = {};
    this.slideBtn = {};
    this.slidePathWidth = 0;
    this.slideBtnWidth = 0;
    this.amountMinText = {};
    this.amountMaxText = {};
    this.sliderValueText = {};
    this.onInit = {};
    this.classes = classes || '';

    this.input = {}; //input type="range" - for native implementation

    this.percent = 0;
    this.amountMin = this.round(min);
    this.amountMax = this.round(max);

    this.isClicked = false;
    this.clientXStart = 0;
    this.slideBtnXStart = 0;
    this.slideBtnX = 0;
    this.deltaX = 0;
    this._onChangeHandlers = [];

    this.oldAndroidVersion = UISlider.androidVersion < 4.5;
}

UISlider.androidVersion = (function ()
{
    'use strict';
    var ua = navigator.userAgent.toLowerCase();
    var match = ua.match(/android\s([0-9\.]*)/);
    var version = match ? parseFloat(match[1]) : 'uknown';
    return version;
}());

UISlider.prototype.round = function (numb)
{
    'use strict';
    /*jslint bitwise: true */
    if (numb)
    {
        var index = ('' + numb).indexOf('.');
        if (index == -1 || index > ('' + numb).length - 4)
        {
            return numb;
        }
        return ((numb * 100) | 0) / 100; // parseFloat(numb).toPrecision(12);
    }
    return 0;
};

UISlider.prototype.init = function (containerID)
{
    /* jshint maxcomplexity: 10 */
    'use strict';
    var container = document.getElementById(containerID);

    if (!container || !this._isHtmlElement(container)) { return false; }

    //Slider Container
    this.sliderContainer = document.createElement('div');
    this.sliderContainer.className = (this.oldAndroidVersion === true) ? 'ui_slider ui_slider--native ' + this.classes : 'ui_slider ' + this.classes;
    this.sliderContainer.innerHTML = (this.oldAndroidVersion === true) ? this.createNativeHTML() : this.createHTML();
    container.appendChild(this.sliderContainer);

    this.slideProgress = this.sliderContainer.getElementsByClassName('ui_slider__progress')[0];

    if (this.oldAndroidVersion === true)
    {
        //Input
        this.input = this.sliderContainer.getElementsByTagName('input')[0];
        this.input.min = this.amountMin;
        this.input.max = this.amountMax;
        this.input.step = 0.01;
    } else
    {
        //Slide Path
        this.slidePath = this.sliderContainer.getElementsByClassName('ui_slider__path')[0];
        this.slidePathWidth = this.slidePath.clientWidth;

        //Slide Button
        this.slideBtn = this.sliderContainer.getElementsByClassName('ui_slider__btn')[0];
        this.slideBtnWidth = parseInt(getComputedStyle(this.slideBtn).getPropertyValue('width'));
       
        this._setSliderX(this.slideBtnX);
    }

    //Amounts and Selected Value
    this.sliderValueText = this.sliderContainer.getElementsByClassName('ui_slider__value')[0];

    this.amountMinText = this.sliderContainer.getElementsByClassName('ui_slider__amountMin')[0]; 
    this.amountMinText.innerHTML = this.formatMinMax(this.amountMin);

    this.amountMaxText = this.sliderContainer.getElementsByClassName('ui_slider__amountMax')[0];
    this.amountMaxText.innerHTML = this.formatMinMax(this.amountMax);

    this.slideFakePath = this.sliderContainer.getElementsByClassName('ui_slider__btn__wrapper')[0];
    this.slideFakePath.style.width = this.slidePathWidth - this.slideBtnWidth + 'px';

    this._attachEvents();

    this._onInitInvoke();

    this._onChange();

    return 'android version: ' + UISlider.androidVersion;
};

UISlider.prototype._attachEvents = function ()
{
    'use strict';

    if (this.oldAndroidVersion === true)
    {
        //Input
        this.input.addEventListener('input', this._inputHandler.bind(this), false);
    } else
    {
        //Slide Button
        this.slideBtn = this.sliderContainer.getElementsByClassName('ui_slider__btn')[0];
        this.slideBtnWidth = parseInt(getComputedStyle(this.slideBtn).getPropertyValue('width'));

        //Document
        this._mouseMoveDocHandlerRef = debounce(this._mouseMoveDocHandler.bind(this), 1);
        this._mouseUpDocHandlerRef = debounce(this._mouseUpDocHandler.bind(this), 1);

        if (typeof Application === 'object' && !Application.deviceType.isDesktop())
        {
            //Slide Path
            this.slidePath.addEventListener('touchstart', this._clickPathHandler.bind(this), false);

            //Slide Button
            this.slideBtn.addEventListener('touchstart', this._mouseDownBtnHandler.bind(this), false);

            //Document
            document.body.addEventListener('touchmove', this._mouseMoveDocHandlerRef, true);
            document.body.addEventListener('touchend', this._mouseUpDocHandlerRef, false);
        }
        else
        {
            //Slide Path
            this.slidePath.addEventListener('mousedown', this._clickPathHandler.bind(this), false);

            //Slide Button
            this.slideBtn.addEventListener('mousedown', this._mouseDownBtnHandler.bind(this), false);

            //Document
            document.body.addEventListener('mousemove', this._mouseMoveDocHandlerRef, false);
            document.body.addEventListener('mouseup', this._mouseUpDocHandlerRef, false);
        }
    }

    return 'android version: ' + UISlider.androidVersion;
};

UISlider.prototype._detachEvents = function ()
{
    'use strict';
    if (this.oldAndroidVersion === false)
    {
        if (typeof Application === 'object' && !Application.deviceType.isDesktop())
        {
            document.body.removeEventListener('touchmove', this._mouseMoveDocHandlerRef);
            document.body.removeEventListener('touchend', this._mouseUpDocHandlerRef);
        }
        else
        {           
            document.body.removeEventListener('mousemove', this._mouseMoveDocHandlerRef);
            document.body.removeEventListener('mouseup', this._mouseUpDocHandlerRef);
        }
    }
    return 'android version: ' + UISlider.androidVersion;
};

UISlider.prototype.destroy = function ()
{
    'use strict';

    this._detachEvents();

    this.sliderContainer.parentNode.removeChild(this.sliderContainer);
    this.sliderContainer = null;

    this._onChangeHandlers = [];

    return true;
};

//-------Handlers----------

//Native input type="range"
UISlider.prototype._inputHandler = function ()
{
    'use strict';
    this.percent = ((this.input.value - this.amountMin) * 100) / (this.amountMax - this.amountMin);
    this.slideProgress.style.width = this.percent + '%';

    this._onChange();

    return this.percent;
};

//Slide Path
UISlider.prototype._clickPathHandler = function (e)
{
    'use strict';
    e.preventDefault();

    var clientX = (e.type === 'mousedown') ? e.clientX : e.touches[0].clientX;

    var pathPositionLeft = this.slidePath.getBoundingClientRect().left;
    var pathClickPosition = clientX - pathPositionLeft;

    this.slidePathWidth = this.slidePath.clientWidth;

    //Beginning and End Snapping
    if (pathClickPosition < this.slideBtnWidth / 2) { pathClickPosition = 0; }
    if (pathClickPosition > this.slidePathWidth - this.slideBtnWidth / 2) { pathClickPosition = this.slidePathWidth; }

    this.clientXStart = clientX;
    this.slideBtnXStart = pathClickPosition;

    this._setSliderX(pathClickPosition);

    this._onChange();

    this.isClicked = true;

    return pathClickPosition;
};

//Slide Button
UISlider.prototype._mouseDownBtnHandler = function (e)
{
    'use strict';
    e.preventDefault();

    var clientX = (e.type === 'mousedown') ? e.clientX : e.touches[0].clientX;

    this.clientXStart = clientX;

    this.slidePathWidth = this.slidePath.clientWidth;
    this.slideBtnX = (this.percent * this.slidePathWidth / 100);
    this.slideBtnXStart = this.slideBtnX;

    this.isClicked = true;

    return this.slideBtnX;
};

UISlider.prototype._mouseMoveDocHandler = function (e)
{
    'use strict';
    if (!this.isClicked) { return false; }
    e.preventDefault();

    var clientX = (e.type === 'mousemove') ? e.clientX : e.changedTouches[0].clientX;

    this.deltaX = clientX - this.clientXStart;
    this.slideBtnX = this.slideBtnXStart + this.deltaX;

    //Check if inside slidePath boundary
    (this.slideBtnX < 0) && (this.slideBtnX = 0);

    (this.slideBtnX > this.slidePathWidth) && (this.slideBtnX = this.slidePathWidth);

    this._setSliderX(this.slideBtnX);

    this._onChange();

    return this.slideBtnX;
};

UISlider.prototype._mouseUpDocHandler = function (e)
{
    'use strict';
    if (!this.isClicked) { return false; }

    e.preventDefault();

    this.isClicked = false;

    this._setSliderX(this.slideBtnX);

    this._onChange();

    return this.slideBtnX;
};
//-------

UISlider.prototype._setSliderX = function (_slideBtnX)
{
    'use strict';
    this.slideBtnX = _slideBtnX;
    this.percent = (_slideBtnX / this.slidePathWidth) * 100;
    // refresh visual elements
    /*jslint bitwise: true */
    this.slideBtn.style.left = ((this.percent * 100) | 0) / 100 + '%';	//limit to two decimal places
    this.slideProgress.style.width = this.slideBtn.style.left;

    return this.slideBtn.style.left;
};

UISlider.prototype._setInputValue = function (value)
{
    'use strict';
    this.input.value = value;
    this.slideProgress.style.width = this.percent + '%';

    return value;
};

UISlider.prototype.setSliderValueText = function (text)
{
    'use strict';
    this.sliderValueText.innerHTML = text;

    return text;
};

UISlider.prototype.hasText = function ()
{
    'use strict';
    if (this.sliderValueText.innerHTML && !!this.sliderValueText.innerHTML.trim())
    {
        return true;
    }
    return false;
};

UISlider.prototype.setMinMax = function (min, max)
{
    'use strict';
    this.amountMin = this.round(min);
    this.amountMinText.innerHTML = this.formatMinMax(this.amountMin);
    this.amountMax = this.round(max);
    this.amountMaxText.innerHTML = this.formatMinMax(this.amountMax);

    this.input.min = this.amountMin;
    this.input.max = this.amountMax;

    this._onChange();

    return 'min: ' + min + '/max: ' + max;
};

UISlider.prototype.formatMinMax = function(value)
{
    return MoneyFormat.format(value);
};
UISlider.prototype.setValue = function (value)
{
    'use strict';
    // validate first
    if (value < this.amountMin) { value = this.amountMin; }
    if (value > this.amountMax) { value = this.amountMax; }
    this.percent = ((value - this.amountMin) * 100) / (this.amountMax - this.amountMin);

    if (this.oldAndroidVersion === true)
    {
        this._setInputValue(value);
    } else
    {
        this._setSliderX((this.percent * this.slidePathWidth / 100));
    }

    this._onChange();

    return value;
};

UISlider.prototype.getRealValue = function ()
{
    'use strict';
    var amount = this.amountMin + ((this.amountMax - this.amountMin) * this.percent / 100);
    var roundedAmount = this.round(amount);
    return roundedAmount;
};

UISlider.prototype.registerChangeHandler = function (f, c)
{
    'use strict';
    var obj = { func: debounce(f, 20), context: c };
    this._onChangeHandlers.push(obj);

    return true;
};

UISlider.prototype._onChange = function ()
{
    'use strict';
    var arr = this._onChangeHandlers;
    for (var i = 0; i < arr.length; i += 1)
    {
        arr[i].func.call(arr[i].context, { name: 'sliderChangeEvent', value: this.getRealValue() });
    }

    return arr;
};

//check for html element validity
UISlider.prototype._isHtmlElement = function (o)
{
    'use strict';
    return (typeof HTMLElement === 'object' ? o instanceof HTMLElement : o && typeof o === 'object' && o !== null && o.nodeType === 1 && typeof o.nodeName === 'string');
};

UISlider.prototype._onInitInvoke = function ()
{
    'use strict';
    var keys = Object.keys(this.onInit);
    var i = keys.length;

    while (i > 0)
    {
        i -= 1;
        if (typeof this.onInit[keys[i]] === 'function')
        {
            this.onInit[keys[i]].call(this);
        }
    }
};

UISlider.prototype.createHTML = function ()
{
    'use strict';
    return '';
    // this should be overriden in TUISlider.js template 
};

UISlider.prototype.createNativeHTML = function ()
{
    'use strict';
    return '';
    // this should be overriden in TUISlider.js template 
};

window.UISlider = UISlider;