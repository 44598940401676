
var SplitType6 = (function (base) {
    "use strict";
    var api = $.extend({}, base);

    api.getLineEventTypeName = function (selection) {
        return selection.LineTypeName + " " + (selection.TeamMappingID == 1 ? selection.Team1Name : selection.Team2Name) + " " + selection.EventTypeName;
    };

    api.buildPreliveEventListView = function (__html, me, event)
    {		
        var linesData = getLinesData(event, me);
        SplitType6Template.buildPreliveListView(__html, me, event, linesData);
    };

    api.buildPreliveEventView = function (__html, me,event) {
        var linesData = getLinesData(event, me);
        SplitType6Template.buildPreliveEventView(__html, me, event,linesData);
    };

    function getLinesData(event, me)
    {
        var result =
            {
                lines: null,
                teamName: null
            };
        var lgs = Array.findAllValues(event.Lines, function(lg) { return lg.hasOdds("OU"); });
       
        lgs.sort(function(lg1, lg2) { return Data.compare(lg1.IsOptional, lg2.IsOptional); });        
        var teamName = event.TeamMapping == 2 ? me.Team2Name : me.Team1Name;

        result.lines = lgs;
        result.teamName = teamName;
        return result;
    }

    return api;
})(BaseSplitType);

window.SplitType6 = SplitType6;

