var EditMyBetsValidator = (function ()
{
    function EditMyBetsValidator(betSlip)
    {
        this.MyBetsCache = SPMyBetsCache.getInstance();
        this.BetSlipMode = betSlip.Modes['combo'] || betSlip.Modes['ukslip'] || betSlip.Modes['comboNoCombination'];
        this.BetSlip = betSlip;
    }

    EditMyBetsValidator.prototype.validateSelections = function (selections)
    {
        if (!this.MyBetsCache.getIsActive())
        {
            return false;
        }

        if (Array.isEmpty(selections))
        {
            return false;
        }

        if (!this.BetSlipMode || !this.BetSlipMode.isComboAllowed())
        {
            return false;
        }

        if (this.hasFreeBetsInCurrentMode())
        {
            return false;
        }

        var ref = this;
        // all selections should be valid 
        var validSelections = selections.all(function (sel) { return ref.validateSingleSelection(sel); });
        if (!validSelections)
        {
            return false;
        }

        //check for virtual branch. We don't want to see edit button in betslip for all virtuals
        if (selections.any(function (sel) { return sel.BranchID && VirtualSports.isBranchVirtual(sel.BranchID); }))
        {
            return false;
        }

        // there should be some bet that can combine with ALL of the current selections in the BetSlip
        var purchases = this.MyBetsCache.getPurchases();
        return purchases.any(function (purchase)
        {
            return ref.validatePurchase(purchase);
        });
    };

    EditMyBetsValidator.prototype.canCombineAllSelections = function (selections)
    {
        for (var i = 0; i < selections.length - 1; i += 1)
        {
            var currentSelection = selections[i];
            for (var j = i + 1; j < selections.length; j += 1)
            {
                var sel = selections[j];
                if (sel.getComboGroupKey() == currentSelection.getComboGroupKey())
                {
                    return false;
                }
            }
        }

        return true;
    };

    EditMyBetsValidator.prototype.hasFreeBetsInCurrentMode = function ()
    {
        if (this.BetSlip.CurrentMode.FreeBet)
        {
            return true;
        }
        var arr = this.BetSlip.CurrentMode.FreeBets ? this.BetSlip.CurrentMode.FreeBets : [];
        return arr.any(function (fb) { return fb && fb != -1; });
    };

    EditMyBetsValidator.prototype.validateSingleSelection = function (item)
    {
        if (!item.Valid ||
            !item.Initialized ||
            item.FreeBet ||
            item.IsEachWaySelection ||
            item.EachWayIncluded ||
            item.EachWaySingleIncluded ||
            item.EachWayMultiplesIncluded)
        {
            return false;
        }
        return true;
    };

    EditMyBetsValidator.prototype.validatePurchase = function (purchase)
    {
        var ref = this;
        var bets = purchase.Bets.where(function (bet)
        {
            return purchase.shouldBuildUIForBet(bet);
        });

        return bets.any(function (bet)
        {
            return ref.validateBetWithCurrentSelections(Array.getValues(ref.BetSlip.Selections), bet);
        });
    };

    EditMyBetsValidator.prototype.validateBetWithCurrentSelections = function (selections, bet)
    {
        var ref = this;
        var allSelections = selections.concat(bet.Selections);
        return selections.all(function (sel)
        {
            return ref.validateMatch(bet, sel);
        })
        && this.canCombineAllSelections(allSelections);
    };

    EditMyBetsValidator.prototype.validateMatch = function (bet, selection)
    {
        if (selection.isNoComboAllowed)
        {
            return false;
        }

        if (!bet.isCashOutAllowed())
        {
            return false;
        }

        if (bet.CashOutData && bet.CashOutData.IsDanger)
        {
            return false;
        }

        if (bet.IsEachWay || bet.EWBetRef)
        {
            return false;
        }

        return !ComboSPSlipMode.prototype.getComboAddError.call(this.BetSlipMode, selection, false, bet.Selections);
    };

    return EditMyBetsValidator;
}());

window.EditMyBetsValidator = EditMyBetsValidator;