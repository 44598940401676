function YourBetSPSlipMode()
{
    BaseSPSlipMode.call(this);

    this.Order = 7;
    this.ID = "yourbet";
    this.Name = $dict.bs("YourBetTab");
    this.TabClass = "betting_slip_nbs yourbet";
    this.BetsTabsContainer = document.getElementById("idBetsTabs");
    this.SelectionDrawType = "yourBetModeLine";
    this.IsSelectionRemoveButton = false;
    this.CashOutUpdateIntervalID = null;
    this.SelectedFreeBet = null;
    this.YourBetOdds = 0;
    this.YourBetStake = 0;
    this.YourBetStakeText = "0";
    this.PurchaseTypeID = SPPurchaseType.YourBet;
    this.EventsManager = new EventsManager();
    this.YourbetTopic = 'sb_yourbet_topic';
    this.SkipYourBetOddsUpdate = false;
}

$.extend(YourBetSPSlipMode.prototype, BaseSPSlipMode.prototype,
{
    activate: function ()
    {
        this.Active = true;
        this.HasBeenActivated = true;
        this.EventsManager.addEventListener('onSkipYourBetOddsUpdate', this, this.setSkipYourBetOddsUpdate, true);
    },

    deactivate: function ()
    {
        this.Active = false;
        this.EventsManager.removeEventListener('onSkipYourBetOddsUpdate', this, true);
    },

    canActivate: function ()
    {
        return 1;
    },

    setSkipYourBetOddsUpdate: function ()
    {
        this.SkipYourBetOddsUpdate = true;
    }, 

    updateYourBetOdds: function (newOdds)
    {
        if (isNaN(newOdds) || newOdds < 0)
        {
            return;
        }

        this.YourBetOdds = newOdds;
    },

    updateStake: function (input)
    {
        var newStake = StakeInputParser.parse(input.value);
        var stakeField = document.getElementById('your-bet-stake-field');

        if (isNaN(newStake) || newStake < 0)
        {
            stakeField.value = 0;
            return;
        }

        this.setStake(input.value);
    },

    incrementStake: function (value) {
        var stakeValue = typeof value === 'string' ? StakeInputParser.parse(value) : value;
        var currency = BetSlip.getCurrencyWithStakeValues();

        if (currency) {
            for (var i = 0; i < currency.StakeValuesSeries.length; i++)
            {
                if (parseInt(currency.StakeValuesSeries[i]) > stakeValue) {
                    stakeValue = currency.StakeValuesSeries[i];
                    break;
                }
            }

            this.setStake(stakeValue);
        }
    },

    decrementStake: function (value) {
        var stakeValue = typeof value === 'string' ? StakeInputParser.parse(value) : value;
        var currency = BetSlip.getCurrencyWithStakeValues();

        if (currency) {
            for (var i = currency.StakeValuesSeries.length - 1; i >= 0; i--)
            {
                if (parseInt(currency.StakeValuesSeries[i]) < stakeValue)
                {
                    stakeValue = currency.StakeValuesSeries[i];
                    break;
                }
            }

            this.setStake(stakeValue);
        }
    },

    setStakeWithDelay: function () {
        var self = this
        var args = arguments;
        clearTimeout(this.setStakeTimeout);
        this.setStakeTimeout = setTimeout(function () { 
            self.setStake.apply(self, args); 
        }, 600);
    },

    setStake: function (value)
    {
        var stakeValue = 0;
        if( typeof value === 'string') {
            this.YourBetStakeText =  value;
            stakeValue = BetSlip.SanitizeNumberString(value);
        }
        else {
            this.YourBetStakeText = BetSlip.getStakeBoxValue(value);
            stakeValue = value;
        }
        this.sendStakeMessage(this.YourBetStakeText);
        stakeValue = isNaN(stakeValue) ? 0 : BetSlip.RoundToStakeRounding(stakeValue);
        this.YourBetStake = stakeValue;

        if (sbMsgBus) {
            var currency = BetSlip.getCurrencyWithStakeValues();

            if (currency) {
                const plusEnabled = stakeValue < currency.StakeValuesSeries[currency.StakeValuesSeries.length - 1];
                const minusEnabled = stakeValue > currency.StakeValuesSeries[0];
                this.sendStakeButtonsMessage(plusEnabled, minusEnabled);
            }
            else {
                this.sendStakeButtonsMessage(false, false);
            }
        }
        this.EventsManager.dispatchEvent("onYourBetUpdateStake", null, true);
    },

    sendStakeButtonsMessage: function (plusEnabled, minusEnabled) {
        const stakeButtonStatesMsg = {
            [this.YourbetTopic] : {
                actions: {
                    stakeButtonsStates: {
                        plusEnabled: plusEnabled,
                        minusEnabled: minusEnabled
                    }
                }
            }
        };
        sbMsgBus.post(stakeButtonStatesMsg);
    },

    sendStakeMessage: function(value) {
        if (sbMsgBus) {
            var msg = {};
            msg[this.YourbetTopic] = {
                actions: {
                    purchase: [{
                        id: 'YourBetPreplacement',
                        stake: value
                    }]
                }
            };
            sbMsgBus.post(msg);
        }
    },

    serialize: function ()
    {
        return {
            YourBetOdds: this.YourBetOdds,
            YourBetStake: this.YourBetStake
        };
    },

    deserialize: function (data)
    {
        this.YourBetOdds = parseFloat(data.YourBetOdds);
        this.YourBetStake = parseFloat(data.YourBetStake);
    },

    createYourBetRequest: function (request)
    {
        var yourBetClientOdds = Odds.convertFromDecimal(this.YourBetOdds, currentOddStyle);
        if (currentOddStyle === OddStyle.EUROPEAN)
        {
            yourBetClientOdds = MoneyFormat.format(yourBetClientOdds);
        }
        else{
            yourBetClientOdds = Odds.format(yourBetClientOdds); 
        }
        var yourBetRequest = {
            Purchase: request,
            YourBetOdds: this.YourBetOdds,
            Stake: this.YourBetStake,
            YourBetClientOdds: yourBetClientOdds
        };

        return yourBetRequest;
    },

    formatedYourBetOdds: function (odds)
    {
        if (currentOddStyle === OddStyle.EUROPEAN)
        {
            return (odds * 1).toFixed(2);
        }

        var convertedOdds = Odds.convertFromDecimal(odds, currentOddStyle);
        return Odds.format(convertedOdds);
    },

    getYourBetMinBet: function (lines)
    {
        var defaultMinBetYourBet = 0.1;
        minBetYourBet = Math.max(defaultMinBetYourBet , Math.max.apply(null, Array.getValues((lines).map(function (line) { return line.minBet; }))));

        var regulationMinBet = this.getRegulationMinBet();
        minBetYourBet = (!!regulationMinBet && regulationMinBet > minBetYourBet) ? regulationMinBet : minBetYourBet;

        return minBetYourBet;
    },

    getYourBetSelections: function (result)
    {
        var key,
            selections = [],
            resultSelectionsLength = result.Selections && result.Selections.length;

        for (key = 0; key < resultSelectionsLength; key += 1)
        {
            var selection = result.Selections[key];
            if (selection && selection.LineID !== 0)
            {
                selections[selection.ViewKey] = BetSlip.Selections[selection.ViewKey];
            }

            if (selection.LineID === 0)
            {
                var mappedSelection = result.Bets[0] && result.Bets[0].Mappings[0] && result.Bets[0].Mappings[0].ViewKey;
                if (mappedSelection)
                {
                    selections[result.Bets[0].Mappings[0].ViewKey] = new RegularLiveSlipSelection(undefined, undefined, undefined, undefined, undefined, selection.Odds, selection.Points);
                }
            }
        }

        return selections;
    },

    placeBetValidate: function (lines)
    {
        if (!UserInfo.current || UserInfo.current == null) {
            this.dispatchError($dict.bs("NotLoggedInMobile"));
            return false;
        }

        if (this.YourBetStake <= 0) {
            this.dispatchError($dict.bs("InvalidBet"));
            return false;
        }

        var minBetCombo = this.getYourBetMinBet(lines);

        if (this.YourBetStake > 0 && this.YourBetStake < minBetCombo) // check min bet 
        {
            this.dispatchError($dict.bs("MinimalBet").concat(" " + MoneyFormat.format(Bets.roundMin(minBetCombo))));
            return false;
        }

        if (this.getRegulationMinPurchaseStake() > this.YourBetStake)
        {
            this.dispatchError($dict.bs("UnderMinTotalCost"));
            return false;
        }

        if (this.isOverMaxWinRegulation(this.YourBetStake * this.YourBetStake, this.YourBetStake))
        {
            this.dispatchError($dict.bs("RegulationsMaxWin").format("{0}{1}".format(BetSlip.UserCurrencyCode, MoneyFormat.format(this.getRegulationMaximumPurchaseWin()))));
            return false;
        }

        if (!BetSlip.SkipClientUserBalanceCheckForSingles && this.YourBetStake > UserInfo.current.getBalance())
        {
            if (typeof (QuickDepositBlock) !== "undefined")
            {
                QuickDepositBlock.showDepositPopupIfNeed();
            }
            this.dispatchError($dict.bs("NotEnoughFunds"));
            return false;
        }

        return true;
    },

    setBetInProcess: function (isBetInProcess, updateView) {
        this.postPlaceBetBtnMessage(!isBetInProcess);
        this.postSetInProcess(isBetInProcess);

        if (!isBetInProcess)
        {
            this.dispatchYourBetUpdateEvent();
        }

        BaseSPSlipMode.prototype.setBetInProcess.call(this, isBetInProcess, updateView);
    },   

    dispatchYourBetUpdateEvent: function ()
    {
        if (this.SkipYourBetOddsUpdate)
        {
            this.SkipYourBetOddsUpdate = false;
            this.EventsManager.dispatchEvent("onExecuteYourBetOddsUpdate", null, true);
        }
    },

    postSetInProcess: function (isBetInProcess)
    {
        if (sbMsgBus)
        {
            var purchaseMessage = {};
            var purchaseStatus = {
                waiting: isBetInProcess,
                accepted: false,
                rejected: false,
                newOffer: false
            };
            purchaseMessage[this.YourbetTopic] = {
                placeResult: {
                    purchase: [{
                        id: 'YourBetPreplacement',
                        status: purchaseStatus
                    }] 
                }
            };

            sbMsgBus.post(purchaseMessage);
        }
    },

    postPlaceBetBtnMessage: function (isBtnEnabled) {
        if (sbMsgBus) {
            var placeBetBtnMessage = {};

            placeBetBtnMessage[this.YourbetTopic] = {
                actions: {
                    placeBetButtonState: {
                        isEnabled: isBtnEnabled
                    }
                }
            };

            sbMsgBus.post(placeBetBtnMessage);
        }
    },

    placeBets: function ()
    {
		if (BaseSPSlipMode.prototype.isGeolocationInProgress.call(this))
		{
			return;
		}

        if (this.IsBetInProcess) return;
        this.setBetInProcess(true, true);
        this.clearErrors();
        const lines = [];
        const freeBets = [];
        
        for (var key in BetSlip.Selections)
        {
            var item = BetSlip.Selections[key];
            if (!item.Valid || item.Danger)
            {
                this.dispatchError($dict.bs("YourBetDanger"));
                this.setBetInProcess(false);
                return;
            }

            lines.push(BetSlip.Selections[key]);
        }

        if (!this.placeBetValidate(lines))
        {
            this.setBetInProcess(false);
            return;
        }

        var ref = this;
        this.SelectedFreeBet && freeBets.push(this.SelectedFreeBet);
        var purchaseRequest = this.formatPurchaseRequest(lines, null, [], null, null, null, null, null, freeBets, {});
        var createYourBetRequest = this.createYourBetRequest(purchaseRequest);

        BettingPageMethods.PlaceYourBetPurchase(createYourBetRequest,
                 function (result)
                 {
                     var purchases = [eval(result)];
                     ref.placePurchaseCallBack(purchases);
                 },
                 this.placePurchaseCallBackError,
                 this
             );
    },

    placePurchaseCallBack: function (purchases)
    {
        BetSlip.sendToJSApi();
        this.setBetInProcess(false);

        if (purchases == -1) {
            this.dispatchError($dict.bs("NotLoggedInMobile"));
            return; // If user is not logged-in
        }

        for (var i in purchases)
        {
            var purchase = purchases[i];
            this.parsePlaceBets(purchase);
        }

        BetSlip.afterBetSuccessfullyPlaces(purchases);
    },

    placePurchaseCallBackError: function (result, ref) {
        var customErrorMessage = null;
        try {
            var customError = eval(result);

            if (customError != null && customError.code)
                customErrorMessage = $dict.bs(customError.code);
        }
        catch (ex) {
            console.warn(ex);
            customErrorMessage = null;
        }
        finally { }
        ref.dispatchError(customErrorMessage || $dict.bs("CommunicationError"));
        ref.setBetInProcess(false);
        ref.updateView();
        BetSlip.updateBSFooter();
    },

    clearErrors: function () {
        for (var i in this.Errors) this.Errors[i] = false;
        this.CurrentError = false;
        this.dispatchError();
    },

    parsePlaceBets: function(result)
    {
        this.clearErrors();

        var bets = [];
        var selections = [];

        switch (result.Status)
        {
            case 1: { this.StakesUpdatedByUserForDropDown = []; }//Accepted
            case 3: //Waiting
                {
                    for (var k in result.Bets) {
                        BetSlip.CurrentMode.SaveReservedFreeBetsInStorage(result.Bets[k].FreeBetID);
                    }
                    var yourBetOdds = this.formatedYourBetOdds(this.YourBetOdds);
                    var yourBetStake = this.YourBetStake;
                    var purchase = SPPurchaseBuilder.createYourbetSlipPurchase(result, this.getYourBetSelections(result), false, false, this.SelectedFreeBet);
                    this.YourBetOdds = yourBetOdds;
                    this.YourBetStake = yourBetStake;
                    UserInfo.updateBalance();

                    this.EventsManager.dispatchEvent("onYourBetSuccessfulPlacement", purchase, true);
                    break;
                }
            case 2: //Rejected
            case -10:
                {
                    this.dispatchError(this.translateBetSlipError(
                        {
                            Status: result.ErrorCode,
                            ErrorText: result.Message,
                            Regulations: result.Regulations,
                            userCurrency: result.userCurrency
                        }));

                        this.dispatchYourBetUpdateEvent();
                    break;
                }
            case -1: //Selections Error
                {
                    var errorsHelper = new BetSlipErrorMessagesHelper();
                    for (var sIdx in result.Selections)
                    {
                        var line = result.Selections[sIdx];
                        var selection = Array.find(BetSlip.Selections, function (item) { return item.ViewKey == line.ViewKey; });
                        if (!selection && !line.IsYourBetSelection) continue;

                        if (!selection && line.IsYourBetSelection)
                        {
                            this.dispatchError(this.translateBetSlipError(line, selection, false));
                            this.dispatchYourBetUpdateEvent();
                            return; 
                        }

                        if (!line.IsEachWaySelection) selection.processBetUpdate(line);

                        if (line.Closed == 1)
                        {
                            line.ErrorText = $dict.bs("LineClosed");
                            this.dispatchError($dict.bs("ComboEventDanger"));
                            if (selection.Live)
                            {
                                selection.Valid = false;
                                selection.LineClosed = true;
                            }
                        }

                        var showError = (!BetSlip.isAlertMessageEnabled || (BetSlip.isAlertMessageEnabled && !selection.UpdatedOdds && !selection.UpdatedPoints))
                        if (showError)
                        {
                            selection.UpdatedOdds && (line.ErrorText = $dict.bs("OddsChanged"));
                            selection.UpdatedPoints && (line.ErrorText = $dict.bs("PointsChanged"));
                            selection.UpdatedOdds && selection.UpdatedPoints && (line.ErrorText = $dict.bs("AllChanged"));

                            //selection.Error = line.ErrorText;
                            this.dispatchError(this.translateBetSlipError(line, selection, false));
                        }

                        this.updateSelection(selection);

                        errorsHelper.AlertIfSelectionUpdated(selection);
                    }

                    this.dispatchYourBetUpdateEvent();
                    break;
                }
            case -2: //Bets Error
                {
                    var bet = result.Bets[0];
                    this.dispatchError(this.translateBetSlipError(bet, null, true));
                    this.dispatchYourBetUpdateEvent();
                    break;
                }
            case -3: //FreeBets error
                {
                    this.handleExpiredFreeBet(result.FreeBets[0]);
                    break;
                }
            case -4: //Betting limit error
                {
                    var errorText = $string('General').BettingLimitHit;
                    errorText = errorText.replace('$VALUE$', UserInfo.current.toUserFormatedCurrencyString(result.RemainingBettingLimit));
                    this.dispatchError(errorText);
                    BetSlip.sendBetLimitsErrorToJSApi(result);
                    this.dispatchYourBetUpdateEvent();
                    break;
                }
            case -18:
                {
                    var errorText = this.getVerificationErrorMessage(result.ErrorCode);
                    this.dispatchError(errorText);
                    this.dispatchYourBetUpdateEvent();
                    break;
                }
        }
    },

    handleExpiredFreeBet(resultFreeBet){
        if (sbMsgBus) {
            const freeBets = {};
            freeBets[this.YourbetTopic] = {
                actions: {
                    freeBets: this.mapToYourBetFreeBet(this.getAvailableFreeBets())
                }
            };
            sbMsgBus.post(freeBets);
        }

        this.dispatchError(this.translateBetSlipError(resultFreeBet, null, true));
    },

    getAvailableFreeBets() {
        let freeBets;
        let balance = 0;
        
        if (UserInfo.current && UserInfo.current.isBalancesInitiated())
        {
            freeBets = UserInfo.current.FreeBets;
            balance = UserInfo.current.getBalance();
        }
        else
        {
            freeBets = UserInfo.getFreeBetsFromStorage();
        }

        const selections = BetSlip.Selections;
        const reservedFreeBets = this.getReservedFreeBets();
        const selectedPulseFreeBets = BetSlip.getSelectedPulseFreeBets();
        let availableFreeBets = [];
        for (let i in freeBets)
        {
            const freeBet = freeBets[i];
            const notAvailableFreeBets = Array.find(reservedFreeBets.ReservedFreeBets, function (e)
            {
                return e == freeBet.BonusID;
            });

            if (notAvailableFreeBets) continue;

            //Avoid duplicating free bets in selectedFreeBets for You
            if (Array.find(selections, function (sel) { return (sel.FreeBet && sel.FreeBet.BonusID == freeBet.BonusID); }) ||
                Array.find(selectedPulseFreeBets, function (bonusID) { return bonusID == freeBet.BonusID; }))
                continue;
            
            if (!BetSlip.SkipClientUserBalanceCheckForSingles && freeBet.IsRiskFreeBet && balance < freeBet.Amount)
            {
                continue;
            }

            if (freeBet.IsAllowedForYourBet(selections, this.YourBetOdds))
            {
                freeBet.Title = `${Bets.formatCurrencyStake(freeBet.Amount)} ${freeBet.Name}`;
                
                availableFreeBets.push(freeBet);
            }
        }
            
        return availableFreeBets;   
    },

    setSelectedFreeBet(freeBetId) {
        let stake = 0;
        this.SelectedFreeBet = this.getAvailableFreeBets().find(freeBet => freeBet.BonusID === freeBetId);

        if (this.SelectedFreeBet) {
           stake = this.SelectedFreeBet.Amount;
        } else {
            BetSlip.getCurrencyWithStakeValues() && (stake = BetSlip.getCurrencyWithStakeValues().DefaultStakeForMultiples || 0);
            
            freeBetId !== -1 && this.handleExpiredFreeBet(this.SelectedFreeBet);
        }

        this.setStake(stake);
    },

    getEventTypeName: function (selection, selectionType)
    {
        var lineEventTypeName = selection.LineTypeName;

        switch (true)
        {
            case selectionType == 'regularSelection':
                lineEventTypeName = Selections.getLineEventTypeName(selection);
                break;
            case selectionType == 'QASelection':
                var tsSelection = Selections.teamSwapQASelection(selection);
                var team1Name = Selections.getFullPickLineValue(selection, tsSelection.Team1Name);
                var team2Name = Selections.getFullPickLineValue(selection, tsSelection.Team2Name);
                lineEventTypeName = Selections.getQALineEventTypeName(selection, team1Name, team2Name);
                break;
        }

        return lineEventTypeName;
    },

    selectionAdded: function (item)
    {
        this.setSelectedFreeBet(-1);
    },

    selectionUpdated: function (item)
    {
        // we need to have this function, but in this mode it does nothing.
    },

    selectionRemoved: function (item)
    {
        if (sbMsgBus && this.Active) {
            this.setSelectedFreeBet(-1);
            var selectionRemoveMsg = {};
            selectionRemoveMsg[this.YourbetTopic] = {
                selections: {
                    prelive: {
                        selections: {
                            remove: [{
                                id: item.LineID.toString(),
                                name: "",
                                viewKey: item.ViewKey,
                                eventType: "",
                                suspended: false
                            }]
                        }
                    }
                },
                actions: {
                    freeBets: this.mapToYourBetFreeBet()
                }
            };

            sbMsgBus.post(selectionRemoveMsg);
        }
    },

    mapToYourBetFreeBet: function () 
    {
        return this.getAvailableFreeBets().map(fb => ({id:fb.BonusID, text:fb.Title}));
    },

    clear: function ()
    {
        this.YourBetOdds = 0;
        this.YourBetStake = 0;
        this.SelectedFreeBet = null;
        var currency = BetSlip.getCurrencyWithStakeValues();

        if (currency)
        {
            this.YourBetStake = currency.DefaultStakeForMultiples || 0;
        }

        var stakeField = document.getElementById('your-bet-stake-field');
        stakeField && (stakeField.value = 0);
        this.setStake(this.YourBetStake);
    },

    CheckForSPIncluded: function ()
    {
        return false;
    },

    CheckForNoOddsSelectionIncluded: function ()
    {
        return false;
    },

    getNumberOfBets: function ()
    {
        return false;
    },

    calculateLoyaltyPoints: function ()
    {
        return false;
    },

    IsMaxBetButtonEnabled: function ()
    {
        return false;
    },

    dispatchError: function (errorMessage)
    {
        var eventID = BetSlip && BetSlip.Selections[0] && BetSlip.Selections[0].EventID;
        this.EventsManager.dispatchEvent("onYourBetPlacementError", { isError: true, eventId: eventID, errorMessage: errorMessage }, true);
    }
     
}, TYourBetSPSlipMode);

window.YourBetSPSlipMode = YourBetSPSlipMode;
