function TeaserSPSlipMode()
{
    BaseSPSlipMode.call(this);

    this.Order = 3;
    this.ID = "teaser";
    this.TabClass = "teaser betting_slip_nbs";
    this.Name = $dict.bs("TeaserBetTab");

    this.Active = false;
    this.HasBeenActivated = false;

    this.Deposit = 0;
    this.FreeBet = null;
    this.SelectionsInTeaser = [];
    this.TeaserTypeID = false;
    this.CurrentError = false;

    this.IsInvisibleModeOn = false;
    this.IsBetInProcess = false;

    this.MinSelections = 2;
    this.ActivationRank = 5;
    this.PurchaseTypeID = SPPurchaseType.Teaser;
    this.StakeUpdatedByUser = false;
    this.LastSelectedPoints = false;
}

$.extend(TeaserSPSlipMode.prototype, BaseSPSlipMode.prototype,
{
    init: function ()
    {
        TeaserSPTypes.init();
        BetSlip.OnModeChanged["TeaserSlipMode"] = function ()
        {
            if (BetSlip.CurrentMode instanceof TeaserSPSlipMode)
            {
                BetSlip.CurrentMode.updateView();
            }
        };
    },

    activate: function ()
    {
        this.Active = true;
        this.HasBeenActivated = true;
    },

    deactivate: function ()
    {
        this.Active = false;

        // Clear the current error if the current tab is disabled and navigated away from.
        // This is done so that if the tab is enabled again and navigated to then the same error would not be present there again
        if (!this.canPlaceBet())
            this.CurrentError = false;
    },

    canActivate: function ()
    {
        if (BetSlip.CheckForCastSelectionIncluded())
            return 0;

        var selLen = Array.getLength(this.SelectionsInTeaser);

        if (selLen >= this.MinSelections)
        {
            var hasAtleastOneGroups = true;

            for (var key in this.SelectionsInTeaser)
            {
                sel = BetSlip.Selections[this.SelectionsInTeaser[key]];
                var group = Array.find(TeaserSPTypes.Groups, function (el) { return Array.indexOf(el.Leagues, sel.LeagueID) >= 0; });
                hasAtleastOneGroups =hasAtleastOneGroups && group;

            }
            return hasAtleastOneGroups ? this.ActivationRank : 0;
        }
        else if (selLen < this.MinSelections && Array.getLength(BetSlip.Selections) >= this.MinSelections && this.HasBeenActivated)
        {
            // This check stops the user from being automatically navigated away from the teaser tab if they uncheck some selections
            // so that less than 2 selections on the teaser remain marked. This can happen because the selections are updated on some period of time
            // after which BetSlip.autoSelectMode() (which calls this method) is called to determine the current tab.
            // Here we check whether the total number of checked and unchecked is at least 2.
            var tmp = [];
            for (var key in this.SelectionsInTeaser) tmp[key] = this.SelectionsInTeaser[key];

            for (var key in BetSlip.Selections)
            {
                if (Array.indexOf(this.SelectionsInTeaser, key) == -1 && !this.getTeaserAddError(BetSlip.Selections[key]))
                {
                    this.SelectionsInTeaser.push(key);
                    if ((++selLen) >= this.MinSelections)
                        break;
                }
            }

            this.SelectionsInTeaser = tmp;

            return selLen >= this.MinSelections ? this.ActivationRank : 0;
        }
        else
        {
            return 0;
        }
    },

    canPlaceBet: function ()
    {
        return Array.getLength(this.SelectionsInTeaser) >= 2;
    },

    isComboSystemOrTeaserBet: function ()
    {
        return true;
    },

    selectionAdded: function (item)
    {
        if (item.TeaserBetIsEnabled && !this.getTeaserAddError(item))
        {
            if (Array.indexOf(this.SelectionsInTeaser, item.ViewKey) == -1)
            {
                this.SelectionsInTeaser.push(item.ViewKey);

                // Remove message "You need to make at least 2 selections applicable for teaser to place a bet!" if now there are two
                if (this.CurrentError == $dict.bs("SelectionsInTeaser") && Array.getLength(this.SelectionsInTeaser) >= 2)
                {
                    this.CurrentError = false;
                }
            }
        }

        if (!(typeof UKSPSlipMode != "undefined" && BetSlip.CurrentMode instanceof UKSPSlipMode) && !this.StakeUpdatedByUser) {
            this.Deposit = BetSlip.getUserCurrency().DefaultStakeForMultiples || BetSlip.getCurrencyWithStakeValues().DefaultStakeForMultiples || 0;
        }

        if (!this.Active) return;

        this.updateView();
        BetSlip.updateBSFooter();
    },

    selectionUpdated: function (item, initcall)
    {
        if (item.TeaserBetIsEnabled && !this.getTeaserAddError(item))
        {
            if (Array.indexOf(this.SelectionsInTeaser, item.ViewKey) == -1 && initcall)
            {
                this.SelectionsInTeaser.push(item.ViewKey);

                // Remove message "You need to make at least 2 selections applicable for teaser to place a bet!" if now there are two
                if (this.CurrentError == $dict.bs("SelectionsInTeaser") && Array.getLength(this.SelectionsInTeaser) >= 2)
                {
                    this.CurrentError = false;
                }
            }
        }

        if (!this.Active) return;

        if (initcall)
        {
            this.updateView();
        }
        else if (item.Updated || !item.Valid)
        {
            // updateSelection() redraws the selection so if the selection is updated we need to redraw it
            // otherwise old odds or points would remain unchanged on the screen. Then updateSelectionData()
            // would make those odds or points highlighted and blinking.
            this.updateSelection(item);
            this.updateSelectionData(item);
        }
        else
            this.updateSelectionData(item);

        BetSlip.updateBSFooter();
    },

    calculateLoyaltyPoints: function ()
    {
        return {};
    },

    selectionRemoved: function (item)
    {
        Array.removeAll(this.SelectionsInTeaser, function (el) { return el == item.ViewKey; });

        // Remove message "One of the events you have selected is suspended or invalid!" if all remaining selections are valid
        if (this.CurrentError == $dict.bs("TeaserEventDanger") && Array.find(this.getSelectionsInTeaser(), function (sel) { return !(sel.Valid && !sel.Error); }) == null)
        {
            this.CurrentError = false;
        }

        // If the user removes the last selection from the slip in the slip act as if the tab has never been opened, i.e. as if the slip was cleared,
        // remove current FreeBet and reset StakeUpdatedByUser flag
        if (Array.getLength(BetSlip.Selections) == 0)
        {
            this.HasBeenActivated = false;
            this.StakeUpdatedByUser = false;
            this.FreeBet = null;
        }

        if (!this.Active) return;

        this.updateView();
        BetSlip.updateBSFooter();
    },

    clear: function ()
    {

        this.Deposit = 0;
        this.SelectionsInTeaser = [];
        this.LastSelectedPoints = this.TeaserTypeID;
        this.TeaserTypeID = false;
        this.CurrentError = false;
        this.IsBetInProcess = false;
        this.HasBeenActivated = false;
        this.StakeUpdatedByUser = false;
        this.FreeBet = null;
        this.StakeUpdatedByUser = false;

        this.updateView();
        BetSlip.updateBSFooter();
    },

    getSelectionsInTeaser: function ()
    {
        var selections = [];

        for (var i in this.SelectionsInTeaser)
        {
            var viewKey = this.SelectionsInTeaser[i];
            var selection = Array.find(BetSlip.Selections, function (el) { return el.ViewKey == viewKey; });
            if (selection)
            {
                selections[selection.ViewKey] = selection;
            }
        }

        return selections;
    },

    getSelections: function ()
    {
        return this.getSelectionsInTeaser();
    },

    getNumberOfBets: function ()
    {
        if (this.Deposit && this.Deposit > 0 && this.canPlaceBet())
        {
            return 1;
        }

        return 0;
    },

    getTotalDeposit: function ()
    {
        return this.Deposit;
    },

    getTotalGain: function ()
    {
        if (this.TeaserTypeID && this.Deposit)
        {
            var teaserTypeInfo = TeaserSPTypes.getTeaserInfo(this.TeaserTypeID);
            var odds = teaserTypeInfo.BasicOdds;

            var fodd;
            fodd = odds > 0 ? (odds / 100 + 1) : (1 - 100 / odds);
            var gainToFixed = (BetMath.roundDecimalOdds(fodd, BetSlip.BaseOddsRoundingMode, !BetSlip.ForceTwoDigitsForOddsRounding) * this.Deposit).toFixed(12);
            var roundingMode = typeof BetSlip != "undefined" && BetSlip.GainRoundingMode || 0;
            return GainUtil.round(gainToFixed, roundingMode);
        }
        return 0;
    },

    getTotalOdds: function ()
    {
        if (this.TeaserTypeID) {
            var teaserTypeInfo = TeaserSPTypes.getTeaserInfo(this.TeaserTypeID);
            var odds = teaserTypeInfo.BasicOdds;

            var fodd;
            fodd = odds > 0 ? (odds / 100 + 1) : (1 - 100 / odds);

            return fodd;
        }
        return 0;
    },

    getOdds: function ()
    {
        if (this.TeaserTypeID && this.TeasetTypeID != -1)
        {
            var info = TeaserSPTypes.getTeaserInfo(this.TeaserTypeID);
            if (info)
            {
                return info.BasicOdds;
            }
        }

        return 0;
    },

    updateView: function (optionChanged)
    {
        if (!this.Active) return;
        var __html = [], containerElement = document.getElementById("bet-slip-container"),
            activeElementID = (document.activeElement && document.activeElement.tagName && document.activeElement.tagName.toLowerCase() === 'input') ? document.activeElement.id : "",
            activeElementValue = (document.activeElement && document.activeElement.tagName && document.activeElement.tagName.toLowerCase() === 'input') ? document.activeElement.value : "";
        var shouldFocusElement = document.activeElement && typeof (document.activeElement.getBoundingClientRect) == 'function' ? document.activeElement.getBoundingClientRect() : null;

        if (containerElement)
        {
            this.buildInnerView(__html);
            containerElement.innerHTML = __html.join("");
        }

        // update options
        var optionsHtml = [], optionsElement = document.getElementById("teaser_options");
        if (optionsElement)
        {
            this.buildTeaserOptions(optionsHtml);
            optionsElement.innerHTML = optionsHtml.join("");
            if (!optionChanged)
            {
                TeaserSPSlipMode.OptionChanged();
            }
        }

        this.setFocusOnStakeBox(activeElementID, activeElementValue, shouldFocusElement);
        

        this.deselectStakeDropdown();

    },

    serialize: function ()
    {
        var selections = [];
        for (var i in this.SelectionsInTeaser)
        {
            selections.push(this.SelectionsInTeaser[i]);
        }

        return { ID: this.ID, Deposit: this.Deposit, SelectionsInTeaser: selections, TeaserTypeID: this.TeaserTypeID, Error: this.CurrentError, TeaserTypes: TeaserSPTypes, HasBeenActivated: this.HasBeenActivated, FreeBet: this.FreeBet ? this.FreeBet.serialize() : null };
    },
    deserialize: function (data)
    {
        this.ID = data.ID;
        this.Deposit = data.Deposit;
        this.TeaserTypeID = data.TeaserTypeID;
        this.CurrentError = data.CurrentError;
        this.HasBeenActivated = data.HasBeenActivated;

        this.SelectionsInTeaser = [];
        for (var i in data.SelectionsInTeaser)
        {
            this.SelectionsInTeaser.push(data.SelectionsInTeaser[i]);
        }

        TeaserSPTypes.Types = [];
        for (var i in data.TeaserTypes.Types)
        {
            if (data.TeaserTypes.Types[i] != null)
            {
                TeaserSPTypes.Types.push(data.TeaserTypes.Types[i]);
            }
        }
        TeaserSPTypes.Groups = [];
        for (var i in data.TeaserTypes.Groups)
        {
            if (data.TeaserTypes.Groups[i] != null)
            {
                TeaserSPTypes.Groups.push(data.TeaserTypes.Groups[i]);
            }
        }

        this.FreeBet = data.FreeBet ? new BonusInfo(data.FreeBet) : null;
    },

    updateSelectionData: function (selection)
    {
        var oddsElement = document.getElementById("cOddsToWhow_" + selection.ViewKey),
            pointsElement = document.getElementById("cPoints_" + selection.ViewKey),
            teaserOdds = document.getElementById("teaserOdds");

        this.setLastSelectionUpdateProps(selection);
        this.setElementsBlinking(selection.ViewKey);

        if (selection.UpdatedOdds && teaserOdds) teaserOdds.innerHTML = Odds.convertFromAmerican(this.getOdds());

        if (oddsElement) oddsElement.innerHTML = this.formatSelectionOddsForSlip(selection);

        if (pointsElement)
        {
            var currPoints = this.getTeaserSelectionPoints(selection);
            disableAsianStyleForBetSlip = (disableAsianStyleForBetSlip && typeof (isAsianView) != "undefined" && isAsianView);
            pointsElement.innerHTML = selection.BetTypeID == 2 ? Odds.formatPoints(currPoints, undefined, undefined, undefined, disableAsianStyleForBetSlip) : Odds.formatPoints(currPoints, true, undefined, undefined, disableAsianStyleForBetSlip);
        }
    },

    getTeaserAddError: function (selection)
    {
        if (!selection.TeaserBetIsEnabled)
            return $dict.bs("SelectionNotAllowedInTeaser");

        var basket = this.getSelectionsInTeaser();

        if (Array.getLength(basket) >= MaxTeaserNumber)
            return $dict.bs("MaxTeaserLinesExceeded");

        if (selection.Live)
        {
            return $dict.bs("LiveSelectionNotAllowedInTeaser");
        }

        if (!(selection.BetTypeID != 1 && selection.SplitType != 4))
        {
            return $dict.bs("TeaserIsAllowedOnPointSpreadOnly");
        }

        for (var i in basket)
        {
            var bSelection = basket[i];
            if (selection.equals(bSelection))
                continue;

            if ((selection.MasterEventID != 0) &&
                (bSelection.MasterEventID == selection.MasterEventID) &&
                (
                    (bSelection.BetTypeID == 1 && selection.BetTypeID == 1) || (bSelection.BetTypeID == 2 && selection.BetTypeID == 2) ||
                    (bSelection.BetTypeID == 3 && selection.BetTypeID == 3)
                )
               )
            {
                return $dict.bs("SelectionNotAllowedInTeaser");
            }

            if (selection.BranchID != bSelection.BranchID)
                return $dict.bs("NoSameBranchInTeaser");
        }

        return false;
    },

    getTeaserTypesForCurrentSelections: function ()
    {
        var numberOfLines = Array.getLength(this.SelectionsInTeaser);
        if (numberOfLines >= 2)
        {
            var lines = this.getSelectionsInTeaser();
            for (var key in lines)
            {
                var leagueID = lines[key].LeagueID;

                return TeaserSPTypes.getTeaserInfoForLeagueAndNumberOfLines(leagueID, numberOfLines);
            }
        }
    },

    getOddsSum: function ()
    {
        var lines = this.getSelectionsInTeaser();
        var result = 0;
        for (var lIdx in lines)
        {
            var line = lines[lIdx];
            result += line.getRealOdd() - 1;
        }

        return result;
    },

    getTeaserMaxBet: function ()
    {
        var lines = this.getSelectionsInTeaser();

        var maxRiskTeaser = Array.getMinValue(
            lines, 
            function (line) { return line.MaxRiskTeaser > 0; }, 
            function (line) { return line.MaxRiskTeaser; }
        );

        var gainDivider = this.getTotalOdds() - this.getNumberOfBets();
        var maxBetComboFromRisk = maxRiskTeaser && gainDivider > 0
            ? Math.floor(maxRiskTeaser / gainDivider)
            : 0;

        var totalOddsSum = this.getOddsSum();
        
        var maxBetTeaser;

        for (var key in lines)
        {
            var line = lines[key];
            if (!maxBetTeaser)
            {
                maxBetTeaser = line.MaxBetTeaser;
            }
            else if ((line.MaxBetTeaser < maxBetTeaser || maxBetTeaser === 0) && line.MaxBetTeaser > 0)
            {
                maxBetTeaser = line.MaxBetTeaser;
            }

            if (totalOddsSum > 0 && line.SingleSelectionRisk > 0 && line.MaxRisk > 0 && line.getRealOdd() > 0) {
                var maxRiskTeaserFromSSR = line.SingleSelectionRisk * line.MaxRiskTeaser / ((line.getRealOdd() - 1) / totalOddsSum);
                var divider = this.getTotalOdds() - this.getNumberOfBets();
                var maxBetComboFromSSR = divider > 0 ? Math.floor(maxRiskTeaserFromSSR / divider) : 0;
                if ((maxBetComboFromSSR > 0 && maxBetComboFromSSR < maxBetTeaser) || maxBetTeaser == 0) {
                    maxBetTeaser = maxBetComboFromSSR;
                }
            }
        }

        if (!maxBetTeaser || (maxBetComboFromRisk > 0 && maxBetComboFromRisk < maxBetTeaser))
        {
            maxBetTeaser = maxBetComboFromRisk;
        }

        if (maxBetTeaser > 0 && BetSlip.StakeRounding == BetSlip.StakeRoundingMethods.ZeroDecimalPoints)
        {
            maxBetTeaser = Math.floor(maxBetTeaser);
        } else {
            maxBetTeaser = Math.floor(maxBetTeaser * 100) / 100;
        }

        return maxBetTeaser;
    },

    setDeposit: function (deposit)
    {
        if (typeof deposit === "string")
        {
            deposit = BetSlip.SanitizeNumberString(deposit);
        }
        if (isNaN(deposit))
        {
            deposit = 0;
        }

        this.Deposit = deposit;
        BetSlip.updateBSFooter();
    },

    setStake: function (element)
    {
        var st = BetSlip.SanitizeNumberString(element.value);
        if (isNaN(st))
        {
            st = 0;
        }

        st = BetSlip.RoundToStakeRounding(st, element);

        if (BetSlip.isDefaultStakeAndStakeValuesAvailable())
        {
            var defaultStake = BetSlip.getCurrencyWithStakeValues().DefaultStakeForMultiples;
            this.StakeUpdatedByUser = st != defaultStake;
            this.deselectStakeDropdown();
        }

        this.Deposit = st;

        this.StakeUpdatedByUser = st > 0;

        var returnBox = document.getElementById("teaser_return_value");

        if (returnBox)
            returnBox.innerHTML = BetSlip.formatMoney(this.getTotalGain());

        BetSlip.updateBSFooter();
        BetSlip.saveState();
    },
    setStakeDropDown: function (element)
    {

        var stakebox = document.getElementById("stakebox_teaser");
        if (element.selectedIndex != -1)
        {
            stakebox.value = element.value;
            element.options[element.selectedIndex].selected = false;
            element.value = 0;
            element.selectedIndex = -1;
        }
        BetSlip.CurrentMode.setStake(stakebox);
    },
    // fills stake with free bet amount
    setFreeBetStake: function (freeBet)
    {
        var amount = freeBet ? freeBet.Amount : 0;
        if (amount > 0 && BetSlip.StakeRounding == BetSlip.StakeRoundingMethods.ZeroDecimalPoints && amount.toString().indexOf('.') > -1)
        {
            amount = Math.floor(amount);
            document.getElementById('stakebox_teaser').value = BetSlip.getStakeBoxValue(amount);
        }

        this.Deposit = amount;

        BetSlip.updateBSFooter();
    },

    getTeaserWaitingAllowed: function ()
    {
        var lines = this.getSelectionsInTeaser();

        if (Array.getLength(lines) == 0)
        {
            return false;
        }

        for (var key in lines)
        {
            if (!lines[key].waitingAllowed)
            {
                return false;
            }
        }

        return true;
    },

    getRate: function ()
    {

        var Lines = this.getSelectionsInTeaser();
        var rate = 1;

        for (var key in Lines) rate *= Math.floor((Lines[key].getRealOdd() * 1000).toPrecision(12)) / 1000;

        rate = Math.floor((rate * 100).toPrecision(12)) / 100;

        var rateByUSOdds = Odds.decimalToAmerican(rate);

        rateByUSOdds = Math.floor(
                                        ((rateByUSOdds > 0 ? (rateByUSOdds / 100 + 1) : (1 - 100 / rateByUSOdds)) * 1000).toPrecision(12)
                                ) / 1000;

        if (rate <= rateByUSOdds)
        {
            rateByUSOdds = rate;
        }
        else
        {
            rateByUSOdds = Odds.decimalToAmerican(rate) + 1;
            rateByUSOdds = Math.floor(
                                            ((rateByUSOdds > 0 ? (rateByUSOdds / 100 + 1) : (1 - 100 / rateByUSOdds)) * 1000).toPrecision(12)
                                    ) / 1000;
        }



        return Math.floor((rateByUSOdds * 100).toPrecision(12)) / 100;
    },

    check: function ()
    {
        var Lines = this.getSelectionsInTeaser();

        if (Array.getLength(Lines) < 2)
        {
            this.setError($dict.bs("SelectionsInTeaser"));
            return false;
        }

        var fd = this.getTotalDeposit();
        if (fd <= 0)
        {
            this.setError($dict.bs("InvalidBet"));
            return false;
        }

        //min bet
        var mx = this.getComboMinBet(Lines, true);

        var freeBetDeposit = this.getFreeBetDeposit();
        var realMoneyDeposit = fd - freeBetDeposit;
        if (realMoneyDeposit > 0 && realMoneyDeposit < mx)
        {
            this.setError($dict.bs("MinimalBetOverFree").concat(" " + MoneyFormat.format(mx)));
            return false;
        }

        // max bet
        var maxBet = this.getTeaserMaxBet();
        if (fd > maxBet && !this.getTeaserWaitingAllowed())
        {
            this.setError($dict.bs("MaximalBetForCombo").format(MoneyFormat.format(maxBet)));
            return false;
        }

        if (!BetSlip.SkipClientUserBalanceCheckForCombos && (fd - freeBetDeposit) > UserInfo.current.getBalance())
        {
            if (typeof (QuickDepositBlock) != "undefined")
            {
                QuickDepositBlock.showDepositPopupIfNeed();
            }
            this.setError($dict.bs("NotEnoughFunds"));
            return false;
        }

        return true;
    },

    // Sets (or re-sets) the betslip in status "Bet in process"
    setBetInProcess: function (isBetInProcess, updateView)
    {
        var placeBetElement = document.getElementById("PlaceBetButton"),
            placeElement = document.getElementById("place"),
            reqAmtMsg = document.getElementById("reqAmtMsg");

        this.IsBetInProcess = isBetInProcess;
        BetSlip.isBetInProcess = isBetInProcess;

        if (updateView)
        {
            this.updateView();
        }

        // Disable/enable "Place bets" button and show/hide request amount message
        if (placeBetElement) placeBetElement.disabled = this.IsBetInProcess;
        if (placeElement) placeElement.classList[this.IsBetInProcess ? "add" : "remove"]("disabledBtn");
        if (reqAmtMsg) reqAmtMsg.classList[this.IsBetInProcess ? "add" : "remove"]("isHidden");
    },

    getPostRequest: function (selection)
    {
        var isLive = false;
        var isDanger = selection.Danger ? selection.Danger : false;
        var score1 = selection.Score1 ? selection.Score1 : 0;
        var score2 = selection.Score2 ? selection.Score2 : 0;
        var betSide = selection.BetSide ? selection.BetSide : 0;

        var teaserTypeInfo = TeaserSPTypes.getTeaserInfo(this.TeaserTypeID);
        var points = 0;
        if (selection.BetTypeID == 3 && selection.BetSide == 1)
        {
            points = selection.Points - teaserTypeInfo.PointsToReduce;
        }
        else
        {
            points = selection.Points + teaserTypeInfo.PointsToReduce;
        }

        return [selection.LineGroupID, selection.LineID, selection.Odds, points, selection.ComboRate, selection.MasterEventID, selection.LeagueID, selection.MaxBetCombo, selection.EventID, selection.BetTypeID, isLive, isDanger, score1, score2, betSide].join(",");
    },

    validateMaxBet: function ()
    {
        var teaserMaxBet = this.getTeaserMaxBet();
        var deposit = this.getTotalDeposit();

        if (deposit > teaserMaxBet)
        {
            this.setError($dict.bs("MaximalBetForCombo").format(Bets.roundMin(teaserMaxBet)), true);
            return false;
        }

        return true;
    },

    placeBets: function ()
    {
		if (BaseSPSlipMode.prototype.isGeolocationInProgress.call(this))
		{
			return;
		}

        if (this.IsBetInProcess) return;

        this.setBetInProcess(true, true);

        if (!this.check())
        {
            this.setBetInProcess(false, true);
            return;
        }

        var totals = BetSlip.getFooterTotals();
        if (UserInfo.TaxProvider.isUserUnderTax() && BetSlip.validatePurchaseWithCountryTaxes(totals.Taxes, this.getTotalDeposit()))
        {
            this.setBetInProcess(false, true);
            this.setError($dict.bs("CountryTaxInvalidOdds"));
            return;
        }

        var lines = [];
        var isLiveCombo = false;

        var selections = this.getSelectionsInTeaser();

        for (var key in selections)
        {
            if (!selections[key].Valid || selections[key].Danger)
            {
                this.setError($dict.bs("TeaserEventDanger"));
                this.setBetInProcess(false, true);
                return;
            }
            if (selections[key].IsBuyPoint)
            {
                selections[key].IsBuyPoint = false;
                selections[key].BuyPointsOdds = -1;
                selections[key].BuyPointsPoints = -1;
                selections[key].BuyPointSelectedIndex = null;
            }

            //lines.push(this.getPostRequest(selections[key]));
            lines.push(selections[key]);
        }

        //var deps = [];
        //deps[0] = Array.getLength(this.SelectionsInTeaser) + ":" + this.Deposit;

        //var totalDeposit = $("#totalDeposit").text();
        //var numberOfBets = $("#numberOfBets").text();
        //var possibleWinnings = $("#totalGain").text();
        //var totalDepositWinningsInfo = totalDeposit + "@" + possibleWinnings;

        //var purchaseTypeID = 2; //combo

        var ref = this;

        BetSlip.disablePlaceBetButton();

        var freeBets = [];
        if (this.FreeBet)
            freeBets.push(this.FreeBet);

        //PageMethods.placeTeaserBet(deps.join("#"), lines.join("#"), isLiveCombo, -1, "", purchaseTypeID, totalDepositWinningsInfo, this.TeaserTypeID,
        BettingPageMethods.placeSinglePurchase(this.formatPurchaseRequest(lines, null, null, this.Deposit, this.TeaserTypeID, null, null, null, freeBets),
            function (res)
            {
                var purchases = [eval(res)];
                ref.placePurchaseCallBack(purchases);
            },
            this.placePurchaseCallBackError,
            this
        );

        // Suppress an unexpected JS error on updateView() so that it cannot stop the "bet in process" state
        try { this.updateView(); } catch (e) { if (console && console.log) console.log(e); }
    },

    clearErrors: function ()
    {
        this.CurrentError = false;

        var selections = this.getSelectionsInTeaser();
        for (var key in selections)
        {
            selections[key].Error = false;
        }
    },

    parsePlaceBets: function (result, keepPreviousPurchase)
    {
        this.clearErrors();

        var bets = [];
        var selections = [];

        switch (result.Status)
        {
            case 1: { this.StakeUpdatedByUser = false; } //Accepted
            case 3: //Waiting
                {
                    BetSlip.CurrentMode.SaveReservedFreeBetsInStorage(result.Bets[0].FreeBetID);
                    var selectionsInTeaser = this.getSelectionsInTeaser();
                    SPPurchaseBuilder.createTeaserSlipPurchase(result, selectionsInTeaser, keepPreviousPurchase, false);

                    this.Deposit = 0;

                    UserInfo.updateBalance();

                    break;
                }
            case 2: //Rejected
            case -10: // generic error
                {
                    this.setPurchaseError(result);
                    break;
                }
            case -1: //Selections Error
                {
                    for (var sIdx in result.Selections)
                    {
                        var line = result.Selections[sIdx];
                        //Prevent points blinking
                        line.Points = line.OrgPoints;
                        var selection = Array.find(BetSlip.Selections, function (item) { return item.ViewKey == line.ViewKey; });
                        if (!selection) continue;

                        selection.processBetUpdate(line);

                        if (line.Closed == 1)
                        {
                            line.ErrorText = $dict.bs("LineClosed");
                            this.CurrentError = $dict.bs("TeaserEventDanger");
                        }
                        if (selection.UpdatedOdds) line.ErrorText = $dict.bs("OddsChanged");
                        if (selection.UpdatedPoints) line.ErrorText = $dict.bs("PointsChanged");
                        if (selection.UpdatedOdds && selection.UpdatedPoints) line.ErrorText = $dict.bs("AllChanged");

                        //selection.Error = line.ErrorText;
                        selection.Error = this.translateBetSlipError(line, selection, false);

                        this.updateSelection(selection);
                        this.updateSelectionData(selection);
                    }

                    break;
                }

            case -2: //Bets Error
                {
                    var bet = result.Bets[0];
                    this.CurrentError = this.translateBetSlipError(bet, null, true);

                    break;
                }
            case -4: //Betting limit error
                {
                    var errorText = $string('General').BettingLimitHit;
                    errorText = errorText.replace('$VALUE$', UserInfo.current.toUserFormatedCurrencyString(result.RemainingBettingLimit));
                    this.setError(errorText);
                    BetSlip.sendBetLimitsErrorToJSApi(result);
                    break;
                }
            case -18:
                {
                    var errorText = this.getVerificationErrorMessage(result.ErrorCode);
                    this.setError(errorText);
                    break;
                }
        }
    },

    // Adding a purchase remove the selections from the slip, so if there are any selections in the betslip then 
    // they had not been checked before placing the bet so we try to add unchecked selections to teaser tab after purchase
    // Please, see addPurchase() method in UniSlip.js
    addUncheckedAfterPurchase: function ()
    {
        for (var viewKey in BetSlip.Selections)
        {
            if (!this.getTeaserAddError(BetSlip.Selections[viewKey]) && Array.indexOf(this.SelectionsInTeaser, viewKey) == -1)
            {
                this.SelectionsInTeaser.push(viewKey);
            }
        }
    },

    getEachWayTotalGain: function ()
    {
        return 0;
    },

    recalcEachWay: function (viewKey)
    {
    },

    updateEachWay: function (viewKey)
    {
    },

    getBetNames: function ()
    {
        var res = [];
        for (var idx in TeaserSPTypes.Types)
        {
            var teaserInfo = TeaserSPTypes.Types[idx];
            res[teaserInfo.ID] = TeaserSPTypes.getTeaserName(teaserInfo);
        }
        return res;
    },

    getFreeBetDeposit: function ()
    {
        if (!this.FreeBet)
            return 0;

        return this.FreeBet.GetFreeBetDeposit(this.Deposit);
    },

    getAvailableFreeBets: function (selections)
    {
        var freeBets = [];
        var balance = 0;
        if (UserInfo.current && UserInfo.current.isBalancesInitiated())
        {
            freeBets = UserInfo.current.FreeBets;
            balance = UserInfo.current.getBalance();
        }
        else
        {
            freeBets = UserInfo.getFreeBetsFromStorage();
        }

        if (!selections) selections = this.getSelectionsInTeaser();
        var tokens = [];

        for (var i in freeBets)
        {
            var freeBet = freeBets[i];
            var reservedFreeBets = BetSlip.CurrentMode.getReservedFreeBets();
            var notAvailableFreeBets = Array.find(reservedFreeBets.ReservedFreeBets, function (e)
            {
                return e == freeBet.BonusID;
            });
            if (notAvailableFreeBets) continue;

            if (!BetSlip.SkipClientUserBalanceCheckForSingles && freeBet.IsRiskFreeBet && balance < freeBet.Amount)
            {
                continue;
            }

            if (freeBet.IsAllowedForTeaserBet(selections, this.getOdds()))
                tokens.push(freeBet);
        }

        return tokens;
    },

    CheckForSPIncluded: function ()
    {
        var selections = this.getSelectionsInTeaser();
        return (selections.any(function (sel) { return sel.isSPSelection(); }));
    },

    CheckForNoOddsSelectionIncluded: function ()
    {
        var selections = this.getSelectionsInTeaser();
        return (selections.any(function (sel) { return sel.Odds == 0; }));
    },

    IsMaxBetButtonEnabled: function ()
    {
        if (typeof (BetSlipRegulations) !== "undefined" && BetSlipRegulations.IsItalian && (BetSlip.CurrentMode instanceof TeaserSPSlipMode))//disabled for NetBetIt
        {
            return false;
        }

        return this.shouldShowMaxbetButtonForMultiSelections(null, this.getSelectionsInTeaser());
    },

    toggleTeaserSelection: function (viewKey)
    {
        var selectionIndex = this.SelectionsInTeaser.indexOf(viewKey);
        var selection = BetSlip.Selections[viewKey];
        if (selectionIndex < 0) {
            this.selectionAdded(selection);
        } else {
            this.selectionRemoved(selection);
        }
    },

    getTeaserContext: function ()
    {
        if (this.TeaserTypeID || this.LastSelectedPoints)
        {
            var info = TeaserSPTypes.getTeaserInfo(this.TeaserTypeID || this.LastSelectedPoints);
            return {
                        pointsToReduce: info.PointsToReduce
                   };
        }
        return {};
    },

    getTeaserSelectionPoints: function (selection)
    {
        var teaserTypeInfo = TeaserSPTypes.getTeaserInfo(this.TeaserTypeID);
        var points = teaserTypeInfo && selection.BetTypeID == 3 && selection.BetSide == 1 ?
            selection.Points - teaserTypeInfo.PointsToReduce : selection.Points + teaserTypeInfo.PointsToReduce;
        return points || 0;
    }

}, TTeaserSPSlipMode);

//*************************************************************************************************************************
TeaserSPSlipMode.CheckForTeaser = function (viewKey, cbox)
{
    if (BetSlip.CurrentMode instanceof TeaserSPSlipMode)
    {
        var teaserMode = BetSlip.CurrentMode;

        if (cbox.checked)
        {
            var err = BetSlip.CurrentMode.getTeaserAddError(BetSlip.Selections[viewKey]);
            if (err)
            {
                Array.removeAll(teaserMode.SelectionsInTeaser, function (el) { el == viewKey; });

                cbox.checked = false;
                teaserMode.updateView();
                return;
            }

            if (Array.indexOf(teaserMode.SelectionsInTeaser, viewKey) == -1)
            {
                teaserMode.SelectionsInTeaser.push(viewKey);

                // Remove message "You need to make at least 2 selections applicable for teaser to place a bet!" if now there are two
                if (teaserMode.CurrentError == $dict.bs("SelectionsInTeaser") && Array.getLength(teaserMode.SelectionsInTeaser) >= 2)
                {
                    teaserMode.CurrentError = false;
                }
            }

            teaserMode.updateView();
        }
        else
        {
            Array.removeAll(teaserMode.SelectionsInTeaser, function (el) { return el == viewKey; });
        }

        BetSlip.updateBSFooter();
        BetSlip.saveState();
        teaserMode.updateView();
    }

    return false;
};

TeaserSPSlipMode.SetTeaserMaxBet = function ()
{
    if (BetSlip.CurrentMode instanceof TeaserSPSlipMode)
    {
        if (BetSlip.CurrentMode.IsBetInProcess)
            return;

        var teaserMaxBet = BetSlip.CurrentMode.getTeaserMaxBet(),
            teaserStakebox = document.getElementById("stakebox_teaser");

        if (teaserStakebox)
        {
            teaserStakebox.value = BetSlip.getStakeBoxValue(teaserMaxBet);
            BetSlip.CurrentMode.setStake(teaserStakebox);
        }
    }
};

TeaserSPSlipMode.OptionChanged = function (element)
{

    element = element || document.getElementById('teaserSelect');
    if ((BetSlip.CurrentMode instanceof TeaserSPSlipMode || BetSlip.CurrentMode instanceof UKSPSlipMode) && element)
    {
        var selectedType = element.value;
        BetSlip.CurrentMode.TeaserTypeID = selectedType;
        BetSlip.CurrentMode.updateView(true);
        BetSlip.saveState();
        BetSlip.updateBSFooter();
    }
};

TeaserSPSlipMode.setFreeBet = function (select)
{
    var mode = BetSlip.CurrentMode;
    if (typeof TeaserSPSlipMode != "undefined" && !(mode instanceof TeaserSPSlipMode)) return;

    var bonusID = select.value * 1;

    var freeBet = UserInfo.current.FreeBets[bonusID];
    if (freeBet)
    {
        if (freeBet.IsAllowedForTeaserBet(mode.getSelectionsInTeaser(), mode.getOdds()))
        {
            mode.FreeBet = freeBet;
            mode.setFreeBetStake(freeBet);
        }
    }
    else if (bonusID == 0)
    {
        mode.FreeBet = null;
        mode.setFreeBetStake();
    }

    BetSlip.updateView();
    BetSlip.updateBSFooter();
    BetSlip.saveState();
};

TeaserSPSlipMode.setDefaultTeaserOptionValue = function (options)
{
    var isCurrentInOptions = Array.find(options, function (option) { return BetSlip.CurrentMode.TeaserTypeID === option.ID.toString(); });
    if (!isCurrentInOptions) {
        var value = Array.first(options);
        BetSlip.CurrentMode.TeaserTypeID = value.ID.toString();
        BetSlip.CurrentMode.updateView(true);
        BetSlip.saveState();
        BetSlip.updateBSFooter();      
    }
};

//*************************************************************************************************************************
function TeaserSPType(id, name, numberOfItems, basicOdds, pointsToReduce, groupID)
{
    this.ID = id;
    this.Name = name;
    this.NumberOfItems = numberOfItems;
    this.BasicOdds = basicOdds;
    this.PointsToReduce = pointsToReduce;
    this.GroupID = groupID;
}

function TeaserSPGroup(id, leagues)
{
    this.ID = id;
    this.Leagues = leagues;
}

var TeaserSPTypes =
{
    Types: [],
    Groups: [],

    init: function ()
    {
        PageMethods.GetTeaserData(
		  -1, // all
		  function (res)
		  {
		      TeaserSPTypes.parse(eval(res));
		  },
		  function (res)
		  {

		  });
    },

    parse: function (data)
    {
        TeaserSPTypes.Types = [];

        for (var key in data.Types)
        {
            var typeData = data.Types[key];

            var type = new TeaserSPType(typeData.TypeID, typeData.TypeName, typeData.NumOfItems, typeData.BasicOdds, typeData.PointsToReduce, typeData.GroupID);
            TeaserSPTypes.Types[type.ID] = type;
        }

        for (var key in data.Groups)
        {
            var groupData = data.Groups[key];

            var group = new TeaserSPGroup(groupData.GroupID, groupData.Leagues);
            TeaserSPTypes.Groups[group.ID] = group;
        }
    },

    getTeaserInfoForLeagueAndNumberOfLines: function (leagueID, numberOfLines)
    {
        // find group ID
        var group = Array.find(TeaserSPTypes.Groups, function (el) { return Array.indexOf(el.Leagues, leagueID) >= 0; });
        var teaserOptions = [];
        if (group)
        {
            teaserOptions =  Array.findAll(TeaserSPTypes.Types, function (el) { return el.NumberOfItems == numberOfLines && el.GroupID == group.ID; });
        }

        TeaserSPSlipMode.setDefaultTeaserOptionValue(teaserOptions);

        return teaserOptions;
    },

    getTeaserInfo: function (id)
    {
        return Array.find(TeaserSPTypes.Types, function (el) { return el.ID == id; });
    },

    getTeaserName: function (teaserTypeInfo)
    {
        return "{0}, {1} {2}".format(teaserTypeInfo.Name, PointsFormat.format(teaserTypeInfo.PointsToReduce), $dict.bs("Points"));
    }
};

window.TeaserSPSlipMode = TeaserSPSlipMode;
window.TeaserSPType = TeaserSPType;
window.TeaserSPGroup = TeaserSPGroup;
window.TeaserSPTypes = TeaserSPTypes;

includeExtension("/JSComponents/Data/UniSlip/Modes/TeaserSPSlipMode.ext.js");
