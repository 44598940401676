﻿var TSPComboNoCombinationPurchase = (function (base)
{
    "use strict";
    var api = $.extend({}, base); // use 'api' only to define extension properties and always 'this' inside functions

    // Implements TComboPurchase.getBets
    api.getBets = function ()
    {
        return this.getSortedBets();
    };

    return api;
}(TComboPurchase));

window.TSPComboNoCombinationPurchase = TSPComboNoCombinationPurchase;