﻿function SPPurchase(selections, bets, status, viewKey, purchaseTypeID, systemKey, alternativeOddsStyle, purchaseID, waitingBetID, bonusAmountStake) {
    this.IsNewSPPurchase = true;

    this.ViewKey = viewKey;
    this.PurchaseStatus = status;
    this.Status = status;
    this.Final = ((status == SPPurchaseStatus.Accepted) || (status == SPPurchaseStatus.Declined));
    if (typeof expandedPurchase != "undefined" && expandedPurchase === true) {
        this.Expanded = true;
    }
    else {
        this.Expanded = false;
    }
    this.PurchaseTypeID = purchaseTypeID;
    this.SystemKey = systemKey;
    this.alternativeOddsStyle = alternativeOddsStyle;
    this.PurchaseID = purchaseID;
    this.WaitingBetID = waitingBetID;
    this.BonusAmountStake = bonusAmountStake * 1;
    this.ExternalTicketID = "";
    this.ExternalTimestamp = new Date();

    this.Selections = [];

    for (var i in selections)
        this.Selections.push(selections[i]);

    for (var i in this.Selections)
        this.Selections[i].SPIndex = i;

    this.Bets = [];

    for (var i in bets) {
        var bet = bets[i];
        bet.Purchase = this;
        this.Bets.push(bet);
    }

    for (var i in this.Bets)
        this.Bets[i].SPIndex = i;

    this.Multiline = (Array.getLength(this.Bets) > 1);
    this.ContainsPermutations = this.Multiline;
    this.IsClientDecline = false;

    //used in Teaser
    this.Bet = this.Bets[0];
}

//*************************************************************************************************************************
function SPComboBet(bet, status, waitingBetID)
{
    SPBaseBet.call(this);
    this.BetType = bet.BetTypeID && bet.BetTypeID === BetTypes.Combinator ? BetTypes.Combinator : 2;
    this.IsTeaser = false;
    this.FreeBetID = bet.FreeBetID;
    this.BetSize = bet.ComboSize;
    this.BetsCount = bet.NumberOfBets;
    this.BetName = bet.BetName;
    this.Deposit = bet.Stake;
    this.FreeBetStake = bet.FreeBetStake;
    this.Status = status;
    this.BetID = bet.BetID;
    this.Final = ((status == SPPurchaseStatus.Accepted) || (status == SPPurchaseStatus.Declined));
    this.IsEWBet = bet.IsEachWay;
    this.MaxBet = bet.MaxBet;
    this.IsLive = bet.IsLive;
    this.VariationType = bet.ComboSize;
    this.Odds = bet.Odds;
    this.Rate = bet.ClientOdds ? bet.ClientOdds : Odds.convertFromAmerican(this.Odds, OddStyle.EUROPEAN);
    this.Gain = bet.Gain;
    this.RowID = null;
    this.FreeBet = BetSlip.CurrentMode.FreeBets ? BetSlip.CurrentMode.FreeBets[bet.ComboSize] : null;

    this.Mappings = bet.Mappings;
    this.LoyaltyPointsPromotions = bet.LoyaltyPointsPromotions;
    this.EWLoyaltyPointsPromotions = bet.EWLoyaltyPointsPromotions;
    this.updateStatus(waitingBetID);
}

window.SPPurchase = SPPurchase;
window.SPComboBet = SPComboBet;