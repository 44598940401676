﻿var TSPPurchase = (function ()
{
    "use strict";
    var api = {}, map;

    var initTypeMap = function ()
    {
        if (!map)
        {
            map = {};

            map[SPPurchaseType.Combo] = TSPComboPurchase;
            map[SPPurchaseType.StraightCombo] = TSPComboNoCombinationPurchase;
            map[SPPurchaseType.Single] = TSinglePurchase;
            map[SPPurchaseType.System] = TSPSystemPurchase;
            map[SPPurchaseType.Teaser] = TTeaserPurchase;
            map[SPPurchaseType.CashOutSingle] = TSPCashOutSinglePurchase;
            map[SPPurchaseType.CashOutCombo] = TSPCashOutSinglePurchase;
            map[SPPurchaseType.UKPurchase] = TSPUKPurchase;
            map[SPPurchaseType.Combinator] = TSPCombinatorPurchase;
        }

        return map;
    };

    var callFromType = function (funcName, thisArg, args)
    {
        var typeFunc = initTypeMap()[thisArg.PurchaseTypeID][funcName];
        if (typeof typeFunc === "function")
        {
            return typeFunc.apply(thisArg, args);
        }

        return null;
    };

    // Override for updateView function to redraw the whole BetSlip view instead of using buildInnerView for the currently updated purchase
    api.updateView = function ()
    {
        callFromType("updateView", this, arguments);
    };

    // Required by TPurchases.js
    api.getTitle = function ()
    {
        return callFromType("getTitle", this, arguments);
    };

    // Required by TPurchases.js
    api.getLinesDescription = function ()
    {
        return callFromType("getLinesDescription", this, arguments);
    };

    // Required by *Purchase.js functions
    api.getStatusClass = function ()
    {
        return callFromType("getStatusClass", this, arguments);
    };

    // Required by TComboPurchase.js
    api.updateContext = function (context)
    {
        return callFromType("updateContext", this, arguments) || context;
    };

    // Required by TSinglePurchase.js
    api.getBets = function ()
    {
        return callFromType("getBets", this, arguments) || this.Bets;
    };

    return api;
}());

var TSPCashOutPurchase =(function ()
{
    "use strict";
    var api = {};
    api.buildInnerViewSingle = function ()
    {
        return null;
    };

    api.buildInnerViewCombo = function ()
    {
        return null;
    };

    return api;
}());

window.TSPPurchase = TSPPurchase;
window.TSPCashOutPurchase = TSPCashOutPurchase;