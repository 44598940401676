﻿function BetSlipErrorMessagesHelper()
{
    var currentInstance = this;

    this.eStatuses = {
        ScoreChanged: -504,
        PointsChanged: -4,
        OddsChanged: -1
    }

    this.SetBetSlipErrorMessage = function (a_result)
    {
        var result = a_result[0];
        var errorText = result.ErrorText;;

        switch (result.Status)
        {
            case -1:
                errorText = getNotEnoughFundsErrorMsgOrDefault(result);
                break;
            case -2:
                errorText = $dict.bs("LinesWereClosed");
                break;
            case -10:
                errorText = getGeneralErrorMsg();
                break;
            case -706:
                errorText = getNotEnoughFundsOrMinimalBetErrorMsg(a_result, result);
                break;
            case -1006:
                errorText = getMaxWinForPurchaseErrorMsg(result);
                break;
        }

        return errorText;
    };

    this.SetSingleBetSlipErrorMessage = function (postResult, selection)
    {
        var errorText = postResult.ErrorText;

        switch (postResult.Status)
        {
            case -2:
                errorText = $dict.bs("LineClosed");
                setSelectionErrorMsg(selection);
                break;
            case -3:
                errorText = $dict.bs("NotEnoughFunds");
                break;
            case -10:
                errorText = getGeneralErrorMsg();
                break;
            case -20:
                errorText = $dict.bs("ErrorWait");
                break;
            case -100:
                errorText = $dict.bs("CommunicationError");
                break;
            case -502:
                errorText = currentInstance.getMaxStakeForItemErrorMsg(postResult, selection);
                break;
            case -503:
                errorText = $dict.bs("ErrorLineDanger");
                break;
            case currentInstance.eStatuses.ScoreChanged:
                errorText = $dict.bs("ScoreChanged");
                break;
            case -620:
                errorText = $dict.bs("ErrorWaitLive");
                break;
            case -1006:
                errorText = getMaxWinForPurchaseErrorMsg(postResult);
                break;
        }

        return errorText;
    }

    this.ShouldSelectionUpdatedAlertBeShown = function(selection)
    {
        var isSelectionValid = !(selection.Live && (selection.Danger || selection.LineClosed));

        var isSelectionUpdated = selection.UpdatedOdds || selection.UpdatedPoints || selection.UpdatedScore;

        var isErrorStatusForUpdate = selection.LastStatus == currentInstance.eStatuses.OddsChanged
            || selection.LastStatus == currentInstance.eStatuses.PointsChanged
            || selection.LastStatus == currentInstance.eStatuses.ScoreChanged;

        var isErrorMessageValid = isErrorStatusForUpdate && BetSlip.CurrentMode.Errors[selection.ViewKey];

        return BetSlip.isAlertMessageEnabled && isSelectionValid && (isSelectionUpdated || isErrorMessageValid);
    }

    this.AlertIfSelectionUpdated = function (selection)
    {
        if (!currentInstance.ShouldSelectionUpdatedAlertBeShown(selection))
        {
            return false;
        }

        var errors = [];

        var preiousSelection = $.extend({}, selection);
        preiousSelection.Odds = selection.PreviousOdds;
        preiousSelection.Points = selection.PreviousPoints;

        selection.UpdatedOdds
            ? errors.push($dict.bs("UpdatedExactOddsAlert").format(preiousSelection.getFormatedOdds(), selection.getFormatedOdds()))
            : selection.LastStatus == currentInstance.eStatuses.OddsChanged && errors.push($dict.bs("UpdatedOddsAlert"));

        selection.UpdatedPoints
            ? errors.push($dict.bs("UpdatedExactPointsAlert").format(preiousSelection.getPointsToShow(), selection.getPointsToShow()))
            : selection.LastStatus == currentInstance.eStatuses.PointsChanged && errors.push($dict.bs("UpdatedPointsAlert"));

        (selection.UpdatedScore || selection.LastStatus == currentInstance.eStatuses.ScoreChanged) && errors.push($dict.bs("UpdatedScoreAlert"));

        if(errors.length > 0)
        {
            BetSlip.CurrentMode.setBetInProcess(false, true);
            alert(errors.join("\n"));
        }
    }

    this.getMaxStakeForItemErrorMsg = function (postResult, selection)
    {
        var maxBet = postResult.maxBet;
        if (selection && !BetTypes.IsForeCast(postResult.BetTypeID) && !BetTypes.IsTriCast(postResult.BetTypeID))
        {
            maxBet = selection.isToWin() && !BetSlip.EnableAsianStyleMinMax ? selection.getToWinFromDeposit(selection.maxBet) : selection.maxBet;
        }

        var formattedMaxBet = BetSlip.formatMoneyWithRounding(maxBet);

        return $dict.bs("MaxStakeForItem").format(formattedMaxBet);
    }

    this.getUKMaxStakeForItemErrorMsg = function (postResult, selection)
    {
        var errorMsg = "";
        if (selection)
        {
            errorMsg = currentInstance.getMaxStakeForItemErrorMsg(postResult, selection);
        }
        else
        {
            if (postResult.BetTypeID == BetTypes.Teaser || postResult.BetTypeID == BetTypes.VirtualTeaser)
            {
                errorMsg = $dict.bs("MaxStakeForTeaser").format(BetSlip.formatMoneyWithRounding(postResult.maxBet));
            }
            else
            {
                errorMsg = $dict.bs("MaxStakeForMultiples").format(BetSlip.formatMoneyWithRounding(postResult.maxBet));
            }
        }

        return errorMsg;
    }

    this.getRegulationMinBetStakeErrorMsg = function (postResult)
    {
        var errorMsg = "";

        if (BetTypes.IsCombo(postResult.BetTypeID) && postResult.Regulations.MinimumComboBetStake)
        {
            errorMsg = $dict.bs("RegulationMinComboBetStake").format("{0}{1}".format(postResult.userCurrency, BetSlip.formatMoneyWithRounding(postResult.Regulations.MinimumComboBetStake)));
        }
        else if (BetTypes.IsSystem(postResult.BetTypeID) && postResult.Regulations.MinimumSystemBetStake)
        {
            errorMsg = $dict.bs("RegulationMinSystemBetStake").format("{0}{1}".format(postResult.userCurrency, BetSlip.formatMoneyWithRounding(postResult.Regulations.MinimumSystemBetStake)));
        }
        else if (BetTypes.IsSingle(postResult.BetTypeID) && postResult.Regulations.MinimumSingleBetStake)
        {
            errorMsg = $dict.bs("RegulationMinSingleBetStake").format("{0}{1}".format(postResult.userCurrency, BetSlip.formatMoneyWithRounding(postResult.Regulations.MinimumSingleBetStake)));
        }
        else if (BetTypes.IsCombinator(postResult.BetTypeID) && postResult.Regulations.MinimumCombinatorBetStake)
        {
            errorMsg = $dict.bs("RegulationMinCombinatorBetStake").format("{0}{1}".format(postResult.userCurrency, BetSlip.formatMoneyWithRounding(postResult.Regulations.MinimumCombinatorBetStake)));
        }
        else
        {
            errorMsg = $dict.bs("RegulationMinBetStake").concat(" {0}".format(BetSlip.formatMoneyWithRounding(postResult.Regulations.MinimumBetStake)));
        }

        return errorMsg;
    }

    var setSelectionErrorMsg = function (selection)
    {
        if (selection.Live)
        {
            selection.Valid = false;
            selection.LineClosed = true;
            selection.Error = $dict.bs("LineClosed");
        }
    }
    
    var getGeneralErrorMsg = function ()
    {
        return $dict.bs("ErrorGeneral");
    }

    var getMaxWinForPurchaseErrorMsg = function (result)
    {
        return $dict.bs("MaxWinForPurchase").format(MoneyFormat.format(result.maxWin), result.userCurrency);
    }

    var getNotEnoughFundsErrorMsgOrDefault = function (result)
    {
        var errorText;

        if (result.ErrorText === "Not enough funds")
        {
            errorText = $dict.bs("NotEnoughFunds");
        }
        else
        {
            errorText = result.ErrorText;
        }

        return errorText;
    };

    var getNotEnoughFundsOrMinimalBetErrorMsg = function (a_result, result)
    {
        var errorText = result.ErrorText;

        if (a_result[2] && a_result[2][0])
        {
            var res = a_result[2][0];

            switch (res.Status)
            {
                case -3:
                    errorText = $dict.bs("NotEnoughFunds");
                    break;
                case -501:
                    errorText = $dict.bs("MinimalBet").concat(" " + MoneyFormat.format(res.minBet));
                    break;
            }
        }

        return errorText;
    };
}

window.BetSlipErrorMessagesHelper = BetSlipErrorMessagesHelper;