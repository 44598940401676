var ComboBonusProvider = (function()
{
    var ComboBonuses = {};
    var RecalculatedComboBonuses = {};

    var BonusMessages = {
        AllLinesGetBonus : 1,
        NotAllLineGetBonus : 2,
        AllLinesGetBonusBetween : 3,
        NotLinesGetBonusBetween : 4
    };

    function setComboBonuses(data)
    {
        ComboBonusProvider.ComboBonuses = data;
    };

    function addRecalculatedComboBonuses(comboBonuses)
    {
        if (comboBonuses) {
            Object.assign(BetSlip.RecalculatedComboBonuses, comboBonuses.Folds);
        } 
    }

    function clearRecalculatedComboBonuses()
    {
        BetSlip.RecalculatedComboBonuses = {};
    }

    function addRecalculatedComboBonuses(comboBonuses)
    {
        if (comboBonuses) {
            Object.assign(this.RecalculatedComboBonuses, comboBonuses.Folds);
        } 
    }

    function clearRecalculatedComboBonuses()
    {
        this.RecalculatedComboBonuses = {};
    }

    return {
        ComboBonuses : ComboBonuses,
        RecalculatedComboBonuses : RecalculatedComboBonuses,
        BonusMessages : BonusMessages,
        setComboBonuses: setComboBonuses,
        addRecalculatedComboBonuses: addRecalculatedComboBonuses,
        clearRecalculatedComboBonuses: clearRecalculatedComboBonuses
    };
}());

window.ComboBonusProvider = ComboBonusProvider;