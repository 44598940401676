﻿var BaseSplitType = (function (api) {
    "use strict";
    api.getLineEventTypeName = function (selection) {        
        var curLineTypeName = (selection.LineTypeName && selection.LineTypeName + " ") || "";
        return curLineTypeName + selection.EventTypeName;
    };

    return api;
})(BaseSplitType || {});

window.BaseSplitType = BaseSplitType;