﻿$.extend(FastMarket.prototype,
{
    buildInterval: function (start,end, id, viewKey, isUpdated, isForSplitEventTitle)
    {
        var idAttr = typeof viewKey != "undefined" ? ' id="' + id + viewKey + '"' : "";
        var classAttr = isUpdated ? (" class='updated'") : "";

        return isForSplitEventTitle 
            ? " <span{0}{1}>{2}:00 - {3}:59</span>".format(idAttr, classAttr, start, end)
            : " <span{0}{1}>({2}:00-{3}:59)</span>".format(idAttr, classAttr, start, end) ;
    },

    buildEventTypeTitle: function (lines, eventTypeName)
    {
        var res = this.calculateFastMarketInterval(lines);

        return eventTypeName + this.buildInterval(res[0], res[1]);
    },

    buildEventTypeTitleSplit: function (lines, eventTypeName)
    {
        var res = this.calculateFastMarketInterval(lines);

        return {eventTypeName: eventTypeName, intervalName: this.buildInterval(res[0], res[1], null, null, null, true)};
    },

    buildPickLine: function (qaLine)
    {
        var intervalPoints = this.getFastMarketInterval(qaLine.QAParameter2);
        var lineName = this.ViewBuilder.buildPickLineName(qaLine);
        var isGameIntervalUpdated = false;

        if (BetSlip && BetSlip.CurrentMode && typeof BetSlip.CurrentMode.isSelectionPropUpdated == "function" && qaLine)
        {
            isGameIntervalUpdated = BetSlip.CurrentMode.isSelectionPropUpdated(qaLine.ViewKey, BetSlipSelectionPropCasesWithBlinking.GameInterval);
        }
        else
        {
            isGameIntervalUpdated = qaLine && qaLine.UpdatedGameInterval;
        }

        return lineName + this.buildInterval(intervalPoints[0], intervalPoints[1], "cGameInterval", qaLine && qaLine.ViewKey, isGameIntervalUpdated);
    },

    buildEventNameForBet: function (line, eventTypeName)
    {
        return this.ViewBuilder.buildEventNameForBet(line, eventTypeName)
    },

    buildWebClientInnerView: function (event, __html, _br, __me)
    {
        var lls = Array.getFlatValues(event.Lines);

        this.ViewBuilder.buildInnerView(event, __html, _br, __me, lls);
    }
});
