﻿function SplitTypeFactory() {

}
SplitTypeFactory.Create = function (splitType) {

    if (isTennisFastMarket(splitType))
    {
        return new TennisFastMarket(splitType);
    }

    if (isFastMarket(splitType))
    {
        return new FastMarket(splitType);
    }

    /* globals SplitType4,SplitType6,SplitType8,SplitType9 */
    switch (splitType) {
        case 4:
            return SplitType4;
        case 8:
            return SplitType8;
        case 9:
            return SplitType9;
        case 6:
            return SplitType6;
        case 7:
            return SplitType7;
        case 0:
        case 2:           
        case 3:                  
        case 5:                              
        case 21:
        case 22:
        case 23:
        case 24:
        case 25:
        case 26:
        case 27:
        case 28:
        case 29:
        case 30:
        case 31:
        case 99:
            return BaseSplitType;
        case 1301:
        case 1305:
        case 1401:
        case 1405:
        case 1501:
        case 1505:
        case 2301:
        case 2305:
        case 2401:
        case 2405:
        case 2501:
        case 2505: {
            return new FastMarket(splitType);
        }
        case 300004:
        case 300104:
        case 400003:
        case 400004:
        case 401024: {
            return new TennisFastMarket(splitType);    
            }               
        default:
            return BaseSplitType;
    }
}

window.SplitTypeFactory = SplitTypeFactory;