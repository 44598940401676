﻿var TSPComboBet = (function ()
{
    "use strict";
    var api = {}; // use 'api' only to define extension properties and always 'this' inside functions

    // Override TComboBet.getVariationSummary
    api.getVariationSummary = function (context)
    {
        return context.Purchase.calculateVariationSummary(this);
    };

    // Required by TPurchases.js
    api.skip = function (context) {
        return Purchases.skipBet(this, context);
    };

    // Required by TPurchases.js
    api.getDeposit = function (context) {
        return Purchases.getComboBetDeposit(this, context);
    };

    // Required by TPurchases.js
    api.getBetTitle = function () {
        return Purchases.getBetTitle(this);
    };

    // Required by TPurchases.js
    api.getOdds = function (context) {
        if (context.HasSP) {
            return $dict.bs("NotAvailable");
        }

        return Purchases.getComboBetOdds(this, context);
    };

    // Required by TPurchases.js
    api.getReturn = function (context) {
        return Purchases.getComboBetReturn(this, context);
    };

    // Required by TPurchases.js
    api.isDepositUpdated = function (context) {
        return context.VariationSummary.DepositUpdated;
    };

    // Required by TPurchases.js
    api.getDescription = function (context) {
        return Purchases.getComboBetDescription(this, context);
    };

    // Required by TPurchases.js
    api.getStatusClass = function () {
        return Purchases.getBetStatusClass(this);
    };

    return api;
}());

window.TSPComboBet = TSPComboBet;