﻿var BetSlipTemplateConroller = (function () {
    var ComboBonusMessageTemplates =
    {
        1: 'buildRegularComboBonusMessageHTML',
        2: 'buildResponsiveComboBonusMessageHTML'
    };

    var SingleOptionsTemplates =
    {
        1: 'buildRegularSingle',
        2: 'buildResponsiveSingle'
    };

    var LoadingBoxTemplates =
    {
        1: 'createRegularLoadingBox',
        2: 'createResponsiveLoadingBox'
    };

    var MessageTemplates =
    {
        1: 'createRegularMessage',
        2: 'createResponsiveMessage'
    };

    var OptionsMessageTemplates =
    {
        1: 'createRegularOptionsMessage',
        2: 'createResponsiveOptionsMessage'
    };

    var UKEachWayTitleTemplates =
    {
        1: 'buildRegularUKEWTitle',
        2: 'buildResponsiveUKEWTitle'
    };

    var ComboBonusIconTemplates =
    {
        1: 'buildRegularComboBonusIcon',
        2: 'buildResponsiveComboBonusIcon'
    };

    function getLineTypeTemplate(templateType) {
        var LineTypeTemplates =
        {
            1: TRegularLineType,
            2: TResponsiveLineType
        };

        return LineTypeTemplates[templateType || CONSTANTS.BetSlipTemplateOptions.REGULAR];
    }

    function getMultiOptionsTemplate(templateType) {
        var MultiOptionsTemplates =
        {
            1: TRegularMultiOptions,
            2: TResponsiveMultiOptions
        };

        return MultiOptionsTemplates[templateType || CONSTANTS.BetSlipTemplateOptions.REGULAR];
    }

    function getPurchasesTemplate(templateType) {
        var PurchasesTemplates =
        {
            1: TRegularPurchases,
            2: TResponsivePurchases
        };

        return PurchasesTemplates[templateType || CONSTANTS.BetSlipTemplateOptions.REGULAR];
    }

    function getComboBonusMessageTemplate(templateType) {
        return ComboBonusMessageTemplates[templateType || CONSTANTS.BetSlipTemplateOptions.REGULAR];
    }

    function getSingleOptionsTemplate(templateType) {
        return SingleOptionsTemplates[templateType || CONSTANTS.BetSlipTemplateOptions.REGULAR];
    }

    function getLoadingBoxTemplate(templateType) {
        return LoadingBoxTemplates[templateType || CONSTANTS.BetSlipTemplateOptions.REGULAR];
    }

    function getMessageTemplate(templateType) {
        return MessageTemplates[templateType || CONSTANTS.BetSlipTemplateOptions.REGULAR];
    }

    function getOptionsMessageTemplate(templateType) {
        return OptionsMessageTemplates[templateType || CONSTANTS.BetSlipTemplateOptions.REGULAR];
    }

    function getUKEachWayTitleTemplate(templateType) {
        return UKEachWayTitleTemplates[templateType || CONSTANTS.BetSlipTemplateOptions.REGULAR];
    }

    function getComboBonusIconTemplate(templateType) {
        return ComboBonusIconTemplates[templateType || CONSTANTS.BetSlipTemplateOptions.REGULAR];
    }

    return {
        getLineTypeTemplate: getLineTypeTemplate,
        getMultiOptionsTemplate: getMultiOptionsTemplate,
        getComboBonusMessageTemplate: getComboBonusMessageTemplate,
        getSingleOptionsTemplate: getSingleOptionsTemplate,
        getPurchasesTemplate: getPurchasesTemplate,
        getLoadingBoxTemplate: getLoadingBoxTemplate,
        getMessageTemplate: getMessageTemplate,
        getOptionsMessageTemplate: getOptionsMessageTemplate,
        getUKEachWayTitleTemplate: getUKEachWayTitleTemplate,
        getComboBonusIconTemplate: getComboBonusIconTemplate
    };
})();

window.BetSlipTemplateConroller = BetSlipTemplateConroller;