$.extend(window.Modes, (function (uniSlip)
{
    "use strict";
    var api = {};

    api.isNBS = function ()
    {
        return uniSlip.UseNewBettingStructure;
    };

    // TSlipShared.js - createSingleStakeBox
    api.isSingleMode = function (mode)
    {
        return (typeof SingleSPSlipMode != "undefined" && mode instanceof SingleSPSlipMode) ||
               (typeof SingleSlipMode != "undefined" && mode instanceof SingleSlipMode) ||
               (typeof UKSPSlipMode != "undefined" && mode instanceof UKSPSlipMode) ||
               (typeof ComboNoCombinationSingleSPSlipMode != "undefined" && mode instanceof ComboNoCombinationSingleSPSlipMode &&
               (mode.isSingleMode() || mode.PurchaseTypeID === SPPurchaseType.Single));
    };

    api.isCombinatorMode = function (mode) {
        return typeof CombinatorSPSlipMode != "undefined" && mode instanceof CombinatorSPSlipMode;
    };

    api.isComboNoCombinationMode = function (mode) {
        return typeof ComboNoCombinationSPSlipMode != "undefined" && mode instanceof ComboNoCombinationSPSlipMode;
    };

    api.isComboNoCombinationSingleMode = function (mode)
    {
        return typeof ComboNoCombinationSingleSPSlipMode != "undefined" && mode instanceof ComboNoCombinationSingleSPSlipMode;
    };

    api.isTeaserMode = function (mode)
    {
        return typeof TeaserSPSlipMode != "undefined" && mode instanceof TeaserSPSlipMode;
    };

    // Single mode "Request Higher Amount" message check
    function isValidRequest(selections)
    {
        var keys = Object.keys(selections),
            i = keys.length;

        while (i > 0)
        {
            i -= 1;
            if (selections[keys[i]].checkForRequest()) { return true; }
        }

        return false;
    }

    // Multi mode "Request Higher Amount" message check
    function hasNoLiveInProgressItems(mode, selections)
    {
        return !(mode.isLiveCombo && mode.isLiveCombo()) &&
            !mode.IsBetInProcess &&
            Object.keys(selections).length > 1;
    }

    // Generic "Request Higher Amount" message check
    api.showRequestAmount = function (mode, selections)
    {
        if (api.isSingleMode(mode))
        {
            return isValidRequest(selections);
        }

        return hasNoLiveInProgressItems(mode, selections);
    };

    function hasTwoNonCastSelections(selections)
    {
        var count = 0,
            keys = Object.keys(selections),
            i = keys.length;

        while (i > 0)
        {
            i -= 1;
            if (!selections[keys[i]].CastSelection || selections[keys[i]].VisibleCastSelection) { count += 1; }
            if (count >= 2) { return true; }
        }

        return false;
    }

    // TSingleOptions.js - buildSingle
    api.showAllStake = function (mode, selections)
    {
        if (Object.keys(mode.Deposits).length === 0) { return false; }

        return hasTwoNonCastSelections(selections);
    };

    // TMultiOptions.js - buildComboInner
    api.getVariationBetsCount = function (mode, variantKey, ewChecked)
    {
        if (api.isNBS())
        {
            if (mode.SelectedEWVariants[variantKey] && mode.EWVariants[variantKey])
            {
                return mode.Variants[variantKey] + mode.EWVariants[variantKey];
            }

            return mode.Variants[variantKey];
        }

        // OBS
        return ewChecked && mode.SelectedVariants[variantKey] ?
            mode.Variants[variantKey].length:
            mode.Variants[variantKey].length;
    };

    // TMultiOptions.js - buildComboInner
    var showCombinations = function (mode)
    {
        return !(mode.isLiveCombo() || mode.ComboNoCombinations);
    };

    // TMultiOptions.js - buildComboInner
    api.showCombinations = function (mode)
    {
        return api.isNBS() || showCombinations(mode);
    };

    // TCombo - buildOptions
    api.calcComboVariants = function (mode)
    {
        mode.calcVariants();
        if (mode.ComboNoCombinations)
        {
            clearDepositsForComboNoCombinations(mode);
        }
    };

    var clearDepositsForComboNoCombinations = function (mode)
    {
        var vl = Array.getLength(mode.Variants);
        /*jshint camelcase: false */
        for (var key = 2; key < (vl - 1) && key <= uniSlip.maxSelectionsNumber; key = key + 1)
        {
            mode.setDeposit(key, 0);
        }
        /*jshint camelcase: true */
    };

    /*global currentOddStyle */
    function getConcreteMultiOdds(mode)
    {
        var oddStyle = mode.getAlternativeOddsStyle() || currentOddStyle;
        return Odds.format(mode.getOdds(oddStyle), oddStyle);
    }

    // TMultiOptions.js - buildComboInner
    api.getMultiOdds = function (mode)
    {
        var selections = mode.getSelectionsInCombo(),
            keys = Object.keys(selections), i = keys.length;

        /*globals $dict */
        while (i > 0)
        {
            i -= 1;
            if (selections[keys[i]].isSPSelection()) { return $dict.bs("NotAvailable"); }
        }

        return getConcreteMultiOdds(mode);
    };

    // TMultiOptions.js - createMultiEachwayBox
    api.isMultiEWVariantChecked = function (mode, key)
    {
        if (api.isNBS())
        {
            return mode.SelectedEWVariants[key];
        }

        // OBS
        return mode.SelectedVariants[key];
    };

    // TMultiOptions.js - buildComboInner
    api.getVariantsStartIndex = function (mode)
    {
        if (api.isNBS())
        {
            return mode.ComboNoCombinations ? 
                getStartIndexComboNoCombinations(mode.Variants) : 
                this.isCombinatorMode(mode) ? 1 : 2;
        }

        return 0;
    };

    var getStartIndexComboNoCombinations = function (variants) 
    {
        var variantsLength = Object.keys(variants).length;
        return variantsLength - 1; 
    };
    
    // UniSlipBlock.js - setComboStake
    api.setComboStake = function (viewKey)
    {
        if (api.isNBS())
        {
            /*globals IsUKBetSlip */
            if (IsUKBetSlip)
            {
                /*globals rebuildUKVariantStakeSP */
                rebuildUKVariantStakeSP(viewKey);
            }
            else
            {
                /*globals rebuildVariantStakeSP */
                rebuildVariantStakeSP(viewKey);
            }
        }
        else
        {
            /*globals rebuildVariantStake */
            rebuildVariantStake(viewKey);
        }
    };

    // UniSlipBlock.js - setSystemStake
    api.setSystemStake = function (viewKey)
    {
        if (api.isNBS())
        {
            /*globals IsUKBetSlip */
            if (IsUKBetSlip)
            {
                /*globals rebuildUKSystemStake */
                rebuildUKSystemStake(true, viewKey);
            }
            else
            {
                /*globals rebuildSystemStake */
                rebuildSystemStake(true);
            }
        }
    };

    // TMultiOptions.js - buildComboInner
    api.showComboNotAllowedMessage = function (mode)
    {
        return mode.showComboNotAllowedMessage && mode.showComboNotAllowedMessage();
    };

    // TSlipShared - createSingleFreeBetDropDown
    api.getAvailableSingleFreeBets = function (mode, selection)
    {
        // Only allow on initialized NBS single bet selections
        if (!(selection.Initialized && api.isNBS() && api.isSingleMode(mode))) { return null; }

        var result = {
            FreeBets: mode.getAvailableFreeBets(selection),
            SelectedID: 0,
            Key: selection.ViewKey
        };

        if (!(result.FreeBets && result.FreeBets.length >= 1))
        {
            selection.FreeBet = null;
            return null;
        }

        if (selection.FreeBet)
        {
            result.SelectedID = selection.FreeBet.BonusID;
        }

        return result;
    };

    // TTeaserOptions - createTeaserFreeBetSelect
    api.getAvailableTeaserFreeBets = function (mode, selections, key)
    {
        // Only allow on NBS teaser bet selections
        if (!(api.isNBS() && api.isTeaserMode(mode))) { return null; }

        var result = {
            FreeBets: mode.getAvailableFreeBets(selections),
            SelectedID: 0,
            Key: key
        };

        if (!result.FreeBets || Array.getLength(result.FreeBets) < 1) 
        {
            mode.FreeBet = null;
            return null;
        }

        if (mode.FreeBet)
        {
            result.SelectedID = mode.FreeBet.BonusID;
        }

        return result;
    };

    // TMultiOptions.js - createMultiFreeBetSelect
    /*jshint maxcomplexity:6*/
    api.getAvailableMultiFreeBets = function (mode, selections, variantKey)
    {
        // Only allow on NBS multi bets with single permutation
        if (!(api.isNBS() && !((api.isSingleMode(mode) && !api.isComboNoCombinationMode(mode) && !api.isComboNoCombinationSingleMode(mode)) || api.isCombinatorMode(mode)))) { return null; }

        //on adding new selection, old selection bonuses should be removed for comboNoCombinationMode and ComboNoCombinationSingleMode
        (api.isComboNoCombinationMode(mode) || api.isComboNoCombinationSingleMode(mode)) && mode.Variants.forEach(function(value,key) {
            mode.Variants[key] != 1 && (mode.FreeBets[key] = null);
        });

        if (mode.Variants[variantKey] != 1)
        {
            mode.FreeBets[variantKey] = null;
            return null;
        }

        var result = {
            FreeBets: mode.getAvailableFreeBets(selections, variantKey),
            SelectedID: 0,
            Key: variantKey
        };

        if (!(result.FreeBets && result.FreeBets.length >= 1))
        {
            mode.FreeBets[variantKey] = null;
            return null;
        }

        if (mode.FreeBet !== null && mode.FreeBet !== undefined && mode.FreeBet.hasOwnProperty(variantKey) && mode.FreeBet[variantKey] !== null)
        {
            result.SelectedID = mode.FreeBet[variantKey].BonusID;
        }
        else if (mode.FreeBets[variantKey] && !(mode.FreeBet !== null && mode.FreeBet !== undefined && mode.FreeBet.hasOwnProperty(variantKey) && (mode.FreeBet[variantKey] === undefined || mode.FreeBet[variantKey] === null)))
        {
            result.SelectedID = mode.FreeBets[variantKey].BonusID;
        }
        return result;
    };

    // TMultiOptions.js - createMultiFreeBetSelect TSlipShared - createSingleFreeBetDropDown
    /*jshint maxparams: 5, maxcomplexity:10*/
    api.getAvailableFreeBetsForUKSlip = function (mode,subMode, selection, selections, variantKey)
    {
        // Only allow on NBS bets
        if (!api.isNBS()) { return null; }

        var result;
        /*globals UKSlipModeSubModes*/
        if (subMode == UKSlipModeSubModes.Single)
        {
            // Only allow on initialized NBS single bet selections
            if (!selection.Initialized) { return null; }

            result =
            {
                FreeBets: mode.getAvailableFreeBets(subMode, selection, null, null, null),
                SelectedID: 0,
                Key: selection.ViewKey
            };

            if (!(result.FreeBets && result.FreeBets.length >= 1))
            {
                selection.FreeBet = null;
                return null;
            }

            if (selection.FreeBet)
            {
                result.SelectedID = selection.FreeBet.BonusID;
            }
        }
        else if (subMode == UKSlipModeSubModes.Combo)
        {
            // Only allow on NBS multi bets with single permutation
            if (mode.Variants[variantKey] != 1) 
            {
                mode.FreeBets[variantKey] = null;
                return null;
            }

            result = 
            {
                FreeBets: mode.getAvailableFreeBets(subMode, null, selections, variantKey, null),
                SelectedID: 0,
                Key: variantKey
            };

            if (!(result.FreeBets && result.FreeBets.length >= 1)) 
            {
                mode.FreeBets[variantKey] = null;
                return null;
            }

            if (mode.FreeBets[variantKey]) 
            {
                result.SelectedID = mode.FreeBets[variantKey].BonusID;
            }
        }

        return result;
    };
    /*jshint maxparams: 3, maxcomplexity:5*/

    // private
    api._isValidRequest = isValidRequest;
    api._hasNoLiveInProgressItems = hasNoLiveInProgressItems;
    api._hasTwoNonCastSelections = hasTwoNonCastSelections;

    /*global BetSlip */
    return api;
}(BetSlip)));
